import React, { useEffect } from "react";
import Header from "../../directories/header";
import Footer from "../../directories/footer";
import terms from "./termsData";

const TermsConditions = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    return (
        <>
            <Header />
            <section className="pt-0">
            <div className="flex flex-col items-center justify-center min-h-screen px-6 bg-gray-100">
      <div className="bg-white max-w-5xl w-full rounded-lg">
        <div className="flex flex-col items-center justify-center p-6">
          <h1 className="text-4xl font-bold mb-4">Terms and Conditions</h1>
        </div>
        <div className="flex flex-col gap-4 p-6 sm:px-20 px-4 max-w-5xl w-full">
          {terms.map((term, index) => (
            <div key={index} className="mb-4 ">
              <h2 className="text-lg font-semibold mb-1">
                {index + 1}. {term.title}
              </h2>
              <p className="text-sm text-gray-600 ml-4 leading-6 tracking-wide">{term.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
            </section>
            <Footer />
        </>
    );
};

export default TermsConditions;
