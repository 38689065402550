import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import { getHiringStage } from "../../../utils/employer.api/employer.api.services";
import Typography from "@mui/material/Typography";
import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Modal from "@mui/material/Modal";
import { set } from "date-fns";
import Loader from "../../../utils/Loader";
import axiosClient from "../../../config/axios.Config";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function ConfigurationStages() {
  const [isStages, setIsStages] = useState(false);
  const loginData = useSelector((state) => state.auth.Auth);
  const [hiringStages, setHiringStages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newStageName, setNewStageName] = useState("");
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [newStagePosition, setNewStagePosition] = useState(1);
  const [stageError, setStageErrors] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null); // Track which stage to delete
  const [loading, setLoading] = useState(true);

  const openDeleteModal = (stage) => {
    setSelectedStage(stage);
    setOpenDelete(true);
  };
  const closeDeleteModal = () => {
    setStageErrors("");
    setOpenDelete(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewStageName("");
    setStageErrors("");
  };

  const fetchHiringStages = async () => {
    setLoading(true);
    try {
      const response = await getHiringStage(loginData.access_token);

      if (response?.data?.stages?.length) {
        setHiringStages(response.data.stages);
        setIsStages(true);
      } else {
        console.log("No stages found, creating default stages...");

        const payload = {
          stages: [
            { name: "Screening", is_fixed: true, is_default: true, order: 0 },
            {
              name: "Technical Round 1",
              is_fixed: false,
              is_default: true,
              order: 1,
            },
            { name: "HR Round", is_fixed: false, is_default: true, order: 2 },
            { name: "Hired", is_fixed: true, is_default: true, order: 9998 },
            { name: "Rejected", is_fixed: true, is_default: true, order: 9999 },
          ],
        };

        const res = await axiosClient.post(
          `${config.apiUrl}employer/hiring-stages`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${loginData.access_token}`,
            },
          }
        );

        if (res.status === 201) {
          console.log("Hiring Stages Created Successfully");
          // Fetch updated hiring stages after successful creation
          fetchHiringStages();
        }
      }
    } catch (err) {
      console.error("Error fetching hiring stages:", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchProfileInfo();
    fetchHiringStages();
  }, [loginData.access_token]);

  const AddStages = async (e) => {
    e.preventDefault();
    try {
      if (newStageName == "") {
        setStageErrors("Please enter stage name");
        return;
      }
      const payload = {
        name: newStageName,
      };
      let res = await axiosClient.post(
        `${config.apiUrl}employer/hiring-stages/append`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (res.status === 200) {
        toast.success("Stage added successfully!");
        handleCloseModal();
        fetchHiringStages();
        setStageErrors("");
      }
    } catch (error) {
      setStageErrors("");
      setStageErrors(error.response.data.detail);
      // console.log(error.response.data.detail);
    }
  };

  const handleDeleteStages = async () => {
    if (!selectedStage) return;
    try {
      let res = await axiosClient.delete(
        `${config.apiUrl}employer/hiring-stages/${selectedStage}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (res.status === 200) {
        toast.success("Stage deleted");
        fetchHiringStages();
        setOpenDelete(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleMoveStageOpen = (stage, index) => {
  //     setNewStageName(stage.name);
  //     setNewStagePosition(index + 1);
  //     setMoveModalOpen(true);
  // }
  const handleCloseMoveModal = () => {
    setMoveModalOpen(false);
    setNewStageName("");
  };
  const moveStage = async (dragIndex, hoverIndex) => {
    const draggedStage = hiringStages[dragIndex];

    // Update order in the frontend
    const updatedStages = update(hiringStages, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedStage],
      ],
    });

    setHiringStages(updatedStages);

    // Call API to update the backend
    try {
      const url = `${config.apiUrl}employer/hiring-stages/move-stage`;
      await axiosClient.put(
        url,
        {
          name: draggedStage.name,
          new_position: hoverIndex,
        },
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      // toast.success("Stage moved");
    } catch (error) {
      console.error(error);
      toast.error("Failed to move stage");
    }
  };
  const StageItem = ({
    stage,
    index,
    moveStage,
    EditStage,
    handleDeleteStages,
  }) => {
    const ref = useRef(null);
    const [isEditing, setIsEditing] = useState(false); // Local state for edit mode
    const [updatedStageName, setUpdatedStageName] = useState(stage.name); // Local state for stage name

    const [, drop] = useDrop({
      accept: "STAGE",
      canDrop: () => !stage.is_fixed, // Prevent drop if stage is_fixed
      drop(item) {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (!stage.is_fixed && dragIndex !== hoverIndex) {
          moveStage(dragIndex, hoverIndex);
        }
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "STAGE",
      canDrag: () => !stage.is_fixed, // Prevent drag if stage is_fixed
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    const handleSaveClick = async () => {
      console.log("Save button clicked"); // Debugging log
      if (updatedStageName !== stage.name) {
        await EditStage(stage.name, updatedStageName); // Call EditStage with correct parameters
      }
      setIsEditing(false); // Exit edit mode after saving
    };

    return (
      <div
        ref={ref}
        className={`stage-item ${stage.is_default ? "default-stage" : ""} ${
          isDragging ? "dragging" : ""
        }`}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <div className="circle"></div>
        <h3>Stage {index + 1}</h3>
        <input
          value={updatedStageName}
          readOnly={stage.is_default} // Make input editable only if it's not default
          onFocus={() => setIsEditing(true)} // Enter edit mode on focus
          onBlur={() => setIsEditing(true)} // Exit edit mode on blur
          onChange={(e) => setUpdatedStageName(e.target.value)} // Update local state
        />

        <div className="stage-actions flex">
          {!stage.is_default && (
            <>
              <img
                src="images/icons/save.svg"
                alt="Save"
                className="cursor-pointer mr-2 p-1"
                onClick={handleSaveClick}
                hidden={!isEditing}
              />
              <img
                src="images/icons/delete.svg"
                alt="Delete"
                className="cursor-pointer mr-2 p-1"
                onClick={() => openDeleteModal(stage.name)}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const EditStage = async (previousStageName, updatedStageName) => {
    try {
      console.log(
        `Updating stage: ${previousStageName} to ${updatedStageName}`
      ); // Debugging log
      let res = await axiosClient.put(
        `${config.apiUrl}employer/hiring-stages/update/${previousStageName}`,
        { name: updatedStageName },
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      console.log("Response:", res.data); // Log response for debugging

      if (res.status === 200) {
        toast.success(res.data.message);
        fetchHiringStages(); // Fetch the latest stages
      }
    } catch (error) {
      console.error("Error updating stage:", error.response || error);
      toast.error("Error updating stage");
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <Box>
            {isStages ? (
              <>
                <div className="flex items-center justify-between mt-4">
                  <div>
                    <h2 className="heading2">
                      Here’s the template you can edit and add new stages
                    </h2>
                  </div>
                  {isStages ? (
                    <button
                      className="border flex items-center gap-1 px-1 py-1 text-[14px] rounded-lg bg-gray-50 pr-1"
                      aria-label="Upload Resume"
                      onClick={handleOpenModal}
                    >
                      <p className="bg-gradient-to-tr from-purple-400 to-sky-400 py-[6px] text-white flex items-center gap-1 px-2 rounded-lg">
                        <svg
                          width="14"
                          height="15"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2519_30917)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 4.5C12.5523 4.5 13 4.94772 13 5.5V19.5C13 20.0523 12.5523 20.5 12 20.5C11.4477 20.5 11 20.0523 11 19.5V5.5C11 4.94772 11.4477 4.5 12 4.5Z"
                              fill="white"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4 12.5C4 11.9477 4.44772 11.5 5 11.5H19C19.5523 11.5 20 11.9477 20 12.5C20 13.0523 19.5523 13.5 19 13.5H5C4.44772 13.5 4 13.0523 4 12.5Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2519_30917">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0 0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </p>
                      <span className="font-semibold text-gray-700">
                        <span className="hidden sm:inline">Add</span> Stage
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="stages mt-3">
                  {hiringStages.map((stage, index) => (
                    <StageItem
                      key={stage.id}
                      index={index}
                      stage={stage}
                      moveStage={moveStage}
                      EditStage={EditStage}
                      handleDeleteStages={handleDeleteStages}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="mt-5">
                {/* <button type='button' className='bordered-btn theme-btn' onClick={CreateDefaultStages}>
                            <span className='color-text'>Create Hiring stages</span>
                        </button> */}
                <p>Please fill the company info first....</p>
              </div>
            )}
          </Box>
        </DndProvider>
      )}
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
          >
            Add New Stage
          </Typography>
          <div>
            <input
              type="input"
              placeholder="Stage name"
              value={newStageName}
              onChange={(e) => setNewStageName(e.target.value)}
              className="w-full border border-gray-300 p-3 mt-4 rounded-md"
            />
            <span className="text-sm text-red-500">{stageError}</span>
          </div>
          <div className="justify-center items-center mt-5 flex">
            <button
              onClick={handleCloseModal}
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={AddStages}
              className="py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium theme-btn rounded-lg"
            >
              Add Stage
            </button>
          </div>
        </Box>
      </Modal>
      <Modal open={moveModalOpen} onClose={handleCloseMoveModal}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
          >
            Move Stage
          </Typography>
          <div>
            <input
              type="text"
              placeholder="Stage name"
              value={newStageName}
              readOnly
              className="w-full border border-gray-300 p-3 mt-4 rounded-md"
            />
            <input
              type="number"
              placeholder="New Position"
              value={newStagePosition}
              onChange={(e) => setNewStagePosition(e.target.value)}
              className="w-full border border-gray-300 p-3 mt-4 rounded-md"
            />
          </div>
          <div className="justify-center items-center mt-5 flex">
            <button
              onClick={handleCloseMoveModal}
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={moveStage}
              className="py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium theme-btn rounded-lg"
            >
              Move Stage
            </button>
          </div>
        </Box>
      </Modal>
      <Modal open={openDelete} onClose={closeDeleteModal}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
          >
            Are you sure want to delete this stage
          </Typography>
          <div className="justify-center items-center mt-5 flex">
            <button
              onClick={closeDeleteModal}
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteStages}
              className="py-2.5 px-5 me-2 mb-2 text-white bg-red-500 text-sm font-medium theme-btn rounded-lg"
            >
              Delete
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
