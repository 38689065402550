import { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

const InternetStatus = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [countdown, setCountdown] = useState(12);
  const [countdownActive, setCountdownActive] = useState(false);
  const [reconnectAttempt, setReconnectAttempt] = useState(0);
  const countdownRef = useRef(null);

  // Handle online/offline status
  useEffect(() => {
    const handleOffline = () => setIsOffline(true);
    const handleOnline = () => {
      setIsOffline(false);
      setCountdownActive(false);
      setReconnectAttempt(0); // Reset attempts on success
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  // Function to start reconnection attempt
  const startReconnectionAttempt = useCallback(() => {
    setReconnectAttempt((prev) => {
      const newAttempt = prev + 1;
      const waitTime =
        newAttempt === 0
          ? 12
          : newAttempt === 1
          ? 18
          : newAttempt === 2
          ? 25
          : newAttempt === 3
          ? 35
          : 45;

      setCountdown(waitTime);
      setCountdownActive(true);

      if (countdownRef.current) clearInterval(countdownRef.current);

      countdownRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownRef.current);
            setCountdownActive(false);

            setTimeout(() => {
              if (!navigator.onLine && isOffline) startReconnectionAttempt();
            }, 1500);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return newAttempt;
    });
  }, [isOffline]);

  // Auto-start first reconnection attempt
  useEffect(() => {
    if (isOffline && reconnectAttempt === 0) {
      setTimeout(() => {
        startReconnectionAttempt();
      }, 500);
    }
  }, [isOffline, reconnectAttempt, startReconnectionAttempt]);

  // Manual reconnect handler
  const handleManualReconnect = () => {
    // Clear any existing countdown
    if (countdownRef.current) clearInterval(countdownRef.current);
    setCountdownActive(false);

    // Try to reconnect immediately
    window.location.reload();
  };

  if (!isOffline) return null;

  return (
    <Box
      className="fixed top-0 left-0 right-0 z-50 flex justify-center"
      sx={(theme) =>({
        zIndex: theme.zIndex.modal + 3,
      })}
    >
      <Box
        className="flex items-center justify-between px-4 py-2 bg-white shadow-md"
        sx={{
          width: { xs: "95%", sm: "90%", md: "80%" },
          maxWidth: "600px",
          borderRadius: "0 0 8px 8px",
          border: "1px solid #e0e0e0",
          borderTop: "none",
        }}
      >
        <Box className="flex items-center">
          <Box
            className="w-3 h-3 rounded-full mr-3"
            sx={{ bgcolor: "#e74c3c" }}
          />
          <Typography variant="body1" className="font-medium">
            Connection lost. Reconnecting in {countdown} seconds...
          </Typography>
        </Box>
        <div className=" flex justify-center items-center gap-1">
          <Button
            variant="contained"
            onClick={handleManualReconnect}
            className="ml-4"
            sx={{
              bgcolor: "#f5f5f5",
              color: "#333",
              boxShadow: "none",
              mr: 1,
              "&:hover": {
                bgcolor: "#e0e0e0",
                boxShadow: "none",

              },
            }}
          >
            Reconnect
          </Button>
          <CircularProgress size={20} color="inherit" />
        </div>
      </Box>
    </Box>
  );
};

export default InternetStatus;
