import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import { toggleDrawer } from "../../../redux copy/actionTypes";
import { useDispatch } from "react-redux";
import axiosClient from "../../../config/axios.Config";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../../../utils/Loader";
import Dialog from "@mui/material/Dialog";
import { handleBreakpoints } from "@mui/system";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Paper, DialogContent } from "@mui/material";
import { TextareaAutosize }from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  MoreVert as MoreVertIcon,
  NotificationsOutlined as NotificationsIcon,
  HelpOutlineOutlined as HelpIcon,
} from "@mui/icons-material";

const formatText = (text) => {
  return text
    .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>") // Bold text
    .replace(
      /(https?:\/\/[^\s]+)/g,
      '<a href="$1" target="_blank" class="text-blue-500 underline">$1</a>'
    ); // Clickable link
};

const InterviewText = ({ text, user }) => {
  // Function to format text: Keep bold, highlight, and clickable "here" without exposing the raw link
  const formatText = (input) => {
    return input
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>") // Bold **text**
      .replace(
        /\[(.*?)\]\((https?:\/\/[^\s]+)\)/g,
        '<a href="$2" target="_blank" class="text-blue-500 underline">$1</a>'
      ); // Clickable text with hidden URL
  };

  return (
    <div
      className={`whitespace-pre-line ${
        user === "ai" ? "text-gray-900" : "text-white"
      }`}
    >
      {text?.split(/\n/)?.map((line, index) => (
        <p
          className={`${user === "ai" ? "text-gray-900" : "text-white"}`}
          key={index}
          dangerouslySetInnerHTML={{ __html: formatText(line) }}
        />
      ))}
    </div>
  );
};

export default function ChatUI() {
  const loginData = useSelector((state) => state.auth.Auth);

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const dispatch = useDispatch();
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [warning, setWarning] = useState(false);
  const [conversationDeleteId, setConversationDeleteId] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const textareaBoxRef = useRef(null);
  const [openGuide, setOpenGuide] = useState(false);
  // Initial data loading
  const fetchConversations = async () => {
    try {
      const res = await axiosClient.get(
        `${config.apiUrl}employer/conversations`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      const conversationsData = res.data?.conversations || [];

      // Handle the case when there are no conversations
      if (conversationsData.length === 0) {
        // Start a new chat automatically
        startNewChat();
        return;
      }

      // Set first conversation as active on initial load
      const updatedConversations = conversationsData.map((chat, index) => ({
        ...chat,
        active: index === 0, // First one is active
      }));

      setConversations(updatedConversations);
      setCurrentConversationId(conversationsData[0].conversation_id);
    } catch (error) {
      console.error("Error fetching conversations:", error);
      toast.error("Failed to load conversations");
      // Even on error, try to start a new chat
      startNewChat();
    } finally {
      setFirstLoad(false);
    }
  };
  useEffect(() => {
    fetchConversations();
  }, []);

  // Fetch messages for the selected conversation
  useEffect(() => {
    if (!currentConversationId) return;

    const fetchMessages = async () => {
      setLoading(true);
      try {
        const res = await axiosClient.get(
          `${config.apiUrl}employer/conversations/${currentConversationId}`,
          {
            headers: {
              Authorization: `Bearer ${loginData.access_token}`,
            },
          }
        );
        setMessages(res.data?.messages || []);

        // Update last active conversation in local storage for persistence across refreshes
        // localStorage.setItem("lastActiveConversation", currentConversationId);
      } catch (error) {
        console.error("Error fetching messages:", error);
        toast.error("Failed to load messages");

        // If conversation doesn't exist anymore, refresh the list
        if (error.response?.status === 404) {
          refreshConversationsList();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [currentConversationId]);

  // Open mobile sidebar when the component mounts
  useEffect(() => {
    dispatch(toggleDrawer(false));
  }, []);

  // Auto-scroll to the bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Auto-resize textarea based on content
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "24px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  // Refresh conversations list (useful after errors)
  const refreshConversationsList = async () => {
    setIsRefreshing(false);
    try {
      const res = await axiosClient.get(
        `${config.apiUrl}employer/conversations`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      const conversationsData = res.data?.conversations || [];

      // If no conversations exist, start a new one
      if (conversationsData.length === 0) {
        startNewChat();
        return;
      }

      // Set first conversation as active
      const updatedConversations = conversationsData.map((chat, index) => ({
        ...chat,
        active: index === 0,
      }));

      setConversations(updatedConversations);
      setCurrentConversationId(conversationsData[0].conversation_id);
    } catch (error) {
      console.error("Error refreshing conversations:", error);
      toast.error("Failed to refresh conversations");
      setIsRefreshing(true);
    }
  };

  const sendMessage = async () => {
    if (isTyping) return;
    if (!input.trim()) return;
    setIsRefreshing(false);
    // If no current conversation, create one first
    if (!currentConversationId) {
      const newId = await chatInitialize();
      if (!newId) {
        toast.error("Cannot send message - failed to create conversation");
        return;
      }
      setCurrentConversationId(newId);
    }

    // Add user message
    const newUserMessage = {
      id: Date.now(),
      content: input,
      type: "user",
      timestamp: new Date(),
    };

    setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    const sentMessage = input.trim(); // Store the sent message
    setInput("");

    // Reset textarea height
    if (textareaRef.current) {
      textareaRef.current.style.height = "24px";
    }

    setIsTyping(true);
    try {
      const res = await axiosClient.post(
        `${config.apiUrl}employer/smart-schedule`,
        {
          text: sentMessage,
          conversation_id: currentConversationId,
        },
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      const agentMessage = res.data?.response || "No response from AI.";
      const formattedMessage = agentMessage;

      const newAgentMessage = {
        id: messages.length + 2,
        content: formattedMessage,
        type: "ai",
        timestamp: new Date(),
      };

      setMessages((prevMessages) => [...prevMessages, newAgentMessage]);

      // Update conversation title if this is the first message
      if (messages.length === 0 || messages.length === 1) {
        updateConversationTitle(sentMessage);
      }
    } catch (error) {
      console.error("Error sending message:", error);

      // Handle token expiration or authorization issues
      if (error.response?.status === 401) {
        toast.error("Your session has expired. Please log in again.");
        // Redirect to login or refresh token logic here
      } else {
        const errorMessage = {
          id: Date.now() + 2,
          content:
            error.response?.data?.detail ||
            "Sorry, I'm unable to connect at the moment. Please try again later.",
          type: "ai",
          timestamp: new Date(),
        };
        setIsRefreshing(true);
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      }
    } finally {
      setIsTyping(false);
    }
  };

  // Update conversation title based on first user message
  const updateConversationTitle = async (message) => {
    // Create a title from the first few words of the message
    const title =
      message.split(" ").slice(0, 5).join(" ") +
      (message.split(" ").length > 5 ? "..." : "");

    try {
      await axiosClient.patch(
        `${config.apiUrl}employer/conversations/${currentConversationId}`,
        { title },
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      // Update local state
      setConversations((prevConversations) =>
        prevConversations.map((chat) =>
          chat.conversation_id === currentConversationId
            ? { ...chat, title }
            : chat
        )
      );
    } catch (error) {
      console.error("Error updating conversation title:", error);
      // Non-critical error, don't show toast
    }
  };

  const handleKeyDown = (e) => {
    if (isTyping) return;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents newline
      if (input.trim()) {
        sendMessage(input);
        setInput(""); // Clear input
        setTimeout(() => textareaRef.current?.focus(), 0); // Keep focus after sending
      }
    }
  };

  const chatInitialize = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.post(
        `${config.apiUrl}employer/conversations/create`,
        {}, // Empty body since headers should be separate
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      const newConversationId = res.data.conversation_id;
      setCurrentConversationId(newConversationId);
      // toast.success("Chat initialized successfully");
      return newConversationId;
    } catch (error) {
      console.error("Error initializing chat:", error);
      toast.error("Failed to create new chat");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const startNewChat = async () => {
    setLoading(true);

    // Initialize chat and get new conversation ID
    const newConversationId = await chatInitialize();
    if (!newConversationId) {
      setLoading(false);
      return;
    }

    // Deactivate all existing conversations
    const updatedConversations = conversations.map((chat) => ({
      ...chat,
      active: false,
    }));

    // Create a new chat entry
    const newChat = {
      conversation_id: newConversationId,
      title: "New chat",
      active: true,
      updated_at: new Date(),
    };

    setConversations([newChat, ...updatedConversations]);

    // Clear messages for a fresh start
    setMessages([]);

    // Close mobile sidebar
    setIsMobileSidebarOpen(false);

    // Update local storage
    // localStorage.setItem("lastActiveConversation", newConversationId);

    setLoading(false);
  };

  const selectConversation = (id) => {
    setLoading(true);
    // If already selected, do nothing
    if (id === currentConversationId) {
      setIsMobileSidebarOpen(false);
      return;
    }

    setConversations(
      conversations.map((chat) => ({
        ...chat,
        active: chat.conversation_id === id,
      }))
    );

    setMessages([]);
    setCurrentConversationId(id);

    // Update local storage
    // localStorage.setItem("lastActiveConversation", id);

    // Close mobile sidebar
    setIsMobileSidebarOpen(false);
  };

  const deleteConversation = async (id) => {
    try {
      await axiosClient.delete(
        `${config.apiUrl}employer/conversations/${conversationDeleteId}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      toast.success("Conversation deleted successfully");

      setConversations((prevConversations) => {
        const updatedConversations = prevConversations.filter(
          (chat) => chat.conversation_id !== id
        );

        console.log(
          "Updated Conversations After Deletion:",
          updatedConversations
        );

        if (updatedConversations.length === 0) {
          console.log("No conversations left. Clearing state...");

          // Clear everything first
          setConversations([]);
          setCurrentConversationId(null);
          setMessages([]);

          // **Force re-render before creating a new chat**
          // setTimeout(() => {
          //   console.log("Starting a new chat...");
          //   startNewChat();
          // }, 200); // Slightly increased delay to ensure state update before new chat

          return [];
        }

        if (id === currentConversationId) {
          const firstConversationId = updatedConversations[0].conversation_id;
          setCurrentConversationId(firstConversationId);
          return updatedConversations.map((chat, index) => ({
            ...chat,
            active: index === 0, // Make first one active
          }));
        }

        return updatedConversations;
      });
    } catch (error) {
      console.error("Error deleting conversation:", error);

      if (error.response?.status === 404) {
        toast.info("Conversation already deleted");
        refreshConversationsList();
      } else {
        toast.error("Failed to delete conversation");
      }
    }
  };

  // ✅ Start a new chat ONLY when conversations state is empty
  useEffect(() => {
    if (conversations.length === 0 && !currentConversationId && !firstLoad) {
      console.log("🚀 No conversations left, creating a new one...");
      startNewChat();
    }
  }, [conversations, currentConversationId]);

  // Handle network disconnection and reconnection
  useEffect(() => {
    const handleOnline = () => {
      toast.success("Back online");
      refreshConversationsList();
    };

    const handleOffline = () => {
      toast.error("Network connection lost");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const formatTime = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString([], {
      month: "short",
      day: "numeric",
    });
  };

  const plainText = (text) => {
    if (!text) return "";
    return text
      .replace(/\n/g, "<br/>") // For line breaks
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
  };

  const handleWarning = (id = null) => {
    setWarning(!warning);
    setConversationDeleteId(id);
  };

  const handleCopy = (text) => {
    if (!text) {
      toast.error("No text to copy");
      return;
    }

    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied to clipboard");
      })
      .catch(() => {
        toast.error("Failed to copy text");
      });
  };

  // Retry logic for API calls
  const retryApiCall = async (apiCallFn, maxRetries = 3) => {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        return await apiCallFn();
      } catch (error) {
        retries++;
        if (retries === maxRetries) throw error;
        await new Promise((resolve) => setTimeout(resolve, 1000 * retries)); // Exponential backoff
      }
    }
  };

  const handleRefresh = () => {
    // setMessages([]);
    if (messages.length > 2) {
      refreshConversationsList();
    } else {
      chatInitialize();
    }
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "24px"; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Expand to fit content
    }
  };
  const handleChange = (e) => {
    setInput(e.target.value);
    adjustHeight();
  };
  useEffect(() => {
    adjustHeight(); // Adjust on mount and when input changes
  }, [input]);

  const handleOpenGuide = () => {
    setOpenGuide(true);
  };

  const handleCloseGuide = () => {
    setOpenGuide(false);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1 }} className="mainContent">
      <Toaster />
      {firstLoad && <Loader />}
      <div className="flex h-[calc(100vh-64px)] bg-gray-100 pt-2">
        {/* Mobile sidebar toggle */}
        <div className="md:hidden fixed top-14 left-4 z-20">
          <button
            onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
            className="p-2 bg-gray-900 text-white rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        {/* Sidebar */}
        <div
          className={`${
            isMobileSidebarOpen ? "fixed inset-0 z-10" : "hidden"
          } md:relative md:flex md:flex-col w-64 bg-gray-100 `}
        >
          {/* Close button for mobile */}
          {isMobileSidebarOpen && (
            <button
              onClick={() => setIsMobileSidebarOpen(false)}
              className="md:hidden absolute top-2 right-2 p-2 text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}

          <Box
            sx={{
              background: "linear-gradient(to right, #d0a5ff, #6fbee7)",
              color: "white",
              p: 1,
              px: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Left side - Logo & Name */}
            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, fontSize: "1.25rem" }} // Larger "Roma AI"
              >
                Roma AI
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  opacity: 0.7,
                  backgroundColor: "#F3F4F6",
                  px: 1.2,
                  py: 0.2,
                  borderRadius: "6px",
                  color: "#333",
                  mr: 1,
                }}
                className="text-xs"
              >
                Beta
              </Typography>
            </Box>
            <Tooltip title="Quick Guide" placement="top" arrow>
              <IconButton
                color="inherit"
                sx={{
                  color: "white",
                  mr: 1,
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                }}
                onClick={handleOpenGuide}
              >
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {/* New chat button */}
          <button
            onClick={startNewChat}
            className="m-3 p-3 border border-gray-600 rounded-md hover:bg-gradient-to-r from-purple-200 to-sky-200 hover:text-[#516AF4] transition-colors text-sm flex items-center gap-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            New chat
          </button>

          {/* Conversations list */}
          <div className="flex-1 overflow-y-auto py-2">
            <div className="px-3 mb-2 text-xs text-gray-400 uppercase">
              Recent conversations
            </div>

            {conversations.map((chat) => (
              <div
                key={chat.conversation_id}
                onClick={() => {
                  if (chat.conversation_id === currentConversationId) return; // Prevent clicks if already selected
                  selectConversation(chat.conversation_id);
                  setLoading(true);
                }}
                className={`px-3 py-3 text-sm cursor-pointer mx-2 rounded group 
    ${
      chat.conversation_id === currentConversationId
        ? "cursor-not-allowed opacity-95" // Disable interactions
        : "hover:bg-gradient-to-r from-purple-200 to-sky-200 hover:text-[#516AF4]"
    }
    ${chat.active ? "bg-active-button-gradient text-[#516AF4]" : ""}`}
              >
                <div className="flex items-center justify-between">
                  {chat.title ? (
                    <Tooltip title={chat.title} placement="top" arrow>
                      <div className="flex items-center gap-3 truncate">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 shrink-0"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                          />
                        </svg>
                        <span className="truncate">{chat.title}</span>
                        <span
                          className={`text-xs ${
                            chat.active ? "text-[#516AF4]" : ""
                          }`}
                        >
                          {formatDate(chat.updated_at)}
                        </span>
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="text-gray-400 italic">Untitled</div>
                  )}

                  <Tooltip title="Delete conversation" placement="right" arrow>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleWarning(chat.conversation_id);
                      }}
                      aria-label="Delete conversation"
                      className="opacity-0 group-hover:opacity-100 text-gray-500 hover:text-red-500 p-1 transition-opacity duration-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>

          {/* User profile */}
          {/* <div className="border-t border-gray-700 p-3 mt-auto">
            <div className="flex items-center gap-3">
              <div className="h-8 w-8 bg-gray-700 rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </div>
              <div className="flex-1 truncate">
                <div className="truncate text-sm font-medium">User Account</div>
                <div className="truncate text-xs text-gray-400">
                  user@example.com
                </div>
              </div>
              <button className="text-gray-400 hover:text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div> */}
        </div>

        {/* Chat Area */}
        <div className="flex flex-col flex-1">
          {/* Chat messages */}
          {loading || firstLoad ? (
            <div className="flex items-center bg-white justify-center h-full">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="flex-1 overflow-y-auto p-4 bg-white">
                {messages.length === 0 ? (
                  <div className="h-full flex flex-col items-center justify-center text-gray-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-12 w-12 mb-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                      />
                    </svg>
                    <p className="text-lg font-medium">
                      How can I help you today?
                    </p>
                    <div className="grid grid-cols-2 gap-2 mt-6 w-full max-w-xl">
                      {[
                        "What are my upcoming interviews?",

                        "Can you reschedule my interview?",

                        "What time is my interview scheduled?",

                        "In which company is my next Interview?",
                      ].map((suggestion, i) => (
                        <button
                          key={i}
                          onClick={() => {
                            setInput(suggestion);
                            if (textareaRef.current) {
                              textareaRef.current.focus();
                            }
                          }}
                          className="p-3 text-sm text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-50 text-left"
                        >
                          {suggestion}
                        </button>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="max-w-5xl mx-auto">
                    {messages.map((msg, index) => (
                      <div
                        key={index}
                        className={`mb-6 flex  ${
                          msg.type === "ai"
                            ? "mr-8 flex-row w-[60%]"
                            : "ml-8 flex-row-reverse "
                        }`}
                      >
                        <div
                          className={`flex ${
                            msg.type !== "ai" ? "justify-end" : "justify-start"
                          }`}
                        >
                          {msg.type === "ai" && (
                            <div className="h-8 w-8 text-sm rounded-full flex items-center justify-center shrink-0 bg-violet-300 mr-3">
                             <img src="images/icons/roma.png" alt="Roma" />
                            </div>
                          )}

                          <div className="max-w-[80%]">
                            <div
                              className={`rounded-lg p-3 ${
                                msg.type === "ai"
                                  ? "bg-blue-100 text-gray-900 rounded-tl-none"
                                  : "bg-blue-500 text-white rounded-tr-none"
                              }`}
                            >
                              <p className="whitespace-pre-wrap break-words">
                                <InterviewText
                                  text={msg.content}
                                  user={msg.type}
                                />
                              </p>

                              <div
                                className={`text-xs text-right mt-1 ${
                                  msg.type === "ai"
                                    ? "text-gray-600"
                                    : "text-white/80"
                                }`}
                              >
                                {formatTime(msg.timestamp)}
                              </div>
                            </div>

                            {msg.type === "ai" && (
                              <div className="flex mt-2 text-sm text-gray-500">
                                <button
                                  className="flex items-center gap-1 hover:text-gray-700"
                                  onClick={() => handleCopy(msg.content)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                                    />
                                  </svg>
                                  Copy
                                </button>
                              </div>
                            )}
                          </div>

                          {msg.type !== "ai" && (
                            <div className="h-8 w-8 rounded-full flex items-center justify-center shrink-0 bg-sky-600 ml-3 text-white">
                              {localStorage.getItem("Name")?.charAt(0) || "U"}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    {isTyping && (
                      <div className="mb-6 mr-8">
                        <div className="flex items-start">
                          <div className="h-8 w-8 text-sm rounded-full flex items-center justify-center shrink-0 bg-[#7744db] text-white">
                          <img src="images/icons/roma.png" alt="Roma" />
                          </div>
                          <div className="ml-3 p-3 bg-gray-100 rounded-lg">
                            <div className="flex space-x-1">
                              <div
                                className="h-2 w-2 bg-gray-400 rounded-full animate-bounce"
                                style={{ animationDelay: "0ms" }}
                              ></div>
                              <div
                                className="h-2 w-2 bg-gray-400 rounded-full animate-bounce"
                                style={{ animationDelay: "150ms" }}
                              ></div>
                              <div
                                className="h-2 w-2 bg-gray-400 rounded-full animate-bounce"
                                style={{ animationDelay: "300ms" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div ref={messagesEndRef} />
                  </div>
                )}
              </div>

              {/* Input area */}
              <div className="border-t border-gray-200 p-4 bg-white">
                <div className="max-w-5xl mx-auto">
                  {isRefreshing ? (
                    <div className="flex items-center justify-center">
                      <button
                        onClick={handleRefresh}
                        className="flex items-center justify-center px-6 py-3 rounded-lg bg-blue-600 text-white font-semibold transition-all hover:bg-blue-700 active:scale-95 shadow-md"
                      >
                        <RefreshIcon className="mr-2" />
                        Refresh
                      </button>
                    </div>
                  ) : (
                    <div className="relative">
                    <TextareaAutosize
                        ref={textareaRef}
                        value={input}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        minRows={1}
                        maxRows={5} // Adjust max rows if needed
                        placeholder="Message Roma..."
                        className="w-full py-3 px-4 pr-12 border border-gray-300 rounded-lg 
        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
        hover:border-blue-400 transition-all duration-200 resize-none overflow-hidden"
                        style={{
                          minHeight: "24px",
                          maxHeight: "128px",
                          fontSize: "16px",
                        }}
                      />
                      <button
                        onClick={() => {
                          if (input.trim()) {
                            sendMessage(input);
                            setInput(""); // Clear input
                            setTimeout(() => textareaRef.current?.focus(), 0); // Keep focus
                          }
                        }}
                        disabled={isTyping}
                        className={`absolute right-3 bottom-4 p-1 rounded ${
                          input.trim() && !isTyping
                            ? "text-blue-500 hover:bg-blue-50"
                            : "text-gray-400"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 transform rotate-90"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Dialog
        open={warning}
        onClose={handleWarning}
        className="bg-transparent flex justify-center items-center"
        PaperProps={{
          sx: {
            borderRadius: "24px", // Ensures rounded corners
            padding: "12px", // Adds spacing
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Soft shadow for modern look
          },
          className: "bg-white max-w-sm w-full transition-all duration-300",
        }}
      >
        <div className="w-[400px] bg-white  pr-2 pl-2 pb-2 ">
          <div className="flex justify-between items-center border-b-0">
            <h2 className="text-lg font-semibold text-gray-800">
              Are you sure you want to proceed?
            </h2>
            <button
              onClick={() => setWarning(false)}
              className="text-gray-500 hover:text-gray-800 text-right p-2"
            >
              ✖
            </button>
          </div>
          <p className="text-sm text-gray-600 mt-3">
            Deleting this conversation will permanently remove it from your
            account. This action cannot be undone.
          </p>
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => setWarning(false)}
              className="py-2 px-4 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200"
            >
              No
            </button>
            <button
              onClick={() => {
                deleteConversation(conversationDeleteId);
                setWarning(false);
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-gradient-to-tr from-purple-400 to-sky-400 rounded-lg hover:from-purple-500 hover:to-sky-500"
            >
              Yes, Delete Now
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={openGuide}
        onClose={handleCloseGuide}
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: "24px",
            width: "800px",
            maxWidth: "90vw",
            boxShadow: "0 4x 20px rgba(0,0,0,0.15)",
            py: 5,
            paddingLeft: 2,
          },
        }}
      >
        <DialogContent sx={{}}>
          {/* Close button */}
          <IconButton
            onClick={handleCloseGuide}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              bgcolor: "#ffebee",
              color: "#d32f2f",
              "&:hover": {
                bgcolor: "#ef9a9a",
              },
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h5"
            component="h2"
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              color: "#1976d2",
            }}
          >
            <span style={{ marginRight: 8 }}>⭐</span>
            Interview Scheduling Guide with Roma, Your AI Agent
          </Typography>
          <Typography variant="body1" sx={{ color: "#424242", mt: 1 }}>
            Learn how to schedule, manage, and prepare for interviews
            effortlessly with Roma AI. Just ask, and Roma will guide you through
            the process.
          </Typography>

          <Box className="mb-8">
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "bold", mb: 2, color: "#1565c0" }}
            >
              Managing Your Interviews
            </Typography>
            <Typography variant="body1" sx={{ color: "#424242", mb: 3 }}>
              Get real-time updates and assistance on interview scheduling,
              rescheduling, and preparation by simply asking Roma.
            </Typography>

            <Box className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Upcoming Interviews
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "What are my upcoming interviews?"
                </Typography>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Reschedule Interview
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "Can you reschedule my interview?"
                </Typography>
              </Paper>
            </Box>

            <Box className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Interview Time
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "What time is my interview scheduled?"
                </Typography>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Interview Location
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "Where is my next interview taking place?"
                </Typography>
              </Paper>
            </Box>

            <Paper
              elevation={0}
              sx={{ bgcolor: "#e3f2fd", p: 3, borderRadius: 2 }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
              >
                Interview Checklist
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#424242", fontStyle: "italic" }}
              >
                "What should I bring to my interview?"
              </Typography>
            </Paper>
          </Box>

          <Box className="mb-4">
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "bold", mb: 2, color: "#0277bd" }}
            >
              Enhancing Your Interview Preparation
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "medium", color: "#424242", mb: 3 }}
            >
              Get AI-powered insights to ace your interview.
            </Typography>

            <Box className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Interview Tips
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "Give me tips to prepare for my interview."
                </Typography>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Job Role Insights
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "What skills are required for this job?"
                </Typography>
              </Paper>
            </Box>

            <Paper
              elevation={0}
              sx={{ bgcolor: "#e3f2fd", p: 3, borderRadius: 2 }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
              >
                Mock Interview
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#424242", fontStyle: "italic" }}
              >
                "Can you conduct a mock interview for me?"
              </Typography>
            </Paper>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
