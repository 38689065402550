
import React, { useState } from "react";
import WithPassword from "./withPassword";
import WithOTP from "./withOtp";
import LoginFooter from "../loginFooter";

const EmployerLogin = () => {
  const [loginMethod, setLoginMethod] = useState('otp');

  // const switchToOtp = () => setLoginMethod('otp');
  // const switchToPassword = () => setLoginMethod('password');

  return (
    <div className="px-md-4 px-2 mb-6">
      {loginMethod === 'password' ? (
        {/* <WithPassword switchToOtp={switchToOtp} /> */}
      ) : (
        <WithOTP />
      )}
    </div>
  );
};

export default EmployerLogin;