import React, { useEffect, useState } from "react";
import axiosClient from "../../../config/axios.Config";
import config from "../../../config/config";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Tooltip, CircularProgress, IconButton } from "@mui/material";

export default function JobDetails({
  jobDetails,
  fetchJobsRecommendations,
  fetchJobDetails,
  drawerLoading,
  setDrawerLoading,
  data,
  setData,
}) {
  const [companyLogoUrl, setCompanyLogoUrl] = useState(""); // Store company logo URL
  const [loadingApplied, setLoadingApplied] = useState(false); // State to store loading status when applying for a job
  const [loadingSaved, setLoadingSaved] = useState(false); // State to store loading status when saving a job
  const loginData = useSelector((state) => state.auth.Auth);
  console.log(jobDetails);
  console.log("drawerLoading", drawerLoading);

  const saveJob = async (jobId) => {
    setLoadingSaved(true); // Set loading status to true
    try {
      console.log("access token", loginData.access_token);
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/save-job/${jobId}`,
        {}, // Empty body if no data is required
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 201) {
        // console.log("Job saved successfully!", response.data);
        toast.dismiss();
        toast.success(response.data.message);
        if (data) {
          const newData = data.map((job) => {
            if (job.id === jobId) {
              return { ...job, is_saved: true };
            }
            return job;
          });
          setData(newData);
        }

        await fetchJobDetails(jobId);

      }
    } catch (error) {
      console.error("Error saving job:", error);
      // Handle error - you might want to display an error message to the user
    } finally {
      setLoadingSaved(false); // Set loading status to false
    }
  };

  const unSaveJob = async (jobId) => {
    setLoadingSaved(true); // Set loading status to true
    try {
      console.log("access token", loginData.access_token);
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/unsave-job/${jobId}`,
        {}, // Empty body if no data is required
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 200) {
        // console.log("Job saved successfully!", response.data);
        toast.dismiss();
        toast.success(response.data.message);
        if (data) {
          const newData = data.map((job) => {
            if (job.id === jobId) {
              return { ...job, is_saved: false };
            }
            return job;
          });
          setData(newData);
        }
        await fetchJobDetails(jobId);
      }
    } catch (error) {
      console.error("Error saving job:", error);
      // Handle error - you might want to display an error message to the user
    } finally {
      setLoadingSaved(false); // Set loading status to false
    }
  };

  // Function to fetch the company logo using the logoId
  const fetchCompanyLogo = async (logoId) => {
    try {
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/company-logo/${logoId}`,
        {
          // responseType: 'blob',
        }
      );
      const logoUrl = response.data.url;
      setCompanyLogoUrl(logoUrl); // Set the company logo URL in state
    } catch (error) {
      console.log("Error fetching company logo:", error);
      return null;
    }
  };

  useEffect(() => {
    if (jobDetails?.employer_id) {
      fetchCompanyLogo(jobDetails.employer_id); // Fetch company logo if ID is present
    }
  }, [jobDetails?.employer_id]);

  useEffect(() => {}, [saveJob, unSaveJob]);

  const applyForJob = async (jobId) => {
    // toast.loading("Applying for job..."); // Show loading toast
    setLoadingApplied(true);
    try {
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/apply-job/${jobId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      if (response.status === 201) {
        toast.dismiss();
        toast.success(response.data.message);
        if (data) {
          const newData = data.map((job) => {
            if (job.id === jobId) {
              return { ...job, applied_status: true };
            }
            return job;
          });
          setData(newData);
        }
        await fetchJobDetails(jobId);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.detail);
      console.log(error);
    } finally {
      setLoadingApplied(false);
    }
  };
  console.log(jobDetails);

  // Function to format salary with 'k'
  const formateSalary = (salary, salary_type) => {
    if (salary) {
      if (salary_type === "Monthly") {
        let yearlySalary = salary * 12;
        return yearlySalary / 100000;
      } else if (salary_type === "Yearly") {
        return salary / 100000;
      }
    } else {
      return "Salary not provided";
    }
  };

  const title = (title) => {
    if (title?.length > 45) {
      return title?.substring(0, 45) + "...";
    } else {
      return title;
    }
  };

  return (
    <>
      <div
        className="p-10 jb-details"
        style={{ backgroundColor: "#E9E9E9", height: "100%" }}
      >
        {drawerLoading ? (
          <div
            className=" flex items-center justify-center h-[100%]"
            style={{ backgroundColor: "#E9E9E9" }}
          >
            <CircularProgress size={50} color="primary" />
          </div>
        ) : (
          <>
            <div className="white-box">
              <div className="flex items-center">
                {companyLogoUrl ? (
                  <img
                    src={companyLogoUrl}
                    alt="Company Logo"
                    className="company-logo"
                  />
                ) : (
                  <img
                    src="images/default.png"
                    alt="Default Logo"
                    className="company-logo"
                  />
                )}
                <div className="ml-2">
                  <h4>{title(jobDetails.title)}</h4>
                  <p>
                    {jobDetails.location ? `${jobDetails.location} -` : ""}{" "}
                    {jobDetails.company}
                  </p>
                  <p>
                    {" "}
                    ₹
                    {formateSalary(
                      jobDetails.expected_salary_min,
                      jobDetails.salary_type
                    )}{" "}
                    - ₹
                    {formateSalary(
                      jobDetails.expected_salary_max,
                      jobDetails.salary_type
                    )}{" "}
                    LPA
                  </p>
                </div>
                <div
                  className={`ml-auto items-center flex gap-4 ${
                    jobDetails.applied_status === true ? "grey-card" : ""
                  }`}
                >
                  <Tooltip
                    title={
                      jobDetails.applied_status ? jobDetails.applied_date : ""
                    }
                  >
                    <button
                      type="button"
                      className={` text-white text-sm ${
                        jobDetails.job_status === "On Hold" ||
                        jobDetails.job_status === "Closed" ||
                        jobDetails.is_accepting_applicants ||
                        jobDetails.applied_status === true
                          ? "bg-gray-400 px-8 p-[6px] rounded-[16px]"
                          : "theme-btn"
                      }`}
                      onClick={() => {
                        if (jobDetails.use_external_apply) {
                          window.open(jobDetails.external_job_url, "_blank");
                        } else {
                          applyForJob(jobDetails.id);
                        }
                      }}
                      disabled={
                        jobDetails.job_status === "On Hold" ||
                        jobDetails.job_status === "Closed" ||
                        jobDetails.is_accepting_applicants ||
                        jobDetails.applied_status === true
                      }
                    >
                      {loadingApplied ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : jobDetails.applied_status === true ? (
                        "Applied"
                      ) : (
                        "Apply Now"
                      )}
                    </button>
                  </Tooltip>
                  {/* <button type="button">
                                <img src="images/icons/share.svg" className="mr-5" alt="Share Icon" />
                            </button> */}
                  <Tooltip
                    title={jobDetails.is_saved ? "Unsave Job" : "Save Job"}
                  >
                    <button type="button">
                      <button type="button">
                        {loadingSaved ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : jobDetails.is_saved ? (
                          <img
                            src="images/icons/save2.svg"
                            alt="save"
                            onClick={() => unSaveJob(jobDetails.id)}
                          />
                        ) : (
                          <img
                            src="images/icons/save.svg"
                            alt="save"
                            onClick={() => saveJob(jobDetails.id)}
                          />
                        )}
                      </button>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-2 mt-2">
              <div className="col-span-8">
                <div className="white-box job-content">
                  <h3>About this role</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: jobDetails.job_description?.replace(
                        /\n/g,
                        "<br/>"
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="col-span-4">
                <div className="white-box job-content">
                  <div className="mb-2">
                    <p>Job posted</p>
                    <h3>{jobDetails.job_posted_date}</h3>
                  </div>
                  <div className="mb-2">
                    <p>Work Experience</p>
                    <h3>{jobDetails.work_experience}</h3>
                  </div>
                  <div className="mb-2">
                    <p>Job Type</p>
                    <h3>{jobDetails.job_types}</h3>
                  </div>
                  <div className="mb-2">
                    <p>Job Mode</p>
                    <h3>{jobDetails.job_modes}</h3>
                  </div>
                  <div className="mb-2">
                    <p>Notice Period</p>
                    <h3>{jobDetails.notice_period}</h3>
                  </div>
                  <div className="mb-2">
                    <p>Number of openings</p>
                    <h3>{jobDetails.openings}</h3>
                  </div>
                  <div className="mb-2">
                    <p>Skills</p>
                    <h3>
                      {jobDetails.skills && jobDetails.skills.length > 0 ? (
                        jobDetails.skills.map((skill, index) => (
                          <span key={index}>
                            {skill.name}
                            <br />
                          </span>
                        ))
                      ) : (
                        <span>No skills mentioned</span>
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
