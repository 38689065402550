import React,{Suspense} from "react";
import { Box} from '@mui/material';
import { useEffect } from "react";
import DrawerHeader from "../../DrawerHeader";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MyProfile from "./myprofile";
import Prefrence from "./preference";
import Resume from "./resume";
import { toast, Toaster } from "react-hot-toast";
import AvatarIcon from "../../AvatarIcon";
const AccountSetting  = React.lazy(()=>import('./accountSetting'))
// const ProfilePictureComponent = React.lazy(() => import('./ProfilePictureComponent'));



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const SettingContent = () => {
    const [value, setValue] = React.useState(localStorage.getItem('settingTab') ? parseInt(localStorage.getItem('settingTab')) : 0);  

    const handleChange = (event, newValue) => {
      toast.dismiss();
      localStorage.setItem('settingTab', newValue);
      setValue(newValue);
    };

    // when go to other menu then set the value of tab to 0
    useEffect(() => {
        return () => {
            localStorage.setItem('settingTab', 0);
        }
    }, []); 

    // toast dismiss on tab change
    useEffect(() => {
        return () => {
            toast.dismiss();
        }
    }, [value]);

    
  
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1 }} className="mainContent" >
                {/* <DrawerHeader /> */}
                {/* <h2 className="menu-heading">Setting</h2> */}
                <Toaster />
                <Box sx={{ width: '100%',padding:2 }} className="setting-tabs">
                {/* <Box sx={{ width: '100%',padding:2,mt:7 }} className="setting-tabs"> */}
                    <Box sx={{ borderColor: 'divider',padding:0, display:'flex' }} className="hirree-tabs">
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{padding:0}} >
                            <Tab label="My Profile" {...a11yProps(0)} />
                            <Tab sx={{padding:0}}  label="Resume" {...a11yProps(1)} />
                            <Tab label="Preferences" {...a11yProps(2)} />
                            <Tab label="Account Settings" {...a11yProps(3)} />
                        </Tabs>
                        {/* <AvatarIcon  /> */}
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <MyProfile/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                    <Resume/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                    <Prefrence/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                    <Suspense fallback={<div>Loading...</div>}>
                       <AccountSetting/>
                       </Suspense>
                    </CustomTabPanel>
                </Box>

            </Box>
        </>
    )
}
export default SettingContent;