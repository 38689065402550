import React, { useState, useEffect } from "react";
import config from "../../config/config";
import * as Yup from "yup";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import SignupFooter from "./signupFooter";
import { Auth } from "../../redux copy/actionTypes";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getCountryColde } from "../../utils/candidate.api/candidate.api.services";
import { TextField, MenuItem, Box, FormHelperText } from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import { Email, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { FormControl, InputLabel } from "@mui/material";

const CandidateSignUp = () => {
  const [form, setForm] = useState({
    email: "",
    name: "",
    password: "",
    confirm_password: "",
    phone_number: "",
    country_code: "+91",
  });
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30); // Set 2 minutes (120 seconds)
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [otpErrors, setOtpErrors] = useState("");
  const [countryCode, setCountryCode] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPassword = () => {
    setshowConfirmPassword((prevState) => !prevState);
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(12, "Password must be at most 12 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[@$!%*#?&]/,
        "Password must contain at least one special character"
      ),

    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),

    country_code: Yup.string().required("Please select the country code"),

    phone_number: Yup.string()
      .matches(/^[0-9]{10}$/, "The phone number is invalid")
      .required("Phone number is required"),

    email: Yup.string()
      .email("Please enter a valid email address") // Built-in validation
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test("valid-domain", "Invalid email format", (value) =>
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      )
      .test("contains-at", "Invalid email format", (value) =>
        value.includes("@")
      ),

    name: Yup.string()
      .required("Name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .max(50, "Name must be at most 50 characters")
      .min(3, "Name must be at least 3 characters"),
  });

  const [errors, setErrors] = useState({});

  const fetchCountryCode = async () => {
    try {
      let response = await getCountryColde();
      setCountryCode(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCountryCode();
  }, []);
  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(form, { abortEarly: false });
      setLoading(true);
      const config1 = {
        method: "post",
        url: `${config.apiUrl}candidate/signup`,
        data: form,
      };

      const response = await axios(config1);

      if (response.status === 201) {
        console.log("Success:", response.data);
        toast.success(response.data.message);
        setShowOtpInput(true);
        setTimeLeft(30); ///timer
        setOtp("");
      } else {
        setOtp(["", "", "", "", "", ""]);
        toast.error("Something went wrong");
      }
    } catch (error) {
      setOtp(["", "", "", "", "", ""]);
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else if (error.status === 400) {
        toast.error(error?.response?.data?.detail);
        console.log("error", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/\D/.test(value)) return; // Prevent non-numeric values

    const newOtp = [...otp];
    setOtpErrors("");
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus the next input only if a value is entered
    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  // Handle keydown for backspace
  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus(); // Focus the previous input box
    }
  };

  // Handle OTP paste
  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 6);
    if (!/^\d+$/.test(pastedData)) return;

    const newOtp = Array(6).fill("");
    pastedData.split("").forEach((char, i) => {
      newOtp[i] = char;
    });

    setOtp(newOtp);
    document.getElementById(`otp-input-${pastedData.length - 1}`).focus();
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const otpValue = otp.join("");
      if (otpValue === "") {
        setOtpErrors("Please enter a valid otp");
        return;
      }
      const response = await axios.post(
        `${config.apiUrl}candidate/verify-otp`,
        { otp: otpValue, email: form.email }
      );
      if (response.status === 200) {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("role", "candidate");
        dispatch({
          type: Auth,
          payload: response.data,
        });
        navigate("/candidate/upload-resume", {
          replace: true,
          state: { from: location.state?.from, showSuccessMessageSignup: true },
        });
        // window.location.href = `${config.baseUrl}candidate/upload-resume`;
      } else {
        setOtp(["", "", "", "", "", ""]);
        toast.error("Failed to verify OTP");
      }
    } catch (error) {
      if (otp !== "") {
        setOtp([]);
        setOtpErrors(error.response.data.detail);
      } else {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => ({ ...old, [name]: value }));
    try {
      // await validationSchema.validateAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
    }
  };
  const resendOtp = async () => {
    setOtpErrors("");
    try {
      setLoading(true);
      const response = await axios.post(
        `${config.apiUrl}candidate/resend-otp`,
        { email: form.email }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setCanResendOtp(false); // Disable resend button
        setTimeLeft(30); // Reset timer
      } else {
        toast.error("Failed to resend OTP");
      }
    } catch (error) {
      toast.error("Error while resending OTP");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    let timer;
    if (showOtpInput && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setCanResendOtp(true);
    }

    return () => clearInterval(timer); // Cleanup timer on unmount
  }, [showOtpInput, timeLeft]);
  return (
    <>
      <Toaster />
      <div className="">
        {!showOtpInput ? (
          <form className="space-y-3" onSubmit={handleSignup}>
            {/* name */}
            <div className="mt-0">
              <TextField
                type="text"
                id="name"
                name="name"
                value={form.name}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border placeholder-gray-400 focus:outline-none
    ${errors.name ? "border-red-500" : "border-gray-300"}`}
                placeholder="Enter your name"
                label={
                  <span>
                    Name <span className="text-red-500">*</span>
                  </span>
                }
                variant="outlined"
                size="small"
                error={Boolean(errors.name)}
                helperText={errors.name}
                sx={{
                  "& .MuiFormHelperText-root": {
                    color: "red", // Change the helper text color
                    p: 0,
                    mx: 0,
                    boxShadow: "none",
                  },
                }}
              />
            </div>
            {/* email */}
            <div className="mt-0">
              <TextField
                type="text"
                id="email"
                name="email"
                size="small"
                value={form.email}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border  placeholder-gray-400 focus:outline-none
                  ${errors.email ? "border-red-500" : "border-gray-300"}`}
                placeholder="Enter your email"
                label={
                  <span>
                    Email <span className="text-red-500">*</span>
                  </span>
                }
                variant="outlined"
                error={errors.email ? true : false}
                helperText={errors.email}
                sx={{
                  "& .MuiFormHelperText-root": {
                    color: "red", // Change the helper text color
                    // Adjust the font size
                    // fontStyle: "italic", // Make it italic
                    p: 0,
                    mx: 0,
                    boxShadow: "none",
                  },
                }}
              />
            </div>
            {/* phone number */}
            <div className="mt-0">
              <div className="flex flex-col w-full">
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={Boolean(errors.phone_number)}
                >
                  {/* Label for Phone Number */}
                  <InputLabel
                    shrink
                    sx={{
                      background: "#fff",
                      px: 1,
                      transition: "color 0.3s ease-in-out", // Smooth transition effect
                      color: errors.phone_number ? "#d32f2f" : "#00000099",
                      "&.Mui-focused": {
                        color: "#3b82f6", // Blue color on focus
                      },
                    }}
                  >
                    Phone Number<span className="text-red-500">*</span>
                  </InputLabel>

                  {/* Phone Input Group */}
                  <div
                    className={`flex w-full border border-[#c4c4c4] rounded-[4px] overflow-hidden transition-all
          ${errors.phone_number ? "border-red-600" : "#C4C4C4"}
          hover:border-gray-600 focus-within:border-transparent focus-within:ring-2 focus-within:ring-[#3374dc] focus-within:outline-none`}
                  >
                    {/* Country Code Dropdown */}
                    <TextField
                      select
                      name="country_code"
                      value={form.country_code}
                      onChange={inputHandler}
                      variant="outlined"
                      size="small"
                      error={Boolean(errors.phone_number)}
                      sx={{
                        width: "140px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 0,
                          borderRight: "1px solid #ccc",
                          transition: "border-color 0.3s",
                          "&:hover": { borderColor: "#777" }, // Keep hover effect
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          borderColor: "transparent", // No active border on focus
                          // boxShadow: "0 0 0 2px #3b82f6", // Blue focus ring
                        },
                      }}
                      InputProps={{
                        readOnly: true,
                        className: "text-[#616161]",
                      }}
                    >
                      <MenuItem value="+91">+91</MenuItem>
                      {countryCode.map((code) => (
                        <MenuItem key={code} value={code}>
                          <div className="flex items-center">
                            <img
                              src="images/icons/flag.svg"
                              alt="flag"
                              className="me-2 w-8 h-6"
                            />
                            <span>{code}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </TextField>

                    {/* Phone Number Input */}
                    <TextField
                      type="text"
                      name="phone_number"
                      value={form.phone_number}
                      onChange={inputHandler}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Enter your phone number"
                      error={Boolean(errors.phone_number)}
                      inputProps={{
                        maxLength: 10,
                        onKeyPress: (e) => {
                          if (!/^\d*$/.test(e.key)) {
                            e.preventDefault(); // Prevent non-numeric input
                          }
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 0,
                          borderRight: "0px",
                          transition: "border-color 0.3s",
                          "&:hover": { borderColor: "#777" }, // Keep hover effect
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          borderColor: "transparent", // No active border on focus
                          // boxShadow: "0 0 0 2px #3b82f6", // Blue focus ring
                        },
                      }}
                    />
                  </div>

                  {/* Error Message Below */}
                  {errors.phone_number && (
                    <Box>
                      <FormHelperText error sx={{ color: "red", mx: 0 }}>
                        {errors.phone_number}
                      </FormHelperText>
                    </Box>
                  )}
                </FormControl>
              </div>
            </div>

            {/* password */}
            <div className="mt-0">
              <TextField
                type={showPassword ? "text" : "password"}
                name="password"
                value={form.password}
                onChange={inputHandler}
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Enter your password"
                label={
                  <span>
                    Password <span className="text-red-500">*</span>
                  </span>
                }
                error={Boolean(errors.password)}
                helperText={errors.password || ""}
                sx={{
                  "& .MuiFormHelperText-root": {
                    color: "red", // Change the helper text color
                    // Adjust the font size
                    // fontStyle: "italic", // Make it italic
                    p: 0,
                    mx: 0,
                    boxShadow: "none",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* confirm password */}
            <div className="mt-0">
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                value={form.confirm_password}
                onChange={inputHandler}
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Confirm your password"
                label={
                  <span>
                    Confirm Password <span className="text-red-500">*</span>
                  </span>
                }
                error={Boolean(errors.confirm_password)}
                helperText={errors.confirm_password || ""}
                sx={{
                  "& .MuiFormHelperText-root": {
                    color: "red", // Change the helper text color
                    // Adjust the font size
                    // fontStyle: "italic", // Make it italic
                    p: 0,
                    mx: 0,
                    boxShadow: "none",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleConfirmPassword} edge="end">
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* <div className="flex items-center mb-4">
              <input id="default-checkbox" type="checkbox" />
              <label htmlFor="default-checkbox" className="ms-2 dark:text-gray-300">Remember me<span className="text-red-500">*</span></label>
            </div> */}
            <div className="mt-0">
              <button
                type="submit"
                className="theme-btn rounded-md mr-2 text-white w-full"
              >
                Sign up
                {loading == true ? (
                  <CircularProgress
                    size={20}
                    disableShrink
                    style={{ color: "#fff", margin: "0 10px" }}
                  />
                ) : null}
              </button>
            </div>
          </form>
        ) : (
          <div className="">
            <div className="mt-0 mb-4">
              <TextField
                type="email"
                id="email"
                name="email"
                size="small"
                value={form.email}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border  placeholder-gray-400 focus:outline-none
                  ${errors.email ? "border-red-500" : "border-gray-300"}`}
                placeholder="Enter your email"
                label="Email"
                variant="outlined"
                error={errors.email ? true : false}
                helperText={errors.email}
                disabled
                sx={{
                  "& .MuiFormHelperText-root": {
                    color: "red", // Change the helper text color
                    // Adjust the font size
                    // fontStyle: "italic", // Make it italic
                    p: 0,
                    mx: 0,
                    boxShadow: "none",
                  },
                }}
              />
            </div>
            <p className="text-sm text-gray-600 mb-4 text-left ">
              Enter the 6-digit code sent to your email
            </p>
            <form className="space-y-4 mt-4 " onSubmit={handleOtpSubmit}>
              <div className="flex justify-between gap-2">
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength={1}
                    className={`w-10 h-12 sm:w-[55px] sm:h-12 text-center border ${
                      otpErrors ? "border-red-600" : ""
                    } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg`}
                    value={otp[index] || ""}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleOtpKeyDown(e, index)}
                    onPaste={handleOtpPaste}
                  />
                ))}
              </div>
              {/* Error Message */}
              {otpErrors && (
                <p className="text-red-500 text-sm text-left">{otpErrors}</p>
              )}

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="theme-btn rounded-md mr-2 text-white w-full"
                  onClick={handleOtpSubmit}
                >
                  Continue
                  {loading && (
                    <CircularProgress
                      size={20}
                      disableShrink
                      style={{ color: "#fff", marginLeft: "10px" }}
                    />
                  )}
                </button>
              </div>
              <div className="flex justify-center items-center">
                {timeLeft > 0 ? (
                  <p className="text-sm">
                    Resend OTP in {Math.floor(timeLeft / 60)}:
                    {(timeLeft % 60).toString().padStart(2, "0")}
                  </p>
                ) : (
                  <p className="text-sm cursor-pointer">
                    Didn't get the OTP?{" "}
                    <span
                      className="color-text cursor-pointer hover:underline"
                      onClick={resendOtp}
                    >
                      Resend OTP
                    </span>
                  </p>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateSignUp;
