import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  IconButton,
  Avatar,
  Chip,
  Divider,
  Paper
} from '@mui/material';
import {
  Close as CloseIcon,
  Business as BusinessIcon,
  Work as WorkIcon,
  Person as PersonIcon,
  CalendarToday as CalendarTodayIcon,
  AccessTime as AccessTimeIcon,
  Category as CategoryIcon,
  Phone as PhoneIcon,
  VideoCall as VideoCallIcon,
  LocationOn as LocationIcon,
  Info as InfoIcon,
  NotificationsActive as NotificationsActiveIcon
} from '@mui/icons-material';

// Component for displaying labeled information with an icon
const InfoRow = ({ icon, label, value, highlight = false }) => (
  <Box className="flex items-center space-x-3">
    <Box className={`p-2 rounded-full ${highlight ? 'bg-blue-100' : 'bg-gray-100'}`}>
      {React.cloneElement(icon, { className: `h-5 w-5 ${highlight ? 'text-blue-600' : 'text-gray-600'}` })}
    </Box>
    <Box>
      <Typography variant="caption" className="text-gray-500 block">
        {label}
      </Typography>
      <Typography variant="body1" className="font-medium">
        {value}
      </Typography>
    </Box>
  </Box>
);

const InterviewInvitationDialog = ({ open, setDialogOpen, selectedNotification }) => {
  // Mock details for demonstration - in production this would come from props
  const details = selectedNotification?.details || {
    company_name: "TechGlobal Inc.",
    role: "Senior Frontend Developer",
    interviewer_name: "Sarah Johnson, Engineering Lead",
    interview_type: "Technical Interview",
    date: "2025-04-15",
    start_time: "10:00 AM",
    end_time: "11:30 AM",
    joining_link: "https://meeting.techglobal.com/interview-123",
    location: "Video Conference",
    phone_number: "+1 (555) 123-4567",
    preparation_tips: "Please be prepared to discuss your experience with React, state management, and responsive design principles."
  };

  const [showPreparationTips, setShowPreparationTips] = useState(false);
  const [reminderSet, setReminderSet] = useState(false);

  // Function to add to calendar
  const addToCalendar = () => {
    // In a real application, this would generate an iCal file or link to Google Calendar
    alert("Calendar event would be created here");
  };

  // Function to set reminder
  const setReminder = () => {
    setReminderSet(true);
    // In a real application, this would schedule a notification
  };

  return (
    <Dialog
      open={open}
      onClose={() => setDialogOpen(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        className: "rounded-xl shadow-xl overflow-hidden",
      }}
    >
      {/* Header with gradient background */}
      <Box className="bg-gradient-to-r from-blue-600 to-purple-600 text-white p-6 relative">
        <IconButton
          onClick={() => setDialogOpen(false)}
          className="absolute right-4 top-4 text-white hover:bg-white/10"
          size="small"
        >
          <CloseIcon className="h-5 w-5" />
        </IconButton>
        
        <Box className="flex items-center">
          <Avatar className="bg-white text-blue-600 mr-4 h-12 w-12">
            <BusinessIcon />
          </Avatar>
          <Box>
            <Typography variant="overline" className="text-blue-100 font-medium opacity-90">
              INTERVIEW INVITATION
            </Typography>
            <Typography variant="h5" className="font-bold">
              {details.role} at {details.company_name}
            </Typography>
          </Box>
        </Box>
        
        <Chip 
          label={`${new Date(details.date).toLocaleDateString("en-US", { month: "short", day: "numeric" })} • ${details.start_time}`} 
          className="bg-white/20 text-white mt-3"
          size="small"
        />
      </Box>

      {/* Content */}
      <DialogContent className="p-0">
        <Box className="bg-white p-6">
          {/* Main information grid */}
          <Box className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Paper elevation={0} className="p-5 bg-gray-50 rounded-xl">
              <Typography variant="subtitle1" className="font-bold mb-4 text-gray-700 flex items-center">
                <BusinessIcon className="mr-2 text-gray-500" /> Company Details
              </Typography>
              <Box className="space-y-4">
                <InfoRow icon={<BusinessIcon />} label="Company" value={details.company_name} />
                <InfoRow icon={<WorkIcon />} label="Position" value={details.role} highlight={true} />
                <InfoRow icon={<PersonIcon />} label="Interviewer" value={details.interviewer_name} />
              </Box>
            </Paper>

            <Paper elevation={0} className="p-5 bg-gray-50 rounded-xl">
              <Typography variant="subtitle1" className="font-bold mb-4 text-gray-700 flex items-center">
                <CalendarTodayIcon className="mr-2 text-gray-500" /> Interview Schedule
              </Typography>
              <Box className="space-y-4">
                <InfoRow 
                  icon={<CalendarTodayIcon />} 
                  label="Date" 
                  value={new Date(details.date).toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })} 
                  highlight={true}
                />
                <InfoRow icon={<AccessTimeIcon />} label="Time" value={`${details.start_time} - ${details.end_time}`} />
                <InfoRow icon={<LocationIcon />} label="Location" value={details.location} />
              </Box>
            </Paper>
          </Box>

          {/* Additional information section */}
          <Box className="mt-6">
            <Paper elevation={0} className="p-5 bg-gray-50 rounded-xl">
              <Typography variant="subtitle1" className="font-bold mb-4 text-gray-700 flex items-center">
                <CategoryIcon className="mr-2 text-gray-500" /> Interview Details
              </Typography>
              <Box className="space-y-4">
                <InfoRow icon={<CategoryIcon />} label="Interview Type" value={details.interview_type} />
                {details.phone_number && (
                  <InfoRow icon={<PhoneIcon />} label="Contact Number" value={details.phone_number} />
                )}
                
                {/* Preparation tips collapsible section */}
                <Box className="mt-2">
                  <Button 
                    onClick={() => setShowPreparationTips(!showPreparationTips)}
                    startIcon={<InfoIcon />}
                    variant="text"
                    className="text-blue-600 normal-case"
                  >
                    {showPreparationTips ? "Hide Preparation Tips" : "Show Preparation Tips"}
                  </Button>
                  
                  {showPreparationTips && (
                    <Paper className="p-4 mt-2 bg-blue-50 border border-blue-100 rounded-lg">
                      <Typography variant="body2" className="text-gray-700">
                        {details.preparation_tips}
                      </Typography>
                    </Paper>
                  )}
                </Box>
              </Box>
            </Paper>
          </Box>

          {/* Action buttons */}
          <Box className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4">
            {details.joining_link && (
              <Button
                variant="contained"
                startIcon={<VideoCallIcon />}
                href={details.joining_link}
                target="_blank"
                className="bg-blue-600 hover:bg-blue-700 py-3 font-medium"
                fullWidth
              >
                Join Interview
              </Button>
            )}
            
            <Button
              variant="outlined"
              startIcon={<CalendarTodayIcon />}
              onClick={addToCalendar}
              className="border-gray-300 text-gray-700 hover:bg-gray-50 py-3 font-medium"
              fullWidth
            >
              Add to Calendar
            </Button>
            
            <Button
              variant="outlined"
              startIcon={<NotificationsActiveIcon />}
              onClick={setReminder}
              disabled={reminderSet}
              className={`${
                reminderSet 
                  ? 'border-green-300 bg-green-50 text-green-700' 
                  : 'border-gray-300 text-gray-700 hover:bg-gray-50'
              } py-3 font-medium`}
              fullWidth
            >
              {reminderSet ? "Reminder Set" : "Set Reminder"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InterviewInvitationDialog;