import React from "react";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { useNavigate } from "react-router-dom";

    const SignupFooter = () => {
        const navigate = useNavigate();
        return (
        <>
 <div className="mt-6 pb-5 text-center text-gray-600">
      {/* Already have an account? */}
      <p className="text-[30px] font-semibold mb-4">
        Already have an account?{" "}
        <span
          className="text-blue-600 cursor-pointer hover:underline"
          onClick={() => navigate("/login")}
        >
          Log In
        </span>
      </p>

      {/* Terms & Privacy Policy */}
      <p className="px-4 leading-relaxed text-lg">
        By clicking
        <span className="font-bold text-gray-700"> "Create Account" </span>, you acknowledge that you have read and accept the  
        <span
          className="text-blue-600 font-medium mx-1 cursor-pointer hover:underline"
          onClick={() => navigate("/terms-and-conditions")}
        >
          Terms of Service
        </span>
        and  
        <span
          className="text-blue-600 font-medium ml-1 cursor-pointer hover:underline"
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </span>.
      </p>
    </div>


        </>
    )
}
export default SignupFooter;