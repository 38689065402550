import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';

function Loader() {
    return (
        <Backdrop
            sx={(theme) => ({
                color: '#fff',
                zIndex: theme.zIndex.modal + 4, // Ensures it's above all components
                position: 'fixed', // Covers the entire screen
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
            })}
            open={true}
        >
            <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="white"
                radius="9"
                ariaLabel="three-dots-loading"
            />
        </Backdrop>
    );
}

export default Loader;
