import React from "react";
import { useState, useEffect } from "react";
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DrawerHeader from "../dashboard/DrawerHeader";
import CompanyInfo from "./companyInfo";
import ConfigurationStages from "../configuration/configrationStages";
import CompanyDoc from "./companyDoc";
import Interviewer from "./interviewer";
import SubscriptionMain from "./subscription";
import {toast, Toaster} from "react-hot-toast";
import { getCompanyInfo } from "../../../utils/employer.api/employer.api.services";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import config from "../../../utils/employer.api.config";
// const AccountSetting  = React.lazy(()=>import('./accountSetting'))
// const ProfilePictureComponent = React.lazy(() => import('./ProfilePictureComponent'));



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const SettingTabs = () => {

  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;

  const location = useLocation();

  const [showSuccessMessage, setShowSuccessMessage] = useState(location.state?.showSuccessMessage);

  const [isStatus, setStatus] = useState(localStorage.getItem("status"));
  const [isProfileExists, setIsProfileExists] = useState(false);

    const [value, setValue] = useState(parseInt(localStorage.getItem("tab")) || 0);

    // check company info status
 
    const checkInfo = async () => {
      if(isStatus === "approved"){
        try {
          const response = await getCompanyInfo(token)
          if (response.data.id) {
            setIsProfileExists(true);
          }
          else {
            localStorage.setItem("tab", 1);
            setValue(1);
          }
      } catch (error) {
          console.log(error)
      }
    }
  }
  useEffect(() => {
    if (showSuccessMessage) {
      toast.success("login successful! Let’s build your dream team.");
      setShowSuccessMessage(false);
    }
}

  , [])

  useEffect(() => {

    checkInfo()
    setStatus(localStorage.getItem("status"));

  

  return () => {
    localStorage.setItem("tab", 0);
    setValue(0);
  }
}

  , [])

    const handleChange = (event, newValue) => {
      const navigateToSettings = (message) => {
        toast.dismiss(); // Dismiss all toasts
        toast.error(message); 
      };
    
      // Handle different statuses
      switch (isStatus) {
        case "incomplete":
          navigateToSettings("Your company details are incomplete. Please update your company details.");
          break;
    
        case "pending":
          navigateToSettings("Your company is under verification. We will notify you once it is verified.");
          break;
    
        case "rejected":
          navigateToSettings("Your company is rejected. Please update your company details.");
          break;
    
        default:
          localStorage.setItem("tab", newValue);
          setValue(newValue); // Update the value if status is not blocked
      }
    };
  
    return (
        <>
        <Toaster />
            <Box component="main" sx={{ flexGrow: 1, p: 2 , mt:7}} className="mainContent" >
                {/* <h2 className="menu-heading">Setting</h2> */}
                <Box sx={{ width: '100%' }} className="setting-tabs">
                    <Box sx={{ borderBottom: 0, borderColor: 'divider' }} className="hirree-tabs">
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                            <Tab label="Company Document" {...a11yProps(0)} />
                            <Tab label="Company Info" {...a11yProps(1)} />
                            <Tab label="Configure Hiring Stages" {...a11yProps(2)} />
                            <Tab label="Interviewer" {...a11yProps(3)} />
                            {/* <Tab label="Account Settings" {...a11yProps(4)} /> */}
                            <Tab label="Subscription" {...a11yProps(4)} />
                        </Tabs>
                    </Box>

                    <CustomTabPanel value={value} index={0}>
                       <CompanyDoc/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                       <CompanyInfo/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                      <ConfigurationStages/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                      <Interviewer/>
                    </CustomTabPanel>
                    {/* <CustomTabPanel value={value} index={4}>
                    <Account/>
                    </CustomTabPanel> */}

                    {/* for subscription */}

                    <CustomTabPanel value={value} index={4}>
                        <SubscriptionMain />
                    </CustomTabPanel>

                </Box>
            </Box>
        </>
    )
}
export default SettingTabs;