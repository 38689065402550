import React, { useEffect, useState } from 'react';
import axiosClient from "../../../config/axios.Config";
import config from '../../../config/config';
import { useApplicantsContext } from '../all Applicants/applicantsContext';
import { useSelector } from 'react-redux';
import {  CircularProgress } from "@mui/material";
import { set } from 'lodash';

const ResumeViewer = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const { applicantsId } = useApplicantsContext();
  const loginData = useSelector((state) => state.auth.Auth);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(true);
    const fetchPdf = async () => {
      try {
        const response = await axiosClient.get(`${config.apiUrl}employer/${applicantsId}/resume`, {
          headers: {
            'Authorization': `Bearer ${loginData.access_token}`,
            'x-highlight-resume': 'true'
        },
          // responseType: 'blob',
        });
        
        // Create a URL for the PDF blob
        const pdfBlobUrl = response.data;
        setPdfUrl(pdfBlobUrl);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPdf();
  }, []);

  return (
    <>
      {loading ? (
      <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    ) : (
      <div style={{ width: '100%', height: '100vh' }}>
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          style={{ width: '100%', height: '100%' }}
          title="Resume PDF"
        />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
    )}
    </>
  );
};

export default ResumeViewer;
