import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Badge, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotificationsOutlined, CheckCircleOutline } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  toggleDrawer,
  LOGOUT,
  REMOVE_PARSE_CV_DATA,
} from "../../redux copy/actionTypes";
import Modal from "@mui/material/Modal";
import { Avatar, Box } from "@mui/material";
import { MenuItem } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../config/axios.Config";
import config from "../../config/config";
import Menu from "@mui/material/Menu";

const drawerWidth = 240;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  p: 4,
  borderRadius: "20px",
};

const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 0,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppBar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.auth.drawerOpen);
  const location = useLocation().pathname;
  const [name, setName] = useState(localStorage.getItem("Name") || "");
  const { pathname } = useLocation();
  console.log(location);

  const handleDrawerOpen = () => {
    dispatch(toggleDrawer(true));
  };
  const handleLogout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");

    await dispatch({ type: LOGOUT });
    await dispatch({ type: REMOVE_PARSE_CV_DATA });
    await indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => {
        indexedDB.deleteDatabase(db.name);
        console.log(`Deleted database: ${db.name}`);
      });
    });

    navigate("/candidate/login", { replace: true }); // Ensures no back navigation
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const jobTab = [
    {
      name: "Recommended Jobs",
      // icon:"images/icons/recommendations.svg",
      link: "candidate/jobs/recomendation",
    },
    {
      name: "My Applications",
      icon: "images/icons/applications.svg",
      link: "candidate/jobs/my-applications",
    },
    {
      name: "Saved Jobs",
      icon: "images/icons/saved.svg",
      link: "candidate/jobs/saved-jobs",
    },
  ];

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (!name || name.trim() === "") {
      return { sx: { bgcolor: "#ccc" }, children: "" }; // Default color & empty avatar
    }

    const nameParts = name.trim().split(" ");
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0]}${nameParts[1][0]}`
        : nameParts[0][0];

    return {
      sx: { bgcolor: stringToColor(name) },
      children: initials.toUpperCase(),
    };
  }

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const menuRef = useRef(null);

  // Function to open menu
  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  // Function to close menu
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Close menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleCloseDialog();
      }
    }

    if (openDialog) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDialog]);

  const logout = async () => {
    dispatch({
      type: "LOGOUT",
    });

    await localStorage.removeItem("formData");
    await localStorage.removeItem("token");

    navigate("/candidate/login", { replace: true });
    setOpenModal(false);
  };
  //
  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;
  const BASE_URL = config.apiUrl;
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openNot = Boolean(anchorEl);
  const [notifications, setNotifications] = useState([]);

  // const unreadCount = notifications.filter((n) => !n.is_read).length;

  const handleOpenNot = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchNotifications = async () => {
    try {
      const response = await axiosClient.get(
        `${BASE_URL}candidate/notifications?skip=0&limit=10`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const newData = response.data || [];
      setNotifications(newData);
      setUnreadCount(newData.filter((n) => !n.is_read).length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  //   const handleWebSocketMessage = (message) => {
  //     if (message === "ping" || message === "pong") return; // Ignore these messages
  //       console.log("Message received on the jsx:", message);
  //     try {
  //         const parsedMessage = JSON.parse(message);
  //         if (parsedMessage.type === "notification") {
  //             const msg = JSON.parse(parsedMessage.data);
  //             setNotifications([msg.messages[0], ...notifications]);
  //             setUnreadCount(unreadCount + 1);
  //             console.log("Notification received:", msg);
  //         }
  //     } catch (error) {
  //         console.warn("Non-JSON message received, ignoring:", message);
  //     }
  // };

  // useWebSocket(`ws://localhost:8000/ws/notifications/candidate/67ade9336ff253083e0c9164?token=${token}`, handleWebSocketMessage);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleCloseNot = () => {
    setAnchorEl(null);
  };

  const handleNotificationRead = (id) => {
    // backend call to mark notification as read

    // string list of notification ids
    const notificationIds = Array.isArray(id) ? id : [id];

    axiosClient
      .post(`${BASE_URL}candidate/mark-read`, notificationIds, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Notification marked as read:", response.data);
        setUnreadCount(unreadCount - notificationIds.length);
        // setUnreadCount(response.data.updated_count);
      })
      .catch((error) => {
        console.error("Error marking notification as read:", error);
      });
  };

  const markAsRead = (id) => {
    setNotifications(
      notifications.map((n) => (n.id === id ? { ...n, is_read: true } : n))
    );
    handleNotificationRead(id);
  };

  const markAllAsRead = () => {
    setNotifications(notifications.map((n) => ({ ...n, is_read: true })));
    // send list of all notification ids
    handleNotificationRead(notifications.map((n) => n.id));
  };

  const handleSeeMore = () => {
    navigate("/candidate/notifications");
  };

  return (
    <AppBarStyled position="fixed" open={open} className="appbar">
      <Toolbar>
        <IconButton
          color="#000"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>

        {location === "/candidate/dashboard" && (
          <Typography variant="h6" noWrap component="div">
            <h2 className="logo text-2xl font-semibold font-family">
              <span className="color-text font-family">
                Welcome Back, Let’s Find Your Job!
              </span>
            </h2>
          </Typography>
        )}

        {location.includes("/candidate/jobs") && (
          <div className="flex flex-wrap gap-4">
            {jobTab.map((tab, index) => {
              const isActive = `${location.substring(1)}` === tab.link;
              console.log("tab.link", tab.link);
              return (
                <a
                  href={tab.link}
                  key={index}
                  className={`flex gap-2 items-center px-2 py-1  text-sm font-extrabold
                            ${
                              isActive
                                ? "bg-active-button-gradient text-[#516AF4] rounded-lg text-[14px]"
                                : "text-[#5E5E5E]"
                            } hover:bg-gradient-to-r from-purple-200 to-sky-200 hover:text-[#516AF4] hover:rounded-lg`}
                >
                  {/* <img 
                  src={tab.icon} 
                  alt={tab.name} 
                  className="w-6 h-6"
                /> */}
                  <span className="font-extrabold">{tab.name}</span>
                </a>
              );
            })}
          </div>
        )}

        {/* <button className='theme-btn ms-auto' onClick={handleOpen}>
          Logout
        </button> */}
      </Toolbar>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
          >
            Are you sure want to logout ?
          </Typography>

          <div className="justify-center items-center mt-5 flex">
            <button
              onClick={handleClose}
              color="primary"
              sx={{ mt: 2 }}
              className="py-2.5 px-5 me-2 mb-2  text-sm font-medium text-black rounded-lg bg-gray-300"
            >
              No
            </button>
            <button
              onClick={handleLogout}
              sx={{ mt: 2 }}
              className=" py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn   rounded-lg"
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>

      <div className="absolute top-3 right-4 flex items-center gap-3 p-0 ">
        {/* <div className="flex flex-col text-right">
            <Typography className="text-sm font-semibold text-gray-800">
              {localStorage.getItem("Name")}
            </Typography>
          </div> */}

        <div className="relative">
          {location.pathname === "/candidate/notifications" ? (
            <IconButton
              onClick={handleOpenNot}
              color="inherit"
              disabled
              // className="bg-gradient-to-r from-[#C1EBF3] to-[#E4D0F4] text-blue-600"
            >
              {/* outlined */}
              <NotificationsIcon fontSize="large" className="text-gray-600" />
            </IconButton>
          ) : (
            // filled with bg-gradient-to-r from-[#C1EBF3] to-[#E4D0F4] and text-blue-600 and disabled
            <IconButton onClick={handleOpenNot} color="inherit">
              <Badge badgeContent={unreadCount} color="error">
                {openNot ? (
                  <NotificationsIcon
                    fontSize="large"
                    className="text-gray-600"
                  />
                ) : (
                  <NotificationsOutlined
                    fontSize="large"
                    className="text-gray-600"
                  />
                )}
              </Badge>
            </IconButton>
          )}

          <Menu
            anchorEl={anchorEl}
            open={openNot}
            onClose={handleCloseNot}
            PaperProps={{
              className: "w-80 max-h-[400px] overflow-hidden",
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* Header */}
            <div className="flex items-center justify-between p-3 border-b border-gray-200 bg-gray-50">
              <div className="flex items-center gap-2">
                {/* <NotificationsOutlined className="text-gray-600" /> */}
                <span className="font-medium text-gray-900">Notifications</span>
              </div>
              {unreadCount > 0 && (
                <button
                  onClick={markAllAsRead}
                  className="text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
                >
                  <CheckCircleOutline className="w-4 h-4" />
                  <span>Mark all read</span>
                </button>
              )}
            </div>

            {/* Notifications List */}
            <div className="overflow-y-auto max-h-[300px]">
              {notifications.length > 0 ? (
                notifications.slice(0, 4).map((notification) => (
                  <MenuItem
                    key={notification.id}
                    onClick={() => markAsRead(notification.id)}
                    className={`
                group px-3 py-2.5 hover:bg-gray-50
                ${notification.is_read ? "bg-white" : "bg-blue-50"}
              `}
                  >
                    <div className="flex items-start justify-between w-full gap-3">
                      <p className="text-sm text-gray-900 flex-1 text-wrap break-words">
                        {notification.messages[0]}
                      </p>
                      <div className="flex flex-col items-end gap-2">
                        <span className="text-xs text-gray-500 whitespace-nowrap">
                          {new Date(notification.timestamp).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </span>
                        {!notification.is_read && (
                          <div className="w-2 h-2 rounded-full bg-blue-600" />
                        )}
                      </div>
                    </div>
                  </MenuItem>
                ))
              ) : (
                <div className="py-8">
                  <p className="text-sm text-gray-500 text-center">
                    No notifications
                  </p>
                </div>
              )}
            </div>

            {/* Footer */}
            {notifications.length > 4 && (
              <MenuItem
                onClick={handleSeeMore}
                className="justify-center text-sm text-blue-600 hover:text-blue-700 hover:bg-gray-50 border-t border-gray-100"
              >
                See all notifications
              </MenuItem>
            )}
          </Menu>
        </div>

        {/* <div className="relative">
          <button
            id="basic-button"
            aria-haspopup="true"
            aria-expanded={openDialog ? "true" : undefined}
            onClick={handleOpenDialog}
          >
            <Avatar className="" {...stringAvatar(`${name}`)} />
          </button>

          {openDialog && (
            <div
              ref={menuRef} // Reference for detecting outside click
              className="absolute top-14 right-4 w-40 bg-white shadow-md rounded-lg"
            >
              <MenuItem
                className="menu-name"
                onClick={() => {
                  navigate("/candidate/setting");
                  handleCloseDialog();
                }}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  fontSize: "14px",
                }}
              >
                <img
                  src="images/icons/setting.svg"
                  className="mr-2 py-1"
                  alt="Settings"
                />
                <span className="text-black menu-name ml-[9px]">Settings</span>
              </MenuItem>
              <MenuItem
                className="menu-name"
                onClick={() => {
                  handleOpen();
                }}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  fontSize: "14px",
                }}
              >
                <img
                  src="images/icons/logout.svg"
                  className="mr-2"
                  alt="Logout"
                />
                <span className="text-black">Logout</span>
              </MenuItem>
            </div>
          )}
        </div> */}
      </div>
    </AppBarStyled>
  );
}
