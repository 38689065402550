import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DrawerHeader from "./DrawerHeader";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import { Avatar, Box, Popover } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import FeedBack from "./feedBack";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Paper, Fab, InputAdornment, TextField } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Send, AttachFile, Close } from "@mui/icons-material";
import Chatbot from "./chatbot";
import {
  toggleDrawer,
  LOGOUT,
  REMOVE_PARSE_CV_DATA,
} from "../../redux copy/actionTypes";

const drawerWidth = 240;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerStyled = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  height: "100%",
  zIndex: theme.zIndex.drawer - 1,

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// remove th currentPage from the local storage while swiching beetween menu

const menuList = [
  {
    name: "Dashboard",
    icon: "images/icons/home.svg",
    link: "dashboard",
  },
  {
    name: "Find Job",
    icon: "images/icons/find_job.svg",
    link: "find-job",
  },
  // {
  //   name:"Recommendations",
  //   icon:"images/icons/recommendations.svg",
  //   link:"jobs/recomendation"
  // },
  // {
  //   name:"My Applications",
  //   icon:"images/icons/applications.svg",
  //   link:"jobs/my-applications"
  // },
  // {
  //   name:"Saved Jobs",
  //   icon:"images/icons/saved.svg",
  //   link:"jobs/saved-jobs"
  // },
  {
    name: "Jobs",
    icon: "images/icons/job.svg",
    link: "jobs",
  },
  {
    name: "Interviews",
    icon: "images/icons/calender.svg",
    link: "interviews",
  },
  // {
  //   name: "Settings",
  //   icon: "images/icons/setting.svg",
  //   link: "setting",
  // },
];
const menuList2 = [
  // {
  //   name:"Settings",
  //   icon:'images/icons/setting.svg',
  //   link:"setting"
  // },
  // {
  //   name:"Help centre",
  //   icon:"images/icons/help.svg",
  //   link:""
  // }
];
export default function Drawer() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const open = useSelector((state) => state.auth.drawerOpen);
  const dispatch = useDispatch();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { pathname } = useLocation();

  const handleOpenFeedBack = () => {
    console.log("Opening Feedback Modal");
    setIsFeedbackOpen(true);
  };

  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    await indexedDB.databases().then(dbs => {
      dbs.forEach(db => {
          indexedDB.deleteDatabase(db.name);
          console.log(`Deleted database: ${db.name}`);
      });
  });

    await dispatch({ type: LOGOUT });
    await dispatch({ type: REMOVE_PARSE_CV_DATA });

    navigate("/candidate/login", { replace: true });  
    setOpenModal(false);
  };

  useEffect(() => {
    localStorage.removeItem("currentPage");
  }, []);



  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const openD = Boolean(anchorEl);
  const [avatar, setAvatar] = useState(
    localStorage.getItem("Name") || "Employer"
  );
  // Function to open menu
  const handleOpenDialog = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenDialog(!openDialog);
  };

  // Function to close menu
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "If you need any help browsing our website just shout.\n\nBtw i'm Annie.",
      sender: "agent",
      timestamp: "7:22pm",
      avatar: "/path-to-annie-avatar.png",
      name: "Annie Smith",
    },
    {
      id: 2,
      text: "Hi Annie,\n\nJames here. Can you help me out? I'm looking for a new jumper. Got any for me to look at?",
      sender: "user",
      timestamp: "7:22pm",
      avatar: "/path-to-james-avatar.png",
      name: "James",
    },
  ]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      scrollToBottom();
    }
  }, [messages, isOpen]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleSendMessage = (e) => {
    e?.preventDefault();
    if (message.trim() === "") return;

    const newUserMessage = {
      id: messages.length + 1,
      text: message,
      sender: "user",
      timestamp: new Date()
        .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
        .toLowerCase(),
      avatar: "/path-to-james-avatar.png",
      name: "James",
    };

    setMessages([...messages, newUserMessage]);
    setMessage("");
  };

  const sendMessage = () => {
    if (!message.trim()) return;
    setMessages([
      ...messages,
      { text: message, sender: "user", time: "7:23pm" },
    ]);
    setMessage("");
  };

  const chatRef = useRef(null); // Reference for the chat container

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatRef.current && !chatRef.current.contains(event.target)) {
        toggleChat(false); // Close chat when clicking outside
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const messagesEndRefB = useRef(null); // Reference for the last message

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <DrawerStyled variant="permanent" open={open}>
      <DrawerHeader />
      <List>
        {menuList.map((item, index) => {
          const isActive = pathname.includes(config.candidateUrl + item.link);

          return (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <div className="flex justify-center items-center">
                {/* Active Indicator */}
                <div
                  className={`w-[4px] h-[32px] rounded-[4px] ${
                    isActive ? "bg-[#516AF4]" : "bg-transparent"
                  }`}
                ></div>

                <ListItemButton
                  component={isActive ? "div" : Link} // Prevent navigation when active
                  to={isActive ? "#" : `${config.candidateUrl}${item.link}`}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    borderRadius: 2,
                    mx: 1,
                    pointerEvents: isActive ? "none" : "auto", // Disable click
                    opacity: isActive ? 0.9 : 1, // Reduce visibility
                  }}
                  className={
                    isActive
                      ? "bg-active-button-gradient text-[#516AF4]"
                      : "text-[#5E5E5E]"
                  }
                >
                  <Tooltip title={item.name} placement="right">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img src={item.icon} alt={item.name} />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    className="menu-name"
                    sx={{ opacity: open ? 1 : 0 }}
                  >
                    {item.name}
                  </ListItemText>
                </ListItemButton>
              </div>
            </ListItem>
          );
        })}

        <ListItem disablePadding sx={{ display: "block" }}>
          <div className="flex  justify-center items-center">
            <div
              className={`w-[4px] h-[32px] rounded-[4px]   ${
                isFeedbackOpen ? " bg-[#516AF4]" : " bg-transparent "
              }`}
            ></div>
            <ListItemButton
              component="a"
              onClick={handleOpenFeedBack}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                borderRadius: 2,
                mx: 1,
              }}
              // className={`${
              //     isFeedbackOpen
              //       ? "bg-active-button-gradient text-[#516AF4]"
              //       : "text-[#5E5E5E]"
              //   }`}
            >
              <Tooltip title="Feedback" placement="right">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="images/icons/feedback.svg" alt="Feedback Icon" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                className="menu-name "
                sx={{ opacity: open ? 1 : 0 }}
              >
                Feedback
              </ListItemText>
            </ListItemButton>
          </div>
        </ListItem>
      </List>
      <List>
        {menuList2.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <div className="flex  justify-center items-center">
              <div
                className={`w-[4px] h-[32px] rounded-[4px]   ${
                  pathname === config.candidateUrl + item.link
                    ? " bg-[#516AF4]"
                    : " bg-transparent "
                }`}
              ></div>
              <ListItemButton
                component={Link}
                to={`${config.candidateUrl}${item.link}`}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  borderRadius: 2,
                  mx: 1,
                }}
                className={`${
                  pathname === config.candidateUrl + item.link
                    ? "bg-active-button-gradient text-[#516AF4]"
                    : "text-[#5E5E5E]"
                }`}
              >
                <Tooltip title={item.name} placement="right">
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src={item.icon} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  className="menu-name"
                  sx={{ opacity: open ? 1 : 0 }}
                >
                  {item.name}
                </ListItemText>
              </ListItemButton>
            </div>
          </ListItem>
        ))}




      </List>
      <FeedBack open={isFeedbackOpen} setOpen={setIsFeedbackOpen} />

      {/* <List style={{ top: "10%" }}>
        <ListItem disablePadding sx={{ display: "block" }}>
          <div className="flex  justify-center items-center">
            <div
              className={`w-[4px] h-[32px] rounded-[4px]   ${
                isFeedbackOpen ? " bg-[#516AF4]" : " bg-transparent "
              }`}
            ></div>
            <ListItemButton
              onClick={handleOpen}
              component={Link}
              //  to={`${config.employerUrl}${item.link}`}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <Tooltip title="Log-out" placement="right">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="images/icons/logout.svg" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              >
                Log-out
              </ListItemText>
            </ListItemButton>
          </div>
        </ListItem>

      </List> */}

            {/* avatar */}
            <div
        className={`absolute left-0 bottom-12 ${
          open ? "left-[14px]" : "left-[4px]"
        }`}
        onClick={handleOpenDialog}
      >
        {/* Button to open menu */}
        <button
          id="basic-button"
          aria-haspopup="true"
          aria-expanded={openDialog ? "true" : undefined}
          onClick={handleOpenDialog}
          className="m-2 flex justify-center items-center gap-2"
        >
          <Avatar className="" {...stringAvatar(avatar)} />{open && avatar}
        </button>

        {/* Popover Menu (Opens Above) */}
        <Popover
          open={openDialog}
          anchorEl={anchorEl} // Correctly positioned
          onClose={handleCloseDialog}
          anchorOrigin={{
            vertical: "top", // Attach at the top of the avatar
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom", // Move the popover upwards
            horizontal: "center",
          }}
          PaperProps={{
            className: "shadow-lg bg-white rounded-lg p-2 z-[9999]", // High z-index
          }}
          sx={{
            mb: 1,
            "& .MuiPaper-root": {
              borderRadius: "12px",
            },
          }}
        >
          <div className="w-40">
            <MenuItem
              className="flex items-center text-sm"
              onClick={() => {
                console.log("Settings Clicked");
                navigate("/candidate/setting");
                handleCloseDialog();
              }}
            >
              <img
                src="images/icons/setting.svg"
                className="mr-2 py-1"
                alt="Settings"
              />
              <span className="text-black">Settings</span>
            </MenuItem>
            <MenuItem
              className="flex items-center text-sm"
              onClick={handleOpen}
            >
              <img
                src="images/icons/logout.svg"
                className="mr-2"
                alt="Logout"
              />
              <span className="text-black">Logout</span>
            </MenuItem>
          </div>
        </Popover>
      </div>

      <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
            >
              Are you sure you want to logout?
            </Typography>
            <div className="justify-center items-center mt-5 flex">
              <button
                onClick={handleClose}
                className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300"
              >
                No
              </button>
              <button
                onClick={logout}
                className="py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium theme-btn rounded-lg"
              >
                Yes
              </button>
            </div>
          </Box>
        </Modal>
      {/* <div className="absolute top-4 right-4 flex items-center gap-3 p-3 rounded-lg hover:bg-gray-100 transition-all">

  <div className="flex flex-col text-right">
    <Typography className="text-sm font-semibold text-gray-800">
      {localStorage.getItem("Name")}
    </Typography>
  </div>

  <Avatar
    className="border-2 border-blue-500 shadow-md"
    {...stringAvatar(`${localStorage.getItem("Name")}`)}
  />
</div> */}
      {pathname !== "/candidate/history" && <Chatbot />}
    </DrawerStyled>
  );
}
