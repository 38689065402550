import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../dashboard/Appbar';
import Drawer from '../dashboard/Drawer';
import TotalApplicantsCards from './totalApplicants';
import { ApplicantsProvider } from '../all Applicants/applicantsContext';

export default function TotalApplicants() {
  return (
    <Box sx={{ display: 'flex' }}>
      {/* <CssBaseline /> */}
      <AppBar />
      <Drawer />
      <ApplicantsProvider>
      <TotalApplicantsCards />
      </ApplicantsProvider>

    </Box>
  );
}
