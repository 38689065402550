import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
  Card,
  CardContent,
  Chip,
  Container,
  Autocomplete,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import SecurityIcon from "@mui/icons-material/Security";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import { Dialog } from "@mui/material";
import { styled } from "@mui/system";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import axiosClient from "../../../config/axios.Config";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    background: "linear-gradient(145deg, #c4f6ff 0%, #efd8ff 100%)",
    borderRadius: "16px",
    padding: theme.spacing(2),
    maxWidth: "600px",
    width: "100%",
  },
}));

const showPaymentSuccessToast = () => {
  toast(
    ({ closeToast }) => (
      <div>
        <p>Payment successful!</p>
        <button
          onClick={closeToast}
          style={{
            marginTop: "10px",
            padding: "5px 10px",
            background: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          OK
        </button>
      </div>
    ),
    {
      autoClose: false, // Prevents auto-closing
      closeOnClick: false, // Prevents closing when clicking outside
      draggable: false,
      closeButton: false, // Removes the default close button
    }
  );
};

const SubscriptionMain = () => {
  const BASE_URL = config.apiUrl;
  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [payAmount, setPayAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponAppliedValue, setCouponAppliedValue] = useState();
  const [user, setUser] = useState();
  const [couponsList, setCouponsList] = useState([]);
  const [activePlan, setActivePlan] = useState(null);

  const navigate = useNavigate();

  const featuresList = [
    "Post up to 5 job openings",
    "Access to 20 candidate profiles",
    "Basic analytics",
    "Email notifications for applications",
    "Priority listing for job postings",
    "Customizable email templates",
    "Premium analytics and insights",
  ];

  const plans = [
    {
      name: "Basic",
      price: "999",
      period: "1 week",
      description:
        "Ideal for small businesses or startups exploring hiring solutions.",
      isPopular: false,
      features: [true, true, true, true, false, false, false],
      buttonText: "Current plan",
      isCurrent: "1 week" === activePlan,
    },
    {
      name: "Standard",
      price: "1999",
      period: "20 days",
      description: "Perfect for growing businesses with ongoing hiring needs.",
      isPopular: true,
      features: [true, true, true, true, true, true, false],
      buttonText: "Select plan",
      isCurrent: "20 days" === activePlan,
    },
    {
      name: "Premium",
      price: "2999",
      period: "1 month",
      description:
        "Designed for enterprises seeking top talent with advanced tools.",
      isPopular: false,
      features: [true, true, true, true, true, true, true],
      buttonText: "Select plan",
      isCurrent: "1 month" === activePlan,
    },
  ];

  // checking payment
  useEffect(() => {
    const checkPayment = async () => {
      try {
        const response = await axiosClient.get(
          `${BASE_URL}employer/payment-status`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Payment status:", response.data);
        // alert("Payment already done!");
        // toast.success("Payment already done!");
        setActivePlan(response.data?.payment_details?.subscription_plan);
      } catch (err) {
        console.error("Error checking payment status:", err.message);
      }
    };

    checkPayment();
  }, [token]);

  useEffect(() => {
    // Fetch coupons from server
    const fetchCoupons = async () => {
      try {
        const response = await axiosClient.get(
          `${BASE_URL}employer/coupons/available`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Coupons response:", response.data);
        setCouponsList(response.data);
        //   couponsList.forEach((coupon) => {
        //     if (coupon.coupon_type === "percentage") {
        //         coupon.discount_details = coupon.discount.replace('%', '');  // Remove "%"
        //     } else {
        //         coupon.discount_details = coupon.discount.replace(/₹/g, ''); // Remove "₹"
        //     }
        // });
      } catch (err) {
        console.error("Error fetching coupons:", err.message);
      }
    };

    // fetch user data
    const fetchUser = async () => {
      try {
        const response = await axiosClient.get(
          `${BASE_URL}employer/employer-info`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("User response:", response.data);
        setUser(response.data);
      } catch (err) {
        console.error("Error fetching user data:", err.message);
      }
    };

    fetchUser();

    fetchCoupons();
  }, [token]);

  const calculateAmount = (amt, dis = 0) => {
    // calculate amount
    let discount = dis;

    // tax
    let tax = parseInt(amt) * 0.18; // 18% GST

    // discount
    if (discount) {
      tax = parseInt((amt - discount) * 0.18);
    }

    console.log("Tax:", tax);

    setTaxAmount(parseInt(tax));
    setPayAmount(parseInt(amt) + parseInt(tax) - parseInt(discount));
  };

  const handleButtonClick = (plan) => {
    if (!plan.isCurrent) {
      setCurrentPlan(plan);
      setSelectedPlan(plan.name);

      calculateAmount(plan.price);

      setOpenDialog(true); // Open the dialog after plan selection
    }
  };

  const handleCloseDialog = () => {
    setCouponAppliedValue(null);
    setOpenDialog(false);
    setSelectedPlan(null);
    setCurrentPlan(null);
  };

  const handleApplyCoupon = () => {
    const couponData = couponsList.find((c) => c.coupon_name === coupon);

    if (!couponData) return;

    console.log("Coupon found:", couponData);
    // Remove "₹"

    let discount = 0;
    if (couponData.coupon_type === "Percentage") {
      couponData.discount_details = couponData.discount_details.replace(
        "%",
        ""
      ); // Remove "%"
      discount = parseInt(
        (currentPlan.price * couponData.discount_details) / 100
      ); // No .toFixed here yet
    } else if (couponData.coupon_type === "Flat Amount") {
      couponData.discount_details = couponData.discount_details.replace(
        /₹/g,
        ""
      );
      discount = parseInt(couponData.discount_details);
    }

    const discountedPrice = currentPlan.price - discount; // Price after applying discount
    const newTax = parseInt(discountedPrice * 0.18); // GST (18% of discounted price)
    const newPayAmount = parseInt(discountedPrice + parseInt(newTax)); // Final amount = discounted price + tax

    console.log("Discounted Price:", discountedPrice);
    console.log("New GST:", newTax);

    setCouponAppliedValue(discount); // Store discount value (rounded)
    calculateAmount(currentPlan?.price, discount);
  };

  // paymentHandler
  const paymentHandler = async (e) => {
    e.preventDefault();

    try {
      // Create order on server
      const response = await axiosClient.post(
        `${BASE_URL}employer/create-order`,
        {
          amount: parseInt(payAmount),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const { order_id } = response.data;
      console.log("Order ID:", order_id);
      const { currency } = response.data;
      console.log("Currency:", currency);
      const { amount } = response.data;
      const pay = parseInt(amount) * 1.18;

      // console.log("Amount in paise:", payInPaise);

      // Razorpay options
      const options = {
        key: "rzp_test_omY72uymXLtw4V", // Use live key in production
        amount: pay.toString(), // Amount is in currency subunits. Default currency is INR.
        currency: currency,
        // name: "Hirree",
        description: "Test Transaction",
        image: "/images/hirree.svg",
        order_id,
        handler: async (response) => {
          try {
            // Validate payment with server
            const validateRes = await axiosClient.post(
              `${BASE_URL}employer/verify-payment`,
              response,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log("Payment validation response:", validateRes.data);
            // alert("Payment successful!");
            // showPaymentSuccessToast();
            toast.success("Payment successful!");

            // Close the dialog
            setOpenDialog(false);

            // Redirect to settings page delay 1 second
            setTimeout(() => {
              navigate("/employer/setting");
            }, 2000);
          } catch (err) {
            console.error("Payment validation failed:", err.message);
            // alert("Payment validation failed. Please contact support.");
            toast.error("Payment validation failed. Please contact support.");
          }
        },
        prefill: {
          name: user.company_name || "Web Dev Matrix",
          email: user.company_email || "webdevmatrix@example.com",
          contact: user.phone_number || "9000000000",
        },
        notes: {
          address: "Razorpay Corporate Office",
          subscription_plan: currentPlan.period,
        },
        theme: {
          color: "#3399cc",
        },
      };

      // Ensure Razorpay is loaded
      if (!window.Razorpay) {
        toast.error("Razorpay SDK failed to load. Please try again.");
        return;
      }

      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", (response) => {
        toast.error("Payment failed. Please try again.");
      });

      rzp1.open();
    } catch (err) {
      console.error("Error initiating payment:", err.message);
      alert("Error occurred while processing payment. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-[#efd8ff] to-[#c4f6ff] w-full h-full">
      <Toaster />
      {/* home url */}
      <a
        href="/employer/setting"
        className="text-blue-600 font-semibold text-lg"
      >
        <img src="images/hirree.svg" className="w-48 pl-10 pt-10" alt="Logo" />
      </a>

      <Container maxWidth="lg">
        <Box className="text-center mb-12">
          <Typography
            variant="h2"
            component="h1"
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            Our Pricing Plans
          </Typography>
          <Typography variant="h6" className="text-gray-600">
            Pick an account plan that fits your workflow
          </Typography>
        </Box>

        <Grid container spacing={4} justifyContent="center">
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.name}>
              <Card
                elevation={plan.isPopular ? 8 : 2}
                onClick={() => handleButtonClick(plan)}
                className={`relative transform transition-all duration-300 cursor-pointer hover:shadow-xl ${
                  selectedPlan === plan.name
                    ? "border-4 border-blue-500"
                    : "border-2 border-gray-200"
                }`}
                style={{ height: "100%" }}
              >
                {plan.isPopular && (
                  <Chip
                    icon={<StarIcon />}
                    label="Popular Choice"
                    color="primary"
                    className="absolute bg-blue-500 text-white"
                  />
                )}

                <CardContent className="p-[32px] mt-6">
                  <Box className="text-center mb-6">
                    <Typography
                      variant="h5"
                      component="h3"
                      className="font-bold text-gray-900 mb-2"
                    >
                      {plan.name}
                    </Typography>
                    <Box className="flex justify-center items-baseline mb-2">
                      <Typography
                        variant="h3"
                        component="span"
                        className="font-extrabold text-blue-600"
                      >
                        ₹{plan.price}
                      </Typography>
                      {plan.period && (
                        <Typography
                          variant="subtitle1"
                          component="span"
                          className="text-gray-500 ml-1"
                        >
                          {plan.period}
                        </Typography>
                      )}
                    </Box>
                    <Typography variant="body2" className="text-gray-600">
                      {plan.description}
                    </Typography>
                  </Box>

                  <Box className="mb-6">
                    {featuresList.map((feature, index) => (
                      <Box key={index} className="flex items-center mb-3">
                        {plan.features[index] ? (
                          <>
                            <CheckIcon
                              className="text-green-500 mr-2"
                              fontSize="small"
                            />
                            <Typography
                              variant="body2"
                              className="text-gray-700"
                            >
                              {feature}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CloseIcon
                              className="text-red-500 mr-2"
                              fontSize="small"
                            />
                            <Typography
                              variant="body2"
                              className="text-gray-400 line-through"
                            >
                              {feature}
                            </Typography>
                          </>
                        )}
                      </Box>
                    ))}
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    color={plan.isCurrent ? "primary" : "secondary"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleButtonClick(plan);
                    }}
                  >
                    {/* {plan.buttonText} */}
                    {plan.isCurrent ? "Current Plan" : "Select Plan"}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Dialog for Summary */}
      {/* Dialog for Summary */}

      {currentPlan && (
        <StyledDialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="sm"
        >
          <Box className="w-full max-w-6xl mx-auto p-2">
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <Card className="h-full shadow-xl rounded-2xl">
                  <CardContent>
                    <Box className="flex justify-between items-center mb-6">
                      <Typography
                        variant="h5"
                        className="font-extrabold text-gray-800"
                      >
                        Order Summary
                      </Typography>
                      <Box className="flex items-center text-gray-500">
                        <LockIcon className="w-5 h-5 mr-2" />
                        <Typography variant="body2">Secure Checkout</Typography>
                      </Box>
                    </Box>
                    <Box className="bg-gray-50 p-6 rounded-xl mb-6 shadow-md">
                      <Box className="flex justify-between items-start">
                        <Box>
                          <Typography variant="h6" className="font-bold mb-2">
                            {currentPlan.name} Plan
                          </Typography>
                          <Typography
                            variant="body2"
                            className="text-gray-600 mb-2"
                          >
                            {currentPlan.description}
                          </Typography>
                          <Typography variant="body2" className="font-medium">
                            Duration: {currentPlan.period}
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          className="font-bold text-blue-600"
                        >
                          ₹{currentPlan.price}
                        </Typography>
                      </Box>
                    </Box>
                    {/* coupon */}
                    <Box className="mb-6 flex space-x-2">
                      <Autocomplete
                        freeSolo
                        options={couponsList.map(
                          (coupon) =>
                            `${coupon.coupon_code} - ${coupon.discount_details} OFF`
                        )} //${'\u00A0'.repeat(45)}
                        fullWidth
                        onInputChange={(event, newValue) => {
                          const selectedCoupon = couponsList.find((coupon) =>
                            newValue.startsWith(coupon.coupon_code)
                          );
                          if (selectedCoupon) {
                            setCoupon(selectedCoupon.coupon_code);
                          }
                          calculateAmount(currentPlan?.price);
                          setCouponAppliedValue(null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Coupon Code"
                            variant="outlined"
                            size="small"
                            className="mb-2"
                          />
                        )}
                      />
                      <Button
                        variant="contained"
                        color={couponAppliedValue ? "primary" : "inherit"} // "inherit" instead of "default"
                        className="shadow-md"
                        onClick={handleApplyCoupon}
                        disabled={couponAppliedValue}
                      >
                        {couponAppliedValue ? "Applied" : "Apply"}
                      </Button>
                    </Box>
                    <Typography
                      variant="h6"
                      className="font-bold mb-4 text-gray-800"
                    >
                      Payment Summary
                    </Typography>
                    <Box className="space-y-3 mb-6 text-gray-700">
                      <Box className="flex justify-between">
                        <Typography className="text-gray-600">
                          Subtotal
                        </Typography>
                        <Typography>₹{currentPlan.price}</Typography>
                      </Box>
                      {couponAppliedValue && (
                        <Box className="flex justify-between">
                          <Typography className="text-gray-600">
                            Coupon Discount
                          </Typography>
                          <Typography>- ₹{couponAppliedValue}</Typography>
                        </Box>
                      )}
                      <Box className="flex justify-between">
                        <Typography className="text-gray-600">GST</Typography>
                        <Typography>+ ₹{taxAmount}</Typography>
                      </Box>
                      <Divider className="my-2" />
                      <Box className="flex justify-between font-bold text-gray-800">
                        <Typography>Total</Typography>
                        <Typography className="text-blue-600">
                          ₹{payAmount}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={paymentHandler}
                      className="bg-blue-600 hover:bg-blue-700 py-3 text-lg font-semibold mb-4 shadow-lg"
                    >
                      Pay ₹{payAmount}
                    </Button>
                    <Box className="flex justify-center items-center text-gray-500 mb-4">
                      <SecurityIcon className="w-5 h-5 mr-2 my-1" />
                      <Typography variant="body2">
                        Secure payment processing
                      </Typography>
                    </Box>
                    <Divider className="mb-4" />
                    <Typography
                      variant="body2"
                      className="text-center text-gray-600"
                    >
                      By proceeding, you agree to our{" "}
                      <span className="text-blue-600 cursor-pointer hover:underline">
                        Terms of Service
                      </span>{" "}
                      and{" "}
                      <span className="text-blue-600 cursor-pointer hover:underline">
                        Privacy Policy
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </StyledDialog>
      )}
    </div>
  );
};

export default SubscriptionMain;
