import React from "react";
import { Link } from "react-router-dom";
import config from "../../config/config";

const LeftImage = () => {
    return (
        <>
            <div className="px-5 pt-10 img-left">

                    {/* <img src="images/candi_login.jpeg" className="login-bg" /> */}
                    <div className="hidden md:flex flex-col justify-center h-full p-8 md:p-9">
                {/* Image Section */}
                <div className="w-full justify-center hidden md:flex">
                  <img
                    src="images/candi_login.jpeg"
                    alt="Employer Login"
                    className="w-8/11 max-w-md h-auto rounded-lg"
                  />
                </div>
              </div>

            </div>
        </>
    )
}
export default LeftImage