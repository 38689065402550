import axiosClient from "../../config/axios.Config";

export const getIndustry = () => {
  return axiosClient("employer/industries", "", "GET");
};
export const getCurrency = () => {
  return axiosClient("employer/currencies", "", "GET");
};
export const getProfileInfo = (token) => {
    return axiosClient("employer/company-info", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const getHiringStage = (token) => {
    return axiosClient("employer/hiring-stages", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const getJobs = (token) => {
    return axiosClient("employer/jobs", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
export const getCompanyDocs = (token) => {
  return axiosClient("employer/company-details", "", "GET", {
    Authorization: `Bearer ${token}`,
  });
};

  export const getCompanyInfo = (token) => {
    return axiosClient("employer/company-info", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };

  export const getUniqueRoles = (token) => {
    return axiosClient("employer/unique-applied-roles", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const getInterviewList = (token,applicant_id) => {
    return axiosClient(`employer/applicant-interviews/list-interviews/${applicant_id}`, "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const getHiringProgress = (token,applicant_id) => {
    return axiosClient(`employer/hiring-progress/${applicant_id}`, "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const DeleteInteview = (token,interview_id) => {
    return axiosClient(`employer/applicant-interviews/delete/${interview_id}`, "", "DELETE", {
      Authorization: `Bearer ${token}`,
    });
  };
  
  export const getInterviewersList = (token) => {
    return axiosClient("employer/interviewers-list", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  }

  export const scheduleInterviewAPI = (jobId, payload, token) => {
    return axiosClient(`employer/${jobId}/schedule-interview`, payload, "POST", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const updateInterviewAPI = (interview_id, payload, token) => {
    return axiosClient(`employer/applicant-interviews/edit/${interview_id}`, payload, "PUT", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const MovetoNextStep = (token,applicantId, payload) => {
    return axiosClient(`employer/hiring-progress/${applicantId}/next-round`, payload, "POST", {
        Authorization: `Bearer ${token}`,
    });
};
export const RejectApplicants = (token,applicantId, payload) => {
  return axiosClient(`employer/hiring-progress/${applicantId}/reject`, payload, "POST", {
      Authorization: `Bearer ${token}`,
  });
};
export const submitFeedback = (token,applicantId, payload) => {
  return axiosClient(`employer/hiring-progress/${applicantId}/feedback`, payload, "POST", {
      Authorization: `Bearer ${token}`,
  });
};
  
export const getEmployerName = (token) => {
  return axiosClient("employer/employer-details", "", "GET", {
    Authorization: `Bearer ${token}`,
  });
}

export const getInterviewConflicts = (token, payload) => {
  return axiosClient("employer/check-interview-conflicts", payload, "POST", {
    Authorization: `Bearer ${token}`,
  });
}