import React, { useState, useRef } from 'react';
import axiosClient from "../../../config/axios.Config";
import { useSelector, useDispatch } from 'react-redux';
import { Toaster, toast } from 'react-hot-toast';
import config from '../../../config/config';
import { setParsedCvData } from '../../../redux copy/actionTypes';
import { useLocation } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button, 
  Paper, 
  Dialog, 
  DialogContent, 
  LinearProgress, 
  IconButton,
  Fade
} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const DragDropFile = () => {
  const loginData = useSelector((state) => state.auth.Auth);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [openLoader, setOpenLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const location = useLocation();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      validateAndSetFile(selectedFile);
    }
  };

  const validateAndSetFile = (selectedFile) => {
    if (selectedFile.size > 10000000) {
      setErrorMessage('File size should not exceed 10MB');
      return;
    }
    
    const validTypes = ['.pdf', '.doc', '.docx', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const fileType = selectedFile.type || selectedFile.name.substring(selectedFile.name.lastIndexOf('.'));
    
    if (!validTypes.some(type => fileType.includes(type))) {
      setErrorMessage('Please upload a PDF or Word document');
      return;
    }
    
    setFile(selectedFile);
    setFileName(selectedFile.name);
    setErrorMessage('');
  };

  const clearFile = (e) => {
    e.stopPropagation();
    setFile(null);
    setFileName('');
    setErrorMessage('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    if (e.dataTransfer.files.length) {
      validateAndSetFile(e.dataTransfer.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('resume_file', file);

    setOpenLoader(true);
    setProgress(0);

    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 95) {
          return prevProgress + 5;
        }
        return 95;
      });
    }, 500);

    try {
      const res = await axiosClient.post(`${config.apiUrl}candidate/parse_cv`, formData, {
        headers: {
          'Authorization': `Bearer ${loginData.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res.status === 200) {
        setProgress(100);
        toast.dismiss();
        toast.success(res.data.message);
        dispatch(setParsedCvData(res.data));
        
        setTimeout(() => {
          window.location.href = location.state?.from || `${config.candidateUrl}setting`;
        }, 500);
        
        setFile(null);
        setFileName('');
      }
    } catch (error) {
      console.error('Error uploading the file:', error);
      toast.dismiss();
      toast.error('File upload failed');
    } finally {
      clearInterval(progressInterval);
      setProgress(100);
      setTimeout(() => {
        setOpenLoader(false);
      }, 500);
    }
  };

  const getFileIcon = () => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (extension === 'pdf') {
      return <Box className="w-12 h-12 flex items-center justify-center bg-red-100 text-red-500 rounded-lg"><InsertDriveFileOutlinedIcon fontSize="large" /></Box>;
    } else if (['doc', 'docx'].includes(extension)) {
      return <Box className="w-12 h-12 flex items-center justify-center bg-blue-100 text-blue-500 rounded-lg"><InsertDriveFileOutlinedIcon fontSize="large" /></Box>;
    } else {
      return <Box className="w-12 h-12 flex items-center justify-center bg-gray-100 text-gray-500 rounded-lg"><InsertDriveFileOutlinedIcon fontSize="large" /></Box>;
    }
  };

  return (
    <>
      <Toaster position="top-center" toastOptions={{ duration: 3000 }} />
      
      {/* Processing Dialog */}
      <Dialog
        open={openLoader}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
            padding: 2
          }
        }}
      >
        <DialogContent>
          <Box className="flex flex-col items-center py-6">
            <Typography variant="h6" className="mb-6 font-semibold text-center">
              {progress < 100 ? 'Processing your resume' : 'Upload complete!'}
            </Typography>
            
            <Box className="w-full mb-6">
              <LinearProgress 
                variant="determinate" 
                value={progress} 
                sx={{ 
                  height: 10, 
                  borderRadius: 5,
                  bgcolor: 'rgba(0,0,0,0.06)',
                  '& .MuiLinearProgress-bar': {
                    bgcolor: progress === 100 ? '#10b981' : '#6366f1',
                    transition: 'transform 0.4s ease'
                  }
                }}
              />
            </Box>
            
            <Box className="flex items-center justify-center">
              {progress < 100 ? (
                <Typography variant="body2" color="text.secondary" className="flex items-center">
                  Extracting details from your resume...
                </Typography>
              ) : (
                <Typography variant="body2" color="success.main" className="flex items-center">
                  <CheckCircleOutlineIcon fontSize="small" className="mr-1" />
                  Resume processed successfully!
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      
      {/* Main Upload Area */}
      <Box className="flex flex-col items-center justify-center w-full max-w-xl mx-auto px-4">
        <Paper
          elevation={0}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`w-full p-8 border-2 border-dashed rounded-2xl transition-all duration-300 ${
            isDragging ? 'border-indigo-400 bg-indigo-50' : 'border-gray-200 bg-gray-50'
          }`}
          sx={{ 
            cursor: 'pointer',
            '&:hover': {
              borderColor: '#6366f1',
              bgcolor: 'rgba(99, 102, 241, 0.04)'
            }
          }}
        >
          <input
            ref={fileInputRef}
            id="dropzone-file"
            type="file"
            onChange={handleFileChange}
            className="hidden"
            accept=".pdf,.doc,.docx"
          />
          
          <label htmlFor="dropzone-file" className="w-full cursor-pointer">
            <Box className="flex flex-col items-center justify-center py-4">
              {!fileName ? (
                <>
                  <Box className="w-20 h-20 flex items-center justify-center bg-indigo-100 text-indigo-600 rounded-full mb-4">
                    <FileUploadOutlinedIcon sx={{ fontSize: 40 }} />
                  </Box>
                  <Typography variant="h6" className="mb-2 font-medium text-center">
                    Drop your resume here
                  </Typography>
                  <Typography variant="body2" color="text.secondary" className="text-center mb-4">
                    or <span className="text-indigo-600 font-medium">browse files</span>
                  </Typography>
                  <Typography variant="caption" color="text.secondary" className="text-center px-6 py-2 bg-gray-100 rounded-full">
                    PDF, DOC, DOCX (Max 10MB)
                  </Typography>
                </>
              ) : (
                <Fade in={!!fileName}>
                  <Box className="w-full">
                    <Box className="flex items-center p-4 bg-white rounded-xl shadow-sm border border-gray-100">
                      {getFileIcon()}
                      <Box className="ml-4 flex-grow">
                        <Typography variant="body1" className="font-medium truncate" sx={{ maxWidth: '200px' }}>
                          {fileName}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Ready to upload
                        </Typography>
                      </Box>
                      <IconButton onClick={clearFile} size="small" className="ml-2">
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                </Fade>
              )}
              
              {errorMessage && (
                <Typography variant="body2" color="error" className="mt-4 text-center">
                  {errorMessage}
                </Typography>
              )}
            </Box>
          </label>
        </Paper>
        
        <Button
          variant="contained"
          onClick={handleUpload}
          disabled={!file}
          className="mt-8 px-8 py-3"
          sx={{
            bgcolor: '#6366f1',
            mt: 4,
            '&:hover': {
              bgcolor: '#4f46e5',
            },
            '&.Mui-disabled': {
              bgcolor: '#e5e7eb',
              color: '#9ca3af',
            },
            borderRadius: 3,
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 500,
            boxShadow: '0 4px 14px rgba(99, 102, 241, 0.4)',
          }}
        >
          Upload Resume
        </Button>
      </Box>
    </>
  );
};

export default DragDropFile;