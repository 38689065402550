import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../../config/config";
import { Typography, Grid, Box, Avatar, Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "./Pagination";
import axiosClient from "../../../config/axios.Config";
import Loader from "../../../utils/Loader";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient
            id="gradientColors"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="rgba(26,217,223,1)" />
            <stop offset="100%" stopColor="rgba(168,36,254,1)" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        variant="determinate"
        {...props}
        size={50}
        thickness={3}
        sx={{
          "& .MuiCircularProgress-circle": {
            stroke: "url(#gradientColors)",
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{
            fontSize: "18px",
          }}
        >
          <span className="color-text font-bold">{`${Math.round(
            props.value
          )}%`}</span>
        </Typography>
      </Box>
    </Box>
  );
}

function Candidates() {
  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;
  const BASE_URL = config.apiUrl;
  const [candidates, setCandidates] = useState([]);
  const [candidateProfile, setCandidateProfile] = useState({});

  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPageCandidate")) || 1
  );
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchCandidates = async () => {
    console.log("Fetching candidates...");
    setLoading(true);

    try {
      const response = await axiosClient.get(
        `${BASE_URL}employer/candidates/list?page=${currentPage}&page_size=6`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { candidates, total_pages: totalPages } = response.data;
      console.log("Candidates fetched successfully");

      setCandidates(candidates);
      setTotalPages(totalPages);

      const photoPromises = candidates.map(async (candidate) => {
        if (!candidate.profile_photo_id) {
          return { profile_photo_id: null, photo_url: null };
        }

        try {
          const photoResponse = await axiosClient.get(
            `${BASE_URL}employer/get_photo/${candidate.profile_photo_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: "blob",
            }
          );

          console.log(`Photo fetched for candidate ${candidate.id}`, photoResponse.data);

          return {
            profile_photo_id: candidate.profile_photo_id,
            photo_url: URL.createObjectURL(photoResponse.data),
          };
        } catch (photoError) {
          console.error(`Error fetching photo for candidate ${candidate.id}`);
          return {
            profile_photo_id: candidate.profile_photo_id,
            photo_url: null,
          };
        }
      });

      const photos = await Promise.allSettled(photoPromises);
      console.log("Photos fetched successfully");

      const profileMap = photos.reduce((map, result) => {
        console.log("Result:", result);
        if (result.status === "fulfilled" && result.value.profile_photo_id) {
          map[result.value.profile_photo_id] = result.value.photo_url;
        }
        return map;
      }, {});

      setCandidateProfile(profileMap);
    } catch (error) {
      console.error("Error fetching candidates:", error.message);
    } finally {
      console.log("Loader being turned off");
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("currentPageCandidate", currentPage);
    fetchCandidates();
    // remove currentPage
    return () => {
      console.log("cleanup");
      localStorage.removeItem("currentPageCandidate");
    };


  }, [currentPage, token]);

  // Handle pagination
  const handlePageChange = (newPage) => {
    setCandidates([]);
    localStorage.setItem("currentPageCandidate", newPage);
    setCurrentPage(newPage);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1 }} className="mainContent">
      {!loading ? (
        <Container maxWidth="xl">
          <div className="  min-h-screen mt-16 px-3 pb-3 pt-5">
          <div className="p-2">
            <h2 className="menu-heading mr-4">Candidates Details</h2>
            </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
              {candidates.map((candidate) => (
                <div
                  className="w-full p-2"
                  key={candidate.email}
                >
                  <div className="bg-white border border-gray-300 rounded-lg shadow-lg overflow-hidden">
                    {/* Header Section */}
                    <div className="p-2 bg-gradient-to-tr from-[#efd8ff] to-[#c4f6ff] text-white flex items-center justify-stretch gap-4">
                      <Avatar
                        src={candidateProfile[candidate?.profile_photo_id]}
                        alt={candidate.name}
                        sx={{
                          width: 56,
                          height: 56,
                          border: "2px solid white",
                        }}
                      />
                      <div>
                        <h3 className="text-lg font-semibold truncate text-[#516AF4]">
                          {candidate.name}
                        </h3>
                        <p className="text-sm truncate text-[#5E5E5E]">
                          Role: {candidate.role}
                        </p>
                      </div>
                      <div className="ml-auto">
                        <CircularProgressWithLabel value={candidate.score} />
                      </div>
                    </div>

                    {/* Content Section */}
                    <div className="px-3 pt-3">
                      <div className="grid grid-cols-1 gap-2">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <img
                              src="/images/icons/email.svg"
                              alt="email"
                              className="w-4 h-4"
                            />
                            <span className="text-sm text-gray-700">
                              {candidate.email}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <img
                              src="/images/icons/phone.svg"
                              alt="phone"
                              className="w-4 h-4"
                            />
                            <span className="text-sm text-gray-700">
                              {candidate.phone_number}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <img
                              src="/images/icons/location.svg"
                              alt="location"
                              className="w-4 h-4"
                            />
                            <span className="text-sm text-gray-700">
                              {candidate.location}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <img
                              src="/images/icons/salary.svg"
                              alt="salary"
                              className="w-6 h-6"
                            />
                            <span className="text-sm text-gray-500">
                              Current CTC:
                            </span>
                            <span className="text-sm text-gray-700">{`${candidate.current_ctc} LPA`}</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <img
                              src="/images/icons/clock.svg"
                              alt="timer"
                              className="w-4 h-4"
                            />
                            <span className="text-sm text-gray-500">
                              Notice Period:
                            </span>
                            <span className="text-sm text-gray-700">
                              {candidate.notice_period}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between items-center"></div>
                      </div>
                    </div>

                    <div className="p-2 flex justify-start items-start gap-1">
                      <h4 className="text-sm font-semibold text-gray-600">
                        Skills:
                      </h4>
                      <div className="flex flex-wrap gap-2">
                        {candidate.skills.map((skill, index) => (
                          <span
                            key={index}
                            className="text-xs font-medium bg-blue-100 text-blue-800 px-2 py-1 rounded-full"
                          >
                            {skill.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              </div>

              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
          </div>
        </Container>
      ) : (
        <Loader />
      )}
    </Box>
  );
}

export default Candidates;
