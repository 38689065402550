import { useState } from "react";
import axiosClient from "../../../config/axios.Config";
import config from "../../../config/config"; // adjust this path as necessary
import Loader from "../../../utils/Loader"; // adjust this path as necessary


const useJobSearch = (loginData) => {
    const [jobResults, setJobResults] = useState([]);
    const [error, setError] = useState(null);
    const [totalPages, setTotalPages] = useState(parseInt(localStorage.getItem("totalPages")) || 1);
    const [currentPage, setCurrentPage] = useState();
    const [loading, setLoading] = useState(false);

    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const handleSearch = async (filters, currentPage, isNewFetch = false) => {
        setLoading(true && isNewFetch);
        localStorage.setItem("currentPage", currentPage);
        setCurrentPage(page);
        const params = new URLSearchParams();
        Object.entries({
            job_title: filters.job_title,
            job_location: filters.job_location,
            job_types: filters.job_types.map(option => option.value),
            job_modes: filters.job_modes.map(option => option.value),
            notice_period: filters.notice_period.value,
            work_experience_min: filters.work_experience_min,
            work_experience_max: filters.work_experience_max,
            expected_salary_min: filters.expected_salary_min,
            expected_salary_max: filters.expected_salary_max,
            skill_set:filters.skill_set.length>0? JSON.stringify(filters.skill_set):"", // Send skill_set as a single JSON string array
            page: currentPage,
        }).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(val => params.append(key, val));
            } else if (value !== undefined && value !== null && value !== "") {
                params.append(key, value);
            }
        });




        try {
            console.log("params", params);
            const response = await axiosClient.get(`${config.apiUrl}candidate/find-jobs`, {
                params,
                headers: {
                    Authorization: `Bearer ${loginData.access_token}`,
                },
            });
            const newData = response.data.jobs;
            if (newData.length >= 0) {
                setJobResults(isNewFetch ? newData : [...jobResults, ...newData]);
                setPage(currentPage);
                setHasMore(newData.length === 9);
            } else {
                setHasMore(false);
            }
            setTotalPages(response.data.total_pages);
            console.log("total pages", response.data.total_pages);

            console.log("response-data", response.data);
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { jobResults, handleSearch, error, totalPages, currentPage, setCurrentPage, loading, hasMore, page, setPage,setJobResults };
};

export default useJobSearch;
