import React, { useState, useEffect } from "react";
import CandidateLogin from "./candidate/CandidateLogin";
import EmployerLogin from "./employer/EmployerLogin";
import LeftImage from "../design/LeftImage";
import { useSelector } from "react-redux";
import { Navigate, useLocation, Link } from "react-router-dom";
import config from "../../config/config";
import {
  getCompanyDocs,
  getCompanyInfo,
} from "../../utils/employer.api/employer.api.services";
import { getCandidateProfile } from "../../utils/candidate.api/candidate.api.services";
import { useNavigate } from "react-router-dom";
import localStorage from "redux-persist/es/storage";

function Login({ userType: initialType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultUserType =
    initialType || location.state?.userType || "candidate"; // Read `userType` from prop or state
  const [showComponent, setshowComponent] = useState(defaultUserType);
  const [activeBtn, setActiveBtn] = useState(defaultUserType);
  const [redirectTo, setRedirectTo] = useState(null);

  React.useEffect(() => {
    localStorage.removeItem("settingTab");
    localStorage.removeItem("tab");
  }, []);

  const handleCandidate = () => {
    setshowComponent("candidate");
    setActiveBtn("candidate");
    navigate("/candidate/login");
  };

  const handleEmployer = () => {
    setshowComponent("employer");
    setActiveBtn("employer");
    navigate("/employer/login");
  };

  const loginData = useSelector((state) => state.auth.Auth);
  const userType = useSelector((state) => state.auth.Auth);

  useEffect(() => {
    const checkProfile = async () => {
      if (loginData.access_token) {
        if (userType.role === "employer") {
          try {
            const profileData = await getCompanyDocs(loginData.access_token);
            const first_name = profileData.data.first_name;
            const second_name = profileData.data.last_name;
            const Name = first_name + " " + second_name;
            localStorage.setItem("Name", Name);
            localStorage.setItem("companyName", profileData.data.company_name);
            if (
              profileData.data.status === "incomplete" ||
              profileData.data.status === "pending"
            ) {
              localStorage.setItem("status", profileData.data.status);
              setRedirectTo(`${config.employerUrl}setting`);
            } else {
              localStorage.setItem("status", profileData.data.status);
              if (profileData.data.status === "approved") {
                try {
                  const response = await getCompanyInfo(loginData.access_token);
                  if (response.data.id) {
                    navigate("/employer/dashboard", { 
                      replace: true, 
                      state: { 
                        from: location.state?.from, 
                        showSuccessMessage: true 
                      } 
                    });
                    
                  } else {
                    navigate("/employer/setting", { 
                      replace: true, 
                      state: { 
                        from: location.state?.from, 
                        showSuccessMessage: true 
                      } 
                    });

                  }
                } catch (error) {
                  console.log(error);
                }
              }
              setRedirectTo(`${config.employerUrl}setting`);
            }
          } catch (error) {
            console.error("Error fetching employer profile:", error);
          }
        }
        // else if (userType.role === "candidate") {
        //   try {
        //     const candidateProfileData = await getCandidateProfile(
        //       loginData.access_token
        //     );

        //     if (candidateProfileData.data === null) {
        //       setRedirectTo(`${config.candidateUrl}upload-resume`);
        //     } else {
        //       localStorage.setItem("Name", candidateProfileData.data.general_details.full_name);
        //       setRedirectTo(`${config.candidateUrl}dashboard`);
        //     }
        //   } catch (error) {
        //     console.error("Error fetching candidate profile:", error);
        //   }
        // }
      }
    };
    checkProfile();
  }, [loginData.access_token, userType.role]);

  if (loginData.access_token) {
    if (userType.role === "candidate" && redirectTo) {
      return <Navigate to={redirectTo} />;
    } else if (userType.role === "employer" && redirectTo) {
      return <Navigate to={redirectTo} />;
    }
  }

  const handleSignUp = () => {
    navigate("/candidate/signup", { state: { from: location.state?.from } });
  };

  return (
    <div className="bg-white min-h-screen">
      {/* Logo */}
      <div className="absolute top-8 left-8 cursor-pointer" onClick={()=>{
        window.location.href = "/";
      }}>
        <img src="/images/hirree.svg" alt="logo" className="h-8" />
      </div>

      {/* Main content */}
      <div className="container mx-auto py-24">
        <div className="grid grid-cols-12 border rounded-lg shadow-md overflow-hidden mt-6 mx-4 md:mx-24 relative">
          {/* Back Navigation - Inside the box at top-left corner */}
          {/* <div className="absolute top-4 left-4 z-10">
            <a
              href="/"
              className="flex items-center text-sky-600 hover:text-sky-700 transition-colors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              <span className="text-sm md:text-base">Back To Explore Page</span>
            </a>
          </div> */}

          {/* Left panel - Marketing content */}
          <div className="md:col-span-7 col-span-12 bg-white pt-12 md:pt-0">
            {activeBtn === "candidate" && <LeftImage />}
            {activeBtn === "employer" && (
              <div className="hidden md:flex flex-col justify-center h-full p-8 md:p-12">
                {/* Image Section */}
                <div className="w-full justify-center hidden md:flex">
                  <img
                    src="/images/emp_login.jpeg"
                    alt="Employer Login"
                    className="w-4/5 max-w-md h-auto rounded-lg"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Right panel - Login form */}
          <div className="bg-white p-6 md:p-10 md:col-span-5 col-span-12 flex flex-col justify-center">
            <div className="mx-auto w-full max-w-md">
              {/* User type toggle */}


              {/* Login forms */}
              {showComponent === "employer" && (
                <EmployerLogin redirectPath="/employer/login" />
              )}
              {showComponent === "candidate" && (
                <CandidateLogin redirectPath="/candidate/login" />
              )}

              {/* Sign up prompt */}
              {activeBtn === "candidate" && (
                <>
                  <div className="my-6 relative flex items-center">
                    <div className="flex-grow border-t border-gray-300"></div>
                    <span className="px-4 text-gray-500 text-sm">or</span>
                    <div className="flex-grow border-t border-gray-300"></div>
                  </div>

                  <div className="text-center">
                    <p className="text-lg font-medium mb-3">
                      Don't have an account?{" "}
                      <button
                        className="text-blue-600 font-semibold hover:underline focus:outline-none"
                        onClick={handleSignUp}
                      >
                        Sign Up
                      </button>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
