import React from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import ScrollAnimation from "react-animate-on-scroll";

const Footer = () => {
    return (
        <>
            <hr className="mt-0" />
            <ScrollAnimation animateIn="fadeIn">
                <footer className="p-4">
                    <div className="container mx-auto">
                        <div className="flex flex-wrap">
                            <div className="md:w-6/12">
                                <div className="footer-content">
                                    <h2>Ready to work <br />with us ?</h2>
                                    <button type="button" className="theme-btn bordered-btn">business@hirree.com</button>
                                    <button type="button" className="theme-btn bordered-btn">hr@hirree.com</button>
                                </div>
                            </div>
                            <div className="md:w-6/12">
                                <div className="address">
                                    <p className="text-center">Address: C-25 Sector 8 Noida-201301, India</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="copy-right flex justify-between py-5">
                            <span>©Copyright 2024. @hirree.com</span>
                            <div>
                            <span className="afterline me-2"><Link to={`${config.baseUrl}contact-us`}>Contact-us</Link></span>
                                <span className="afterline me-2"><Link to={`${config.baseUrl}privacy-policy`}>Privacy Policy</Link></span>
                                <span><Link to={`${config.baseUrl}terms-and-conditions`}>Terms and Condition</Link></span>
                            </div>
                        </div>
                    </div>
                </footer>
            </ScrollAnimation>
        </>
    )
}

export default Footer;
