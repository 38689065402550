import { useEffect } from "react";
import { toast } from "react-hot-toast";

const ToastCleanup = () => {
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                toast.dismiss();
            }
        };

        // Listen for tab change
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            // Cleanup listener and dismiss on unmount (route change)
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            toast.dismiss();
        };
    }, []);

    return null; // This component doesn't render anything
};

export default ToastCleanup;
