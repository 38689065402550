import React from 'react';
import { useLocation } from 'react-router-dom';
import PostJobForm from './postFrom';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../dashboard/Appbar';
import Drawer from '../dashboard/Drawer';
import JobPreviewForm from './jobPreviewForm';
const PostJobPage = () => {
    const location = useLocation();
    const job = location.state?.job; // Retrieve job data passed via state
    const step = location.state?.step; // Retrieve step data passed via state
    const canDelete = location.state?.canDelete || false ; // Retrieve canDelete data passed via state

    return (
        <Box className="w-100" sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar />
        <Drawer />
        <div className="post-job-page" style={{width:"90vw"}}>
            <h1></h1>
            <PostJobForm initialData={job} step={step} canDelete={canDelete}/>
            {/* {step &&(
                <JobPreviewForm formData={job} />
            )} */}
        </div>
        </Box>
    );
};

export default PostJobPage;
