import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../Appbar';
import Drawer from '../Drawer';
import MainContent from './MainContent';
import AvatarIcon from '../AvatarIcon';

export default function CandidateDashboard() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar />
      <Drawer />
      <MainContent />
    </Box>
  );
}
