import { TextField, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getCountryColde } from "../../utils/candidate.api/candidate.api.services";
import { Box, FormHelperText } from "@mui/material";
import config from "../../config/config";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { Toaster, toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { width } from "@mui/system";
import { FormControl, FormLabel, InputLabel } from "@mui/material";
// Left panel image component
const LeftPanel = () => {
  return (
    <div className="flex flex-col h-full justify-center px-8 py-12">
      <span className="text-3xl font-bold color-text mb-6">Get in Touch</span>
      <p className="text-gray-600 mb-8">
        Have questions or feedback? We're here to help! Our team will get back
        to you within 24 hours.
      </p>
      <div className="bg-blue-50 p-6 rounded-lg border border-blue-100 mb-8">
        <h3 className="font-semibold text-blue-700 mb-3">
          Contact Information
        </h3>
        <div className="flex items-center mb-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-blue-500 mr-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <span className="text-gray-700">support@hirree.com</span>
        </div>
        <div className="flex items-center mb-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-blue-500 mr-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
          <span className="text-gray-700">+1 (123) 456-7890</span>
        </div>
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-blue-500 mr-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <span className="text-gray-700">
            C-25 Sector 8 Noida-201301, India
          </span>
        </div>
      </div>
      {/* <div className=" flex justify-center">
        <img
          src="/images/contact-illustration.svg"
          alt="Contact us illustration"
          className="h-40  "
        />
      </div> */}
    </div>
  );
};

export default function ContactUs() {
  const purposeOptions = [
    { value: "General Inquiry", label: "General Inquiry" },
    { value: "Product Demo", label: "Product Demo" },
    { value: "Other", label: "Other Inquiry" },
  ];

  const Base_url = config.apiUrl;

  const [countryCode, setCountryCode] = useState([]);
  const allowedDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "protonmail.com",
  ];
  const corporateDomainPattern =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|co\.in|net|org)$/;
  // const fetchCountryCode = async () => {
  //   try {
  //     let response = await getCountryColde();
  //     const countryCode = response.data;
  //     //   remove +91 from the list
  //     const index = countryCode.indexOf("+91");
  //     if (index > -1) {
  //       countryCode.splice(index, 1);
  //     }
  //     setCountryCode(countryCode);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   fetchCountryCode();
  // }, []);
  // Form schema using Yup
  const contactSchema = yup.object().shape({
    name: yup
      .string()
      .required("Full name is required")
      .min(3, "Name must be at least 3 characters")
      .max(100, "Name must be at most 100 characters")
      .matches(/^[a-zA-Z\s]*$/, "Name must contain only alphabets"),
    email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test(
        "is-valid-domain",
        "Please enter a valid email address",
        (value) => {
          if (!value) return false;
          const domain = value.split("@")[1];
          return (
            allowedDomains.includes(domain) ||
            corporateDomainPattern.test(value)
          );
        }
      ),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must be numeric")
      .min(10, "Phone number must be 10 digits")
      .max(10, "Phone number must be 10 digits")
      .required("Phone number is required"),
    purpose: yup.string().required("Please select a purpose"),
    message: yup.string().required("Message is required"),
  });

  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    country_code: "+91",
    phone: "",
    purpose: "General Inquiry", // Default value set to "general"
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const fetchCountryCode = async () => {
    try {
      let response = await getCountryColde();
      setCountryCode(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCountryCode();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setContactDetails({ ...contactDetails, [id]: value });

    // Clear error when user types
    if (errors[id]) {
      setErrors({ ...errors, [id]: "" });
    }
  };

  const validateField = async (field, value) => {
    try {
      await yup.reach(contactSchema, field).validate(value);
      return undefined;
    } catch (error) {
      return error.message;
    }
  };

  const handleBlur = async (e) => {
    const { id, value } = e.target;
    const error = await validateField(id, value);
    setErrors({ ...errors, [id]: error });
  };

  const validateForm = async () => {
    try {
      await contactSchema.validate(contactDetails, { abortEarly: false });
      return true;
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    console.log(contactDetails);

    const data = {
      full_name: contactDetails.name,
      email: contactDetails.email,
      country_code: contactDetails.country_code,
      phone_number: contactDetails.phone, // Remove the first character (+)
      message: contactDetails.message,
      contact_request_type: contactDetails.purpose,
    };

    console.log(data);

    if (await validateForm()) {
      // Simulate API call

      try {
        const response = await axios.post(`${Base_url}contact-requests`, data);
        setFormSubmitted(true);
        setIsSubmitting(false);
        toast.success(
          "Thank you for contacting us. Our team will get back to you soon."
        );
        resetForm();
      } catch (error) {
        console.log(error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setContactDetails({
      name: "",
      email: "",
      country_code: "+91",
      phone: "",
      purpose: "General Inquiry", // Default value set to "general"
      message: "",
    });
    setErrors({});
    setFormSubmitted(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      {/* Logo */}
      <Toaster />
      <div
        className="absolute top-8 left-8 cursor-pointer"
        onClick={() => (window.location.href = "/")}
      >
        <img src="/images/hirree.svg" alt="logo" className="h-8" />
      </div>

      <div className="container mx-auto max-w-6xl">
        <div className="bg-white mt-6 rounded-xl shadow-xl overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2">
            {/* Left panel - Marketing content */}
            <div className="hidden md:block bg-gradient-to-br from-blue-50 to-indigo-50 border-r border-gray-100">
              <LeftPanel />
            </div>

            {/* Right panel - Contact form */}
            <div className="p-8">
              <div className="flex flex-col">
                <div className="text-center mb-8">
                  <span className="text-3xl font-bold color-text">
                    Contact Us
                  </span>
                  <p className="text-gray-500 mt-2">
                    We'd love to hear from you. Please fill out the form below.
                  </p>
                </div>

                <div className="space-y-3">
                  <TextField
                    fullWidth
                    size="small"
                    id="name"
                    label={
                      <span>
                        Full Name<span className="text-red-500">*</span>
                      </span>
                    }
                    variant="outlined"
                    value={contactDetails.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.name}
                    helperText={errors.name}
                    InputProps={{
                      className: "bg-white",
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        color: "red", // Change the helper text color
                        // Adjust the font size
                        // fontStyle: "italic", // Make it italic
                        p: 0,
                        mx: 0,
                        boxShadow: "none",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    size="small"
                    id="email"
                    label={
                      <span>
                        Email Address<span className="text-red-500">*</span>
                      </span>
                    }
                    variant="outlined"
                    value={contactDetails.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.email}
                    helperText={errors.email}
                    InputProps={{
                      className: "bg-white",
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        color: "red", // Change the helper text color
                        // Adjust the font size
                        // fontStyle: "italic", // Make it italic
                        p: 0,
                        mx: 0,
                        boxShadow: "none",
                      },
                    }}
                  />
                  <div className="mt-0">
                    <div className="flex flex-col w-full">
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={Boolean(errors.phone)}
                      >
                        {/* Label for Phone Number */}
                        <InputLabel
                          shrink
                          sx={{
                            background: "#fff",
                            px: 1,
                            transition: "color 0.3s ease-in-out", // Smooth transition effect
                            color: errors.phone
                              ? "#d32f2f"
                              : "#00000099",
                            "&.Mui-focused": {
                              color: "#3b82f6", // Blue color on focus
                            },
                          }}
                        >
                          Phone Number<span className="text-red-500">*</span>
                        </InputLabel>

                        {/* Phone Input Group */}
                        <div
                          className={`flex w-full border border-[#c4c4c4] rounded-[4px] overflow-hidden transition-all
          ${errors.phone ? "border-red-600" : "#C4C4C4"}
          hover:border-gray-600 focus-within:border-transparent focus-within:ring-2 focus-within:ring-[#3374dc] focus-within:outline-none`}
                        >
                          {/* Country Code Dropdown */}
                          <TextField
                            select
                            name="country_code"
                            value={contactDetails.country_code}
                            // onChange={inputHandler}
                            variant="outlined"
                            size="small"
                            error={Boolean(errors.phone)}
                            sx={{
                              width: "140px",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: 0,
                                borderRight: "1px solid #ccc",
                                transition: "border-color 0.3s",
                                "&:hover": { borderColor: "#777" }, // Keep hover effect
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                borderColor: "transparent", // No active border on focus
                                // boxShadow: "0 0 0 2px #3b82f6", // Blue focus ring
                              },
                            }}
                            InputProps={{
                              readOnly: true,
                              className: "text-[#616161]",
                            }}
                          >
                            <MenuItem value="+91">+91</MenuItem>
                            {countryCode.map((code) => (
                              <MenuItem key={code} value={code}>
                                <div className="flex items-center">
                                  <img
                                    src="images/icons/flag.svg"
                                    alt="flag"
                                    className="me-2 w-8 h-6"
                                  />
                                  <span>{code}</span>
                                </div>
                              </MenuItem>
                            ))}
                          </TextField>

                          {/* Phone Number Input */}
                          <TextField
                            type="text"
                            name="phone"
                            value={contactDetails.phone}
                            // onChange={inputHandler}
                            onChange={(e) => {
                              // Explicitly handle the change for this field
                              setContactDetails({
                                ...contactDetails,
                                phone: e.target.value,
                              });
                              // Clear any errors
                              if (errors.phone) {
                                setErrors({ ...errors, phone: undefined });
                              }
                            }
                            }
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Enter your phone number"
                            error={Boolean(errors.phone)}
                            inputProps={{
                              maxLength: 10,
                              onKeyPress: (e) => {
                                if (!/^\d*$/.test(e.key)) {
                                  e.preventDefault(); // Prevent non-numeric input
                                }
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: 0,
                                borderRight: "0px",
                                transition: "border-color 0.3s",
                                "&:hover": { borderColor: "#777" }, // Keep hover effect
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                borderColor: "transparent", // No active border on focus
                                // boxShadow: "0 0 0 2px #3b82f6", // Blue focus ring
                              },
                            }}
                          />
                        </div>

                        {/* Error Message Below */}
                        {errors.phone && (
                          <Box>
                            <FormHelperText error sx={{ color: "red", mx: 0 }}>
                              {errors.phone}
                            </FormHelperText>
                          </Box>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <TextField
                    fullWidth
                    size="small"
                    id="purpose"
                    select
                    label={
                      <span>
                        Purpose<span className="text-red-500">*</span>
                      </span>
                    }
                    variant="outlined"
                    value={contactDetails.purpose}
                    onChange={(e) => {
                      // Explicitly handle the change for this field
                      setContactDetails({
                        ...contactDetails,
                        purpose: e.target.value,
                      });
                      // Clear any errors
                      if (errors.purpose) {
                        setErrors({ ...errors, purpose: undefined });
                      }
                    }}
                    onBlur={handleBlur}
                    error={!!errors.purpose}
                    helperText={errors.purpose}
                    InputProps={{
                      className: "bg-white",
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        color: "red", // Change the helper text color
                        // Adjust the font size
                        // fontStyle: "italic", // Make it italic
                        p: 0,
                        mx: 0,
                        boxShadow: "none",
                      },
                    }}
                  >
                    {purposeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    fullWidth
                    size="small"
                    id="message"
                    label={
                      <span>
                        Message<span className="text-red-500">*</span>
                      </span>
                    }
                    variant="outlined"
                    multiline
                    rows={6}
                    value={contactDetails.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.message}
                    helperText={errors.message}
                    InputProps={{
                      className: "bg-white",
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        color: "red", // Change the helper text color
                        // Adjust the font size
                        // fontStyle: "italic", // Make it italic
                        p: 0,
                        mx: 0,
                        boxShadow: "none",
                      },
                    }}
                  />

                  <button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className={`w-full py-3 px-4 rounded-md font-bold text-white transition-all duration-300 theme-btn ${
                      isSubmitting
                        ? "bg-gray-400 cursor-not-allowed"
                        : "theme-btn hover:from-blue-600 hover:to-indigo-700 shadow-md hover:shadow-lg"
                    }`}
                  >
                    {isSubmitting ? (
                      <span className="flex items-center justify-center">
                        <CircularProgress size={20} color="inherit" />
                        <span className="ml-2">Submitting...</span>
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
