import React, { Suspense, useState } from "react";
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ApplicationContent from "./applicationContent";
import { useEffect } from "react";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import axiosClient from "../../../config/axios.Config";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StatusTabs = () => {
    const BASE_URL = config.apiUrl;
    const loginData = useSelector((state) => state.auth.Auth);
    const token = loginData.access_token;
    const [firstLoad, setFirstLoad] = useState(true);

    const [stageCounts,setStageCounts] = React.useState({});

    useEffect(() => {
        setFirstLoad(true);
        axiosClient
        .get(`${BASE_URL}candidate/my-applications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
            setStageCounts(response.data.stage_counts || {});
            // console.log(response.data.stage_counts["Applied"]);
        })
        .catch((error) => {
            console.log(error);
            }
        )
        .finally(() => {
            setFirstLoad(false);
        });
    }, []);






    const [value, setValue] = React.useState(0);
    const stages = ["Applied", "In-Progress", "Hired", "Rejected"];

    const handleChange = (event, newValue) => {
        
        setValue(newValue);
    };

    return (
        <>
            {/* <h2 className="menu-heading">My Applications</h2> */}
            {/* <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="hirree-tabs">
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {stages.map((stage, index) => (
                           <> <Tab key={stage} label={`${stage} (${stageCounts[stage] || 0}) `} {...a11yProps(index)} /></>
                        ))}
                    </Tabs>
                </Box>
                {stages.map((stage, index) => (
                    <CustomTabPanel key={stage} value={value} index={index}>
                        {index === value && <ApplicationContent stage={stage} />} 
                    </CustomTabPanel>
                ))}
            </Box> */}
            <>
            {/* <h2 className="menu-heading">My Applications</h2> */}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="hirree-tabs">
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {stages.map((stage, index) => (
                            <Tab key={stage} label={`${stage} (${stageCounts[stage] || 0}) `} {...a11yProps(index)}/>
                        ))}
                    </Tabs>
                </Box>
                {stages.map((stage, index) => (
                    <CustomTabPanel key={stage} value={value} index={index}>
                        {index === value && <ApplicationContent stage={stage} firstLoad={firstLoad} setFirstLoad={setFirstLoad} />}
                    </CustomTabPanel>
                ))}
            </Box>
        </>
        </>
    );
};

export default StatusTabs;
