import React from "react";
import CandidateSignUp from "./CandidateForm";
import EmployerSignup from "./EmployerSignup";
import LeftImage from "../design/LeftImage";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { useNavigate } from "react-router-dom";

function Signup({ userType }) {
  const isCandidate = userType === "candidate";
  const navigate = useNavigate();

  return (
    <div className="bg-white min-h-screen">
      {/* Logo */}
      <div className="absolute top-8 left-8 cursor-pointer" onClick={()=>{
        window.location.href = "/";
      }}>
        <img src="/images/hirree.svg" alt="logo" className="h-8" />
      </div>

      {/* Main content */}
      <div className="container mx-auto pt-24">
        <div className="grid grid-cols-12 border rounded-lg shadow-md overflow-hidden mt-6 mx-4 md:mx-24 relative">
          {/* Back Navigation - Inside the box at top-left corner */}
          {/* <div className="absolute top-4 left-4 z-10">
            <a
              href="/"
              className="flex items-center text-sky-600 hover:text-sky-700 transition-colors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              <span className="text-sm md:text-base">Back To Explore Page</span>
            </a>
          </div> */}

          {/* Left panel - Marketing content */}
          <div className="md:col-span-7 col-span-12 bg-white pt-12 md:pt-0">
            <LeftImage />
          </div>

          {/* Right Section */}
          <div className="bg-white md:pt-12 md:px-8 p-2 md:col-span-5 col-span-12">
            {/* Header */}
            <div className="flex justify-center items-center mb-5">
              <span className="text-2xl color-text font-bold">
                Candidate Signup
              </span>
            </div>

            {/* Form Rendering */}
            {isCandidate ? <CandidateSignUp /> : <EmployerSignup />}

            {/* Footer */}
            <div className="my-5 relative">
              <span className="or">or</span>
            </div>
            <div className="mt-6 pb-5 text-center text-gray-700">
              {/* Log In Section */}
              <p className="text-lg font-medium mb-3">
                Already have an account?{" "}
                <span
                  className="text-blue-600 font-semibold cursor-pointer hover:underline"
                  onClick={() =>
                    navigate(`/${userType}/login`, { state: { userType } })
                  }
                >
                  Log In
                </span>
              </p>

              {/* Terms & Privacy Policy */}
              <p className="text-sm leading-relaxed max-w-lg mx-auto px-4 text-gray-600">
                By clicking
                <span className="font-semibold text-gray-800"> "Sign Up" </span>
                , you acknowledge that you have read and accept the
                <span
                  className="text-blue-600 font-medium cursor-pointer hover:underline mx-1"
                  onClick={() =>
                    navigate(`${config.baseUrl}terms-and-conditions`)
                  }
                >
                  Terms of Service
                </span>
                and
                <span
                  className="text-blue-600 font-medium cursor-pointer hover:underline ml-1"
                  onClick={() => navigate(`${config.baseUrl}privacy-policy`)}
                >
                  Privacy Policy
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
