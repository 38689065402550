import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Avatar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Paper, Fab, TextField } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import { Send } from "@mui/icons-material";
import { Dialog, DialogContent, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosClient from "../../config/axios.Config";
import config from "../../config/config";
import { useSelector } from "react-redux";

export default function Chatbot() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.Auth);

  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [currId, setCurrId] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const messagesEndRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (isOpen) {
      scrollToBottom();
    }
  }, [messages, isOpen]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const chatIntialize = async () => {
    setIsTyping(true);
    setMessages([]);
    setIsRefreshing(false);
    try {
      const res = await axiosClient.post(
        `${config.apiUrl}employer/conversations/create`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      setConversationId(res.data.conversation_id);
      setCurrId(currId + 1);

      const newUserMessage = {
        id: messages.length + 1,
        text: "Hi, I'm Roma, your AI assistant. How can I help you today?",
        sender: "agent",
        timestamp: new Date()
          .toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
          .toLowerCase(),
      };

      setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    } catch (error) {
      const newUserMessage = {
        id: messages.length + 1,
        text: "Sorry, I'm unable to connect at the moment. Please try again later.",
        sender: "agent",
        timestamp: new Date()
          .toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
          .toLowerCase(),
      };

      setMessages((prevMessages) => [...prevMessages, newUserMessage]);
      setIsRefreshing(true);
    } finally {
      setIsTyping(false);
    }
  };

  const openChat = async () => {
    setMessages([]);
    setIsOpen(true);
    chatIntialize();
  };

  const closeChat = () => setIsOpen(false);

  const handleSendMessage = async (e) => {
    e?.preventDefault();
    if (message.trim() === "") return;

    const newUserMessage = {
      id: messages.length + 1,
      text: message,
      sender: "user",
      timestamp: new Date()
        .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
        .toLowerCase(),
    };

    setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    setMessage("");

    setIsTyping(true);
    try {
      const res = await axiosClient.post(
        `${config.apiUrl}employer/smart-schedule`,
        {
          text: message,
          conversation_id: conversationId,
        },
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      const agentMessage = res.data?.response;

      const formattedMessage = agentMessage
        .replace(/\*\*(.*?)\*\*/g, (_, boldText) => `<b>${boldText}</b>`) // Bold to HTML <b>
        .replace(/\\n/g, "<br>") // Convert \n to <br>
        .replace(
          /\[(.*?)\]\((https?:\/\/[^\s]+)\)/g,
          '<a href="$2" target="_blank" class="text-blue-500 underline">$1</a>'
        ); // Clickable text with hidden URL

      const newAgentMessage = {
        id: messages.length + 2,
        text: formattedMessage,
        sender: "agent",
        timestamp: new Date()
          .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
          .toLowerCase(),
      };

      setMessages((prevMessages) => [...prevMessages, newAgentMessage]);
    } catch (error) {
      console.log(error);
      const newAgentMessage = {
        id: messages.length + 2,
        text:
          error.response.data?.detail ||
          "Sorry, I'm unable to connect at the moment. Please try again later.",
        sender: "agent",
        timestamp: new Date()
          .toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
          .toLowerCase(),
      };

      setMessages((prevMessages) => [...prevMessages, newAgentMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  const sendMessage = () => {
    handleSendMessage();
  };

  const chatRef = useRef(null); // Reference for the chat container

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatRef.current && !chatRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const messagesEndRefB = useRef(null); // Reference for the last message

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  // Function to open menu
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRefresh = () => {
    // setMessages([]);
    chatIntialize();
  };

  return (
    <>
      <Box className="fixed bottom-6 right-6 ">
        {/* Chat Toggle Button */}
        <Tooltip title="Chat with us" placement="left" arrow>
          <Fab
            sx={{
              bgcolor: "#8A4EFC",
              color: "white",
              "&:hover": {
                bgcolor: "#7744db",
              },
              boxShadow: "0 8px 20px rgba(138, 78, 252, 0.6)",
              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)",

              position: "absolute",
              bottom: 0,
              right: 0,

              borderRadius: isOpen
                ? "50px 0px 50px 50px"
                : "50px 50px 0px 50px",
            }}
            aria-label="chat"
            onClick={isOpen ? closeChat : openChat}
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : isOpen ? (
              <CloseIcon />
            ) : (
              <ChatIcon />
            )}
          </Fab>
        </Tooltip>
        {isOpen && (
          <Paper
            elevation={3}
            sx={{
              width: { xs: "90vw", sm: "70vw", md: "50vw", lg: "40vw" }, // Responsive width
              maxWidth: "600px", // Prevents it from getting too large
              height: { xs: "60vh", sm: "65vh", md: "70vh" }, // Responsive height
              maxHeight: "600px", // Ensures it doesn't get too big
              display: "flex",
              flexDirection: "column",
              borderRadius: "20px 20px 0",
              overflow: "hidden",
              boxShadow: "0 8px 32px rgba(0,0,0,0.12)",
              position: "absolute",
              bottom: "60px", // Ensures it appears above the FAB
              right: { xs: "5%", sm: "2%", md: "1%" }, // Adjusts position dynamically
            }}
          >
            {/* Header */}
            <Box
              sx={{
                background: "linear-gradient(to right, #d0a5ff, #6fbee7)",
                color: "white",
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* Left side - Logo & Name */}
              <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
              <img
                  src="images/icons/roma.png" alt="Roma"
                  className="w-12 h-12 rounded-lg mr-2"
                />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, fontSize: "1.5rem" }} // Larger "Roma AI"
                >
                  Roma AI
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.75rem",
                    opacity: 0.7,
                    backgroundColor: "#F3F4F6",
                    px: 1.2,
                    py: 0.2,
                    borderRadius: "6px",
                    color: "#333",
                    mr: 1,
                  }}
                  className="text-xs"
                >
                  Beta
                </Typography>
              </Box>

              {/* Right side - More options menu */}
              <IconButton color="inherit" onClick={handleOpenMenu}>
                <MoreVertIcon />
              </IconButton>

              {/* Menu for quick guide */}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleOpen}>Quick Guide</MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/employer/history");
                  }}
                >
                  History
                </MenuItem>
              </Menu>
            </Box>

            {/* Messages Area */}
            <Box
              sx={{
                flexGrow: 1,
                p: 2,
                overflow: "auto",
                bgcolor: "#FBFBFB",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {messages.map((msg) => (
                <Box
                  key={msg.id}
                  sx={{
                    display: "flex",
                    flexDirection:
                      msg.sender === "user" ? "row-reverse" : "row",
                    mb: 3,
                    alignItems: "flex-start",
                  }}
                >
                  {/* <Avatar
                    src={msg.avatar}
                    alt={msg.name}
                    sx={{
                      width: 32,
                      height: 32,
                      ml: msg.sender === "user" ? 1 : 0,
                      mr: msg.sender === "agent" ? 1 : 0,
                    }}
                  /> */}

                  <Box
                    sx={{
                      maxWidth: "70%",
                      p: 2,
                      borderRadius:
                        msg.sender === "user" ? "16px 16px 0" : "0px 16px 16px",
                      bgcolor: msg.sender === "user" ? "#007AFF" : "#E3F2FD",
                      color: msg.sender === "user" ? "white" : "black",
                      position: "relative",
                      whiteSpace: "pre-wrap", // ✅ Preserves new lines & wraps long text
                      wordBreak: "break-word", // ✅ Breaks long words properly
                      overflowWrap: "break-word", // ✅ Ensures proper wrapping
                      boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                      "& .MuiTypography-root": {
                        lineHeight: 1.5,
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ color: msg.sender === "user" ? "white" : "#333" }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        display: "block",
                        textAlign: "right",
                        mt: 1,
                        opacity: 0.7,
                      }}
                    >
                      {msg.timestamp}
                    </Typography>
                  </Box>
                </Box>
              ))}
              {isTyping && (
                <div className="mb-6 mr-8">
                  <div className="flex items-start">
                    <div className="h-8 w-8 rounded-full flex items-center justify-center shrink-0 bg-[#7744db] text-white">
                      AI
                    </div>
                    <div className="ml-3 p-3 bg-gray-100 rounded-lg">
                      <div className="flex space-x-1">
                        <div
                          className="h-2 w-2 bg-gray-400 rounded-full animate-bounce"
                          style={{ animationDelay: "0ms" }}
                        ></div>
                        <div
                          className="h-2 w-2 bg-gray-400 rounded-full animate-bounce"
                          style={{ animationDelay: "150ms" }}
                        ></div>
                        <div
                          className="h-2 w-2 bg-gray-400 rounded-full animate-bounce"
                          style={{ animationDelay: "300ms" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </Box>
            {/* Message Input */}
            <Box
              sx={{
                p: 2,
                bgcolor: "white",
                borderTop: "1px solid #EEEEEE",
              }}
            >
              {" "}
              {isRefreshing ? (
                <div className="flex items-center justify-center">
                  <button
                    onClick={handleRefresh}
                    className="flex items-center justify-center px-6 py-3 rounded-lg bg-blue-600 text-white font-semibold transition-all hover:bg-blue-700 active:scale-95 shadow-md"
                  >
                    <RefreshIcon className="mr-2" />
                    Refresh
                  </button>
                </div>
              ) : (
                <form onSubmit={handleSendMessage}>
                  <Tooltip
                    title={isTyping ? "AI is Typing" : ""}
                    placement="top"
                    arrow
                  >
                    <TextField
                      fullWidth
                      placeholder="Type a Message..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      variant="outlined"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault(); // Prevents newline
                          if (!isTyping) sendMessage(); // Calls sendMessage only if not typing
                        }
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          bgcolor: "white",
                          transition: "border-color 0.2s ease-in-out",
                          "& fieldset": {
                            borderColor: "#EEEEEE",
                          },
                          "&:hover fieldset": {
                            borderColor: "#007BFF", // Highlight on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#0056b3", // More intense highlight on focus
                            borderWidth: "2px",
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            size="small"
                            onClick={!isTyping ? sendMessage : null}
                            disabled={isTyping}
                          >
                            <Send fontSize="small" color="primary" />
                          </IconButton>
                        ),
                      }}
                    />
                  </Tooltip>
                </form>
              )}
            </Box>
          </Paper>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: "24px",
            width: "800px",
            maxWidth: "90vw",
            boxShadow: "0 4x 20px rgba(0,0,0,0.15)",
            py: 5,
            paddingLeft: 2,
          },
        }}
      >
        <DialogContent sx={{}}>
          {/* Close button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              bgcolor: "#ffebee",
              color: "#d32f2f",
              "&:hover": {
                bgcolor: "#ef9a9a",
              },
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h5"
            component="h2"
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              color: "#1976d2",
            }}
          >
            <span style={{ marginRight: 8 }}>⭐</span>
            Interview Scheduling Guide with Roma, Your AI Agent
          </Typography>
          <Typography variant="body1" sx={{ color: "#424242", mt: 1 }}>
            Learn how to schedule, manage, and prepare for interviews
            effortlessly with Roma AI. Just ask, and Roma will guide you through
            the process.
          </Typography>

          <Box className="mb-8">
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "bold", mb: 2, color: "#1565c0" }}
            >
              Managing Your Interviews
            </Typography>
            <Typography variant="body1" sx={{ color: "#424242", mb: 3 }}>
              Get real-time updates and assistance on interview scheduling,
              rescheduling, and preparation by simply asking Roma.
            </Typography>

            <Box className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Upcoming Interviews
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "What are my upcoming interviews?"
                </Typography>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Reschedule Interview
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "Can you reschedule my interview?"
                </Typography>
              </Paper>
            </Box>

            <Box className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Interview Time
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "What time is my interview scheduled?"
                </Typography>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Interview Location
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "Where is my next interview taking place?"
                </Typography>
              </Paper>
            </Box>

            <Paper
              elevation={0}
              sx={{ bgcolor: "#e3f2fd", p: 3, borderRadius: 2 }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
              >
                Interview Checklist
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#424242", fontStyle: "italic" }}
              >
                "What should I bring to my interview?"
              </Typography>
            </Paper>
          </Box>

          <Box className="mb-4">
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "bold", mb: 2, color: "#0277bd" }}
            >
              Enhancing Your Interview Preparation
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "medium", color: "#424242", mb: 3 }}
            >
              Get AI-powered insights to ace your interview.
            </Typography>

            <Box className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Interview Tips
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "Give me tips to prepare for my interview."
                </Typography>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 3,
                  borderRadius: "2px 16px 16px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
                >
                  Job Role Insights
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#424242", fontStyle: "italic" }}
                >
                  "What skills are required for this job?"
                </Typography>
              </Paper>
            </Box>

            <Paper
              elevation={0}
              sx={{ bgcolor: "#e3f2fd", p: 3, borderRadius: 2 }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", mb: 1, color: "#1565c0" }}
              >
                Mock Interview
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#424242", fontStyle: "italic" }}
              >
                "Can you conduct a mock interview for me?"
              </Typography>
            </Paper>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
