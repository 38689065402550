import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../Appbar';
import Drawer from '../Drawer';
// import RecomendationContent from './recomendation.content';
import DrawerHeader from '../DrawerHeader';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// redirect to recomendation content
import { Redirect } from 'react-router-dom';




function JobsTab() {

    const navigate = useNavigate();

    useEffect(() => {
        return navigate('/candidate/jobs/recomendation')
    }
    , [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar />
            <Drawer />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent">
                <DrawerHeader />
                {/* <RecomendationContent /> */}
            </Box>
        </Box>
    );
}

export default JobsTab


