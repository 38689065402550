import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Select from "react-select";
import useJobSearch from "./useJobSearch";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getSkills } from "../../../utils/candidate.api/candidate.api.services";
import InputAdornment from "@mui/material/InputAdornment";
import { debounce, throttle } from "lodash";

const noticePeriods = [
  { label: "90 days or more", value: "90 days or more" },
  { label: "60 days", value: "60 days" },
  { label: "30 days", value: "30 days" },
  { label: "Immediate/15 days", value: "Immediate/15 days" },
  //   { label: "Serving notice period", value: "Serving notice period" },
];
const options = [
  { label: "Full Time", value: "Full-time" },
  // { label: 'Part Time', value: 'Part-time' },
  { label: "Internship", value: "Internship" },
  { label: "Contractual", value: "Contractual" },
];
const jobModes = [
  { label: "Remote", value: "Remote" },
  { label: "On-site", value: "On-site" },
  { label: "Hybrid", value: "Hybrid" },
];
const Filters = ({ filters, setFilters, resetFilter }) => {
  const loginData = useSelector((state) => state.auth.Auth);
  const { handleSearch } = useJobSearch(loginData);
  const [skills, setSkills] = useState([]);
  const [errors, setErrors] = useState({});

  // Handle skill selection change
  const handleSkillChange = async (selectedOptions) => {
    const updatedSkills = selectedOptions.map((option) => ({
      name: option.value,
      level: option.level || 1,
    }));
    //setSelectedSkills(updatedSkills);
    setFilters((prev) => ({
      ...prev,
      skill_set: updatedSkills,
    }));
    await handleSearch(filters, 1, true);
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log("name", name, value);

    if (value == "+" || value == "-") return;

    setFilters((prev) => {
      let updatedFilters = { ...prev };

      if (name === "work_experience_min" || name === "work_experience_max") {
        // Allow only numeric values
        if (value && !/^\d*$/.test(value)) return;

        const numericValue = value ? parseInt(value, 10) : "";

        // Allow deletion
        if (value === "") {
          updatedFilters[name] = "";
          setErrors((prev) => ({ ...prev, [name]: "" }));

          // If min is cleared, also clear max errors
          if (name === "work_experience_min") {
            setErrors((prev) => ({ ...prev, work_experience_max: "" }));
          }
          return updatedFilters;
        }

        // Prevent negative values
        if (numericValue < 0) return;

        updatedFilters[name] = numericValue; // Always update value

        // Validate max > min dynamically
        if (name === "work_experience_max") {
          if (
            numericValue !== "" &&
            prev.work_experience_min !== "" &&
            numericValue <= prev.work_experience_min
          ) {
            setErrors((prev) => ({
              ...prev,
              work_experience_max:
                "Maximum experience should be greater than minimum experience.",
            }));
          } else {
            setErrors((prev) => ({ ...prev, work_experience_max: "" })); // Clear error if valid
          }
        }

        if (name === "work_experience_min") {
          if (
            prev.work_experience_max !== "" &&
            numericValue >= prev.work_experience_max
          ) {
            setErrors((prev) => ({
              ...prev,
              work_experience_max:
                "Maximum experience should be greater than minimum experience.",
            }));
          } else {
            setErrors((prev) => ({ ...prev, work_experience_max: "" })); // Clear max error if min becomes valid
          }
        }
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
        updatedFilters[name] = value;
      }

      return updatedFilters;
    });

    // if no errors, update filters

    await handleSearch(filters, 1, true);
  };

  const [isOpen, setIsOpen] = useState({
    jobType: true,
    jobMode: true,
    noticePeriod: true,
  });

  const toggleDropdown = (name) => {
    console.log(name);
    setIsOpen({ ...isOpen, [name]: !isOpen[name] });
  };

  const handleCheckboxChange = async (option, name) => {
    console.log(option);
    setFilters((prev) => ({
      ...prev,
      [name]: prev[name].includes(option)
        ? prev[name].filter((item) => item !== option)
        : [...prev[name], option],
    }));
    console.log("filter", filters);
    await handleSearch(filters, 1, true);
  };
  const handleRadioChange = async (option, name) => {
    setFilters((prev) => ({
      ...prev,
      [name]: option,
    }));
    await handleSearch(filters, 1, true);
  };

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        let response = await getSkills();
        const fetchedSkills = response.data.map((skill) => skill.toLowerCase()); // Convert fetched skills to lowercase
        const skillOptions = fetchedSkills.map((skill) => ({
          label: skill,
          value: skill,
        }));
        setSkills(skillOptions);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSkills();
  }, []);



  return (
    <div className="sticky top-0 mx-auto px-6 py-2 bg-white rounded-lg shadow-md w-full">
      <div className="mb-3 border-b flex justify-between p-2">
        <h2 className="text-xl text-center menu-heading"> All Filters</h2>
        <button
          className="bg-gray-200 text-gray-700  rounded-full flex items-center text-xs p-2"
          onClick={() => {
            resetFilter();
            setErrors({});
          }}
        >
          Reset Filters
        </button>
      </div>
      <div className="flex flex-col mb-2 border-b">
        <div
          className="w-full border-none h-5 rounded flex justify-between items-center cursor-pointer"
          onClick={() => toggleDropdown("jobType")}
        >
          <span className="ml-1 font-semibold text-sm">Job Type</span>
          <span>
            {isOpen.jobType ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </span>
        </div>
        {isOpen.jobType && (
          <div className="relative m-1 bg-white border-none z-10 w-full rounded">
            {options.map((option) => (
              <div key={option.value} className="flex items-center p-1 gap-2">
                <input
                  type="checkbox"
                  id={`checkbox-${option.value}`} // Unique ID for each checkbox
                  value={option.value}
                  checked={filters.job_types.includes(option)}
                  onChange={() => handleCheckboxChange(option, "job_types")}
                  className="mr-2"
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
                <label
                  htmlFor={`checkbox-${option.value}`} // Associate label with checkbox
                  className="text-sm text-gray-600 font-medium cursor-pointer" // Ensures the pointer appears
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* <div className="block mb-2">
                <label className='block mb-1 font-bold text-sm'>Job Type</label>
                <select onChange={handleChange} name="job_type" className="block w-full p-2 border border-gray-300 rounded-md">
                    <option value="" hidden>Select Job Type</option>
                    <option value="Full-time">Full Time</option>
                    <option value="Part-time">Part Time</option>
                    <option value="Internship">Internship</option>
                    <option value="Contractual">Contractual</option>
                </select>
            </div> */}
      <div className="flex flex-col mb-3 border-b">
        <div
          className="w-full border-none h-5 rounded flex justify-between items-center cursor-pointer"
          onClick={() => toggleDropdown("jobMode")}
        >
          <span className="ml-1 font-semibold text-sm">Work Mode</span>
          <span>
            {isOpen ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </span>
        </div>
        {isOpen.jobMode && (
          <div className="relative m-1 bg-white border-none z-10 w-full rounded">
            {jobModes.map((option) => (
              <div key={option} className="flex items-center p-1 gap-2">
                <input
                  type="checkbox"
                  id={`checkbox-${option.value}`} // Unique ID for each checkbox
                  value={option.value}
                  checked={filters.job_modes.includes(option)}
                  onChange={() => handleCheckboxChange(option, "job_modes")}
                  className="mr-2 border border-4 border-gray-300"
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
                <label
                  htmlFor={`checkbox-${option.value}`}
                  className="text-sm text-gray-600 font-medium"
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col mb-3 border-b">
        <div
          className="w-full border-none h-5 rounded flex justify-between items-center cursor-pointer"
          onClick={() => toggleDropdown("noticePeriod")}
        >
          <span className="ml-1 font-semibold text-sm">Notice Period</span>
          <span>
            {isOpen.noticePeriod ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </span>
        </div>
        {isOpen.noticePeriod && (
          <div className="relative m-1 bg-white border-none z-10 w-full rounded">
            {noticePeriods.map((option) => (
              <div key={option.value} className="flex items-center p-1 gap-2">
                <input
                  type="radio"
                  id={`radio-${option.value}`} // Unique ID for each radio button
                  name="noticePeriod" // Ensure all options are grouped under the same name for single selection
                  value={option.value}
                  checked={filters.notice_period === option}
                  onChange={() => handleRadioChange(option, "notice_period")} // Update function for radio button change
                  className="mr-2"
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
                <label
                  htmlFor={`radio-${option.value}`}
                  className="text-sm text-gray-600 font-medium"
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col mb-1">
        <label className="block mb-2 font-semibold text-sm">
          Work Experience{" "}
          <span className="font-light text-xs text-gray-400">| Yrs</span>
        </label>
        <div className="block mb-1 flex gap-4">
          <TextField
            id="outlined-input-min"
            type="number"
            onChange={handleChange}
            name="work_experience_min"
            label="Min"
            size="small"
            value={filters.work_experience_min}
            inputProps={{ step: 1, min: 0, style: { fontSize: "14px" } }}
            // avoid negative values
          />
          <TextField
            id="outlined-input-max"
            type="number"
            label="Max"
            onChange={handleChange}
            name="work_experience_max"
            size="small"
            value={filters.work_experience_max}
            inputProps={{ step: 1, min: 0, style: { fontSize: "14px" } }}
          />
        </div>
        {errors.work_experience_max && (
          <p className="text-red-500 text-xs">{errors.work_experience_max}</p>
        )}
      </div>

      <div className="flex flex-col mb-1">
        <label className="block mb-2 font-semibold text-sm">
          Expected Salary{" "}
          <span className="font-light text-xs text-gray-400">| Yearly</span>
        </label>

        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <TextField
              id="outlined-basic-min"
              variant="outlined"
              size="small"
              inputProps={{ style: { fontSize: "14px" } }}
              value={filters.expected_salary_min}
              onChange={(e) => {
                let inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                let value = inputValue === "" ? "" : parseInt(inputValue, 10);

                setFilters((prev) => {
                  let updatedFilters = { ...prev, expected_salary_min: value };
                  if (value == "+" || value == "-") return;
                  // Allow clearing input
                  if (value === "") {
                    setErrors((prev) => ({ ...prev, expected_salary_min: "" }));
                    return updatedFilters;
                  }

                  // Prevent negative values
                  if (value < 0) return prev;

                  // Validate min < max
                  if (
                    prev.expected_salary_max !== "" &&
                    value >= prev.expected_salary_max
                  ) {
                    setErrors((prev) => ({
                      ...prev,
                      expected_salary_max:
                        "Maximum salary should be greater than minimum salary.",
                    }));
                  } else {
                    setErrors((prev) => ({ ...prev, expected_salary_max: "" })); // Clear error when corrected
                  }

                  return updatedFilters;
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
            <div className="font-light text-xs text-gray-400">to</div>
            <TextField
              id="outlined-basic-max"
              variant="outlined"
              size="small"
              inputProps={{ style: { fontSize: "14px" } }}
              value={filters.expected_salary_max}
              onChange={(e) => {
                let value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters

                // if non-numeric characters are entered, value will be empty and parseInt will return
                if (value == "+" || value == "-") return;

                // Convert to number or keep empty
                value = value ? parseInt(value, 10) : "";

                setFilters((prev) => {
                  let updatedFilters = { ...prev, expected_salary_max: value };

                  // Allow clearing input
                  if (value === "") {
                    setErrors((prev) => ({ ...prev, expected_salary_max: "" }));
                    return updatedFilters;
                  }

                  // Validate max > min
                  if (
                    prev.expected_salary_min !== "" &&
                    value <= prev.expected_salary_min
                  ) {
                    setErrors((prev) => ({
                      ...prev,
                      expected_salary_max:
                        "Maximum salary should be greater than minimum salary.",
                    }));
                  } else {
                    setErrors((prev) => ({ ...prev, expected_salary_max: "" })); // Clear error when corrected
                  }

                  return updatedFilters;
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
          </Box>
          {errors.expected_salary_max && (
            <p className="text-red-500 text-xs">{errors.expected_salary_max}</p>
          )}
        </div>
        <Box
          sx={{
            width: "75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            paddingTop: "10px",
          }}
        >
          <Slider
            getAriaLabel={() => "Expected Salary"}
            value={[
              Math.min(
                filters.expected_salary_min,
                filters.expected_salary_max
              ), // Ensure slider syncs with min/max
              Math.max(
                filters.expected_salary_min,
                filters.expected_salary_max
              ),
            ]}
            onChange={(e, newValue) => {
              setFilters((prev) => ({
                ...prev,
                expected_salary_min: newValue[0], // Sync slider with fields
                expected_salary_max: newValue[1],
              }));
            }}
            size="large"
            valueLabelDisplay="auto"
            min={0}
            max={5000000}
            step={10000}
          />
        </Box>
      </div>

      <div className="block mb-2">
        <label className="block mb-2 font-semibold text-sm">Skill set</label>
        <div className="flex flex-col gap-2">
          <Select
            isMulti
            name="skills"
            options={skills}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleSkillChange}
            value={filters.skill_set.map((skill) => ({
              label: skill.name,
              value: skill.name,
            }))}
            placeholder="Select skills"
            menuPlacement="top" // Dropdown upar khulega
            menuPortalTarget={document.body} // Places dropdown outside normal DOM flow
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Increases z-index
              menu: (base) => ({ ...base, zIndex: 9999 }), // Ensures dropdown stays on top
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
