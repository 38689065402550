import React, { useState, useRef, useEffect } from "react";
import axiosClient from "../../../config/axios.Config";
import { useSelector, useDispatch } from "react-redux";
import { Toaster, resolveValue, toast } from "react-hot-toast";
import config from "../../../config/config";
import {
  setParsedCvData,
  REMOVE_PARSE_CV_DATA,
} from "../../../redux copy/actionTypes";



const UploaFile = ({ onUploadSuccess, setOpen }) => {
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.Auth);
  const fileInputRef = useRef(null); // Add ref for the file input
  const modalRef = useRef(null);


  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click is outside the modal
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpen(false); // Close the modal
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setOpen]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(e.target.files[0]);
    setFileName(selectedFile ? selectedFile.name : "");
    setErrorMessage("");
    return;
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("resume_file", file);
    dispatch({ type: REMOVE_PARSE_CV_DATA });
    try {
      const res = await axiosClient.post(
        `${config.apiUrl}candidate/upload_cv`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status == 200) {
        toast.dismiss();
        toast.success(res.data.message);
        fileInputRef.current.value = "";
        setFile(null);
        setFileName("");
        setOpen(false);
        // window.location.href = `${config.candidateUrl}setting`;
      }
      onUploadSuccess();
    } catch (error) {
      console.error("Error uploading the file:", error);
      setErrorMessage(error.response.data.detail);
    }
  };

  return (
    <>

      <div className="flex items-center rounded-3xl ">
        <div className="relative bg-white p-2 sm:p-8 w-[100%]  text-center">
          {/* Close Button */}
          <button
            onClick={() => {setOpen(false)}}
            className="absolute -top-2 right-2 text-gray-400 hover:text-gray-600"
          >
            ✖
          </button>

          {/* Title */}
          <h2 className="text-lg sm:text-xl font-bold text-gray-800 mb-4">
            Upload Resume to Get Started
          </h2>

          {/* Icon */}
          {/* <div className="flex justify-center items-center mb-6">
            <img
              src="/images/icons/upload.svg"
              alt="Upload Icon"
              className="w-10 sm:w-12 h-10 sm:h-12"
            />
          </div> */}



          {/* File Input */}
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-[530px] h-36 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm sm:text-base text-gray-600">
                <span className="font-semibold">Select a file</span> or drag and
                drop here
              </p>
              {/* <p className="text-xs sm:text-sm text-gray-500">
                Doc or PDF, file size no more than 10MB
              </p> */}

              {fileName && (
                <p className="text-black text-sm mt-2">{fileName}</p>
              )}
            </div>
            <input
              id="dropzone-file"
              ref={fileInputRef}
              type="file"
              onChange={handleFileChange}
              className="hidden"
              accept=".pdf,.doc,.docx"
            />
          </label>

          {errorMessage && (
                <p className="text-red-500 text-xs mt-1 p-1">{errorMessage}</p>
            )}

          {/* Instructions */}
          <p className="text-[10px] sm:text-sm text-gray-600 mt-4 mb-2">
            Accepted file formats: <strong>PDF</strong> or <strong>Word</strong>.  
            Maximum file size: <strong>10MB</strong>.
          </p>


          {/* Upload Button */}
          <button
            onClick={handleUpload}
            className="bg-gradient-to-tr from-purple-400 to-sky-400 text-white py-2 px-6 rounded-lg  w-full hover:from-purple-500 hover:to-sky-500"
          >
            Upload
          </button>
        </div>
      </div>
      {/* <div class="flex items-center  mt-3" style={{width:"50%"}}>
        <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer ">
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <img src='images/icons/upload.svg' className='mb-5'/>
          <p class="mb-2 text-md text-black ">Select a file or drag and drop here</p>
          <p class="text-xs text-gray-500 dark:text-gray-400">JPG, PNG or PDF, file size no more than 10MB</p>
            {errorMessage && <p className="text-red-500 text-xs">{errorMessage}</p>} 
            {fileName && <p className="text-black text-sm mt-2">{fileName}</p>} 
          </div>
          <input id="dropzone-file" ref={fileInputRef} type="file" onChange={handleFileChange} className="hidden" />
          <button onClick={handleUpload} className='block theme-btn text-white mt-4 float-right'>Upload</button>
        </label>
      </div> */}
    </>
  );
};

export default UploaFile;
