import React, { useState, useEffect, useCallback } from "react";
import { Toaster } from "react-hot-toast";
import axiosClient from "../../../config/axios.Config";
import RightDrawer from "./rightDrawer";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import useJobSearch from "./useJobSearch";
import Jobs from "./Jobs";
import config from "../../../config/config";
import Pagination from "./Pagination";
import { Autocomplete, TextField } from "@mui/material";
import Loader from "../../../utils/Loader";
import { getJobLocations } from "../../../utils/candidate.api/candidate.api.services";
import { Formik } from "formik";
import AvatarIcon from "../AvatarIcon";
import { debounce, throttle } from "lodash";

const JobSearch = () => {
  const [searchTriggered, setSearchTriggered] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const loginData = useSelector((state) => state.auth.Auth);
  const [city, setCity] = useState([]);
  const initialFilters = {
    job_title: "",
    job_types: [],
    job_modes: [],
    job_location: "",
    notice_period: "",
    work_experience_min: "",
    work_experience_max: "",
    expected_salary_min: "",
    expected_salary_max: "",
    skill_set: [],
  };
  const [filters, setFilters] = useState(initialFilters);

  const defaultFilters = {
    job_title: filters.job_title,
    job_location: filters.job_location,
    job_types: [],
    job_modes: [],
    notice_period: "",
    work_experience_min: "",
    work_experience_max: "",
    expected_salary_min: "",
    expected_salary_max: "",
    skill_set: [],
  };

  const [allJobTitles, setAllJobTitles] = useState([]); // Store all job titles
  const [suggestions, setSuggestions] = useState([]); // Store filtered suggestions
  const [headerError, setHeaderError] = useState(null); // To handle error state
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");

  // Fetch all job titles once when the component is mounted
  const debouncedSearch = useCallback(
    debounce((updatedFilters) => {
      handleSearch(updatedFilters, 1, true);
    }, 500), // 500ms delay
    []
  );

  useEffect(() => {

    // Call debounced function
    debouncedSearch(filters);

    return () => debouncedSearch.cancel(); 
  }, [filters, searchTriggered]);

  const resetFilter = () => {
    setFilters(defaultFilters);
  };

  useEffect(() => {
    const fetchJobTitles = async () => {
      try {
        const response = await axiosClient.get(
          `${config.apiUrl}candidate/job-titles`,
          {
            headers: {
              Authorization: `Bearer ${loginData.access_token}`,
            },
          }
        );

        // Store the job titles in state
        setAllJobTitles(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching job titles:", error);
      }
    };

    const fetchLocation = async () => {
      try {
        let response = await getJobLocations();
        setCity(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLocation();

    fetchJobTitles();
    return () => {
      console.log("cleanup");
      localStorage.removeItem("currentPage");
    };
  }, []); // Empty dependency array ensures it runs only once

  // Handle changes to the input field

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    console.log("value", value);
    setTitle(value);
    // setSearchTriggered(!searchTriggered);

    if (value === "") {
      filters.job_title = "";
      setSuggestions([]);
      setHeaderError(null);
      setSearchTriggered(!searchTriggered);
      return;
    }
    // Filter the job titles based on the keyword entered
    const filteredSuggestions = allJobTitles.filter(
      (title) => title.toLowerCase().includes(value.toLowerCase()) // Case-insensitive search
    );
    setSuggestions(filteredSuggestions);
  };

  // Handle selection of a suggestion
  const handleSelectSuggestion = (title) => {
    setTitle(title);
    setFilters({ ...filters, job_title: title });
    setSuggestions([]);
    setSearchTriggered(!searchTriggered);
  };
  const {
    jobResults,
    handleSearch,
    error,
    totalPages,
    currentPage,
    setCurrentPage,
    loading,
    page,
    setPage,
    hasMore,
    setJobResults
  } = useJobSearch(loginData);

  const handleJobSearch = () => {
    // Trim input values to avoid searches with only spaces
    const trimmedTitle = title?.trim();
    const trimmedLocation = location?.trim();

    // Prevent empty search
    if (!trimmedTitle && !trimmedLocation) {
      setHeaderError("Please enter a job title or location");
      return;
    }

    setHeaderError(null); // Clear any previous errors

    // Update filters safely
    const updatedFilters = { ...filters };
    if (trimmedTitle) updatedFilters.job_title = trimmedTitle;
    if (trimmedLocation) updatedFilters.job_location = trimmedLocation;

    // Call the search function with updated filters
    handleSearch(updatedFilters, 1, true);
  };

  const handleChange = async (event) => {
    setHeaderError(null);

    const { name, value } = event.target;
    setFilters((prev) => ({
      ...(name === "job_location" || "job_title" ? defaultFilters : prev),
      [name]: value,
    }));
    setSearchTriggered(!searchTriggered);
  };
  const toggleDrawer = (open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const resetFilterR = () => {
    // role and location
    filters.job_title = "";
    filters.job_location = "";
    setTitle("");
    setLocation("");
    setSearchTriggered(!searchTriggered);
  };

  const loadMoreData = () => {
    console.log("load more data");
    if (hasMore) {
      handleSearch(filters, page + 1);
    }
  };

  return (
    <>
      {console.log("jobResults", loading, jobResults)}
      {loading && <Loader />}
      <div className="w-full h-full m-0 p-0">
        <div className="flex flex-col w-full bg-gray-80 p-4">
          {/* <div className="w-full shadow-lg p-5"> */}
          <Toaster />
          <RightDrawer
            name="filters"
            open={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            filters={filters}
            setFilters={setFilters}
            data={jobResults}
            setData={setJobResults}           
          />

          <div className="flex align-center items-start  gap-5 mb-4 bg-white h-30 ml-4 mr-4 p-4 shadow-2xl shadow-gray-100">
            <div className="relative w-1/4">
              <div className="relative w-full">
                {" "}
                {/* Full width container */}
                <div className="w-full">
                  <div className="flex items-center border-b-2 w-full">
                    <SearchIcon fontSize="medium" className="text-gray-500" />
                    <Autocomplete
                      freeSolo
                      options={suggestions}
                      value={title}
                      onInputChange={(event, newValue) =>{
                        handleTitleChange({ target: { value: newValue } })
                        setHeaderError(null); 
                      }
                      }
                      onChange={(event, newValue) => {
                        handleSelectSuggestion(newValue);
                        setHeaderError(null);
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Job title or keyword"
                          variant="standard" // Removes box border
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true, // Removes underline
                            className: "p-1 outline-none text-lg", // Custom styles
                          }}
                        />
                      )}
                    />
                  </div>

                  {/* Error Message Below Input */}
                  {headerError && (
                    <p className="text-red-500 text-sm mt-1">{headerError}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-items-center items-center border-b-2">
              <FmdGoodOutlinedIcon fontSize="medium" />
              <select
                name="job_location"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value)
                  setHeaderError(null)
                    }}
                className="mx-2 p-2 "
              >
                <option value="" hidden>
                  Select Location
                </option>
                {city.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            {/* <button
                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded-full flex items-center space-x-2"
                    onClick={toggleDrawer(true)}
                >
                    <span><SortOutlinedIcon fontSize="medium" /></span>
                    <span>Filter</span>
                </button> */}

            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-full ml-2"
              onClick={handleJobSearch}
            >
              Search
            </button>
            {/*  */}
            <button
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-full flex items-center space-x-2"
              onClick={resetFilterR}
            >
              <span>Reset Filter</span>
            </button>
            <AvatarIcon />   
          </div>
          <div className="ml-5 mr-4">
            <h3 className="text-xs text-gray-400">
              Popular: UI Designer, UX Researcher, Android Developer
            </h3>
          </div>
        </div>

        <Jobs
          data={jobResults}
          filters={filters}
          setFilters={setFilters}
          handleSearch={handleSearch}
          resetFilter={resetFilter}
          loadMoreData={loadMoreData}
          hasMore={hasMore}
          setData={setJobResults}
          loading={loading}
        />

        {/* Pagination */}
        {/* {totalPages > 1 && (
          <div className="mt-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => {
                setCurrentPage(page); // Update local state
                handlePageChange(page); // Fetch jobs for the new page
              }}
            />
          </div>
        )} */}
      </div>
    </>
  );
};

export default JobSearch;
