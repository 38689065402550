import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, Toaster } from "react-hot-toast";

import { useSelector } from "react-redux";
import config from "../../../config/config";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { getLocation } from "../../../utils/candidate.api/candidate.api.services";
import axiosClient from "../../../config/axios.Config";
import Loader from "../../../utils/Loader";
import { getEmployerName } from "../../../utils/employer.api/employer.api.services";
import Dexie from "dexie";

// Initialize IndexedDB
const db = new Dexie("CompanyFormDB");
db.version(1).stores({ form: "key,data" });

const saveFormData = async (key, data) => {
  await db.form.put({ key, data }); // Use `put` to insert or update data
};

function CompanyDocUpload() {
  const [panDocumentId, setPanDocumentId] = useState(null);
  const [cinDocumentId, setCinDocumentId] = useState(null);
  const [gstDocumentId, setGstDocumentId] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [currDocument, setCurrDocument] = useState("content");
  const [location, setLocation] = useState([]);
  const [isFileChanged, setIsFileChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const [change, setChange] = useState(false);

  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;
  const BASE_URL = config.apiUrl;

  const fetchLocation = async () => {
    try {
      const response = await getLocation();
      setLocation(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadDocument = async (url, file) => {
    // console.log("Uploading document:", file);
    try {
      const response = await axiosClient.post(
        `${BASE_URL}${url}`,
        {
          document: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Return the response data if successful
      return response.data;
    } catch (error) {
      console.error("Error uploading document:", error);
      throw error;
    }
  };

  const updateDocument = async (documentId, file) => {
    console.log("Updating document with ID:", documentId);
    try {
      const formData = new FormData();
      formData.append("document", file);

      const response = await axiosClient.put(
        `${BASE_URL}employer/company-documents/${documentId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(
        `Document with ID ${documentId} updated successfully:`,
        response.data
      );
      return response.data;
    } catch (error) {
      console.error(`Error updating document with ID ${documentId}:`, error);
      throw error;
    }
  };

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required("Company name is required")
      .min(2, "Company name must be at least 2 characters"),

    companyEmail: Yup.string()
      .email("Invalid email format")
      .required("Company email is required"),

    location: Yup.string()
      .required("Location is required")
      .min(2, "Location must be at least 2 characters"),

    firstName: Yup.string()
      .required("First name is required")
      .matches(/^[A-Za-z]+$/, "First name must contain only letters"),

    lastName: Yup.string()
      .required("Last name is required")
      .matches(/^[A-Za-z]+$/, "Last name must contain only letters"),

    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),

    cin: Yup.string()
      .required("CIN is required")
      .matches(/^[A-Z]\d{5}[A-Z]{2}\d{4}(PLC|PTC)\d{6}$/, "Invalid CIN format")
      .min(21, "CIN should have at least 21 characters"),

    pan: Yup.string()
      .required("PAN is required")
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format")
      .min(10, "PAN should have exactly 10 characters"),

    gst: Yup.string()
      .required("GST is required")
      .matches(/^\d{2}[A-Z]{5}\d{4}[A-Z]\d{1}Z[A-Z0-9]$/, "Invalid GST format")
      .min(15, "GST should have exactly 15 characters"),

    documentCin: Yup.mixed()
      .required("CIN document is required")
      .test(
        "fileSize",
        "File too large (max 5MB)",
        (value) => !value || value.size <= 5 * 1024 * 1024
      )
      .test(
        "fileType",
        "Unsupported file type",
        (value) =>
          !value ||
          ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
      ),

    documentPan: Yup.mixed()
      .required("PAN document is required")
      .test(
        "fileSize",
        "File too large (max 5MB)",
        (value) => !value || value.size <= 5 * 1024 * 1024
      )
      .test(
        "fileType",
        "Unsupported file type",
        (value) =>
          !value ||
          ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
      ),

    documentGst: Yup.mixed()
      .required("GST document is required")
      .test(
        "fileSize",
        "File too large (max 5MB)",
        (value) => !value || value.size <= 5 * 1024 * 1024
      )
      .test(
        "fileType",
        "Unsupported file type",
        (value) =>
          !value ||
          ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
      ),
  });

  // fetch company details and upload documents and display them in the form

  const downloadFileFromS3 = async (fileUrl) => {
    try {
      console.log("Downloading file from S3:", fileUrl);
  
      // Fetch the file from the S3 URL
      const response = await fetch(fileUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }
  
      // Convert response into Blob
      const blob = await response.blob();
      
      // Extract filename from S3 URL
      const fileName = fileUrl.split("/").pop().split("?")[0]; // Remove query params if any
      
      // Create a File object
      const file = new File([blob], fileName, { type: blob.type });
  
      console.log("Converted File:", file);
      
      return file; // Now you can use this file for submission
  
    } catch (error) {
      console.error("Error downloading file:", error);
      return null;
    }
  };
  


  const fetchCompanyDetails = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `${BASE_URL}employer/company-details`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let companyDetails = response.data;
      setCompanyData(companyDetails);
      localStorage.setItem("status", companyDetails.status);

      const fetchDocument = async (documentType, documentId) => {
        try {
          const documentResponse = await axiosClient.get(
            `${BASE_URL}employer/document-${currDocument}/${documentType}/${documentId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
      
          const fileLink = documentResponse.data; // Assuming the API returns an S3 URL
          console.log("S3 File Link:", fileLink);
      
          // // Fetch the file from S3
          // const response = await fetch(fileLink);
          // const blob = await response.blob();
      
          // // Extract filename from the S3 link
          // const fileName = fileLink.split("/").pop(); 
      
          // // Create a File object
          // const file = new File([blob], fileName, { type: blob.type });
      
          // console.log("Converted File:", file);
      
          // return await downloadFileFromS3(fileLink);
          return fileLink;
      
        } catch (err) {
          console.error(`Failed to fetch ${documentType} document:`, err);
          toast.dismiss();
          toast.error(`Failed to fetch ${documentType} document.`);
          return null; // Return null on failure
        }
      };
      
      // Set document IDs into state
      setCinDocumentId(companyDetails.cin_document?.file_id);
      setPanDocumentId(companyDetails.pan_document?.file_id);
      setGstDocumentId(companyDetails.gst_document?.file_id);

      // Fetch all documents
      const [cinDocument, panDocument, gstDocument] = await Promise.all([
        companyDetails.cin_document?.file_id
          ? fetchDocument("cin", companyDetails.cin_document.file_id)
          : null,
        companyDetails.pan_document?.file_id
          ? fetchDocument("pan", companyDetails.pan_document.file_id)
          : null,
        companyDetails.gst_document?.file_id
          ? fetchDocument("gst", companyDetails.gst_document.file_id)
          : null,
      ]);

      console.log("Fetched documents:", cinDocument, panDocument, gstDocument);

      if (companyDetails.company_name) {
        setIsDisable(true);
      }

      // if (!companyDetails.gst_document && localStorage.getItem("companyDetails")) {
      //   try {
      //     console.log("try hello")
      //     companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
      //        console.log(companyDetails )
      //   } catch (error) {
      //       console.error("Error parsing companyDetails from localStorage", error);
      //   }
      // }

      // Set company details and documents into Formikz
      formik.setValues({
        companyName: companyDetails.company_name,
        companyEmail: companyDetails.company_email,
        firstName: companyDetails.first_name,
        lastName: companyDetails.last_name,
        phoneNumber: companyDetails.phone_number,
        location: companyDetails.location,
        cin: companyDetails.company_cin,
        pan: companyDetails.company_pan,
        gst: companyDetails.company_gst,
        documentCin: cinDocument,
        documentPan: panDocument,
        documentGst: gstDocument,
      });
    } catch (error) {
      console.error("Failed to fetch company details:", error);
      toast.dismiss();
      toast.error("Failed to fetch company details. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const storedData = await db.form.get("companyformData");
      const status = localStorage.getItem("status") || "pending";

      console.log("storedData", storedData);


      if (storedData && status === "approved") {
        formik.setValues(storedData.data);
        // setCompanyData(storedData.data);
      } else {
        fetchCompanyDetails();
      }
    };
    fetchData();
    fetchLocation();
  }, [currDocument]);

  console.log("companyData", companyData);


  const formik = useFormik({
    initialValues: {
      companyName: "",
      companyEmail: "",
      firstName: "",
      lastName: "",
      location: "",
      cin: "",
      pan: "",
      gst: "",
      documentCin: null,
      documentPan: null,
      documentGst: null,
    },

    validationSchema,
    
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // Fetch company details based on CIN, PAN, and GST
        const response = await axiosClient.post(
          `${BASE_URL}employer/company-documents/details`,
          {
            company_name: values.companyName,
            company_email: values.companyEmail,
            first_name: values.firstName,
            last_name: values.lastName,
            phone_number: values.phoneNumber,
            location: values.location,
            company_cin: values.cin,
            company_pan: values.pan,
            company_gst: values.gst,
            status: "pending",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Company details submitted successfully:", response.data);
        
        // const res = await getEmployerName(token);
        // localStorage.setItem("Name", res.data?.name);
        localStorage.setItem("Name", values.firstName + " " + values.lastName);

        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          if (value instanceof File) {
            formData.append(key, value);
          } else if (value !== null) {
            formData.append(key, value.toString());
          }
        });

        // Update or upload documents based on their existing document ID
        const handleDocument = async (documentFile, documentId, uploadUrl) => {
          

          console.log("Handling document:", documentId, documentFile);
          if (documentFile) {
            if (documentId) {
              // Update existing document
              console.log(`Updating document with ID ${documentId}...`);
              await updateDocument(documentId, documentFile);
            } else {
              // Upload new document
              console.log(`Uploading new document to ${uploadUrl}...`);
              await uploadDocument(uploadUrl, documentFile);
            }
          }
        };

        // Handle CIN document
        await handleDocument(
          values.documentCin,
          cinDocumentId,
          "employer/company-documents/upload-cin"
        );

        // Handle PAN document
        await handleDocument(
          values.documentPan,
          panDocumentId,
          "employer/company-documents/upload-pan"
        );

        // Handle GST document
        await handleDocument(
          values.documentGst,
          gstDocumentId,
          "employer/company-documents/upload-gst"
        );


        if (gstDocumentId && panDocumentId && cinDocumentId) {
          // after uploading documents, reload the page to fetch the updated details
          toast.dismiss();
          toast.success("Documents updated successfully! ", {
            id: "upload-success",
          });
          window.location.reload(); // Reload the page to fetch the updated details
          setIsDisable(true);
        } else {
          toast.dismiss();
          toast.success("Documents uploaded successfully! ", {
            id: "upload-success",
          });
          window.location.reload();
        }

        // formik.resetForm();
        // setFiles({ cin: null, pan: null, gst: null });
        localStorage.setItem("Tab", "0");
        localStorage.setItem("status", "pending");
        // window.location.href = window.location.href.split("?")[0] + "?t=" + new Date().getTime();
      } catch (error) {
        toast.dismiss();
        toast.error(
          error.response.data.detail ||
            "Failed to upload documents or fetch details. Please try again."
        );
      } finally {
        setLoading(false);
      }
    },

    enableReinitialize: true,
  });
  // Save the form data to local storage
    // console.log("formik", formik.values)
  const saveFormData = async () => {
    try {
      await db.form.put({ key: "companyformData", data: formik.values });
      toast.dismiss();
      toast.success("Data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // disable submit button if form is not valid or not touched
  useEffect(() => {
    if (
      Object.keys(formik.errors).length === 0 &&
      Object.keys(formik.touched).length !== 0
    ) {
      setIsSubmitDisabled(false);
    }
  }, [formik.errors, formik.touched]);

  const renderErrorMessage = (fieldName) => {
    return formik.touched[fieldName] && formik.errors[fieldName] ? (
      <span className="text-red-500 text-xs">{formik.errors[fieldName]}</span>
    ) : null;
  };

  const RenderFileUpload = (type, name) => {
    const fieldName = `document${type.charAt(0).toUpperCase() + type.slice(1)}`;
    const file = formik.values[fieldName];
    console.log("file", file);
    const [previewFile, setPreviewFile] = useState(null);
    const [nameDoc, setNameDoc] = useState(name);

    // setNameDoc(name);
    useEffect(() => {
      setNameDoc(name);
    }, [name]);

    return (
      <div className="mb-4">
        {/* Image Preview when hovering over the "View" Button */}
        {previewFile && (
          <img
            src={
              previewFile instanceof File
                ? URL.createObjectURL(previewFile)
                : previewFile
            }
            alt="Document Preview"
            className="absolute left-[10px] top-[100px] w-[60%] h-[70%] object-contain rounded-md z-[9999] bg-white shadow-lg"
          />
        )}
        <label className="block font-medium text-gray-700">
          Upload {type.toUpperCase()} Document
        </label>
        <div
          className={`grid ${file ? "grid-cols-2 gap-3" : "grid-cols-1"} mt-2`}
        >
          {/* File input */}
          <div className="relative flex items-center space-x-2 col-span-2 sm:col-span-1">
            {/* Text box to display file name */}
            <input
              type="text"
              value={nameDoc ? nameDoc : "No file chosen"}
              readOnly
              className="w-full p-2 border border-gray-300 rounded-md text-gray-500 pr-12" // Add right padding for space
            />

            {/* File input hidden */}
            <input
              id={`file-upload-${fieldName}`}
              type="file"
              accept=".pdf,.jpg,.jpeg,.png"
              onChange={(e) => {
                formik.setFieldValue(fieldName, e.target.files[0]); // Store file in Formik state
                // formik.setFieldTouched(fieldName, true);
                setNameDoc(e.target.files[0].name);
                setIsFileChanged(true);
                setChange(true);
              }}
              className="hidden mr-1"
            />

            {/* Button inside the text box */}
            <label
              htmlFor={`file-upload-${fieldName}`}
              className="absolute right-1 top-1/2 transform -translate-y-1/2 bg-gray-200 text-black px-3 py-1 rounded-md cursor-pointer"
            >
              Choose File
            </label>
          </div>

          {/* If a file is selected or already exists */}
          {file && (
            <div className="flex justify-between sm:justify-start items-center gap-4 relative">
              {/* View Button with hover effect */}
              <button
                type="button"
                onMouseEnter={() => {
                  setCurrDocument("content");
                  // Show the document preview when hovering over the "View" button
                  // const previewFile =
                  //   file instanceof File
                  //     ? URL.createObjectURL(file)
                  //     : `${BASE_URL}employer/documents/${type}/${file.file_id}`;
                  // setPreviewFile(previewFile); // Save the preview file URL to state
                  if (typeof file === "string" || file instanceof File) {
                    setPreviewFile(file);
                  }
                }}
                onMouseLeave={() => {
                  // Hide the document preview when the hover ends
                  setPreviewFile(null);
                }}
                onClick={() => {
                  setCurrDocument("content");
                  // Open the file on click
                  // if (file instanceof File) {
                  //   const fileURL = URL.createObjectURL(file);
                  //   window.open(fileURL, "_blank");
                  // } else if (file && file.file_id) {
                  //   const fileURL = `${BASE_URL}employer/documents/${type}/${file.file_id}`;
                  //   window.open(fileURL, "_blank");
                  // }
                  if (typeof file === "string" || file instanceof File) {
                    window.open(file, "_blank"); // ✅ S3 URL directly open karega
                  }
                  console.log("file", file);
                }}
                className="relative text-blue-500 hover:underline px-3 py-1 border border-blue-500 rounded-md"
              >
                View
              </button>

              {/* Remove Button */}
              <button
                type="button"
                onClick={() => formik.setFieldValue(fieldName, null)} // Remove the file
                className="text-red-500 hover:underline px-3 py-1 border border-red-500 rounded-md"
              >
                Remove
              </button>
            </div>
          )}
        </div>
        {renderErrorMessage(fieldName)} {/* Render any validation errors */}
      </div>
    );
  };

  return (
    <div className=" ">
      
      {loading && <Loader />}
      <div className="bg-white pt-6 rounded ">
        <form onSubmit={formik.handleSubmit}>
          {/* Image Preview when hovering over the "View" Button */}

          {/* Grid for Company Name and Company Email (1.5 and 1.5 columns) */}
          <div className="flex gap-3">
            <div className="col-span-2 w-[70%]">
              <label className="block font-medium text-gray-700">
                Company Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="companyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your company name"
                disabled={isDisable}
              />
              {renderErrorMessage("companyName")}
            </div>
            <div className="col-span-2 w-[70%]">
              <label className="block font-medium text-gray-700">
                Company Email<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="companyEmail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyEmail}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your company email"
                disabled
              />
              {renderErrorMessage("companyEmail")}
            </div>
            <div className="col-span-2 w-[70%]">
              {/* <label className="block font-medium text-gray-700">
                Location<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="location"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.location}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your company location"
              /> */}

              <label className="block font-medium text-gray-700">
                Location<span className="text-red-500">*</span>
              </label>
              <select
                id="location"
                name="location"
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your company location"
                value={formik.values.location}
                onChange={(e) => (formik.handleChange(e), setChange(true))}
                onBlur={formik.handleBlur}
              >
                <option value="" disabled hidden>
                  Location
                </option>
                {location.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
              {renderErrorMessage("location")}
            </div>
          </div>

          {/* Grid for First Name, Last Name, and Phone Number */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-6">
            <div>
              <label className="block font-medium text-gray-700">
                First Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                onChange={(e) => {
                  formik.handleChange(e); // Formik ka default handleChange call karo
                  setChange(true); // Extra state update karo
                }}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your first name"
                // disabled={isDisable}
              />
              {renderErrorMessage("firstName")}
            </div>
            <div>
              <label className="block font-medium text-gray-700">
                Last Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                onChange={(e) => {
                  formik.handleChange(e); // Formik ka default handleChange call karo
                  setChange(true); // Extra state update karo
                }}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your last name"
                // disabled={isDisable}
              />
              {renderErrorMessage("lastName")}
            </div>
            <div>
              <label className="block font-medium text-gray-700">
                Phone Number<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter phone number"
                disabled={isDisable}
              />
              {renderErrorMessage("phoneNumber")}
            </div>
          </div>

          {/* Grid for CIN, PAN, GST and file upload */}
          {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-6"> */}
          {/* CIN Input and File Upload */}
          <div className="flex flex-col sm:flex-row gap-3 mt-4">
            {/* CIN Input and File Upload */}
            <div className="sm:w-1/2 w-full ">
              <label className="block font-medium text-gray-700">
                CIN<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="cin"
                onChange={(e) => {
                  formik.handleChange(e); // Formik ka default handleChange call karo
                  setChange(true); // Extra state update karo
                }}
                onBlur={formik.handleBlur}
                value={formik.values.cin}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md "
                placeholder="Enter CIN"
              />
              {renderErrorMessage("cin")}
            </div>
            <div className="sm:w-1/2 w-full">
              {RenderFileUpload(
                "cin",
                companyData?.cin_document?.filename
                  ? companyData?.cin_document?.filename
                  : formik.values.documentCin?.name
              )}
            </div>
          </div>

          {/* PAN Input and File Upload */}
          <div className="flex flex-col sm:flex-row  gap-3 mt-4">
            <div className="sm:w-1/2 w-full">
              <label className="block font-medium text-gray-700">
                PAN<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="pan"
                onChange={(e) => {
                  formik.handleChange(e); // Formik ka default handleChange call karo
                  setChange(true); // Extra state update karo
                }}
                onBlur={formik.handleBlur}
                value={formik.values.pan}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter PAN"
              />
              {renderErrorMessage("pan")}
            </div>
            <div className="sm:w-1/2 w-full">
              {RenderFileUpload("pan", companyData?.pan_document?.filename ? companyData?.pan_document?.filename : formik.values.documentPan?.name)}
            </div>
          </div>

          {/* GST Input and File Upload */}
          <div className="flex flex-col sm:flex-row  gap-3 mt-4">
            <div className="sm:w-1/2 w-full">
              <label className="block font-medium text-gray-700">
                GST<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="gst"
                onChange={(e) => {
                  formik.handleChange(e); // Formik ka default handleChange call karo
                  setChange(true); // Extra state update karo
                }}
                onBlur={formik.handleBlur}
                value={formik.values.gst}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter GST"
              />
              {renderErrorMessage("gst")}
            </div>
            <div className="sm:w-1/2 w-full">
              {RenderFileUpload("gst", companyData.gst_document?.filename ? companyData.gst_document?.filename : formik.values.documentGst?.name)}
            </div>
          </div>

          {/* </div> */}

          {/* Submit Button */}
          <div className="mt-6 text-center flex justify-end gap-4">
            <button
              type="button"
              className="theme-btn bordered-btn py-2.5  float-right"
              onClick={saveFormData}
            >
              <span className="color-text">Save</span>
            </button>
            <button
              type="button"
              className="theme-btn bordered-btn py-2.5  float-right"
            >
              <Link to="/employer/subscription" className="color-text">
                <span className="color-text">Procced to Payment</span>
              </Link>
            </button>
            <Tooltip title="Submit for Approval" placement="top" arrow>
              <button
                type="button"
                className={`theme-btn py-2.5  float-right ${
                  !change ? "bg-gray-300 text-gray-500 cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  console.log();
                  formik.handleSubmit();
                }}
                title="Submit for Approval"
                disabled={!change}
              >
                <span className="text-white">Submit</span>
              </button>
            </Tooltip>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyDocUpload;
