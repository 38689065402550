import React, { useState, useEffect } from "react";
import config from "../../../config/config";
import * as Yup from "yup";
import axios from "axios";
import { Toaster, resolveValue, toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import LoginFooter from "../loginFooter";
import { Auth } from "../../../redux copy/actionTypes";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { getCandidateNames } from "../../../utils/candidate.api/candidate.api.services";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import "../../../utils/toastConfig";
// import ForgotPasswordModal from "./ForgetPass";

const WithOTP = ({ switchToPassword }) => {
  const [email, setEmail] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(30); // 2-minute timer (30 seconds)
  const [canResend, setCanResend] = useState(false); // To track when to show 'Resend OTP'
  const [errors, setErrors] = useState({});
  const [otpErrors, setOtpErrors] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address") // Built-in validation
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test("valid-domain", "Invalid email format", (value) =>
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      )
      .test("contains-at", "Invalid email format", (value) =>
        value.includes("@")
      ),
  });

  // set otp to intial state
  const resetOtp = () => {
    setOtp(["", "", "", "", "", ""]);
  };

  // Handle the OTP sending logic
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate({ email }, { abortEarly: false });
      setLoading(true);
      const response = await axios.post(`${config.apiUrl}candidate/login/otp`, {
        email,
      });
      if (response.status === 200) {
        toast.dismiss();
        toast.success(response.data.message);
        setShowOtpInput(true);
        setCanResend(false);
        setTimer(30);
      } else {
        resetOtp();
        toast.dismiss();
        toast.error("Something went wrong");
      }
    } catch (error) {
      resetOtp();
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/\D/.test(value)) return; // Prevent non-numeric values

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setOtpErrors(null);

    // Focus the next input box if there's a value entered
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  // Handle keydown for backspace
  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus(); // Focus the previous input box
    }
  };

  // Handle OTP paste
  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    if (!/^\d+$/.test(pastedData)) return; // Ensure only numeric values are pasted

    const pastedArray = pastedData.split("");
    const newOtp = [...otp];
    for (let i = 0; i < pastedArray.length && i < otp.length; i++) {
      newOtp[i] = pastedArray[i];
    }
    setOtp(newOtp);

    // Focus the next input box after pasting
    const nextIndex = Math.min(pastedArray.length, otp.length - 1);
    document.getElementById(`otp-input-${nextIndex}`).focus();
  };

  // Handle OTP verification
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const otpValue = otp.join("");
    try {
      if (otpValue === "") {
        setOtpErrors("Please enter a valid otp");
        return;
      }
      const response = await axios.post(
        `${config.apiUrl}candidate/login/otp/verify`,
        { otp: otpValue, email }
      );

      if (response.status === 200) {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("role", "candidate");
        dispatch({
          type: Auth,
          payload: response.data,
        });

        const candidateNames = await getCandidateNames(
          response.data.refresh_token
        );

        // console.log(candidateNames)
        const name = candidateNames.data.name;
        await localStorage.setItem("Name", name);

        // Redirect logic here
        if (response.data.profile_exists && response.data.cv_exists) {
          navigate("/candidate/dashboard", {
            replace: true,
            state: {
              from: location.state?.from,
              showSuccessMessage: true,
            },
          });
        } else {
          navigate("/candidate/upload-resume", {
            replace: true,
            state: {
              from: location.state?.from,
              showSuccessMessage: true,
            },
          });
        }
      } else {
        // empty otp field
        setOtp(["", "", "", "", "", ""]);
        toast.dismiss();
        toast.error("Failed to verify OTP");
      }
    } catch (error) {
      if (otp !== "") {
        setOtp(["", "", "", "", "", ""]);
        setOtpErrors(error.response.data.detail);
      } else {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle Resend OTP
  const handleResendOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${config.apiUrl}candidate/login/otp/resend`,
        { email }
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success(response.data.message);
        setCanResend(false); // Disable resend button
        setTimer(30); // Reset timer
        setOtpErrors(null);
        resetOtp();
      } else {
        toast.dismiss();
        toast.error("Failed to resend OTP");
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  // Timer effect to count down from 30 seconds (2 minutes)
  useEffect(() => {
    let countdown;
    if (showOtpInput && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResend(true); // Show resend option when timer ends
    }

    return () => clearInterval(countdown);
  }, [showOtpInput, timer]);

  const handleErrors = (error) => {
    const validationErrors = {};

    // Check if the error comes from the API
    if (error.response) {
      const apiErrorDetail = error.response.data?.detail;

      // Handle string errors (e.g., "Oops! This email address is not registered.")
      if (typeof apiErrorDetail === "string") {
        // If the error is related to the email, show it as an email error
        if (apiErrorDetail.toLowerCase().includes("email")) {
          validationErrors.email = apiErrorDetail; // Set email error
        } else {
          // Otherwise, set it as a general error
          validationErrors.general = apiErrorDetail; // Set general error
        }
      }
      // Handle array errors (field-specific errors)
      else if (Array.isArray(apiErrorDetail)) {
        apiErrorDetail.forEach((err) => {
          if (err.loc && err.loc.length === 2) {
            const field = err.loc[1]; // Extract field name (e.g., "email")
            validationErrors[field] = err.msg; // Set field-specific errors
          }
        });
      }
      // Fallback for generic API error
      else {
        validationErrors.general = "Something went wrong with the API.";
      }
    }
    // Handle Yup validation errors
    else if (error instanceof Yup.ValidationError) {
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message; // Set Yup validation errors
      });
    }
    // Fallback for unexpected errors
    else {
      console.error(error);
      validationErrors.general = "An unexpected error occurred.";
    }

    // Set the error messages to state
    setErrors(validationErrors);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    navigate("/candidate/forgot-password");
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false);
    setEmail("");
  };

  return (
    <>
      <Toaster />
      <div className="">
        {/* header login withOPT */}
        {/* <div className="text-center py-4 font-semibold text-lg rounded-t-md">
          Login with OTP
        </div> */}

        <div className="flex flex-col w-full gap-2 mb-4">
          <TextField
            type="email"
            label={
              <span>
                Email <span className="text-red-500">*</span>
              </span>
            }
            variant="outlined"
            fullWidth
            value={email}
            size="small"
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors({});
            }}
            error={Boolean(errors.email)}
            helperText={errors.email}
            disabled={showOtpInput}
            sx={{
              "& .MuiFormHelperText-root": {
                color: "red", // Change the helper text color
                fontSize: "14px", // Adjust the font size
                // fontStyle: "italic", // Make it italic
                p: 0,
                mx: 0,
                boxShadow: "none",
              },
            }}
          />
        </div>

        {!showOtpInput ? (
          <div className="space-y-4">
            <div className="text-right">
              <div
                onClick={handleOpen}
                className="float-left mb-3 text-blue-500 text-[14px] text-decoration-none cursor-pointer hover:underline"
                style={{ fontFamily: "poppins medium" }}
              >
                Forgot Password?
              </div>
              <Link
                to="#"
                onClick={switchToPassword}
                className="text-blue-500 hover:underline"
                style={{ fontFamily: "Poppins Medium" }}
              >
                Login with Password
              </Link>
            </div>

            <button
              className="theme-btn rounded-md text-white w-full flex justify-center items-center"
              onClick={handleLogin} // Directly calling handleLogin
            >
              Send OTP
              {loading && (
                <CircularProgress
                  size={20}
                  disableShrink
                  className="ml-2 text-white"
                />
              )}
            </button>
          </div>
        ) : (
          <div>
            {/* Display Email (Disabled) */}
            {/* OTP Inputs */}
            <p className="text-sm text-gray-600 mb-4 text-left ">
              Enter the 6-digit code sent to your email
            </p>
            <form className="space-y-4 mt-4 " onSubmit={handleOtpSubmit}>
              <div className="flex justify-between gap-2">
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength={1}
                    className={`w-10 h-12 sm:w-[55px] sm:h-12 text-center border ${
                      otpErrors ? "border-red-600" : ""
                    } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg`}
                    value={otp[index] || ""}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleOtpKeyDown(e, index)}
                    onPaste={handleOtpPaste}
                  />
                ))}
              </div>
              {/* Error Message */}
              {otpErrors && (
                <p className="text-red-500 text-sm text-left">{otpErrors}</p>
              )}

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="theme-btn rounded-md mr-2 text-white w-full"
                  onClick={handleOtpSubmit}
                >
                  Continue
                  {loading && (
                    <CircularProgress
                      size={20}
                      disableShrink
                      style={{ color: "#fff", marginLeft: "10px" }}
                    />
                  )}
                </button>
              </div>
              <div className="flex justify-center items-center">
              {timer > 0 ? (
                <p className="text-sm">
                  Resend OTP in {Math.floor(timer / 60)}:
                  {(timer % 60).toString().padStart(2, "0")}
                </p>
              ) : (
                <p className="text-sm text-center cursor-pointer">
                  Didn't get the OTP?{" "}
                  <span
                    className="color-text cursor-pointer hover:underline"
                    onClick={handleResendOtp}
                  >
                    Resend OTP
                  </span>
                </p>
              )}
              </div>
            </form>
          </div>
        )}
      </div>
      {/* <ForgotPasswordModal open={open} handleClose={handleClose} /> */}
    </>
  );
};

export default WithOTP;
