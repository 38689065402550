import React, { useEffect, useState } from "react";
import { getCompanyInfo, getIndustry } from "../../../utils/employer.api/employer.api.services";
import { getLocation } from "../../../utils/candidate.api/candidate.api.services";
import config from "../../../config/config";
import Loader from "../../../utils/Loader";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import * as Yup from 'yup';
import axiosClient from "../../../config/axios.Config";
import axios from "axios";
import Dexie from "dexie";


// Initialize IndexedDB
const db = new Dexie("CompanyInfoDB");
db.version(1).stores({ form: "key,data" });

const saveFormData = async (key, data) => {
    await db.form.put({ key, data }); // Use `put` to insert or update data
};


// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: 'none',
//     boxShadow: 24,
//     p: 4,
//     borderRadius: "20px"
// };

const CompanyInfo = () => {

    const BASE_URL = config.apiUrl;
    const loginData = useSelector((state) => state.auth.Auth);
    const token = loginData.access_token;

    const [industry, setIndustry] = useState([]);
    const [location, setLocation] = useState([]);
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [isProfileExists, setIsProfileExists] = useState(false)
    const [errors, setErrors] = useState({});
    // const loginData = useSelector((state) => state.auth.Auth);
    const [openSocialModal, setOpenSocialModal] = useState(false);
    const [additionalLinks, setAdditionalLinks] = useState({});
    const [isSocialMedia, setIsSocialMedia] = useState("")
    const [loading, setLoading] = useState(false);
    const [trigger, setTrigger] = useState(false)

    const [socialMedia, setSocialMedia] = useState({
        platform: "",
        url: ""
    })
    const handleSocialMedia = (e) => {
        const { name, value } = e.target;
        setSocialMedia((prev) => ({
            ...prev,
            [name]: value,
        }));
    }
    const AddSocialMedia = async (e) => {
        e.preventDefault()
        try {
            const response = await axiosClient.post(`${config.apiUrl}employer/social-media-profile/add-link`, socialMedia,
                {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    }
                }
            )
            if (response.status == 200) {
                toast.dismiss()
                toast.success("Social media Added")
                setAdditionalLinks((prevLinks) => ({
                    ...prevLinks,
                    [socialMedia.platform]: socialMedia.url,
                }));

                setOpenSocialModal(false)
                setSocialMedia("")
            }
        }
        catch (error) {
            toast.dismiss()
            toast.error("Faild to add")
            console.log(error)
        }
    }
    const [formData, setFormData] = useState({
        company_name: "",
        website: "",
        location: "",
        team_size: "",
        industry: "",
        year_of_establishment: "",
        about_company: "",
        logo_id : null,
        linkedin: null,
        facebook: null,
        twitter: null
    });
    useEffect(() => {
        fetchIndustry();
        fetchLocation();
        fetchLogo();
        // fetchBanner();
    }, []);

    const validationSchema = Yup.object().shape({
        company_name: Yup.string().required('Company name required'),
        website: Yup.string().url('Invalid URL format').required('URL required'),
        location: Yup.string().required('Location required'),
        team_size: Yup.string().required('Team size required'),
        industry: Yup.string().required('Industry name required'),
        year_of_establishment: Yup.string().nullable(),
        about_company: Yup.string()
            .max(5000, 'Max length is 5000 characters')
            .required('Please write about the company'),
        twitter: Yup.string()
            .matches( /^$|^https:\/\/(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_.-]+\/?$/, "Invalid Twitter URL").nullable(), // Allows empty string or valid URL
    
        facebook: Yup.string()
            .matches( /^$|^https:\/\/(www\.)?facebook\.com\/[A-Za-z0-9_.-]+\/?$/, "Invalid Facebook URL").nullable(), // Allows empty string or valid URL
    
        linkedin: Yup.string()
            .matches(/^$|^https:\/\/(www\.)?linkedin\.com\/(in|company)\/[A-Za-z0-9_-]+\/?$/, "Invalid LinkedIn URL").nullable(),
        

    });

    const fetchIndustry = async () => {
        try {
            const response = await getIndustry();
            setIndustry(response.data);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchLocation = async () => {
        try {
            const response = await getLocation();
            setLocation(response.data);
        } catch (error) {
            console.log(error);
        }
    };


    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        try {
            await validationSchema.validateAt(name, { [name]: value });
            setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
        } catch (error) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
        }
    };
    const handleLogoUpload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];

        if (!file) return;

        // Check the image dimensions before uploading
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async function () {
            const width = img.width;
            const height = img.height;

            // Check if dimensions exceed the limit
            if (width > 1024 || height > 1024) {
                toast.dismiss()
                toast.error("Logo image dimensions should not exceed 1024x1024.");
                return; // Prevent upload if dimensions are too large
            }

            let image_as_base64 = URL.createObjectURL(file); // For preview
            setLogo(file); // Set file
            setLogoPreview(image_as_base64); // Preview image

            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axiosClient.post(`${config.apiUrl}employer/company/logo`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    }
                });
                toast.success("Logo uploaded successfully!");

                if (response.status === 200) {
                    toast.dismiss()
                    toast.success("Logo uploaded successfully!");
                    setTrigger(!trigger)
                }
            } catch (error) {
                toast.dismiss()
                toast.error("Error uploading logo. Please try again.");
                console.log("Error uploading logo:", error);
            }
        };
    };


    // // reset logo
    // const handleResetLogo = async (e) => {
    //     setLogo(""); // Set file
    //     setLogoPreview(""); // Preview image

    //     const formData = new FormData();
    //     formData.append('file', "");
    //     e.preventDefault();
    //     try {
    //         const response = await axiosClient.post(`${config.apiUrl}employer/company/logo`, {
    //             headers: {
    //                 'Authorization': `Bearer ${loginData.access_token}`,
    //             },
    //         });
    //         if (response.status === 200) {
    //             setLogo(null);
    //             setLogoPreview(null);
    //             toast.dismiss()
    //             toast.success("Logo removed successfully");
    //         }
    //     } catch (error) {
    //         console.error("Error deleting logo:", error);
    //         toast.dismiss()
    //         toast.error("Failed to remove logo");
    //     }
    // };


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            // const method = isProfileExists ? 'put' : 'post';
            console.log( validationSchema.validate(formData, { abortEarly: false }))

            let response;

            if (isProfileExists) {
                response = await axiosClient.put(`${config.apiUrl}employer/company`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    },
                });
            } else {
                response = await axiosClient.post(`${config.apiUrl}employer/company`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    },
                });
            }

            // const response = await axiosClient({
            //     method: method,
            //     url: `${config.apiUrl}employer/company`,
            //     data: formData,
            //     headers: {
            //         'Authorization': `Bearer ${loginData.access_token}`,
            //     }
            // });

            // Check if company profile creation was successful (only for POST, not PUT)
            if (!isProfileExists && response.status === 201) {
                toast.dismiss()
                toast.success("Company Profile Created");
                const profileId = response.data.id;
                // Prepare the payload for the second API (Hiring Stages)
                // const payload = {
                //     // company_id: profileId,
                //     stages: [
                //         { name: "Screening", is_fixed: true, is_default: true, order: 0 },
                //         { name: "Technical Round 1", is_fixed: false, is_default: true, order: 1 },
                //         { name: "HR Round", is_fixed: false, is_default: true, order: 2 },
                //         { name: "Hired", is_fixed: true, is_default: true, order: 9998 },
                //         { name: "Rejected", is_fixed: true, is_default: true, order: 9999 }
                //     ]
                // };
                // // Second API call to create hiring stages
                // const res = await axiosClient.post(`${config.apiUrl}employer/hiring-stages`, payload, {
                //     headers: {
                //         'Authorization': `Bearer ${loginData.access_token}`,
                //     }
                // });

                // Handle the success of the second API
                // if (res.status === 201) {
                //     //   toast.success("Hiring Stages Created Successfully");
                //     setTimeout(() => window.location.reload(), 1000);
                // }
            } else if (isProfileExists && response.status === 200) {
                toast.dismiss()
                toast.success("Company Information Updated");
            }

            await saveFormData("isProfileExists", true);


        } catch (error) {
            // Validation error handling
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(err => {
                    validationErrors[err.path] = err.message;
                });
                setErrors(validationErrors);
            } else {
                console.error("Unexpected error:", error);
                toast.dismiss()
                toast.error(error.response?.data?.detail || "Failed to update company information");
            }
        }
    };


    const fetchLogo = async () => {
        try {
            const response = await axiosClient.get(`${config.apiUrl}employer/company/logo`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                    // 'Content-Type': 'arraybuffer'
                },
                // responseType: 'arraybuffer'
            });
            console.log("logo", response.data.url)
            if (response.data.url) {
               await setLogoPreview(response.data.url);
            }
        } catch (error) {
            console.error("Error fetching profile picture:", error);
        }
    };

    const fetchProfileData = async () => {
        setLoading(true);
        try {
            const response = await getCompanyInfo(loginData.access_token)
            console.log("response", response.data)
            if (response.data) {
                const info = response.data
                setFormData({
                    company_name: info.company_name,
                    website: info.website,
                    location: info.location,
                    team_size: info.team_size,
                    industry: info.industry,
                    year_of_establishment: info.year_of_establishment,
                    about_company: info.about_company,
                    logo_id : info.logo_id ,
                    linkedin: info.linkedin,
                    facebook: info.facebook,
                    twitter: info.twitter
                })
                if(response.data.id){
                    setIsProfileExists(true);
                }else{
                    setIsProfileExists(false);
                }
                setIsSocialMedia(info.linkedin)
            }
            else {
                setIsProfileExists(false);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            const storedData = await db.form.get("companyInfoData");
            const isProfile = await db.form.get("isProfileExists");
      
            if (storedData && isProfile) {
                setFormData(storedData.data);
                setIsProfileExists(isProfile.data);    
            } else {
                fetchProfileData();
            }
            };
            fetchData();
    }, [loginData.access_token, trigger]);
    const handleDelete = async (platform, e) => {
        e.preventDefault(); // Prevent default form submission behavior
        try {
            const response = await axiosClient.delete(`${config.apiUrl}employer/social-media-profile/remove-link/${platform}`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                },
            });
            if (response.status === 200) {
                // toast.success(`${platform} link removed successfully`);
                // Update state to remove the deleted platform link
                setAdditionalLinks((prevLinks) => {
                    const updatedLinks = { ...prevLinks };
                    delete updatedLinks[platform]; // Remove the platform
                    return updatedLinks;
                });
                toast.dismiss()
                toast.success("Social media deleted")
            }
        } catch (error) {
            console.error("Error deleting social media link:", error);
            toast.dismiss()
            toast.error("Failed to remove the link");
        }
    };

    const handleLogoReset = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosClient.delete(`${config.apiUrl}employer/company/logo`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`
                }
            });
            if (response.status === 200) {
                setLogo(null);
                setLogoPreview(null);
                toast.dismiss()
                toast.success("Logo removed successfully");
                setTrigger(!trigger)
            }
        } catch (error) {
            console.error("Error deleting logo:", error);
            toast.dismiss()
            toast.error("Failed to remove logo");
        }
    };

    useEffect(() => {
        setAdditionalLinks(formData.additional_links || {});
    }, [formData.additional_links]);

    const handleSave = async () => {
        try {
            await db.form.put({ key: "companyInfoData", data: formData });
            toast.success("Data saved successfully");
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };
    

    return (
        <>
            
            {loading ? <Loader />
                :   
                <div>
                <div>
                </div>
                <form className="mt-10 form-theme">
                    <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-3">
                        <div>
                            <label className="block mb-2">Company name<span className="text-red-500">*</span></label>
                            <input
                                type="text"
                                name="company_name"
                                className="w-full border border-gray-300"
                                placeholder="Name"
                                value={formData.company_name}
                                onChange={handleChange}
                                disabled
                            />
                            {errors.company_name && <span className="text-red-500 text-sm">{errors.company_name}</span>}
                        </div>
                        <div>
                            <label className="block mb-2">Website<span className="text-red-500">*</span></label>
                            <input
                                type="text"
                                name="website"
                                className="w-full border border-gray-300"
                                placeholder="URL"
                                value={formData.website}
                                onChange={handleChange}
                            />
                            {errors.website && <span className="text-red-500 text-sm">{errors.website}</span>}

                        </div>
                        <div>
                            <label htmlFor="location" className="block mb-2">Location<span className="text-red-500">*</span></label>
                            <select
                                id="location"
                                name="location"
                                className="w-full border border-gray-300"
                                value={formData.location}
                                onChange={handleChange}
                                disabled
                            >
                                <option value="" disabled hidden>Location</option>
                                {location.map(item => (
                                    <option>{item}</option>
                                ))}
                            </select>
                            {errors.location && <span className="text-red-500 text-sm">{errors.location}</span>}

                        </div>
                        <div>
                            <label htmlFor="team_size" className="block mb-2">Team size<span className="text-red-500">*</span></label>
                            <select
                                id="team_size"
                                name="team_size"
                                className="w-full border border-gray-300"
                                value={formData.team_size ? formData.team_size : ""}
                                onChange={handleChange}
                            >
                                <option value="" hidden disabled>Team size</option>
                                <option value="0-10">0-10</option>
                                <option value="20-50">20-50</option>
                                <option value="1000-5000">1000-5000</option>
                                <option value="10000-20000">10000-20000</option>
                                <option value="50000+">50000+</option>
                            </select>
                            {errors.team_size && <span className="text-red-500 text-sm">{errors.team_size}</span>}
                        </div>
                        <div>
                            <label htmlFor="industry" className="block mb-2">Industry<span className="text-red-500">*</span></label>
                            <select
                                id="industry"
                                name="industry"
                                className="w-full border border-gray-300"
                                value={formData.industry ? formData.industry : ""}
                                onChange={handleChange}
                            >
                                <option value="" disabled hidden >Industry</option>
                                {industry.map((ind, idx) => (
                                    <option key={idx} value={ind.name}>
                                        {ind}
                                    </option>
                                ))}
                            </select>
                            {errors.industry && <span className="text-red-500 text-sm">{errors.industry}</span>}

                        </div>
                        <div>
                            <label className="block mb-2">
                                Year of Establishment<span className="text-red-500"></span>
                            </label>
                            <input
                                type="month"
                                name="year_of_establishment"
                                className="w-full border border-gray-300"
                                value={formData.year_of_establishment}
                                onChange={handleChange}
                                onFocus={(e) => {
                                    if (!e.target.disabled && e.target.showPicker) {
                                    e.target.showPicker();
                                    }
                                }}
                                placeholder="YYYY-MM"
                                disabled={formData.year_of_establishment ? true : false} 
                            />

                            {errors.year_of_establishment && (
                                <span className="text-red-500 text-sm">{errors.year_of_establishment}</span>
                            )}
                        </div>


                    </div>

                    <div>
                        <label>About Us<span className="text-red-500">*</span></label>
                        <textarea
                            id="about_company"
                            name="about_company"
                            rows="4"
                            className="block p-2.5 w-full border border-gray-300"
                            placeholder="Write about company..."
                            value={formData.about_company}
                            onChange={handleChange}
                            maxLength={5000}
                        ></textarea>
                        {errors.about_company && <span className="text-red-500 text-sm">{errors.about_company}</span>}
                    </div>
                    <div className="mt-5 grid grid-cols-2">
                        <div>
                            <label>Logo</label>
                            <div className="flex items-center">
                            <div className="flex w-full">
                                {/* Upload or Reset Button */}
                                <label className="h-10 mt-5">
                                <div className="">
                                    {logoPreview  ? (
                                    <span className="theme-btn text-white" onClick={handleLogoReset}>Reset</span>
                                    ) : (
                                    <span className="theme-btn text-white mr-2">Upload</span>
                                    )}
                                </div>

                                {!formData.logo_id && (
                                    <input
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    onChange={handleLogoUpload}
                                    accept=".png,.svg,.jpg,.jpeg"
                                    />
                                )}
                                </label>

                                {/* Image Preview */}
                                <div className="w-16 h-16 flex items-center justify-center rounded-[48px] ml-4 overflow-hidden" onClick={()=>{
                                    window.open(logoPreview)
                                }}>
                                {logoPreview ? (
                                    <img src={logoPreview} alt="Logo Preview" className="h-24 w-24 rounded-[48px]" />
                                ) : (
                                    <p className="text-xs text-gray-500"></p>
                                )}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-12 gap-x-6 mt-4">
                        <div className="col-span-3">
                            {/* {isSocialMedia && 
                                   <label><button type="button" className="theme-btn px-5 py-1 text-white float-right" onClick={() => setOpenSocialModal(true)}>Add</button></label>
                                }            */}
                            <div className="mt-2">
                                <label className="block mb-2">
                                    <img src='images/icons/linkedIn.svg' className='inline-block mr-2 h-5 w-5' alt="LinkedIn" />LinkedIn
                                </label>
                                <input
                                    type="text"
                                    name="linkedin"
                                    value={formData.linkedin}
                                    onChange={handleChange}
                                    className="w-full border border-gray-300"
                                    placeholder="https://linkedin.com/yourprofile"
                                />
                                {errors.linkedin && <span className="text-red-500 text-sm">{errors.linkedin}</span>}
                            </div>
                            {/* {Object.entries(additionalLinks).map(([platform, url]) => (
                                <div className="mt-2">
                                    <label className="block mb-2">
                                        <button
                                            onClick={(e) => handleDelete(platform, e)}
                                            className="ml-2 float-right"
                                        >
                                            <img src="images/icons/delet.svg"/>
                                        </button>
                                        {platform}
                                    </label>
                                    <input
                                        type="text"
                                        name="facebook"
                                        value={url}
                                        onChange={handleChange}
                                        className="w-full border border-gray-300"
                                        readOnly
                                    />
                                </div>
                            ))} */}
                        </div>
                        <div className="col-span-3">
                        <div className="mt-2">
                                <label className="block mb-2">
                                    <img src='images/icons/facebook.svg' className='inline-block mr-2 h-5 w-5' alt="Facebook" />Facebook
                                </label>
                                <input
                                    type="URL"
                                    name="facebook"
                                    value={formData.facebook}
                                    onChange={handleChange}
                                    className="w-full border border-gray-300"
                                    placeholder="https://facebook.com/yourprofile"
                                />
                                {errors.facebook && <span className="text-red-500 text-sm">{errors.facebook}</span>}
                            </div>

                        </div>
                        <div className="col-span-3">
                            <div className="mt-2">
                                <label className="block mb-2">
                                    <img src='images/icons/twitter.png' className='inline-block mr-2 h-5 w-5' alt="Twitter" />Twitter
                                </label>
                                <input
                                    type="url"
                                    name="twitter"
                                    value={formData.twitter}
                                    onChange={handleChange}
                                    className="w-full border border-gray-300"
                                    placeholder="https://twitter.com/yourprofile"
                                />
                               { errors.twitter && <span className="text-red-500 text-sm">{errors.twitter}</span>}
                            </div>
                        </div>
                    </div>
                  
                    <button
                        type="button"
                        className="theme-btn bordered-btn py-2.5 mt-4 float-left"
                        onClick={handleSubmit}
                    >
                        {/* <span className="color-text">{isProfileExists ? 'Update' : 'Submit'}</span> */}
                        <span className="color-text">Submit</span>
                    </button>
                    <button type="button" className="theme-btn bordered-btn py-2.5 ml-2 mt-4" onClick={handleSave}>
                                        <span className="color-text">Save</span>
                    </button>
                </form>
            </div>
            }
            {/* <Modal open={openSocialModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                        Add Social Medial Links
                    </Typography>
                    <div>
                        <input
                            type='input'
                            placeholder='Plateform'
                            value={socialMedia.platform}
                            name="platform"
                            onChange={handleSocialMedia}
                            className='w-full border border-gray-300 p-3 mt-4 rounded-md'
                        />
                    </div>
                    <div>
                        <input
                            type='input'
                            placeholder='URL'
                            name="url"
                            value={socialMedia.url}
                            onChange={handleSocialMedia}
                            className='w-full border border-gray-300 p-3 mt-4 rounded-md'
                        />
                    </div>

                    <div className='justify-center items-center mt-5 flex'>
                        <button onClick={() => setOpenSocialModal(false)} className='py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300'>
                            Cancel
                        </button>
                        <button onClick={AddSocialMedia} className='py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium theme-btn rounded-lg'>
                            Add
                        </button>
                    </div>
                </Box>
            </Modal> */}
        </>
    );
};

export default CompanyInfo;
