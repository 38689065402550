import React, { useState, useEffect } from "react";

import { Box } from "@mui/material";
import DrawerHeader from "../dashboard/DrawerHeader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import RightDrawer from "./ApplicantsDetail";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { getUniqueRoles } from "../../../utils/employer.api/employer.api.services";
import { useApplicantsContext } from "./applicantsContext";
import axiosClient from "../../../config/axios.Config";
import { toast, Toaster } from "react-hot-toast";
import { Tooltip, CircularProgress, IconButton } from "@mui/material";

const DataTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectJob, setSelectJob] = useState( "");
  const [selectByStatus, setSelectByStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [roles, setRoles] = useState([]);
  const loginData = useSelector((state) => state.auth.Auth);
  const [loading, setLoading] = useState(false);

  // Fetch applicants data
  const fetchUniqueRoles = async () => {
    try {
      const response = await getUniqueRoles(loginData.access_token);
      setRoles(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllApplicants = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `${config.apiUrl}employer/all-applicants`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
          params: {
            applied_role: selectJob,
            applicant_status: selectByStatus,
            search: searchQuery,
          },
        }
      );
      setApplicants(response.data.applicants);
      setTotalApplicants(response.data.total_applicants);
    } catch (error) {
      console.error("Error fetching applicants", error);
      toast.error("Unable to fetch applicants.Please try again later");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when filter or search inputs change
  useEffect(() => {
    fetchUniqueRoles();
  }, []);
  useEffect(() => {
    fetchAllApplicants();
  }, [selectJob, selectByStatus, searchQuery]);

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Handle dropdown and search input changes
  const handleChangeJob = (event) => {
    localStorage.setItem("job", event.target.value);
    setSelectJob(event.target.value);
  };

  const handleChangeStatus = (event) => {
    localStorage.setItem("job_status", event.target.value);
    setSelectByStatus(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Toggle drawer for applicant details
  const { setApplicantsId } = useApplicantsContext();

  const toggleDrawer = (open, id) => (event) => {
    console.log("toggleDrawer", open, id);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
    setApplicantsId(id);
    // fetchAllApplicants();
  };

  const columns = [
    { id: "applicant", label: "Applicant", minWidth: 200 },
    { id: "rating", label: "Rating", minWidth: 100 },
    { id: "stage", label: "Stage", minWidth: 170, align: "left" },
    { id: "applied_role", label: "Applied Role", minWidth: 170, align: "left" },
    { id: "applied_on", label: "Applied On", minWidth: 170, align: "left" },
    { id: "details", label: "See Details", minWidth: 170, align: "center" },
  ];

  const title = (title) => {
    if (title.length > 20) {
      return title.substring(0, 20) + "...";
    } else {
      return title;
    }
  };

  return (
    <>
      <Toaster />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, marginTop: 10 }}
        className="mainContent"
      >
        {/* <DrawerHeader /> */}
        <RightDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer} />
        <h2 className="menu-heading">All Applicants</h2>

        {/* Filters */}
        <div className="filters flex justify-end mb-4">
          <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
            <InputLabel id="job-select-label">Based on Job Role</InputLabel>
            <Select
              labelId="job-select-label"
              id="job-select"
              value={selectJob}
              onChange={handleChangeJob}
              label="Based on Job Role"
            >
              <MenuItem value="">All</MenuItem>
              {roles.map((items) => (
                <MenuItem value={items}>{items}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
            <InputLabel id="status-select-label">Applicant Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={selectByStatus}
              onChange={handleChangeStatus}
              label="Applicant Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="in-review">In review</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ m: 1, width: "150px" }} size="small">
            <TextField
              id="outlined-search"
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              size="small"
            />
          </FormControl>
        </div>

        {/* Table */}
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer>
            <Table stickyHeader aria-label="applicants table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress size={30} className="" />
                    </TableCell>
                  </TableRow>
                ) : applicants.length > 0 ? (
                  applicants
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((applicant) => (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={applicant.id}
                        className="relative border-none after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[1px] after:bg-gray-200"
                      >
                        <TableCell className="py-3 px-4">
                          {applicant.name}
                        </TableCell>
                        <TableCell className="py-3 px-4 flex items-center gap-1">
                          <StarRateRoundedIcon sx={{ color: "#FFD928" }} />
                          {applicant.rating}
                        </TableCell>
                        <TableCell className="py-3 px-4">
                          {applicant.stage}
                        </TableCell>
                        <TableCell className="py-3 px-4">
                          {title(applicant.applied_role)}
                        </TableCell>
                        <TableCell className="py-3 px-4">
                          {applicant.applied_on}
                        </TableCell>
                        <TableCell align="center">
                        <MoreHorizIcon onClick={toggleDrawer(true,applicant.id)} />
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      className="py-6 text-gray-500"
                    >
                      No applicants found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalApplicants}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default DataTable;
