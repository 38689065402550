// RightDrawer.js
import React from "react";
import Drawer from "@mui/material/Drawer";
import JobDetails from "./jobDetails";

const RightDrawer = ({
  open,
  toggleDrawer,
  jobDetails,
  fetchJobsRecommendations,
  fetchJobDetails,
  drawerLoading,
  setDrawerLoading,
  data,
  setData
}) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      className="candidate-details-section"
    >
      <JobDetails
        jobDetails={jobDetails}
        fetchJobsRecommendations={fetchJobsRecommendations}
        fetchJobDetails={fetchJobDetails}
        drawerLoading={drawerLoading}
        setDrawerLoading={setDrawerLoading}
        data={data}
        setData={setData}
      />
      {open && (
        <div
          onClick={toggleDrawer(false)}
          className="fixed right-[780px] top-4 w-[48px] h-[48px] z-50"
        >
          <div className="w-full h-full rounded-full border-[2px] flex items-center justify-center bg-white border-gray-600 ">
            <svg
              width="11"
              height="20"
              viewBox="0 0 11 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 19L10 10L1 1"
                stroke="#86888A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default RightDrawer;
