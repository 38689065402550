import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getInterviewPage } from "../../../utils/candidate.api/candidate.api.services";
import { useSelector } from "react-redux";
import axiosClient from "../../../config/axios.Config";
import config from "../../../config/config";
import Loader from "../../../utils/Loader";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Close as CloseIcon,
  Event as EventIcon,
  Schedule as ScheduleIcon,
  ContentCopy as CopyIcon,
  Phone as PhoneIcon,
  Videocam as VideocamIcon,
  LocationCity as BuildingIcon,
  Person as PersonIcon,
  EventRepeat as EventRepeatIcon,
} from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast, Toaster } from "react-hot-toast";
import { set } from "date-fns";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const InterviewContent = () => {
  const loginData = useSelector((state) => state.auth.Auth);
  const [value, setValue] = React.useState(0);
  const [logoUrls, setLogoUrls] = useState({});
  const [open, setOpen] = useState(false);
  const [openR, setOpenR] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isAnyAction, setIsAnyAction] = useState(false);
  const [isTriggered, setIsTriggered] = useState(false);
  // const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [loading, setLoading] = useState(false);
  const BASE_URL = config.apiUrl;
  const token = loginData.access_token;

  const [selectedDays, setSelectedDays] = useState({});

  // current time
  const currentTime = new Date().toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  // Generate available dates
  const today = new Date();

  // disable past dates
  const disablePastDates = (date) => {
    const interviewDate = new Date(selectedInterview?.interview_date);
    const selectedDate = new Date(date);

    // Normalize time to midnight to avoid time-related issues
    interviewDate.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    console.log(
      "Interview Date:",
      interviewDate,
      "Selected Date:",
      selectedDate
    );

    return selectedDate <= interviewDate; // Disables past dates
  };

  // disable past time slots
  const disablePastTimeSlots = (time, date) => {
    const today = new Date();
    const selectedDate = new Date(date);
    const originalDate = new Date(selectedInterview?.interview_date);

    // Allow future dates
    if (selectedDate.getTime() > originalDate.getTime()) {
      return false;
    }

    const originalTime = selectedInterview?.interview_time?.start_time;
    const [selectedHours, selectedMinutes] = time.split(":").map(Number);

    // If date matches the interview date, compare time
    if (selectedDate.getTime() === originalDate.getTime()) {
      const [originalHours, originalMinutes] = originalTime
        .split(":")
        .map(Number);
      return (
        selectedHours < originalHours ||
        (selectedHours === originalHours && selectedMinutes < originalMinutes)
      );
    }

    // Compare with current time for today’s date
    if (selectedDate.toDateString() === today.toDateString()) {
      const currentTime = new Date();
      const currHours = currentTime.getHours();
      const currMinutes = currentTime.getMinutes();

      return (
        selectedHours < currHours ||
        (selectedHours === currHours && selectedMinutes <= currMinutes)
      );
    }

    return false;
  };

  const availableDates = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    return {
      formattedDate: date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
      }), // Format for display
      isoDate: date.toISOString().split("T")[0], // Format for backend (YYYY-MM-DD)
    };
  });

  // Define available time slots
  const availableTimeSlots = [
    { time: "10:00 - 11:00", period: "AM" },
    { time: "11:30 - 12:30", period: "PM" },
    { time: "13:00 - 14:00", period: "PM" },
    { time: "14:30 - 15:00", period: "PM" },
    { time: "15:30 - 16:30", period: "PM" },
    { time: "17:30 - 18:00", period: "PM" },
  ];

  // Handle day selection (toggle the day)
  const toggleSelectedDay = (day) => {
    setSelectedDays((prev) => {
      const newSelectedDays = { ...prev };
      if (newSelectedDays[day]) {
        delete newSelectedDays[day]; // Deselect if already selected
      } else {
        newSelectedDays[day] = []; // Add empty array for time slots
      }
      return newSelectedDays;
    });
  };

  // Handle time slot selection for a selected day (add or remove time slots)
  const toggleSelectedTimeSlot = (date, time) => {
    setSelectedDays((prev) => {
      const currentTimes = prev[date] || [];
      const isSelected = currentTimes.includes(time);

      return {
        ...prev,
        [date]: isSelected
          ? currentTimes.filter((t) => t !== time)
          : [...currentTimes, time],
      };
    });
  };

  const onClose = () => {
    setOpen(false);
    setSelectedInterview(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onCloseR = () => {
    // reset form values
    formik.resetForm();
    setSelectedDays({});
    setOpenR(false);
    setSelectedDate("");
    // setSelectedTimeSlot("");
  };

  const handleOpenDialog = () => setOpenR(true);

  const validationSchema = Yup.object({
    reason: Yup.string()
      .min(10, "Reason must be at least 10 characters")
      .required("Reason is required"),
  });

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const proposed_datetime = Object.entries(selectedDays).map(
        ([proposed_date, proposed_time_slots]) => ({
          proposed_date,
          proposed_time_slots: proposed_time_slots.map((slot) => {
            const [start_time, end_time] = slot.split(" - ");
            return { start_time: start_time.trim(), end_time: end_time.trim() };
          }),
        })
      );

      const formattedData = {
        proposed_datetime,
        reason: values.reason.trim(),
      };

      console.log("Reschedule data:", formattedData);

      axiosClient
        .post(
          `${BASE_URL}candidate/interview/${selectedInterview?.id}/request-reschedule`,
          formattedData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          toast.dismiss();
          toast.success("Interview rescheduled request sent successfully");
          setIsAnyAction(!isAnyAction);
          setIsTriggered(!isTriggered);
        })
        .catch((error) => {
          console.error(
            "Error rescheduling interview:",
            error.response ? error.response.data : error.message
          );
          toast.dismiss();
          toast.error(error.response.data.detail);
        });
      onCloseR();
      onClose();
    },
  });

  // decline interview
  const handleDecline = (id) => {
    axiosClient
      .post(
        `${BASE_URL}candidate/interview/${id}/decline`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        toast.dismiss();
        toast.success("Interview declined successfully");
        setIsAnyAction(!isAnyAction);
        setIsTriggered(!isTriggered);
      })
      .catch((error) => {
        console.error(
          "Error declining interview:",
          error.response ? error.response.data : error.message
        );
        toast.dismiss()
        toast.error(error.response.data.detail || "Error declining interview");
      });
    onClose();
  };

  // accept interview
  const handleAccept = (id) => {
    axiosClient
      .post(
        `${BASE_URL}candidate/interview/${id}/accept`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        toast.dismiss();
        toast.success("Interview accepted successfully");
        setIsAnyAction(!isAnyAction);
        setIsTriggered(!isTriggered);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(error.response.data.detail);
      });
    onClose();
  };

  // Determine the appropriate icon for interview type
  const getInterviewTypeIcon = (type) => {
    switch (type?.toLowerCase()) {
      case "phone":
        return <PhoneIcon className=" text-gray-400 mr-2" />;
      case "in-office":
        return <BuildingIcon className=" text-gray-400 mr-2" />;
      case "video call":
        return <VideocamIcon className=" text-gray-400 mr-2" />;
      default:
        return null;
    }
  };

  const [interviewData, setInterviewData] = useState({
    stats: {
      interviews_for_today: 0,
      pending_interviews: 0,
      completed_interviews: 0,
    },
    upcoming_interviews: [],
    completed_interviews: [],
    reschedule_requests: [],
  });
  const fetchAllInterviews = async () => {
    setLoading(true);
    try {
      const response = await getInterviewPage(loginData.access_token);
      if (response.status == 200) {
        setInterviewData(response.data);
        console.log(response.data);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error(
        "Error fetching interviews:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };
  const fetchCompanyLogo = async (logo_id) => {
    try {
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/company-logo/${logo_id}`,
        {
          // responseType: "blob",
        }
      );
      if (response.status === 200) {
        const logoUrl = response.data.url;
        setLogoUrls((prev) => ({ ...prev, [logo_id]: logoUrl })); // Store logo URL with logo_id as key
      }
    } catch (error) {
      console.error(
        "Error fetching company logo:",
        error.response ? error.response.data : error.message
      );
    }
  };
  const fetchAllCompanyLogos = (interviews) => {
    interviews.forEach((interviewDay) => {
      interviewDay.interviews.forEach((interview) => {
        if (!logoUrls[interview.employer_id]) {
          fetchCompanyLogo(interview.employer_id);
        }
      });
    });
  };
  useEffect(() => {
    if (interviewData.upcoming_interviews) {
      fetchAllCompanyLogos(interviewData.upcoming_interviews);
    }
    if (interviewData.completed_interviews) {
      fetchAllCompanyLogos(interviewData.completed_interviews);
    }
    if (interviewData.reschedule_requests) {
      fetchAllCompanyLogos(interviewData.reschedule_requests);
    }
  }, [interviewData]);

  useEffect(() => {
    fetchAllInterviews();
  }, [loginData.access_token, isAnyAction, isTriggered]);

  const handleView = (interview) => {
    setSelectedInterview(interview);
    setOpen(true);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedInterview.joining_link || "");
    toast.dismiss();
    toast.success("Meeting link copied to clipboard");
  };

  const FormatSalary = (salary) => {
    console.log(salary);
    return parseInt(salary) / 100000;
  };

  const title = (title) => {
    if (title?.length > 25) {
      return title?.substring(0, 25) + "...";
    } else {
      return title;
    }
  };

  return (
    <>
      <Toaster toastOptions={{ limit: 1 }} />
      {loading && <Loader />}
      <div>
        {/* <h2 className="menu-heading pl-0 lg:mb-2">Interview Status</h2> */}
        {/* <div className="flex gap-x-4">
          <div className="status-box">
            <h2>Interviews for Today</h2>
            <h3>{interviewData.stats.interviews_for_today}</h3>
          </div>
          <div className="status-box">
            <h2>Pending Interviews</h2>
            <h3>{interviewData.stats.pending_interviews}</h3>
          </div>
          <div className="status-box">
            <h2>Completed Interviews</h2>
            <h3>{interviewData.stats.completed_interviews}</h3>
          </div>
        </div> */}
        <div>
          {/* <h2 className="menu-heading pl-0 lg:mb-2">Your Interviews</h2> */}
          <Box sx={{ width: "100%" }} className="setting-tabs">
            <Box
              sx={{ borderBottom: 0, borderColor: "divider" }}
              className="hirree-tabs"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Upcoming " {...a11yProps(0)} />
                <Tab label="Completed" {...a11yProps(1)} />
                <Tab label="Rescheduled" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              {interviewData.upcoming_interviews.map(
                (interviewDay, dayIndex) => (
                  <>
                    <div>{interviewDay.status}</div>
                    <div key={dayIndex} className="mt-4">
                      <h3 className="mb-2 font-semibold">
                        {interviewDay.date}
                      </h3>
                      <div className="grid grid-cols-3 gap-x-4 ">
                        {interviewDay.interviews.map((interview, index) => (
                          <>
                            <div
                              className="interview-card relative  w-full h-full"
                              key={index}
                              onClick={() => handleView(interview)}
                            >
                              {/* {console.log(interview)} */}

                              <div className="flex items-center gap-x-4 mb-2">
                                <div className="user-img" alt="logo">
                                  <img
                                    src={
                                      logoUrls[interview.employer_id] ||
                                      "images/default.png"
                                    }
                                    alt="Company Logo"
                                  />
                                </div>
                                <div>
                                  <div className="flex mt-[15px] justify-between items-center">
                                    <p className="text-[12px] text-[#484848] mb-1">
                                      <img
                                        src="images/icons/clock.svg"
                                        className="inline mr-1"
                                        alt="logo"
                                      />
                                      {new Date(
                                        `1970-01-01T${interview.interview_time.start_time}`
                                      )
                                        .toLocaleTimeString("en-US", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        })
                                        .replace(" ", "")
                                        .toUpperCase()}
                                      -
                                      {new Date(
                                        `1970-01-01T${interview.interview_time.end_time}`
                                      )
                                        .toLocaleTimeString("en-US", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        })
                                        .replace(" ", "")
                                        .toUpperCase()}
                                    </p>
                                  </div>
                                  <h3 className="text-[16px] text-black mb-1">
                                    {interview.company_name}
                                  </h3>
                                  <p className="text-[12px] text-[#86888A] mb-1">
                                    <Tooltip
                                      title={interview.job_title}
                                      placement="top"
                                      arrow
                                    >
                                      <span className="truncate">
                                        {title(interview.job_title)}
                                      </span>
                                    </Tooltip>

                                    {/* {"  "}
                                    <span>{interview.job_location ? interview.job_location : "Remote"} |</span>
                                    {"  "}
                                    <span>{FormatSalary(interview.salary)} Lakh </span> */}
                                  </p>
                                  <p className="text-[12px] text-[#484848] mb-1">
                                    <img
                                      src={
                                        interview.interview_type ===
                                        "Video call"
                                          ? "images/icons/video-call.svg"
                                          : interview.interview_type === "Phone"
                                          ? "images/icons/phone.svg"
                                          : "images/icons/location.svg"
                                      }
                                      className="inline mr-1"
                                      alt="logo"
                                    />
                                    <span className="">
                                      {interview.interview_type}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              {interview.status === "scheduled" && (
                                <button
                                  type="button"
                                  className="schedule-btn bg-red-500"
                                  style={{ background: "orange" }}
                                >
                                  Interview Scheduled
                                </button>
                              )}
                              {interview.status === "accepted" && (
                                <button
                                  type="button"
                                  className="schedule-btn bg-red-500"
                                  style={{ background: "green" }}
                                >
                                  Interview Accepted
                                </button>
                              )}
                              {interview.status === "completed" && (
                                <button
                                  type="button"
                                  className="schedule-btn bg-red-500"
                                  style={{ background: "green" }}
                                >
                                  Interview Completed
                                </button>
                              )}
                              {interview.status === "reschedule_requested" && (
                                <button
                                  type="button"
                                  className="schedule-btn bg-blue-500"
                                  style={{ background: "blue" }}
                                >
                                  Reschedule Requested
                                </button>
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </>
                )
              )}
              {interviewData.upcoming_interviews.length === 0 && !loading && (
                <div
                  className="flex justify-center items-center"
                  style={{ height: "70vh" }}
                >
                  <p className="text-center text-gray-400  ">
                    No Upcoming Interview found
                  </p>
                </div>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {interviewData.completed_interviews.map(
                (interviewDay, dayIndex) => (
                  <div key={dayIndex}>
                    <h3 className="mb-2">{interviewDay.date}</h3>{" "}
                    {/* Display the date */}
                    <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-2">
                      {interviewDay.interviews.map((interview, index) => (
                        <div className="interview-card relative" key={index}>
                          <div className="flex items-center mt-[15px] gap-x-4">
                            <div className="user-img">
                              <img
                                src={
                                  logoUrls[interview.employer_id] ||
                                  "images/default.png"
                                } // Fetch logo by ID
                                alt="Company Logo"
                              />
                            </div>
                            <div>
                              <p className="text-[12px] text-[#484848] mb-2">
                                <img
                                  src="images/icons/clock.svg"
                                  className="inline mr-1"
                                  alt="logo"
                                />
                                {new Date(
                                  `1970-01-01T${interview.interview_time.start_time}`
                                )
                                  .toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                  .replace(" ", "")
                                  .toUpperCase()}
                                -
                                {new Date(
                                  `1970-01-01T${interview.interview_time.end_time}`
                                )
                                  .toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                  .replace(" ", "")
                                  .toUpperCase()}
                              </p>
                              <h3 className="text-[16px] text-black">
                                {interview.company_name}
                              </h3>
                              <p className="text-[12px] text-[#86888A]">
                                <span>{interview.job_title}</span>
                                {/* {"  "}
                                <span>{interview.job_location ? interview.job_location : "Remote"} |</span>
                                {"  "}
                                <span>{FormatSalary(interview.salary)} Lakh </span> */}
                              </p>
                              <p className="text-[12px] text-[#484848] mb-1">
                                <img
                                  src={
                                    interview.interview_type === "Video call"
                                      ? "images/icons/video-call.svg"
                                      : interview.interview_type === "Phone"
                                      ? "images/icons/phone.svg"
                                      : "images/icons/location.svg"
                                  }
                                  className="inline mr-1"
                                  alt="logo"
                                />
                                <span className="">
                                  {interview.interview_type}
                                </span>
                              </p>
                            </div>
                          </div>
                          {interview.status === "scheduled" && (
                            <button
                              type="button"
                              className="schedule-btn bg-red-500"
                              style={{ background: "orange" }}
                            >
                              Interview Scheduled
                            </button>
                          )}
                          {interview.status === "accepted" && (
                            <button
                              type="button"
                              className="schedule-btn bg-red-500"
                              style={{ background: "green" }}
                            >
                              Interview Accepted
                            </button>
                          )}
                          {interview.status === "completed" && (
                            <button
                              type="button"
                              className="schedule-btn bg-red-500"
                              style={{ background: "green" }}
                            >
                              Interview Completed
                            </button>
                          )}
                          {interview.status === "reschedule_requested" && (
                            <button
                              type="button"
                              className="schedule-btn bg-blue-500"
                              style={{ background: "blue" }}
                            >
                              Reschedule Requested
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
              {interviewData.completed_interviews.length === 0 && !loading && (
                <div
                  className="flex justify-center items-center"
                  style={{ height: "70vh" }}
                >
                  <p className="text-center text-gray-400  ">
                    No Completed Interview found
                  </p>
                </div>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {interviewData.reschedule_requests.map(
                (interviewDay, dayIndex) => (
                  <div key={dayIndex}>
                    <h3 className="mb-2">{interviewDay.date}</h3>{" "}
                    {/* Display the date */}
                    <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-2">
                      {interviewDay.interviews.map((interview, index) => (
                        <div className="interview-card relative" key={index}>
                          <div className="flex items-center mt-[15px] gap-x-4">
                            <div className="user-img">
                              <img
                                src={
                                  logoUrls[interview.employer_id] ||
                                  "images/default.png"
                                } // Fetch logo by ID
                                alt="Company Logo"
                              />
                            </div>
                            <div>
                              <p className="text-[12px] text-[#484848] mb-2">
                                <img
                                  src="images/icons/clock.svg"
                                  className="inline mr-1"
                                  alt="logo"
                                />
                                {new Date(
                                  `1970-01-01T${interview.interview_time.start_time}`
                                )
                                  .toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                  .replace(" ", "")
                                  .toUpperCase()}
                                -
                                {new Date(
                                  `1970-01-01T${interview.interview_time.end_time}`
                                )
                                  .toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                  .replace(" ", "")
                                  .toUpperCase()}
                              </p>
                              <h3 className="text-[16px] text-black">
                                {interview.company_name}
                              </h3>
                              <p className="text-[12px] text-[#86888A]">
                                <span>{interview.job_title}</span>
                                {/* {"  "}
                                <span>{interview.job_location ? interview.job_location : "Remote"} |</span>
                                {"  "}
                                <span>{FormatSalary(interview.salary)} Lakh </span> */}
                              </p>
                              <p className="text-[12px] text-[#484848] mb-1">
                                <img
                                  src={
                                    interview.interview_type === "Video call"
                                      ? "images/icons/video-call.svg"
                                      : interview.interview_type === "Phone"
                                      ? "images/icons/phone.svg"
                                      : "images/icons/location.svg"
                                  }
                                  className="inline mr-1"
                                  alt="logo"
                                />
                                <span className="">
                                  {interview.interview_type}
                                </span>
                              </p>
                            </div>
                          </div>
                          {interview.status === "scheduled" && (
                            <button
                              type="button"
                              className="schedule-btn bg-red-500"
                              style={{ background: "orange" }}
                            >
                              Interview Scheduled
                            </button>
                          )}
                          {interview.status === "accepted" && (
                            <button
                              type="button"
                              className="schedule-btn bg-red-500"
                              style={{ background: "green" }}
                            >
                              Interview Accepted
                            </button>
                          )}
                          {interview.status === "completed" && (
                            <button
                              type="button"
                              className="schedule-btn bg-red-500"
                              style={{ background: "green" }}
                            >
                              Interview Completed
                            </button>
                          )}
                          {interview.status === "reschedule_rejected" && (
                            <button
                              type="button"
                              className="schedule-btn bg-blue-500"
                              style={{ background: "red" }}
                            >
                              Reschedule Rejected
                            </button>
                          )}

                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
              {interviewData.reschedule_requests.length === 0 && !loading && (
                <div
                  className="flex justify-center items-center"
                  style={{ height: "70vh" }}
                >
                  <p className="text-center text-gray-400  ">
                    No Rescheduled Interview found
                  </p>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </div>
        {/* Dialog Box for Detailed Interview View */}
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            className: "rounded-[32px]",
          }}
        >
          {/* Dialog Header */}
          <DialogTitle className="bg-gradient-to-r from-[#C1EBF3] to-[#E4D0F4] text-black flex items-center justify-between mb-3">
            <div className="flex items-center space-x-3">
              <img
                src={
                  logoUrls[selectedInterview?.employer_id] ||
                  "images/default.png"
                }
                alt="Company Logo"
                className="w-10 h-10 rounded-md"
              />
              <div className="color-text">
                <Typography variant="h6" component="div" className="color-text">
                  {selectedInterview?.job_title}
                </Typography>
                <Typography variant="subtitle2" className=" text-gray-600">
                  {selectedInterview?.company_name}{" "}
                  {selectedInterview?.location}
                  <span>
                    {" "}
                    · ₹{FormatSalary(selectedInterview?.salary)} Lakh{" "}
                  </span>
                </Typography>
              </div>
            </div>
            <IconButton onClick={onClose} className="text-white">
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          {/* Dialog Content */}
          <DialogContent className="p-6 ">
            <div className="mt-4 flex items-center space-x-2">
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Current Stage"
                value="Technical round 1"
                InputProps={{
                  readOnly: true,
                  className: "text-sm text-[#616161]",
                }}
                // disabled
              />
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center space-x-2">
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Interview Type"
                  value={selectedInterview?.interview_type || ""}
                  sx={{
                    color: "text-gray-400",
                  }}
                  InputProps={{
                    readOnly: true,
                    className: "text-sm text-[#616161]",
                    startAdornment: getInterviewTypeIcon(
                      selectedInterview?.interview_type
                    ),
                  }}
                  // disabled
                />
              </div>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Date"
                value={
                  selectedInterview?.interview_date
                    ? new Date(
                        selectedInterview.interview_date
                      ).toLocaleDateString("en-IN")
                    : "N/A"
                }
                InputProps={{
                  startAdornment: <EventIcon className="mr-2 text-gray-400" />,
                  className: "text-sm",
                  readOnly: true,
                }}
                // disabled
              />
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Time"
                value={
                  selectedInterview?.interview_time
                    ? `${new Date(
                        `1970-01-01T${selectedInterview.interview_time.start_time}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })} - ${new Date(
                        `1970-01-01T${selectedInterview.interview_time.end_time}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}`
                    : "N/A"
                }
                InputProps={{
                  startAdornment: (
                    <ScheduleIcon className="mr-2 text-gray-400" />
                  ),
                  className: "text-sm",
                  readOnly: true,
                }}
                // disabled
              />
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Interviewer"
                value={selectedInterview?.interviewer_name || "N/A"}
                InputProps={{
                  startAdornment: <PersonIcon className="mr-2 text-gray-400" />,
                  className: "text-sm",
                  readOnly: true,
                }}
                // disabled
              />
            </div>

            <div className="mt-4">
              {(() => {
                switch (selectedInterview?.interview_type?.toLowerCase()) {
                  case "phone":
                    return (
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Phone Number"
                        value={
                          selectedInterview?.phone_number
                            ? `${selectedInterview.phone_number.slice(
                                0,
                                5
                              )}*****`
                            : "N/A"
                        }
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <PhoneIcon className=" text-gray-400 mr-2" />
                          ),
                          className: "text-sm",
                        }}
                        // disabled
                      />
                    );
                  case "video call":
                    return (
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Meeting Link"
                        value={selectedInterview?.joining_link || "N/A"}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton onClick={handleCopy}>
                              <CopyIcon />
                            </IconButton>
                          ),
                          startAdornment: (
                            <VideocamIcon className=" text-gray-400 mr-2" />
                          ),
                          className: "text-sm",
                        }}
                        // disabled
                      />
                    );
                  case "in-office":
                    return (
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Office Location"
                        value={selectedInterview?.location || "N/A"}
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <BuildingIcon className=" text-gray-400 mr-2" />
                          ),
                          className: "text-sm",
                        }}
                        // disabled
                      />
                    );
                  default:
                    return (
                      <Typography variant="body2" className="text-gray-500">
                        No additional details available.
                      </Typography>
                    );
                }
              })()}
            </div>
          </DialogContent>

          {/* Dialog Actions */}
          <div className="flex justify-around p-4 space-x-4">
            {(() => {
              switch (selectedInterview?.status) {
                case "scheduled":
                  return (
                    <>
                      <Button
                        variant="contained"
                        className="theme-btn"
                        onClick={handleOpenDialog}
                      >
                        Request Reschedule
                      </Button>
                      <Button
                        variant="contained"
                        className="theme-btn"
                        onClick={() => handleAccept(selectedInterview?.id)}
                      >
                        Accept Invite
                      </Button>
                      <Button
                        variant="contained"
                        className=" bg-gradient-to-r from-red-500 to-red-400 hover:opacity-80"
                        onClick={() => handleDecline(selectedInterview?.id)}
                      >
                        Decline Invite
                      </Button>
                    </>
                  );
                case "accepted":
                  return (
                    <>
                      <Button
                        variant="contained"
                        className="theme-btn"
                        onClick={handleOpenDialog}
                      >
                        Request Reschedule
                      </Button>
                      <Button
                        variant="contained"
                        className="theme-btn"
                        onClick={() => handleAccept(selectedInterview?.id)}
                        disabled
                      >
                        Accepted Invite
                      </Button>
                    </>
                  );
                case "declined":
                  return (
                    <Button
                      variant="contained"
                      className="bg-gradient-to-r from-red-500 to-red-400 cursor-not-allowed text-white disabled:opacity-80"
                      onClick={() => alert("Invite accepted")}
                      disabled
                    >
                      declined Interview
                    </Button>
                  );
                case "reschedule_requested":
                  return (
                    <>
                      <Button
                        variant="contained"
                        className="theme-btn"
                        onClick={handleOpenDialog}
                        disabled
                      >
                        Request rescheduled
                      </Button>
                      <Button
                        variant="contained"
                        className="theme-btn"
                        onClick={() => handleAccept(selectedInterview?.id)}
                      >
                        Accept Invite
                      </Button>
                      <Button
                        variant="contained"
                        className=" bg-gradient-to-r from-red-500 to-red-400 hover:opacity-80"
                        onClick={() => handleDecline(selectedInterview?.id)}
                      >
                        Decline Invite
                      </Button>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </Dialog>

        <Dialog
          open={openR}
          onClose={onCloseR}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            className: "rounded-[32px]",
          }}
        >
          {/* Dialog Header */}
          <DialogTitle className="bg-gradient-to-r from-[#C1EBF3] to-[#E4D0F4] text-black flex items-center justify-between py-4 px-6 mb-4">
            <span className="font-bold text-lg">Request Reschedule</span>
            <IconButton onClick={onCloseR} className="text-black">
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          {/* Dialog Content */}
          <DialogContent className="p-6">
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-4 space-y-6">
                {/* Current Stage */}
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Current Stage"
                  value="Technical Round 1"
                  InputProps={{
                    readOnly: true,
                    className: "text-sm mb-2",
                  }}
                  disabled
                />

                {/* select your all psbl dateb */}
                <span className=" text-gray-400 text-lg font-semibold">
                  Select Date and Time
                </span>

                {/* Date Tabs */}
                <div className="flex space-x-2 overflow-x-auto pb-2 border-b">
                  {availableDates.map((date) => (
                    <Tooltip
                      title={
                        disablePastDates(date.isoDate)
                          ? "Date should be after original scheduled date"
                          : ""
                      }
                      key={date.isoDate}
                    >
                      <button
                        key={date.isoDate}
                        className={`px-4 py-2 rounded-lg cursor-pointer ${
                          selectedDays[date.isoDate]
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200"
                        }`}
                        onClick={() => toggleSelectedDay(date.isoDate)}
                        disabled={disablePastDates(date.isoDate)}
                      >
                        {date.formattedDate}
                      </button>
                    </Tooltip>
                  ))}
                </div>

                {/* Time Slots for each date */}
                {availableDates.map(
                  (date) =>
                    selectedDays[date.isoDate] && (
                      <div key={date.isoDate} className="mt-4">
                        <div className="font-semibold text-lg text-gray-400">
                          {date.formattedDate} Time Slots
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                          {availableTimeSlots.map((slot) => (
                            <button
                              key={slot.time}
                              onClick={() =>
                                toggleSelectedTimeSlot(date.isoDate, slot.time)
                              }
                              className={`px-4 py-2 rounded-lg text-sm ${
                                selectedDays[date.isoDate]?.includes(slot.time)
                                  ? "bg-blue-500 text-white"
                                  : "bg-gray-200"
                              }`}
                              disabled={disablePastTimeSlots(
                                slot.time,
                                date.isoDate
                              )}
                            >
                              {console.log(
                                "disablePastTimeSlots(slot.time)",
                                disablePastTimeSlots(slot.time)
                              )}
                              {slot.time}
                            </button>
                          ))}
                        </div>
                      </div>
                    )
                )}

                {/* Reason for Reschedule */}
                <TextField
                  fullWidth
                  size="small"
                  label="Reason for Reschedule"
                  placeholder="Explain Reason"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="reason"
                  value={formik.values.reason}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.reason && Boolean(formik.errors.reason)}
                  helperText={formik.touched.reason && formik.errors.reason}
                />
              </div>

              {/* Dialog Actions */}
              <div className="p-4 flex justify-end">
                <Button type="submit" variant="contained" className="theme-btn">
                  <EventRepeatIcon />
                  <span>Request Reschedule</span>
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
export default InterviewContent;
