import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MoreHorizRounded } from "@mui/icons-material";
import InterviewSchedule from "../interviewSchedule";
import { useSelector } from "react-redux";
import { useApplicantsContext } from "../all Applicants/applicantsContext";
import {
  DeleteInteview,
  getInterviewList,
} from "../../../utils/employer.api/employer.api.services";
import { toast } from "react-hot-toast";
import { Dialog } from "@mui/material";
import { CircularProgress } from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Scheduled = ({ ApplicantsDetail, fetchDetails }) => {
  const { applicantsId } = useApplicantsContext();
  const [value, setValue] = React.useState(0);
  const [inprogressInterviews, setInProgressInterviews] = useState([]);
  const [completedInterviews, setCompletedInterviews] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [interviewId, setInterviewId] = useState(null);
  const [warning, setWarning] = useState(false);
  const [currentInterview, setCurrentInterview] = useState(null);
  const [loading, setLoading] = useState(true);

  const dropdownRef = useRef(null);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [openModal, setOpenModal] = useState(false);
  const loginData = useSelector((state) => state.auth.Auth);

  const handleCloseModal = () => {
    setOpenModal(false);
    setInterviewId(null);
  };
  const OpenInterviewModal = (interviewId) => {
    setShowDropDown(false);
    fetchDetails();
    setInterviewId(interviewId);
    // console.log("Selected applicant ID: ", applicantId);
    setOpenModal(true);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchInterviews = async () => {
    setLoading(true);
    try {
      const response = await getInterviewList(
        loginData.access_token,
        applicantsId
      );
      console.log(response.data);
      setInProgressInterviews(response.data.in_progress);
      setCompletedInterviews(response.data.completed);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInterviews();
  }, [applicantsId]);
  const handleDeleteInterview = async () => {
    const interview_id = currentInterview.id;
    try {
      const response = await DeleteInteview(
        loginData.access_token,
        interview_id
      );
      toast.dismiss();
      toast.success("Interview deleted successfully");
      // Refresh or update inprogressInterviews here, e.g., by removing the deleted item from the state
      fetchInterviews();
      // ApplicantsDetail()
    } catch (error) {
      console.log("error while de", error);
      toast.dismiss();
      toast.error("Failed to delete interview");
    }
  };

  const handleWarning = () => {
    setWarning(!warning);
  };
  return (
    <>

        <>
          {!inprogressInterviews.length > 0 &&
            ApplicantsDetail?.detail_information?.stage !== "Rejected" &&
            ApplicantsDetail?.detail_information?.stage !== "Hired" && (
              <button
                type="button"
                className="color-btn"
                onClick={() => OpenInterviewModal()}
              >
                <img src="images/icons/plus.svg" className="inline" />
                Schedule Interview
              </button>
            )}
          <InterviewSchedule
            openModal={openModal}
            closeModal={handleCloseModal}
            jobId={ApplicantsDetail.detail_information.job_id}
            token={loginData.access_token}
            applicantId={applicantsId}
            applicantData={ApplicantsDetail.detail_information}
            applicantStages={ApplicantsDetail.stages}
            refreshInterviews={fetchInterviews}
            interviewId={interviewId}
            interviewdetails={inprogressInterviews}
            fetchDetails={fetchDetails}
          />
          <Box className="">
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="hirree-tabs bg-white"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="bg-white"
              >
                <Tab label="In-progress" {...a11yProps(0)} />
                <Tab label="Completed" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              {inprogressInterviews.map((item, index) => (
                <div key={index}>
                  <div className="flex justify-between relative">
                    <p>{item.interview_date}</p>
                    <MoreHorizRounded
                      onClick={toggleDropDown}
                      className="cursor-pointer"
                    />
                    {showDropDown && (
                      <div
                        ref={dropdownRef}
                        className="absolute right-0 top-6 w-24 rounded-md p-2 border-gray-500 border-solid border"
                        style={{ background: "#fff" }}
                      >
                        <button
                          type="button"
                          className="block text-sm mb-2"
                          onClick={() => {
                            OpenInterviewModal(item.id);
                            setShowDropDown(false);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="block text-sm"
                          onClick={() => {
                            // handleDeleteInterview(item.id)
                            setWarning(true);
                            setCurrentInterview(item);
                            setShowDropDown(false);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="interview flex justify-between items-center mt-3 gap-x-2">
                    <div className="flex items-center w-1/2">
                      {/* <img src="images/man.svg" className="mr-3" /> */}
                      <div>
                        <h3>{item.applicant_name}</h3>
                        <h5>{item.current_stage}</h5>
                      </div>
                    </div>
                    <div>
                      <h3>
                        {item.interview_time?.start_time} -{" "}
                        {item.interview_time?.end_time}
                      </h3>
                      <h5>
                        {item.joining_link
                          ? `Joining Link: ${item.joining_link}`
                          : item.phone_number
                          ? `Phone: ${item.phone_number}`
                          : item.location
                          ? `Location: ${item.location}`
                          : "No details available"}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
              {inprogressInterviews.length === 0 && (
                <div className="flex justify-center items-center h-40">
                  <p className="text-center">No interviews scheduled</p>
                </div>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {completedInterviews.map((item, index) => (
                <div key={index}>
                  <div className="flex justify-between relative">
                    <p>{item.interview_date}</p>
                  </div>
                  <div className="interview flex justify-between items-center mt-3 ">
                    <div className="flex items-center">
                      {/* <img src="images/man.svg" className="mr-3" /> */}
                      <div>
                        <h3>{item.applicant_name}</h3>
                        <h5>{item.current_stage}</h5>
                      </div>
                    </div>
                    <div>
                      <h3>
                        {item.interview_time?.start_time} -{" "}
                        {item.interview_time?.end_time}
                      </h3>
                      <h5>
                        {item.joining_link
                          ? `Joining Link: ${item.joining_link}`
                          : item.phone_number
                          ? `Phone: ${item.phone_number}`
                          : item.location
                          ? `Location: ${item.location}`
                          : "No details available"}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
              {completedInterviews.length === 0 && (
                <div className="flex justify-center items-center h-40">
                  <p className="text-center">No interviews completed</p>
                </div>
              )}
            </CustomTabPanel>
          </Box>
          <Dialog
            open={warning}
            handler={handleWarning}
            onClose={handleWarning}
            className="bg-transparent flex justify-center items-center"
            PaperProps={{
              sx: {
                borderRadius: "24px 0px 24px 24px",
                padding: "12px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                position: "relative", // Ensures child elements respect absolute positioning
              },
              className: "bg-white max-w-sm w-full transition-all duration-300",
            }}
          >
            <button
              onClick={() => setWarning(false)}
              className="absolute top-0 right-1 text-gray-500 hover:text-gray-800"
            >
              ✖
            </button>
            <div className="w-[400px] bg-white  px-2 pb-2">
              <div className="flex justify-between items-center border-b-0">
                <h2 className="text-lg font-semibold text-gray-800">
                  Are you sure you want to proceed?
                </h2>
              </div>
              <p className="text-sm text-gray-600 mt-3">
                Deleting this item will permanently remove it from your account.
                This action cannot be undone.
              </p>
              <div className="flex justify-end gap-3 mt-6">
                <button
                  onClick={() => setWarning(false)}
                  className="py-2 px-4 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200"
                >
                  No
                </button>
                <button
                  onClick={() => {
                    // DeletCv(fileIdUpdate);
                    handleDeleteInterview();
                    setWarning(false);
                  }}
                  className="py-2 px-4 text-sm font-medium text-white bg-gradient-to-tr from-purple-400 to-sky-400 rounded-lg hover:from-purple-500 hover:to-sky-500"
                >
                  Yes, Delete Now
                </button>
              </div>
            </div>
          </Dialog>
        </>

    </>
  );
};
export default Scheduled;
