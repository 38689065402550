import React, { useEffect } from "react";
import Header from "../../directories/header";
import Footer from "../../directories/footer";
import policies from "./privacyData";

const PrivacyPolicy = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    return (
        <>
            <Header />
            <section className="pt-0 flex justify-center">
            <div className="bg-white max-w-5xl w-full  rounded-lg">
        <div className="flex flex-col items-center justify-center p-6">
          <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
        </div>
        <div className="flex flex-col gap-4 p-6 sm:px-20 px-4 max-w-5xl w-full">
          {policies.map((policy, index) => (
            <div key={index} className="mb-4">
              <h2 className="text-lg font-semibold">{policy.title}</h2>
              <ul className="list-[lower-alpha] ml-8 text-sm text-gray-600">
                {policy.points.map((point, subIndex) => (
                  <li key={subIndex} className="mt-1 leading-6 tracking-wide">
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
            </section>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
