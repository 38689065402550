import React, { useState, useEffect } from "react";
import RightDrawer from "./rightDrawer";
import axiosClient from "../../../config/axios.Config";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import config from "../../../config/config";
import Filters from "./formFilter";
import AvatarIcon from "../AvatarIcon";
import { Chip } from "@mui/material";
import Loader from "../../../utils/Loader";
import { Tooltip, CircularProgress, IconButton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";


const Jobs = ({
  data,
  filters,
  setFilters,
  handleSearch,
  resetFilter,
  loading,
  loadMoreData,
  hasMore,
  setData,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedJobDetails, setSelectedJobDetails] = useState({});
  const loginData = useSelector((state) => state.auth.Auth);
  const [loadingJobId, setLoadingJobId] = useState(null);
  const [findLoader, setFindLoader] = useState(loading || true);

  // const [appliedJobs, setAppliedJobs] = useState({});
  const [companyLogos, setCompanyLogos] = useState({});

  const fetchCompanyLogo = async (logoId) => {
    if (logoId === "None") {
      setCompanyLogos((prev) => ({ ...prev, [logoId]: null }));
      return null;
    }
    try {
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/company-logo/${logoId}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
          // responseType: "blob",
        }
      );
      const logoUrl = response.data.url;
      return logoUrl;
    } catch (error) {
      console.error("Error fetching company logo:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchLogos = async () => {
      const logoMap = {};
      for (const job of data) {
        // console.log("job", job?.employer_id);
        if (job.employer_id && !logoMap[job.employer_id]) {
          const logoUrl = await fetchCompanyLogo(job?.employer_id);
          console.log("logoUrl", logoUrl);
          if (logoUrl) {
            logoMap[job?.employer_id] = logoUrl;
          }
        }
      }
      setCompanyLogos((prevLogos) => ({ ...prevLogos, ...logoMap }));
    };

    fetchLogos();

    return () => {
      Object.values(companyLogos).forEach((url) => URL.revokeObjectURL(url)); // Clean up blob URLs
    };
  }, [data]);

  useEffect(() => {
    return () => {
      Object.values(companyLogos).forEach((url) => URL.revokeObjectURL(url)); // Clean up on unmount
    };
  }, [companyLogos]);

  const saveJob = async (jobId) => {
    setLoadingJobId(jobId); // Show loader for this job

    try {
      console.log("access token", loginData.access_token);
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/save-job/${jobId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 201) {
        toast.dismiss();
        toast.success(response.data.message);

        // Update UI immediately
        data.forEach((item) => {
          if (item.id === jobId) {
            item.is_saved = true;
          }
        });

        // Optionally refresh job recommendations
        // fetchJobsRecommendations(1, true);
      }
    } catch (error) {
      console.error("Error saving job:", error);
      toast.error("Failed to save job!");
    } finally {
      setLoadingJobId(null); // Hide loader
    }
  };
  const unSaveJob = async (jobId) => {
    setLoadingJobId(jobId); // Set loader for this job

    try {
      console.log("access token", loginData.access_token);
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/unsave-job/${jobId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.dismiss();
        toast.success(response.data.message);

        // Update state synchronously
        data.forEach((item) => {
          if (item.id === jobId) {
            item.is_saved = false;
          }
        });
      }
    } catch (error) {
      console.error("Error unsaving job:", error);
      toast.error("Failed to unsave job!");
    } finally {
      setLoadingJobId(null); // Remove loader
    }
  };

  const toggleDrawer = (open, jobId) => async (event) => {
    console.log("toggling right drawer");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);

    if (open && jobId) {
      await fetchJobDetails(jobId);
    }
  };
  const fetchJobDetails = async (jobId) => {
    try {
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/job/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      setSelectedJobDetails(response.data);
    } catch (error) {
      console.log("Error fetching job details:", error);
    }
  };
  // const applyForJob = async (jobId) => {
  //     try {
  //         const response = await axiosClient.post(`${config.apiUrl}candidate/apply-job/${jobId}`, "", {
  //             headers: {
  //                 Authorization: `Bearer ${loginData.access_token}`,
  //             },
  //         });
  //         if (response.status === 201) {
  //             toast.success(response.data.message);
  //             setAppliedJobs((prev) => ({ ...prev, [jobId]: true }));

  //         }
  //     } catch (error) {
  //         toast.error(error.response.data.detail);
  //         console.log(error);
  //     }
  // };

  const formateSalary = (salary, salary_type) => {
    if (salary) {
      if (salary_type === "Monthly") {
        let yearlySalary = salary * 12;
        return yearlySalary / 100000;
      } else if (salary_type === "Yearly") {
        return salary / 100000;
      }
    } else {
      return "Salary not provided";
    }
  };

  const title = (title) => {
    if (title.length > 50) {
      return title.substring(0, 50) + "...";
    } else {
      return title;
    }
  };

  return (
    <div className="flex flex-col md:flex-row align-center gap-5 mb-4 h-30 ml-4 mr-4 p-4">
        <div className="flex-grow">
          {data.length > 0 &&
            !loading && (
              <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={hasMore}
                loader={
                  <div style={{ textAlign: "center", padding: "10px" }}>
                    <CircularProgress size={24} />
                  </div>
                }
                scrollableTarget="scrollable-table"
              >
                <div className="flex-grow">
                  <>
                    <RightDrawer
                      open={isDrawerOpen}
                      toggleDrawer={toggleDrawer}
                      jobDetails={selectedJobDetails}
                      fetchJobDetails={fetchJobDetails}
                      data={data}
                      setData={setData}
                    />

                    <div className="default-cards flex flex-col gap-3 items-center">
                      {data.map((item) => (
                        <div
                          className={`mb-3 w-full border rounded-2xl p-4 bg-white  ${
                            !item.is_accepting_applicants ? "grey-card" : ""
                          }`}
                          key={item.id}
                        >
                          {!item.is_accepting_applicants && (
                            <h2 className="text-sm ">
                              This job is no longer accepting applications.
                            </h2>
                          )}
                          <div className="flex items-center justify-between w-full">
                            {companyLogos[item?.employer_id] ? (
                              <img
                                src={companyLogos[item?.employer_id]}
                                alt="Company logo"
                                className="company-logo"
                              />
                            ) : (
                              <img
                                src="images/default.png"
                                alt="Default logo"
                                className="company-logo"
                              />
                            )}
                          
                            <div className="ml-4 flex-grow">
                              <h4 className="text-xl font-semibold text-gray-800">
                                {title(item.title)}
                              </h4>
                              <p className="text-gray-600">
                                {item.job_location
                                  ? item.job_location
                                  : "Remote"}{" "}
                                ₹
                                {formateSalary(
                                  item.expected_salary_min,
                                  item.salary_type
                                )}
                                -
                                {formateSalary(
                                  item.expected_salary_max,
                                  item.salary_type
                                )}{" "}
                                LPA
                              </p>
                            </div>
                            <button
                              className="flex items-center justify-center rounded-full h-9 w-9 bg-gray-300"
                              onClick={toggleDrawer(true, item.id)}
                            >
                              <img
                                src="images/icons/send-arrow.svg"
                                className=""
                                alt="View details"
                              />
                            </button>
                          </div>
                          <div className="ml-2 my-3 flex items-center gap-4">
                            <div className="flex items-center text-sm text-gray-600">
                              <img
                                src="images/icons/location.svg"
                                alt="Location"
                                className="mr-1"
                              />
                              <span>{item.job_modes}</span>
                            </div>
                            <div className="flex items-center text-sm text-gray-600">
                              <img
                                src="images/icons/cap.svg"
                                alt="Experience"
                                className="mr-1"
                              />
                              <span>{item.experience}</span>
                            </div>
                            <div className="flex items-center text-sm text-gray-600">
                              <img
                                src="images/icons/job1.svg"
                                alt="Job type"
                                className="mr-1"
                              />
                              <span>{item.job_types}</span>
                            </div>
                          </div>
                          <div className="ml-3 text-gray-600 text-sm">
                            {item.skill_set.map((skill, index) => (
                              <Chip
                                label={skill.name}
                                variant="filled"
                                size="small"
                                className="bg-pink-100 text-pink-800 mr-[4px]"
                              />
                            ))}
                          </div>
                          <div className="flex px-2 py-3 items-center">
                            <span className="flex items-center text-gray-600 text-sm">
                              <img
                                src="images/icons/history.svg"
                                className="mr-1"
                                alt="Posted date"
                              />
                              {item.posted_date}
                            </span>
                            <Tooltip
                          title={item.is_saved ? "Unsave Job" : "Save Job"}
                        >
                            <div className="ml-auto flex items-center">
                              {/* <button type='button' className='mr-4'>
                                                    <img src='images/icons/share.svg' alt="Share" />
                                                </button> */}
                              {loadingJobId === item.id ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <button type="button">
                                  {item.is_saved ? (
                                    <img
                                      src="images/icons/save2.svg"
                                      alt="save"
                                      onClick={() => unSaveJob(item.id)}
                                    />
                                  ) : (
                                    <img
                                      src="images/icons/save.svg"
                                      alt="save"
                                      onClick={() => saveJob(item.id)}
                                    />
                                  )}
                                </button>
                              )}
                            </div>
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                </div>
              </InfiniteScroll>
            )}

          {data.length === 0 && !loading && (
            <p className="text-center">No job results found.</p>
          )}
        </div>

      {/* filters */}
      <div className="w-full ml-auto md:w-1/4 md:ml-4 sticky top-0 bg-white rounded-2xl -z-4">
        <Filters
          filters={filters}
          setFilters={setFilters}
          resetFilter={resetFilter}
        />
      </div>
    </div>
  );
};

export default Jobs;
