import React, { Component, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Suspense, lazy } from "react";
import config from "../config/config";
import Home from "../components/Home/home";
import ForgotPassword from "../components/Login/candidate/ForgetPass";
import ComingSoon from "../components/coming soon/comming2";
import PrivacyPolicy from "../components/content page/privacyPolicy";
import TermsConditions from "../components/content page/termsCondition";
import SignUp from "../components/Sign Up/SignUp";
import Login from "../components/Login/login";
import CandidateDashboard from "../components/candidate/dashboard/dashboard";
import store, { persistor } from "../redux copy/storeConfig";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Setting from "../components/candidate/dashboard/setting";
import EmployerDashboard from "../components/employer/dashboard/dashboard";
import JobListening from "../components/employer/jobListening/index";
import TotalApplicants from "../components/employer/total Applicants";
import AllApplicants from "../components/employer/all Applicants";
import EmployerSetting from "../components/employer/setting";
import Configuration from "../components/employer/configuration";
import Recomendation from "../components/candidate/recomendation";
import MyApplications from "../components/candidate/my application";
import SavedJob from "../components/candidate/saved jobs";
import ProtectedRoute from "../components/privateRoute";
import Help from "../components/employer/help";
import PostJobPage from "../components/employer/Job-post/postJobPage";
import Interview from "../components/candidate/interview";
import FindJob from "../components/candidate/findJob";
import Calendar from "../components/employer/calender";
import UploadResumeModal from "../components/candidate/resume/uploadResumeModal";
import Request from "../components/employer/requests";
import Subscription from "../components/employer/subscription";
import Candidate from "../components/employer/candidate";
import JobsTab from "../components/candidate/jobs/JobsTab";
import CandidateNotification from "../components/candidate/Notifications";
import ChatHistory from "../components/candidate/chatHistory";
import ChatUIEmp from "../components/employer/chatHistory/History";
import ChatHistoryEmp from "../components/employer/chatHistory";
import InternetStatus from "../utils/Nointernet";
import JobSlug from "../components/candidate/JobId";
import ToastCleanup from "../utils/toastCleanUp";
import EmployerNotification from "../components/employer/Notifications";
import ContactUs from "../components/content page/contactUs";
import Loader from "../utils/Loader";

const RouterComponent = () => {
  return (
    <Provider store={store}>
    <InternetStatus />
    <ToastCleanup />
      <PersistGate loading={<Loader />} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path={`${config.baseUrl}`} element={<Home />} />
            <Route
              path={`${config.baseUrl}coming-soon`}
              element={<ComingSoon />}
            />
            <Route
              path={`${config.baseUrl}privacy-policy`}
              element={<PrivacyPolicy />}
            />
            <Route
              path={`${config.baseUrl}contact-us`}
              element={<ContactUs />}
            />
            <Route
              path={`${config.baseUrl}terms-and-conditions`}
              element={<TermsConditions />}
            />

            <Route
              path={`${config.candidateUrl}signup`}
              element={<SignUp userType="candidate" />}
            />
            <Route
              path={`${config.candidateUrl}forgot-password`}
              element={<ForgotPassword />}
            />
            <Route
              path={`${config.employerUrl}signup`}
              element={<Login userType="employer" />}
            />

            {/* ================candidate======================== */}

            <Route
              path={`${config.candidateUrl}login`}
              element={<Login userType="candidate" />}
            />
            <Route
              path={`${config.baseUrl}candidate/dashboard`}
              element={
                <ProtectedRoute>
                  <CandidateDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.candidateUrl}setting`}
              element={
                <ProtectedRoute>
                  <Setting />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.candidateUrl}find-job`}
              element={
                <ProtectedRoute>
                  <FindJob />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.candidateUrl}jobs`}
              element={
                <ProtectedRoute>
                  <JobsTab />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${config.candidateUrl}jobs/recomendation`}
              element={
                <ProtectedRoute>
                  <Recomendation />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.candidateUrl}jobs/my-applications`}
              element={
                <ProtectedRoute>
                  <MyApplications />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.candidateUrl}jobs/saved-jobs`}
              element={
                <ProtectedRoute>
                  <SavedJob />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.candidateUrl}interviews`}
              element={
                <ProtectedRoute>
                  <Interview />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.candidateUrl}upload-resume`}
              element={
                <ProtectedRoute>
                  <UploadResumeModal />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.candidateUrl}notifications`}
              element={
                <ProtectedRoute>
                  <CandidateNotification />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.candidateUrl}history`}
              element={
                <ProtectedRoute>
                  <ChatHistory />
                </ProtectedRoute>
              }
            />

            {/* slug based jobs */}
            <Route
              path={`jobs/:slug`}
              element={
                <>
                  <JobSlug />
                </>
              }
            />

            {/* ================Employer======================== */}

            <Route
              path={`${config.employerUrl}login`}
              element={<Login userType="employer" />}
            />
            <Route
              path={`${config.employerUrl}dashboard`}
              element={
                <ProtectedRoute>
                  <EmployerDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}job-listing`}
              element={
                <ProtectedRoute>
                  <JobListening />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}total-applicants/:jobId`}
              element={
                <ProtectedRoute>
                  <TotalApplicants />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}all-applicants`}
              element={
                <ProtectedRoute>
                  <AllApplicants />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}setting`}
              element={
                <ProtectedRoute>
                  <EmployerSetting />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}configuration`}
              element={
                <ProtectedRoute>
                  <Configuration />
                </ProtectedRoute>
              }
            />
            <Route path={`${config.employerUrl}help`} element={<Help />} />
            <Route
              path={`${config.employerUrl}post-job`}
              element={
                <ProtectedRoute>
                  <PostJobPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}mySchedule`}
              element={
                <ProtectedRoute>
                  <Calendar />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}requests`}
              element={
                <ProtectedRoute>
                  <Request />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}subscription`}
              element={
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}candidates`}
              element={
                <ProtectedRoute>
                  <Candidate />
                </ProtectedRoute>
              }
            />
             <Route
              path={`${config.employerUrl}history`}
              element={
                <ProtectedRoute>
                  <ChatHistoryEmp />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${config.employerUrl}notifications`}
              element={
                <ProtectedRoute>
                  <EmployerNotification />
                </ProtectedRoute>
              }
            />

            {/* <Route
              path={`${config.employerUrl}mySchedule`}
              element={<FeedBack />}
            /> */}
          </Routes>
          {/* </Suspense> */}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};
export default RouterComponent;
