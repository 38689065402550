import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../dashboard/Appbar'
import Drawer from '../dashboard/Drawer';
import DrawerHeader from '../dashboard/DrawerHeader';
import Notification from './Notification';
// import AvatarIcon from '../AvatarIcon';

export default function EmployerNotification() {
    return (
        <Box sx={{ display: 'flex' }}>
            {/* <CssBaseline /> */}
            {/* <AppBar /> */}
            <Drawer />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent">
                {/* <DrawerHeader /> */}
                <Notification />
            </Box>
            {/* <AvatarIcon /> */}
        </Box>
    );
}
