import axios from "axios";
import config from "./config";

// Create Axios instance
const client = axios.create({});

// Request and Response Interceptors
client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Handle response errors
      return Promise.reject(error);
    }
    console.error("Network or Server error:", error.message);
    return Promise.reject(new Error("Network or Server error"));
  }
);

let isRefreshing = false;
let subscribers = [];

function onTokenRefreshed(newToken) {
  subscribers.forEach((callback) => callback(newToken));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const role = localStorage.getItem("role");

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addSubscriber((newToken) => {
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            resolve(client(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem("refresh_token");
        const response = await axios.post(
          `${config.apiUrl}${role}/${role == "employer"? "refresh" : "refresh-token" }?refresh_token=${refreshToken}`
        );

        const newAccessToken = response.data.access_token;
        localStorage.setItem("access_token", newAccessToken);

        const data = localStorage.getItem("persist:root");
        if (data) {
          const parsedData = JSON.parse(data);
          const authData = JSON.parse(parsedData.auth);
          authData.Auth.access_token = newAccessToken;
          parsedData.auth = JSON.stringify(authData);
          localStorage.setItem("persist:root", JSON.stringify(parsedData));
          console.log("Access token updated successfully!");
        //   window.location.reload();
        } else {
          console.error("No data found in localStorage to update.");
        }

        onTokenRefreshed(newAccessToken);

        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return client(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

// Centralized Axios Client Function
const axiosClient = async (endpoint, payload = {}, method = "get", headers = {}) => {
  const axiosConfig = {
    method: method.toLowerCase(),
    url: `${config.apiUrl}${endpoint}`,
    headers: { ...headers },
  };

  if (method.toLowerCase() === "get") {
    axiosConfig.params = payload;
  } else {
    axiosConfig.data = payload;
  }

  return client(axiosConfig);
};

// Expose the raw Axios instance for chaining calls
// Expose the raw client methods for chaining
axiosClient.get = (url, config) => client.get(url, config);
axiosClient.post = (url, data, config) => client.post(url, data, config);
axiosClient.put = (url, data, config) => client.put(url, data, config);
axiosClient.delete = (url, config) => client.delete(url, config);
axiosClient.patch = (url, data, config) => client.patch(url, data, config);

export default axiosClient;
