import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import config from '../config/config';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from "../utils/Loader";

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useSelector(state => !!state.auth.Auth);

  const dispatch = useDispatch();

  const location = useLocation();

  const role = useSelector(state => state.auth.Auth.role);
  console.log(role);
  const currentPath = location.pathname;
  const urlRole = currentPath.split('/')[1];
  console.log(urlRole)

  const handleLogout = async () => {
    await dispatch({ type: 'LOGOUT' });
    await localStorage.removeItem('token'); 
    await localStorage.removeItem('refresh_token');
    setTimeout(() => {
      window.location.reload(true); 
      window.location.href = "/";
    }, 100);
  };

  useEffect(() => {
    if(role === 'candidate' && urlRole === 'employer') {
      // logout
      handleLogout();
      
    }
    if(role === 'employer' && urlRole === 'candidate') {
      // logout
      handleLogout();
    }
  }
  , [role, currentPath]);


  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 100);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader/> // Or some spinner
  }

  return isAuthenticated ? children : <Navigate to={`${config.baseUrl}`} />;
};

export default ProtectedRoute;
