import React, { useEffect, useState } from "react";
import { Tabs, Tab, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import config from "../../config/config";
import axiosClient from "../../config/axios.Config";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import Header from "./JobShareNavBar";

const JobPostingPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const { slug } = useParams();
  const loginData = useSelector((state) => state.auth.Auth);
  const [jobDetails, setJobDetails] = useState(null);
  const [companyLogo, setCompanyLogo] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingJobId, setLoadingJobId] = useState(null);
  const [token, setToken] = useState(loginData.access_token || "");
  const [appliedInfo, setAppliedInfo] = useState(null);

  const navigate = useNavigate();

  // info about apllied job
  const checkApplied = async () => {
    if (!slug) return;
    try {
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/applications/status?job_slug=${slug}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      const appliedJobs = response.data;
      console.log("appliedJobs", appliedJobs);
      setAppliedInfo(appliedJobs);
    } catch (error) {
      console.error("Error fetching applied jobs:", error);
      // toast.error("Failed to load applied jobs");
    }
  };
  useEffect(() => {
    console.log("loginData", loginData);
    if (loginData.access_token) {
      console.log("loginData", loginData);
      checkApplied();
    }
  }, []);

  // Fetch job details
  const fetchJobDetails = async () => {
    if (!slug) return;

    setLoading(true);
    try {
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/share/${slug}`
      );
      setJobDetails(response.data);
      if (response.data.employer_id) {
        fetchCompanyLogo(response.data.employer_id);
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
      toast.error("Failed to load job details");
    } finally {
      setLoading(false);
    }
  };

  // Fetch company logo
  const fetchCompanyLogo = async (logoId) => {
    if (!logoId) return;

    try {
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/company-logo/${logoId}`
      );
      setCompanyLogo(response.data.url);
    } catch (error) {
      console.error("Error fetching company logo:", error);
      setCompanyLogo("");
    }
  };

  // Save job function
  const saveJob = async () => {
    if (!jobDetails?.id) return;

    setLoadingJobId(jobDetails.id);
    try {
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/save-job/${jobDetails.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      if (response.status === 201) {
        toast.success(response.data.message);
        fetchJobDetails();
      }
    } catch (error) {
      console.error("Error saving job:", error);
      toast.error("Failed to save job");
    } finally {
      setLoadingJobId(null);
    }
  };

  // Unsave job function
  const unSaveJob = async () => {
    if (!jobDetails?.id) return;

    setLoadingJobId(jobDetails.id);
    try {
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/unsave-job/${jobDetails.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchJobDetails();
      }
    } catch (error) {
      console.error("Error unsaving job:", error);
      toast.error("Failed to unsave job");
    } finally {
      setLoadingJobId(null);
    }
  };

  // Apply for job function
  const applyForJob = async () => {
    if (!jobDetails?.id) return;

    try {
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/apply-job/${jobDetails.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      if (response.status === 201) {
        toast.success(response.data.message);
        fetchJobDetails();
        checkApplied();
      }
    } catch (error) {
      //   if unauthorized, redirect to login
      if (
        error.response?.status === 401 ||
        error.response?.status === 403 ||
        error.response?.status === 404
      ) {
        navigate("/candidate/login", {
          state: { from: window.location.pathname },
        });
      } else {
        toast.error(error.response?.data?.detail || "Failed to apply for job");
      }

      console.error("Error applying for job:", error);
    }
  };

  // Format salary function
  const formatSalary = (salary, salaryType) => {
    if (!salary) return "Not disclosed";
    let annualSalary = salary;
    if (salaryType === "Monthly") {
      annualSalary = salary * 12;
    }
    return `₹${(annualSalary / 100000).toFixed(1)} LPA`;
  };

  // Format job title
  const formatTitle = (title) => {
    if (!title) return "";
    return title.length > 45 ? `${title.substring(0, 45)}...` : title;
  };

  // Helper function to render list items (handles both strings and objects)
  const renderListItem = (item) => {
    if (typeof item === "string") return item;
    if (item && typeof item === "object") {
      return item.name || item.title || item.description || "Unnamed item";
    }
    return "Unknown item";
  };

  useEffect(() => {
    fetchJobDetails();
  }, [slug]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShare = (platform) => {
    // const id = selectedJob.id;
    const jobUrl = window.location.href;

    const encodedTitle = encodeURIComponent("");
    const encodedUrl = encodeURIComponent("");

    let shareLink = "";
    switch (platform) {
      case "linkedin":
        shareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
        break;
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case "twitter":
        shareLink = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`;
        break;
      case "copy":
        navigator.clipboard.writeText(jobUrl);
        toast.success("Link copied to clipboard");
        return;
      default:
        return;
    }

    window.open(shareLink, "_blank");
    handleClose();
  };

  const handleClosePage = () => {
    if (loginData.access_token && loginData.role === "candidate") {
      navigate("/candidate/jobs/recomendation");
    } else {
      navigate("/");
    }
  };

  const dateFormater = (date) => {
    if (!date) return "";

    const newDate = new Date(date);
    // normalize date
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();
    return `${day}/${month}/${year}`; // format: dd/mm/yyyy
  };

  return (
    <div className="min-h-screen w-full flex flex-col">
      <Toaster />
      <div className="w-full bg-white shadow-md">
        <Header />
      </div>
      <div className="w-full mx-auto overflow-hidden px-6 my-4 flex-grow bg-white">
        {/* NavBar */}

        {loading ? (
          <div className="flex justify-center items-center h-full">
            <CircularProgress />
          </div>
        ) : !jobDetails ? (
          <div className="text-center text-gray-600">
            No job details available
          </div>
        ) : (
          <>
            {/* Header with actions */}
            <div className="flex items-center justify-between p-4 bg-gray-50">
              <div className="flex items-center gap-4">
                <button
                  className="text-gray-600 hover:text-gray-800"
                  onClick={() => handleClosePage()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">
                  {jobDetails.applicants_count || 0} applicants
                </span>
              </div>
              <div className="flex items-center gap-2">
                {/* <button
                  className="text-gray-600 hover:text-gray-800"
                  onClick={jobDetails.isSaved ? unSaveJob : saveJob}
                  disabled={loadingJobId === jobDetails.id}
                >
                  {loadingJobId === jobDetails.id ? (
                    <CircularProgress size={20} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill={jobDetails.isSaved ? "currentColor" : "none"}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                  )}
                </button> */}
                <Tooltip title={dateFormater(appliedInfo?.applied_date)} arrow>
                  <button
                    // className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg theme-btn disabled:bg-gray-400"
                    onClick={() => {
                      if (jobDetails.use_external_apply) {
                        window.open(jobDetails.external_job_url, "_blank");
                      } else {
                        applyForJob();
                      }
                    }}
                    className={`text-white text-sm w-32 flex gap-1 ${
                      jobDetails.job_status === "On Hold" ||
                      jobDetails.job_status === "Closed" ||
                      !jobDetails.is_accepting_applicants ||
                      appliedInfo?.is_applied === true
                        ? "bg-gray-400 px-8 p-[6px] rounded-[16px]"
                        : "theme-btn"
                    }`}
                  >
                    {appliedInfo?.is_applied ? "Applied" : "Apply Now"}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </button>
                </Tooltip>
              </div>
            </div>

            {/* Navigation Tabs */}
            <div className="border-b border-gray-200 sticky top-0 bg-white z-10 flex justify-between items-end">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                className="px-4"
                TabIndicatorProps={{ style: { backgroundColor: "#3b82f6" } }}
                textColor="inherit"
              >
                <Tab label="Job Description" className="font-bold" />
                <Tab label="Company Overview" />
              </Tabs>
              <div className="flex justify-end mb-4">
                <div>
                  <Tooltip title="Share Job">
                    <IconButton
                      onClick={(e) => {
                        handleClick(e);
                        // setSelectedJob(jobDetails.share_url);
                      }}
                      sx={{
                        color:
                          "linear-gradient(150deg, rgba(168, 36, 254, 1) 0%, rgba(26, 217, 223, 1) 100%)", // Ensure the icon remains visible
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                      // remove default MUI styles
                      "& .MuiMenu-paper": {
                        boxShadow: "0 0 0 0",
                        borderRadius: "8px",
                        border: "1px solid #e0e0e0",
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleShare("linkedin")}>
                      <LinkedInIcon className="mr-2 text-blue-600" />
                      Share on LinkedIn
                    </MenuItem>
                    {/* <MenuItem onClick={() => handleShare("facebook")}>
                              <FacebookIcon className="mr-2 text-blue-700" />
                              Share on Facebook
                            </MenuItem>
                            <MenuItem onClick={() => handleShare("twitter")}>
                              <TwitterIcon className="mr-2 text-blue-400" />
                              Share on Twitter
                            </MenuItem> */}
                    <MenuItem onClick={() => handleShare("copy")}>
                      <ContentCopyIcon className="mr-2 text-gray-600" />
                      Copy link
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>

            {/* Main Content */}
            <div className="p-6 bg-[#fcfcfc]">
              {/* Company and Job Title */}

              {/* Job Description */}
              {tabValue === 0 && (
                <>
                  <div className="flex items-center justify-between mb-4 gap-6">
                    <div className="flex flex-col justify-between shadow-sm w-1/2 p-4 border-1 rounded-lg h-48 bg-white">
                      <div className="flex items-start mb-6">
                        <div className="w-12 h-12 bg-blue-100 text-blue-600 flex items-center justify-center rounded-full mr-4 font-bold">
                          {jobDetails.company_logo ? (
                            <img
                              src={jobDetails.company_logo}
                              alt="Company Logo"
                              className="w-full h-full rounded-full object-cover"
                            />
                          ) : (
                            jobDetails.company_name?.charAt(0) || "N/A"
                          )}
                        </div>
                        <div>
                          <h2 className="text-xl font-bold text-gray-900 mt-1">
                            {formatTitle(jobDetails.title) || "Untitled Job"}
                          </h2>
                          <div className="flex items-center">
                            <h3 className="text-[14px] font-medium text-gray-600">
                              {jobDetails.company_name || "Unknown Company"}
                            </h3>
                            <span className="ml-2 text-sm text-gray-500">
                              • {jobDetails.posted_date || "Recently posted"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="gap-4 mb-6">
                        <div className="flex gap-8">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 text-blue-600 mr-2"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <span className="text-gray-700">
                              {jobDetails.job_types?.join(", ") ||
                                "Not specified"}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 text-blue-600 mr-2"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                              />
                            </svg>
                            <span className="text-gray-700">
                              {jobDetails.job_modes?.join(", ") ||
                                "Not specified"}
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 text-blue-600 mr-2"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 3v4M3 5h4M5 17v4m-2-2h4m6-14v4m-2-2h4M19 5v4m-2-2h4M5 11v4m-2-2h4m6-2v4m-2-2h4"
                              />
                            </svg>
                            <span className="text-gray-700">
                              {jobDetails.experience || "Not specified"}
                            </span>
                          </div>
                          <div>
                            <p className="flex justify-start items-center gap-1">
                              <svg
                                width="10"
                                height="13"
                                viewBox="0 0 10 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                //   className="h-5 w-5 text-blue-600 mr-2"
                              >
                                <path
                                  d="M0.60977 0C0.273011 0 1.47791e-05 0.274542 1.47791e-05 0.613208C1.47791e-05 0.951873 0.273011 1.22642 0.60977 1.22642H3.00444C4.21331 1.22642 5.26022 2.12407 5.52378 3.3645H0.609755C0.272997 3.3645 1.94632e-10 3.63904 1.94632e-10 3.9777C1.94632e-10 4.31637 0.272997 4.59091 0.609755 4.59091H5.52352C5.25953 5.8307 4.21291 6.72776 3.00444 6.72776H0.60977C0.365725 6.72776 0.145187 6.8741 0.0491805 7.09974C-0.0468263 7.32537 0.000193134 7.58684 0.1687 7.76437L4.95805 12.8102C5.19057 13.0552 5.57654 13.0642 5.82014 12.8304C6.06374 12.5965 6.07271 12.2084 5.84019 11.9634L2.03476 7.95418H3.00444C4.92675 7.95418 6.48095 6.47699 6.76179 4.59091H9.39023C9.72699 4.59091 9.99998 4.31637 9.99998 3.9777C9.99998 3.63904 9.72699 3.3645 9.39023 3.3645H6.76198C6.6403 2.54566 6.27863 1.80383 5.75101 1.22642H9.39024C9.727 1.22642 10 0.951873 10 0.613208C10 0.274542 9.727 0 9.39024 0H0.60977Z"
                                  fill="#2563eb"
                                />
                              </svg>

                              {jobDetails.min_salary && jobDetails.max_salary
                                ? ` ${jobDetails.min_salary.toLocaleString()} - ${jobDetails.max_salary.toLocaleString()} ${
                                    jobDetails.salary_type || "Monthly"
                                  }`
                                : "Not specified"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* skill */}
                    <div className="flex flex-col justify-start gap-4 shadow-sm w-1/2 p-4 border-1 rounded-lg h-48 bg-white">
                        <h3 className="text-lg font-bold text-gray-900 mb-2">
                          Skills Required
                        </h3>
                        <div className="flex flex-wrap gap-2 mb-4">
                          {(jobDetails.skills || []).map((skill, index) => (
                            <span
                              key={index}
                              className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700"
                            >
                              {skill.name}
                              {skill.level && ` (${skill.level})`}
                            </span>
                          ))}
                        </div>
                      </div>
                          </div>

                  <div className="mb-6">
                    <h3 className="text-lg font-bold text-gray-900 mb-2">
                      Job Description
                    </h3>
                    <p className="text-gray-700 mb-4">
                      {jobDetails.job_description ||
                        jobDetails.description ||
                        "No description available."}
                    </p>
                  </div>

                  <hr className="my-6 border-gray-200" />
                </>
              )}

              {/* Company Overview (Tab 1) */}
              {tabValue === 1 && (
                <div>
                  {/* Header */}
                  <div className="flex items-start mb-4 gap-2">
                    <img
                      src="images/icons/company.svg"
                      alt="Company"
                      className="w-6 h-6"
                    />
                    <h3 className="text-lg font-bold text-gray-900">
                      Company Overview
                    </h3>
                  </div>

                  {/* Grid Layout - 6 Parts */}
                  <div className="grid grid-cols-6 gap-4">
                    {/* Company Info (5 parts) */}
                    <div className="col-span-4 border p-4 rounded-lg  bg-white">
                      <h3 className="text-lg font-bold text-gray-900 mb-2">
                        {jobDetails.company_name || "Company Name"}
                      </h3>
                      <p className="text-gray-600 italic">
                        {jobDetails.companyDescription ||
                          "The first step is the most important one."}
                      </p>
                    </div>

                    {/* Additional Section (1 part) */}
                    <div className="col-span-2 border p-4 rounded-lg  flex items-center">
                      <div className="text-gray-700 space-y-2 mt-4">
                        <p>
                          <img
                            src="images/icons/location.svg"
                            alt="Location"
                            className="w-4 h-4 inline-block mr-2"
                          />
                          Location:{" "}
                          {jobDetails.company_location || "Not specified"}
                        </p>
                        <p>
                          <img
                            src="images/icons/employee.svg"
                            alt="Employee"
                            className="w-4 h-4 inline-block mr-2"
                          />
                          Employees:{" "}
                          {jobDetails.no_of_employees || "Not specified"}
                        </p>
                        <p>
                          <img
                            src="images/icons/established.svg"
                            alt="Established"
                            className="w-4 h-4 inline-block mr-2"
                          />
                          Founded:{" "}
                          {jobDetails.year_of_establishment || "Not specified"}
                        </p>
                        <p>
                          <img
                            src="images/icons/website.svg"
                            alt="Website"
                            className="w-4 h-4 inline-block mr-2"
                          />
                          Website:
                          {jobDetails.website_url ? (
                            <a
                              href={jobDetails.website_url}
                              className="text-blue-600 hover:underline ml-1"
                            >
                              {jobDetails.website_url}
                            </a>
                          ) : (
                            "Not specified"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JobPostingPage;
