import { toast } from "react-hot-toast";

// Store the original functions
const originalToastError = toast.error;
const originalToastSuccess = toast.success;

// Override toast.error globally
toast.error = (message, options = {}) => {
  return originalToastError(message, {
    style: {
      border: "2px solid red",
      padding: "10px",
      minWidth: "300px",
      maxWidth: "600px",
      backgroundColor: "#ffebee", // Light red background
      color: "#d32f2f", // Dark red text
      fontWeight: "bold",
      ...options.style, // Allow extra styles if needed
    },
    position: "top-center",
    duration: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options, // Allow additional options
  });
};

// Override toast.success globally
toast.success = (message, options = {}) => {
  return originalToastSuccess(message, {
    style: {
      border: "2px solid green",
      padding: "10px",
      minWidth: "300px",
      maxWidth: "600px",
      backgroundColor: "#e8f5e9", // Light green background
      color: "#2e7d32", // Dark green text
      fontWeight: "bold",
      ...options.style, // Allow extra styles if needed
    },
    position: "top-center",
    duration: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options, // Allow additional options
  });
};
