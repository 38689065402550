import React, { useState } from "react";
import AppBar from "../../Appbar";
import Drawer from "../../Drawer";
import { Box, CssBaseline } from '@mui/material';
import SettingContent from "./setting";
import AvatarIcon from "../../AvatarIcon";
import { useEffect } from "react";

const Setting = () => {
    // const [isSettingLoaded, setIsSettingLoaded] = useState(false);
    // useEffect(() => {
    //     const settingTab = async () => {
    //         await localStorage.setItem("settingTab", 0);
    //         setIsSettingLoaded(true); // Jab localStorage set ho jaye tab state update karo
    //     };
    //     settingTab();
    // }, []);
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* <AppBar /> */}
                <Drawer />
                {/* {isSettingLoaded && <SettingContent />} */}
                <SettingContent />
                <AvatarIcon  />
            </Box>
        </>
    )
}
export default Setting;