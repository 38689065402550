import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  Badge,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axiosClient from "../../../config/axios.Config";
import { useSelector } from "react-redux";
import config from "../../../config/config";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CategoryIcon from "@mui/icons-material/Category";
import PhoneIcon from "@mui/icons-material/Phone";
import InterviewInvitationDialog from "./InterviewInvitationDialog";

// InfoRow Component
const InfoRow = ({ icon, label, value }) => (
    <Box className="flex items-center space-x-2">
      {icon}
      <Typography className="font-medium text-gray-700">{label}:</Typography>
      <Typography className="text-gray-900">{value}</Typography>
    </Box>
  );

function Notification() {
  const [filter, setFilter] = useState("all"); // 'all' or 'unread'
  const [notifications, setNotifications] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;
  const BASE_URL = config.apiUrl;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const fetchNotifications = async (currentPage) => {
    try {
      const response = await axiosClient.get(
        `${BASE_URL}candidate/notifications?skip=${
          (currentPage - 1) * 10
        }&limit=10`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const newData = response.data || [];
      if (newData.length > 0) {
        setNotifications((prev) => [...prev, ...newData]);
        setPage(currentPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications(1);
  }, []);

  const handleNotificationRead = (id) => {
    // backend call to mark notification as read

    // string list of notification ids
    const notificationIds = Array.isArray(id) ? id : [id];

    axiosClient
      .post(
        `${BASE_URL}candidate/mark-read`,notificationIds,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Notification marked as read:", response.data);
      })
      .catch((error) => {
        console.error("Error marking notification as read:", error);
      });
  };
  const details = selectedNotification?.interview_details;

  const handleFilterChange = (newFilter) => setFilter(newFilter);

const handleMarkAsRead = (id) => {
  const updatedNotifications = notifications.map((n) =>
    n.id === id ? { ...n, is_read: true } : n
  );
  setNotifications(updatedNotifications);
  
  // mark notification as read in the backend
  handleNotificationRead(id);
  
  console.log("Marked as read:", id);
  
  // Find the selected notification
  const selected = notifications.find((n) => n.id === id);
  
  // Check if it has interview details (using either property name)
  if (selected && (selected.interview_details || selected.details)) {
    console.log("Selected notification:", selected);
    
    // If the details are under interview_details but component expects details
    if (selected.interview_details && !selected.details) {
      selected.details = selected.interview_details;
    }
    
    setSelectedNotification(selected);
    setDialogOpen(true);
  }
};


  const filteredNotifications = notifications.filter((n) =>
    filter === "all" ? true : !n.is_read
  );
  const unreadCount = notifications.filter((n) => !n.is_read).length;

  return (
    <Card
      className="max-w-2xl mx-auto bg-gray-50"
      sx={{
        boxShadow: "none",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        className="flex items-center justify-between p-4 bg-white"
        sx={{ boxShadow: "none" }}
      >
        <Box className="flex items-center space-x-2">
          {/* <Badge badgeContent={unreadCount} color="error">
            <NotificationsIcon className="text-gray-700" />
          </Badge> */}
          <Typography variant="h6">Notifications</Typography>
        </Box>
        <IconButton>
          <SettingsIcon className="text-gray-600" />
        </IconButton>
      </Box>

      <Box className="flex gap-2 p-3 ">
        <Button
          variant={filter === "all" ? "contained" : "outlined"}
          size="small"
          onClick={() => handleFilterChange("all")}
        >
          All
        </Button>
        <Button
          variant={filter === "unread" ? "contained" : "outlined"}
          size="small"
          onClick={() => handleFilterChange("unread")}
        >
          Unread ({unreadCount})
        </Button>
      </Box>

      <List
        className="bg-white"
        id="scrollableDiv"
        style={{ height: "calc(100vh - 150px)", overflow: "auto" }}
      >
        <InfiniteScroll
          dataLength={notifications.length}
          next={() => fetchNotifications(page)}
          hasMore={hasMore}
          loader={
            <Box className="text-center p-2">
              <CircularProgress size={24} />
            </Box>
          }
          scrollableTarget="scrollableDiv"
        >
          {filteredNotifications.length === 0 ? (
            <ListItem>
              <ListItemText
                primary={
                  <Typography className="text-center text-gray-500">
                    No {filter === "unread" ? "unread " : ""}notifications
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            filteredNotifications.map((notification, index) => (
              <React.Fragment key={notification.id}>
                <ListItem
                  className={`hover:bg-gray-50 cursor-pointer ${
                    !notification.is_read ? "bg-blue-50" : ""
                  }`}
                  onClick={() => handleMarkAsRead(notification.id)}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={notification.avatar || "/default-avatar.png"}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body1" className="text-gray-900">
                        {notification.messages[0]}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" className="text-gray-500">
                        {new Date(notification.timestamp).toLocaleString()}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    {!notification.is_read && (
                      <FiberManualRecordIcon
                        className="text-blue-600"
                        style={{ fontSize: 12 }}
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                {index < filteredNotifications.length - 1 && <Divider />}
              </React.Fragment>
            ))
          )}
        </InfiniteScroll>
      </List>

      {/* Interview Invitation Dialog */}
      <InterviewInvitationDialog 
  open={dialogOpen} 
  setDialogOpen={setDialogOpen} 
  selectedNotification={selectedNotification} 
/>
    </Card>
  );
}

export default Notification;
