import React, { useEffect, useState } from "react";
import RightDrawer from "./rightDrawer";
import axiosClient from "../../../config/axios.Config";
import { getSavedJobs } from "../../../utils/candidate.api/candidate.api.services";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import config from "../../../config/config";
import Loader from "../../../utils/Loader";
import Pagination from "./Pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tooltip, CircularProgress, IconButton } from "@mui/material";
import { Typography } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function RecomendationContent() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedJobDetails, setSelectedJobDetails] = useState({});
  const loginData = useSelector((state) => state.auth.Auth);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [appliedJobs, setAppliedJobs] = useState({});
  const [companyLogos, setCompanyLogos] = useState({}); // Store company logos
  const [notFoundErr, setNotFoundErr] = useState();
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPage")) || 1
  );
  const [totalPages, setTotalPages] = useState(1);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const [isHovered, setIsHovered] = useState(null);
  const [matchedData, setMatchedData] = useState([]);

  const [loadingJobId, setLoadingJobId] = useState(null);
  const [loadingJobIdApply, setLoadingJobIdApply] = useState(null);
  const [drawerLoading, setDrawerLoading] = useState(false);

  const [selectedJob, setSelectedJob] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShare = (platform) => {
    // const id = selectedJob.id;
    const jobUrl = selectedJob?.replace(":8000",":3000");

    const encodedTitle = encodeURIComponent("");
    const encodedUrl = encodeURIComponent("");

    let shareLink = "";
    switch (platform) {
      case "linkedin":
        shareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
        break;
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case "twitter":
        shareLink = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`;
        break;
      case "copy":
        navigator.clipboard.writeText(jobUrl);
        toast.success("Link copied to clipboard");
        return;
      default:
        return;
    }

    window.open(shareLink, "_blank");
    handleClose();
  };


  const unSaveJob = async (jobId) => {
    setLoadingJobId(jobId); // Set loader for this job

    try {
      console.log("access token", loginData.access_token);
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/unsave-job/${jobId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.dismiss();
        toast.success(response.data.message);

        // Update state synchronously removing the saved job
        setData((prevData) => prevData.filter((job) => job.id !== jobId));
      }
    } catch (error) {
      console.error("Error unsaving job:", error);
      toast.error("Failed to unsave job!");
    } finally {
      setLoadingJobId(null); // Remove loader
    }
  };

  const toggleDrawer = (open, jobId) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);

    if (open && jobId) {
      await fetchJobDetails(jobId);
    }
  };

  const fetchJobDetails = async (jobId) => {
    setDrawerLoading(true);
    try {
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/job/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      setSelectedJobDetails(response.data);
    } catch (error) {
      console.log("Error fetching job details:", error);
    } finally {
      setDrawerLoading(false);
    }
  };

  const fetchCompanyLogo = async (logoId) => {
    try {
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/company-logo/${logoId}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
          // responseType: "blob",
        }
      );
      return response.data?.url;
    } catch (error) {
      console.log("Error fetching company logo:", error);
      return "";
    }
  };

  const fetchSavedJobs = async (currentPage, isNewFetch = false) => {
    try {
      if (isNewFetch) {
        setLoading(true);
      }

      const response = await getSavedJobs(loginData.access_token, currentPage);
      const newData = response.data.jobs;
      if (newData.length > 0) {
        setData((prevData) =>
          isNewFetch ? newData : [...prevData, ...newData]
        );
        setPage(currentPage);
        setHasMore(newData.length >= 5);
      } else {
        setHasMore(false);
      }
      setTotalPages(response.data.total_pages);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setNotFoundErr(error.response.data.detail);
        }
      } else {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const loadMoreData = () => {
    console.log("load more data");
    if (hasMore) {
      fetchSavedJobs(page + 1);
    }
  };

  useEffect(() => {
    const fetchLogos = async () => {
      const logoMap = {};
      for (const job of data) {
        // console.log("job", job?.employer_id);
        if (job?.employer_id && !logoMap[job?.employer_id]) {
          const logoUrl = await fetchCompanyLogo(job?.employer_id);

            logoMap[job?.employer_id] = logoUrl;
          
        }
      }
      setCompanyLogos((prevLogos) => ({ ...prevLogos, ...logoMap }));
    };

    fetchLogos();

    return () => {
      Object.values(companyLogos).forEach((url) => URL.revokeObjectURL(url)); // Clean up blob URLs
    };
  }, [data]);

  const applyForJob = async (jobId) => {
    setLoadingJobIdApply(jobId); // Show loader for this job

    try {
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/apply-job/${jobId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 201) {
        toast.dismiss();
        toast.success(response.data.message);

        // Update UI immediately with applied_status = true
        setData((prevData) =>
          prevData.map((item) =>
            item.id === jobId ? { ...item, applied_status: true } : item
          )
        );

        setAppliedJobs((prev) => ({ ...prev, [jobId]: true }));

        // Optionally refresh job recommendations
        // fetchJobsRecommendations();
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response?.data?.detail || "Failed to apply for job");
      console.log(error);
    } finally {
      setLoadingJobIdApply(null); // Hide loader
    }
  };

  useEffect(() => {
    fetchSavedJobs(1, true);
  }, [loginData.access_token, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to format salary with 'k'
  const formateSalary = (salary, salary_type) => {
    if (salary) {
      if (salary_type === "Monthly") {
        let yearlySalary = salary * 12;
        return yearlySalary / 100000;
      } else if (salary_type === "Yearly") {
        return salary / 100000;
      }
    } else {
      return "Salary not provided";
    }
  };

  const title = (title) => {
    if (title.length > 35) {
      return title.substring(0, 35) + "...";
    } else {
      return title;
    }
  };
  const matchData = [
    { label: "Experience Level", value: matchedData?.experience_level || 0 },
    { label: "Skills", value: matchedData?.skills_match || 0 },
    { label: "Salary", value: matchedData?.salary_match || 0 },
    { label: "Location", value: matchedData?.location_match || 0 },
    { label: "Job Type", value: matchedData?.job_type_match || 0 },
    { label: "Notice Period", value: matchedData?.notice_period_match || 0 },
  ]
    .filter((item) => item.value > 0) // Remove items with 0 value
    .sort((a, b) => b.value - a.value) // Sort in descending order
    .slice(0, 5); // Take only the top 5
  return (
    <>
      <Toaster />
      <RightDrawer
        open={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        jobDetails={selectedJobDetails}
        fetchJobsRecommendations={fetchSavedJobs}
        fetchJobDetails={fetchJobDetails}
        drawerLoading={drawerLoading}
        setDrawerLoading={setDrawerLoading}
        data={data}
        setData={setData}
      />
      {loading ? <Loader /> :
      <div className="jobs-field overflow-hidden">
        {/* {notFoundErr} */}
        {data.length === 0 && !loading ? (
          <div className="flex items-center justify-center h-[600px]">
            <p className="text-gray-500">No Saved Job found.</p>
          </div>
        ) : (
          <div
            className="default-cards h-[calc(100vh-104px)] overflow-auto mt-[30px]"
            id="scrollable-table"
          >
            <InfiniteScroll
              dataLength={data.length}
              next={loadMoreData}
              hasMore={hasMore}
              loader={
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <CircularProgress size={24} />
                </div>
              }
              scrollableTarget="scrollable-table"
            >
              <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-4 p-1">
                {data.map((item) => (
                  <div
                    className={`card1 mb-3 ${
                      item.applied_status === true ? "" : ""
                    }`}
                    key={item.id}
                    onMouseLeave={() => setIsHovered(null)}
                  >
                    {isHovered === item.id ? (
                      <div className="bg-white card-data max-h-[180px] min-h-[180px] p-2 w-full">
                        <h3 className="flex items-center gap-2">
                          <img src="images/icons/star1.svg" alt="match" />
                          <span className="text-sm font-semibold text-black">
                            Why This Job Is A Match
                          </span>
                        </h3>

                        {/* <p className="text-gray-600 mt-1">
                        CrowdStrike is a cybersecurity technology firm that
                        provides...
                      </p> */}
                        <div className="flex justify-around mt-4 gap-8">
                          {matchData.map((item, index) => (
                            <div
                              key={index}
                              className="flex flex-col items-center"
                            >
                              <div className="relative">
                                <CircularProgress
                                  variant="determinate"
                                  value={item.value}
                                  size={60}
                                  thickness={4}
                                  className="text-green-400"
                                />
                                <span className="absolute inset-0 flex items-center justify-center text-black font-semibold">
                                  {parseInt(item.value)}%
                                </span>
                              </div>
                              <p className="text-sm text-black font-semibold mt-2">
                                {item.label}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="card-data cursor-pointer min-h-[180px] p-2"
                        onClick={toggleDrawer(true, item.id)}
                      >
                        <div className="flex items-center gap-x-2 mt-3">
                          {/* Display company logo if available */}
                          <div className="section-1">
                            {companyLogos[item?.employer_id] ? (
                              <img
                                src={companyLogos[item?.employer_id]}
                                alt="Company logo"
                                className="company-logo"
                              />
                            ) : (
                              <img
                                src="images/default.png"
                                alt="Default logo"
                                className="company-logo"
                              />
                            )}
                          </div>
                          <div className=" bg-white section-2">
                            <h4 className="text-lg font-semibold text-gray-900">
                              {item.company_name}
                            </h4>
                            {/* <p className="text-gray-600"><span className="font-bold text-gray-800">{item.company_name}</span></p> */}
                            <p className="text-gray-600">
                              <span className="font-bold text-gray-800">
                                {title(item.title)}
                              </span>
                              {item.job_location
                                ? ` • ${item.job_location}`
                                : ""}
                              <p>
                                {" "}
                                ₹
                                {formateSalary(
                                  item.expected_salary_min,
                                  item.salary_type
                                )}{" "}
                                - ₹
                                {formateSalary(
                                  item.expected_salary_max,
                                  item.salary_type
                                )}{" "}
                                LPA
                              </p>
                            </p>
                          </div>
                          {/* <div className="section-3">
                          <button
                            className="drawer-open-arrow bg-[#E7E7E7] rounded-full"
                            onClick={toggleDrawer(true, item.id)}
                          >
                            {" "}
                          
                            <img
                              src="images/icons/send-arrow.svg"
                              className="brand-icon"
                              alt="view details"
                            />
                          </button>
                        </div> */}
                        </div>
                        <div className="my-3 type-btns flex items-center gap-2">
                          <div>
                            <img
                              src="images/icons/location.svg"
                              alt="location"
                              className="mr-1"
                            />
                            {/* seperate with commas */}
                            {item.job_mode.split(",").join(", ")}
                          </div>
                          <div>
                            <img
                              src="images/icons/cap.svg"
                              alt="experience"
                              className="mr-1"
                            />
                            {item.experience}
                          </div>
                          <div>
                            <img
                              src="images/icons/job1.svg"
                              alt="job type"
                              className="mr-1"
                            />
                            {item.job_type}
                          </div>
                        </div>
                        <div className="flex justify-between type-btns">
                          <span>
                            <img
                              src="images/icons/history.svg"
                              className="md:mr-1 lg:mr-2 inline-block"
                              alt="posted date"
                            />
                            {item.posted_date}
                          </span>

                          <span>Np : {item.notice_period}</span>
                        </div>
                      </div>
                    )}
                    <div className="foot-content flex items-center px-6 py-3 bg-white rounded-lg shadow-md">
                      {item.is_early_applicant ? (
                        <span className="bg-gray-100 px-3 py-1 rounded-2xl flex items-center text-sm">
                          <img
                            src="images/icons/early.svg"
                            className="mr-2"
                            alt="early applicant"
                          />
                          Be an early applicant
                        </span>
                      ) : (
                        <span className="bg-gray-100 px-3 py-1 rounded-2xl flex items-center text-sm">
                          <img
                            src="images/icons/man.svg"
                            className="mr-2"
                            alt="applicants"
                          />
                          {item.applicants_count} applicants
                        </span>
                      )}
                      {!item.is_accepting_applicants && (
                        <h2 className="text-xs text-gray-400 ml-4">
                          This job is no longer accepting applications.
                        </h2>
                      )}
                      <div className="ml-auto flex items-center space-x-4">
                        {/* Share Button */}
                        <div>
                          <Tooltip title="Share Job">
                            <IconButton
                              onClick={(e) => {
                                handleClick(e);
                                setSelectedJob(item.share_url);
                              }}
                              sx={{
                                color:
                                  "linear-gradient(150deg, rgba(168, 36, 254, 1) 0%, rgba(26, 217, 223, 1) 100%)", // Ensure the icon remains visible
                              }}
                            >
                              <ShareIcon />
                            </IconButton>
                          </Tooltip>

                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{
                              // remove default MUI styles
                              "& .MuiMenu-paper": {
                                boxShadow: "0 0 0 0",
                                borderRadius: "8px",
                                border: "1px solid #e0e0e0",
                              },
                            }}
                          >
                            <MenuItem onClick={() => handleShare("linkedin")}>
                              <LinkedInIcon className="mr-2 text-blue-600" />
                              Share on LinkedIn
                            </MenuItem>
                            {/* <MenuItem onClick={() => handleShare("facebook")}>
                              <FacebookIcon className="mr-2 text-blue-700" />
                              Share on Facebook
                            </MenuItem>
                            <MenuItem onClick={() => handleShare("twitter")}>
                              <TwitterIcon className="mr-2 text-blue-400" />
                              Share on Twitter
                            </MenuItem> */}
                            <MenuItem onClick={() => handleShare("copy")}>
                              <ContentCopyIcon className="mr-2 text-gray-600" />
                              Copy link
                            </MenuItem>
                          </Menu>
                        </div>
                        {/* Save Job Button */}
                        <Tooltip title="Unsave Job" arrow>
                        {loadingJobId === item.id ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <button type="button" className="flex items-center ">
                            {item.is_saved ? (
                              <img
                                src="images/icons/save2.svg"
                                alt="save"
                                onClick={() => unSaveJob(item.id)}
                              />
                            ) : (
                              <img
                                src="images/icons/save.svg"
                                alt="save"
                                // onClick={() => saveJob(item.id)}
                              />
                            )}
                          </button>
                        )}
                        </Tooltip>
                        {/* Apply Button */}
                        <Tooltip title={item.applied_date} arrow>
                          <button
                            type="button"
                            className={`text-white text-sm w-32 ${
                              item.job_status === "On Hold" ||
                              item.job_status === "Closed" ||
                              !item.is_accepting_applicants ||
                              item.applied_status === true
                                ? "bg-gray-400 px-8 p-[6px] rounded-[16px]"
                                : "theme-btn"
                            }`}
                            onClick={() =>{
                              if(item.use_external_apply){
                                window.open(item.external_job_url, "_blank");
                              }
                              else{
                                applyForJob(item.id)
                              }
                            }}
                            disabled={
                              item.job_status === "On Hold" ||
                              item.job_status === "Closed" ||
                              !item.is_accepting_applicants ||
                              item.applied_status === true
                            }
                          >
                            {loadingJobIdApply === item.id ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : item.applied_status === true ? (
                              "Applied"
                            ) : (
                              "Apply Now"
                            )}
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                    {/* matching job requirements  */}
                    {isHovered !== item.id && item.rank > 0 && (
                      <>
                      {parseInt(item.rank) === 0 && (
                          <button
                            className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                            style={{ background: "none" }}
                            // onMouseEnter={() => {
                            //   setIsHovered(item.id);
                            //   setMatchedData(item?.component_scores);
                            // }}
                          >
                            {/* MUI Circular Progress */}
                            <div className="relative w-24 h-24 flex items-center justify-center">
                              <Tooltip
                                title="Please Complete your profile to get better recommendations"
                                arrow
                              >
                                <div className="relative flex items-center justify-center">
                                  <CircularProgress
                                    variant="determinate"
                                    value={parseInt(item.rank)}
                                    size={100} // Increased size
                                    thickness={3} // Thicker border
                                    sx={{
                                      color: "rgb(56 189 248)",
                                      position: "relative",
                                    }}
                                  />
                                  {/* Text Inside the Circle */}
                                  <div className="absolute flex flex-col items-center justify-center">
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px", // Bigger percentage text
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {parseInt(item.rank)}%
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        fontSize: "14px", // Bigger "Fair Match" text
                                        fontWeight: "bold",
                                        color: "gray",
                                      }}
                                    >
                                      Fair Match
                                    </Typography>
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </button>
                        )}
                        {parseInt(item.rank) < 60 && item.rank > 0 && (
                          <button
                            className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                            style={{ background: "none" }}
                            onMouseEnter={() => {
                              setIsHovered(item.id);
                              setMatchedData(item?.component_scores);
                            }}
                          >
                            {/* MUI Circular Progress */}
                            <div className="relative w-24 h-24 flex items-center justify-center">
                              <Tooltip
                                title={`${parseInt(item.rank)}% Match`}
                                arrow
                              >
                                <div className="relative flex items-center justify-center">
                                  <CircularProgress
                                    variant="determinate"
                                    value={parseInt(item.rank)}
                                    size={100} // Increased size
                                    thickness={3} // Thicker border
                                    sx={{
                                      color: "rgb(56 189 248)",
                                      position: "relative",
                                    }}
                                  />
                                  {/* Text Inside the Circle */}
                                  <div className="absolute flex flex-col items-center justify-center">
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px", // Bigger percentage text
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {parseInt(item.rank)}%
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        fontSize: "14px", // Bigger "Fair Match" text
                                        fontWeight: "bold",
                                        color: "gray",
                                      }}
                                    >
                                      Fair Match
                                    </Typography>
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </button>
                        )}

                        {parseInt(item.rank) >= 60 &&
                          parseInt(item.rank) < 70 && (
                            <button
                              className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                              style={{ background: "none" }}
                              onMouseEnter={() => {
                                setIsHovered(item.id);
                                setMatchedData(item?.component_scores);
                              }}
                            >
                              {/* MUI Circular Progress */}
                              <div className="relative w-24 h-24 flex items-center justify-center">
                                <Tooltip
                                  title={`${parseInt(item.rank)}% Match`}
                                  arrow
                                >
                                  <div className="relative flex items-center justify-center">
                                    <CircularProgress
                                      variant="determinate"
                                      value={parseInt(item.rank)}
                                      size={100} // Increased size
                                      thickness={3} // Thicker border
                                      sx={{
                                        color: "rgb(56 189 248)",
                                        position: "relative",
                                      }}
                                    />
                                    {/* Text Inside the Circle */}
                                    <div className="absolute flex flex-col items-center justify-center">
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px", // Bigger percentage text
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        {parseInt(item.rank)}%
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          fontSize: "14px", // Bigger "Fair Match" text
                                          fontWeight: "bold",
                                          color: "gray",
                                        }}
                                      >
                                        Good Match
                                      </Typography>
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            </button>
                          )}

                        {parseInt(item.rank) >= 70 &&
                          parseInt(item.rank) < 80 && (
                            <button
                              className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                              style={{ background: "none" }}
                              onMouseEnter={() => {
                                setIsHovered(item.id);
                                setMatchedData(item?.component_scores);
                              }}
                            >
                              {/* MUI Circular Progress */}
                              <div className="relative w-24 h-24 flex items-center justify-center">
                                <Tooltip
                                  title={`${parseInt(item.rank)}% Match`}
                                  arrow
                                >
                                  <div className="relative flex items-center justify-center">
                                    <CircularProgress
                                      variant="determinate"
                                      value={parseInt(item.rank)}
                                      size={100} // Increased size
                                      thickness={3} // Thicker border
                                      sx={{
                                        color: "rgb(56 189 248)",
                                        position: "relative",
                                      }}
                                    />
                                    {/* Text Inside the Circle */}
                                    <div className="absolute flex flex-col items-center justify-center">
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px", // Bigger percentage text
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        {parseInt(item.rank)}%
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          fontSize: "14px", // Bigger "Fair Match" text
                                          fontWeight: "bold",
                                          color: "gray",
                                        }}
                                      >
                                        Strong Match
                                      </Typography>
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            </button>
                          )}

                        {parseInt(item.rank) >= 80 &&
                          parseInt(item.rank) < 90 && (
                            <button
                              className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                              style={{ background: "none" }}
                              onMouseEnter={() => {
                                setIsHovered(item.id);
                                setMatchedData(item?.component_scores);
                              }}
                            >
                              {/* MUI Circular Progress */}
                              <div className="relative w-24 h-24 flex items-center justify-center">
                                <Tooltip
                                  title={`${parseInt(item.rank)}% Match`}
                                  arrow
                                >
                                  <div className="relative flex items-center justify-center">
                                    <CircularProgress
                                      variant="determinate"
                                      value={parseInt(item.rank)}
                                      size={100} // Increased size
                                      thickness={3} // Thicker border
                                      sx={{
                                        color: "rgb(56 189 248)",
                                        position: "relative",
                                      }}
                                    />
                                    {/* Text Inside the Circle */}
                                    <div className="absolute flex flex-col items-center justify-center">
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px", // Bigger percentage text
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        {parseInt(item.rank)}%
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          fontSize: "14px", // Bigger "Fair Match" text
                                          fontWeight: "bold",
                                          color: "gray",
                                        }}
                                      >
                                        Best Match
                                      </Typography>
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            </button>
                          )}

                        {parseInt(item.rank) >= 90 && (
                          <button
                            className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                            style={{ background: "none" }}
                            onMouseEnter={() => {
                              setIsHovered(item.id);
                              setMatchedData(item?.component_scores);
                            }}
                          >
                            {/* MUI Circular Progress */}
                            <div className="relative w-24 h-24 flex items-center justify-center">
                              <Tooltip
                                title={`${parseInt(item.rank)}% Match`}
                                arrow
                              >
                                <div className="relative flex items-center justify-center">
                                  <CircularProgress
                                    variant="determinate"
                                    value={parseInt(item.rank)}
                                    size={100} // Increased size
                                    thickness={3} // Thicker border
                                    sx={{
                                      color: "rgb(56 189 248)",
                                      position: "relative",
                                    }}
                                  />
                                  {/* Text Inside the Circle */}
                                  <div className="absolute flex flex-col items-center justify-center">
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px", // Bigger percentage text
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {parseInt(item.rank)}%
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        fontSize: "14px", // Bigger "Fair Match" text
                                        fontWeight: "bold",
                                        color: "gray",
                                      }}
                                    >
                                      Elite Match
                                    </Typography>
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        )}

        {/* no recommendations */}
      </div>
      }
      {/* Pagination Controls */}
      {/* {totalPages > 1 && !loading && (
        <div className="flex justify-center">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )} */}
    </>
  );
}
