import React from "react";
import { Box, CssBaseline } from "@mui/material";
import AppBar from "../dashboard/Appbar";
import Drawer from "../dashboard/Drawer";
import History from "./History";
import DrawerHeader from "../dashboard/DrawerHeader";

export default function ChatHistoryEmp() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar />
      <Drawer />

      <Box component="main" sx={{ flexGrow: 1 }} className="mainContent">
      <DrawerHeader />
        <History />
      </Box>
    </Box>
  );
}
