import React, { useEffect, useState, useRef } from "react";
import axiosClient from "../../../../config/axios.Config";
import { useSelector } from "react-redux";
import UploaFile from "../../resume/uploadResume";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import config from "../../../../config/config";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getAllCv } from "../../../../utils/candidate.api/candidate.api.services";
import Dialog from "@mui/material/Dialog";
import { Menu, MenuItem, Box } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import flexbox from "@mui/system/flexbox";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../../../../utils/Loader";
import {
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";

const Resume = () => {
  const loginData = useSelector((state) => state.auth.Auth);
  const Cv_Name = useSelector((state) => state.parsedCv.parseCvData?.filename);
  const fileId = useSelector((state) => state.parsedCv.parseCvData?.file_id);
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState(null);
  const [openDelet, setOpenDelet] = useState(null);
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const [file, setFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);
  const [resumeName, setResumeName] = useState("");
  const [resumeTitle, setResumeTitle] = useState("");
  const [fileIdUpdate, setFileIdUpdate] = useState(null);
  const [resumeUpdateError, setResumeUpdateError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [warning, setWarning] = useState(false);
  const [warningDefault, setWarningDefault] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      console.log("Uploading:", file.name);
    }
    setOpen(false);
  };

  const fetchAllResumes = async () => {
    setLoading(true);
    try {
      const response = await getAllCv(loginData.access_token);
      // Handle data based on structure

      const resumes = Array.isArray(response.data)
        ? response.data
        : response.data.cv_files || [];
      setFileList(
        resumes.map((resume) => ({
          id: resume.file_id,
          name: resume?.filename,
          url: `${config.apiUrl}candidate/get_cv/${resume?.file_id}`,
          defaultCV: resume?.default_cv,
          upload_date: resume?.upload_date,
        }))
      );
    } catch (error) {
      console.error(`Error fetching all resumes: ${error}`);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // if (!fileId) return;
    fetchAllResumes();
  }, [loginData, fileId, Cv_Name]);

  const openPdfInNewTab = async (url) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loginData.access_token}`,
          "Content-Type": "application/pdf",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json(); // JSON response parse karo

      if (data.url) {
        window.open(data.url, "_blank"); // Directly open the URL
      } else {
        console.error("PDF URL not found in response");
      }
    } catch (error) {
      console.error(`Error opening PDF: ${error}`);
    }
  };

  const setDefaultCv = async (fileId) => {
    try {
      await axiosClient.put(
        `${config.apiUrl}candidate/set_default_cv/${fileId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      toast.success("Primary resume updated successfully");
      setOpenDelet(null);
      fetchAllResumes();
    } catch (error) {
      console.log(error);
    }
  };

  const DeletCv = async (fileId) => {
    try {
      await axiosClient.delete(
        `${config.apiUrl}candidate/delete_cv/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      setOpenDelet(null);
      fetchAllResumes();
      toast.success("Resume deleted successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const RenameCv = async () => {
    try {
      await axiosClient.put(
        `${config.apiUrl}candidate/rename_cv/${fileIdUpdate}?new_name=${
          resumeTitle.split(".")[0]
        }`,
        {},
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      setDialogOpen(false);
      toast.success("Resume title updated successfully");
      fetchAllResumes();
    } catch (error) {
      console.log(error);
      setResumeUpdateError(error.response.data.detail);
    }
  };

  const handleDropdownToggle = (id) => {
    setOpenDelet((prev) => (prev === id ? null : id));
  };

  // if (loading) return <p>Loading...</p>;
  if (error) return <p>CV not found</p>;

  // const [openDelet, setOpenDelet] = useState(null); // This is to track the menu state
  // Track menu state
  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setOpenDelet(openDelet === id ? null : id); // Toggle menu visibility
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setOpenDelet(null); // Close menu on item click or when clicking outside
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenDelet(null);
  };

  const handleWarning = () => {
    setWarning(!warning);
  };

  const handleWarningDefault = () => {
    setWarningDefault(!warningDefault);
  };

  return (
    <div className="p-0">
      {/* <Toaster /> */}
      {loading && <Loader />}
      <div>
        {/* right side add upload resume button */}
        <div className="flex justify-between items-center  rounded-lg">
          <span className="flex items-end text-gray-700 text-[14px] font-bold">
            <InfoOutlined className="text-gray-500 mr-1" />
            You have {fileList.length} resumes saved out of 5 available slots.
          </span>

          <button
            className="border flex items-center gap-1 px-1 py-1 text-[14px] rounded-lg bg-gray-50 pr-1"
            aria-label="Upload Resume"
            onClick={() => {
              setOpen(true);
              setError(null);
            }}
          >
            <p className="bg-gradient-to-tr from-purple-400 to-sky-400  py-[6px] text-white flex items-center gap-1 px-2   rounded-lg">
              <svg
                width="14"
                height="15"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2519_30917)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4.5C12.5523 4.5 13 4.94772 13 5.5V19.5C13 20.0523 12.5523 20.5 12 20.5C11.4477 20.5 11 20.0523 11 19.5V5.5C11 4.94772 11.4477 4.5 12 4.5Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 12.5C4 11.9477 4.44772 11.5 5 11.5H19C19.5523 11.5 20 11.9477 20 12.5C20 13.0523 19.5523 13.5 19 13.5H5C4.44772 13.5 4 13.0523 4 12.5Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2519_30917">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </p>
            <span className=" font-semibold text-gray-700">
              <span className="hidden sm:inline">Add</span> Resume
            </span>
          </button>
        </div>

        {fileList.length > 0 && (
          <Paper
            className="mt-8 min-h-full ml-0"
            sx={{
              width: "100%",
              overflow: "hidden",
              boxShadow: "none",
              height: "100%",
            }}
          >
            <TableContainer>
              <Table
                stickyHeader
                aria-label="table"
                className="border-none"
                sx={{ "& .MuiTable-root": { borderBottom: "none" } }}
              >
                <TableHead
                  sx={{ "& .MuiTable-root": { borderBottom: "none" } }}
                >
                  <TableRow className="relative border-none after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[1px] after:bg-gray-300 after:opacity-50">
                    <TableCell
                      sx={{ fontWeight: "bold", paddingLeft: 0 }}
                      className="font-bold"
                    >
                      Resume
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className="font-bold"
                    >
                      Uploaded On
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className="font-bold text-center"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fileList.map((item) => (
                    <>
                      <TableRow
                        key={item.id}
                        className="relative border-none after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[1px] after:bg-gray-50 after:opacity-80"
                      >
                        <TableCell sx={{ fontWeight: "bold", paddingLeft: 0 }}>
                          <button
                            onClick={() => openPdfInNewTab(item.url)}
                            className="font-extrabold inline-flex items-center space-x-2 pl-0"
                          >
                            <span className="hover:underline ">
                              {item.name}
                            </span>
                            {item.defaultCV && (
                              <span className="py-[2px] font-normal text-[12px] bg-active-button-gradient text-[#516AF4] rounded-lg px-1 flex items-center space-x-1">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.9938 1.00195C12.3745 1.00195 12.7221 1.21805 12.8906 1.55939L15.7438 7.34073L22.1233 8.26529C22.5002 8.31992 22.8133 8.58388 22.9309 8.94609C23.0486 9.30831 22.9502 9.70589 22.6774 9.97153L18.0544 14.4715L19.1444 20.8259C19.2088 21.2012 19.0544 21.5805 18.7463 21.8042C18.4381 22.0279 18.0296 22.0572 17.6927 21.8796L12.0003 18.88L6.2942 21.8801C5.95728 22.0572 5.54901 22.0277 5.24106 21.804C4.9331 21.5802 4.77888 21.2011 4.84324 20.8259L5.93327 14.4715L1.31033 9.97153C1.03743 9.70589 0.939109 9.30831 1.05674 8.94609C1.17436 8.58388 1.48752 8.31992 1.86441 8.26529L8.24388 7.34073L11.0971 1.55939C11.2656 1.21805 11.6132 1.00195 11.9938 1.00195Z"
                                    fill="#117CFF"
                                  />
                                </svg>
                                <span>Primary</span>
                              </span>
                            )}
                          </button>
                        </TableCell>
                        <TableCell>
                          {new Date(item.upload_date).toLocaleDateString()}
                        </TableCell>
                        <TableCell className="text-center">
                          <Box
                            sx={{
                              position: "relative",
                              width: "auto",
                              height: "auto",
                            }}
                          >
                            <button
                              onClick={(event) =>
                                handleMenuClick(event, item.id)
                              }
                              style={{
                                background: "none",
                                border: "none",
                                padding: "0",
                                cursor: "pointer",
                              }}
                            >
                              <MoreVertIcon />
                            </button>
                            <Menu
                              anchorEl={anchorEl}
                              open={openDelet === item.id}
                              onClose={handleClose}
                              sx={{
                                mt: 1,
                                // width: "150px",
                                borderRadius: "8px",
                                // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                boxShadow: "none",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  setDialogOpen(true);
                                  setResumeUpdateError(null);
                                  setResumeName(item.name);
                                  setFileIdUpdate(item.id);
                                  setResumeTitle(item.name);
                                  handleClose();
                                }}
                                sx={{
                                  display: flexbox,
                                  justifyContent: "start",
                                  justifyItems: "start",
                                  fontSize: "14px",
                                }}
                              >
                                <img
                                  src="images/icons/edit1.svg"
                                  className="mr-2"
                                  alt="Set Default"
                                />
                                Edit Resume Title
                              </MenuItem>
                              <Tooltip
                                placement="right"
                                title={
                                  item.defaultCV
                                    ? "This is already your primary resume"
                                    : ""
                                }
                              >
                                <span>
                                  {" "}
                                  {/* Tooltip works on span even when MenuItem is "disabled" */}
                                  <MenuItem
                                    onClick={(e) => {
                                      if (item.defaultCV) {
                                        e.preventDefault();
                                        e.stopPropagation(); // Blocks clicks but allows hover
                                        return;
                                      }
                                      setFileIdUpdate(item.id);
                                      setWarningDefault(true);
                                      handleClose();
                                    }}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      cursor: item.defaultCV
                                        ? "not-allowed"
                                        : "pointer",
                                      opacity: item.defaultCV ? 0.6 : 1, // Reduce opacity for a disabled effect
                                      // ⚠️ No pointerEvents: "none" to keep tooltip functional
                                    }}
                                    aria-disabled={item.defaultCV} // Accessibility improvement
                                  >
                                    <img
                                      src="images/icons/setDefault.svg"
                                      className="mr-2"
                                      alt="Set Default"
                                    />
                                    Set as Primary
                                  </MenuItem>
                                </span>
                              </Tooltip>

                              <Tooltip
                                placement="right"
                                title={
                                  item.defaultCV
                                    ? "You can't delete your primary resume"
                                    : ""
                                }
                                disableFocusListener
                              >
                                <span>
                                  {" "}
                                  {/* Wrap in a <span> to ensure Tooltip works when MenuItem is "disabled" */}
                                  <MenuItem
                                    onClick={(e) => {
                                      if (item.defaultCV) {
                                        e.preventDefault();
                                        e.stopPropagation(); // Stops click but allows hover
                                        return; // Prevents further execution
                                      }
                                      setFileIdUpdate(item.id);
                                      setWarning(true);
                                      handleClose();
                                    }}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      cursor: item.defaultCV
                                        ? "not-allowed"
                                        : "pointer",
                                      opacity: item.defaultCV ? 0.6 : 1, // Reduce opacity for a disabled effect
                                      // ⚠️ Don't use pointerEvents: "none" to keep tooltip working
                                    }}
                                    aria-disabled={item.defaultCV} // Improves accessibility
                                  >
                                    <img
                                      src="images/icons/delete.svg"
                                      className="mr-2"
                                      alt="Delete"
                                    />
                                    Delete
                                  </MenuItem>
                                </span>
                              </Tooltip>
                            </Menu>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </div>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          handleClose();
        }}
        fullWidth
        size="lg"
      >
        <div
          class="flex  my-3 justify-center items-center"
          style={{ width: "100%" }}
        >
          <UploaFile onUploadSuccess={fetchAllResumes} setOpen={setOpen} />
        </div>
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Edit Resume Title</DialogTitle>
        <DialogContent>
          <TextField
            label="Resume Name"
            value={resumeName.split(".")[0]}
            onChange={(e) => setResumeName(e.target.value)}
            fullWidth
            required
            sx={{ my: 2 }}
            InputProps={{
              sx: {
                py: 0,
                height: "40px",
                fontSize: "14px",
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "15px",
              },
            }}
            disabled
          />
          {!resumeName && (
            <span
              style={{
                color: "red",
                fontSize: "12px",
                display: "block",
                marginTop: "4px",
              }}
            >
              This field is required.
            </span>
          )}

          <TextField
            label="Resume Title"
            value={resumeTitle.split(".")[0]}
            onChange={(e) => setResumeTitle(e.target.value)}
            fullWidth
            required
            sx={{ my: 2 }}
            InputProps={{
              sx: {
                py: 0,
                height: "40px",
                fontSize: "14px",
              },
              "&.Mui-focused": {
                boxShadow: "none", // Remove box shadow on focus
              },
            }}
            InputLabelProps={{
              sx: {
                py: 0,
                height: "40px",
                fontSize: "14px",
              },
              "&.Mui-focused": {
                boxShadow: "none", // Remove box shadow on focus
              },
            }}
          />
          {resumeUpdateError && (
            <span
              style={{
                color: "red",
                fontSize: "12px",
                display: "block",
                marginTop: "4px",
              }}
            >
              {resumeUpdateError.replace("Filename", "Resume title")}
            </span>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <button
            className="py-2 px-4 text-sm font-medium text-white  bg-gradient-to-tr from-purple-400 to-sky-400 rounded-lg hover:from-purple-500 hover:to-sky-500 cursor-pointer"
            onClick={RenameCv}
            variant="contained"
            color="primary"
          >
            Update
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={warning}
        handler={handleWarning}
        onClose={handleWarning}
        className="bg-transparent flex justify-center items-center"
        PaperProps={{
          sx: {
            borderRadius: "24px 0px 24px 24px",
            padding: "12px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            position: "relative", // Ensures child elements respect absolute positioning
          },
          className: "bg-white max-w-sm w-full transition-all duration-300",
        }}
      >
        <button
          onClick={() => setWarning(false)}
          className="absolute top-0 right-1 text-gray-500 hover:text-gray-800"
        >
          ✖
        </button>
        <div className="w-[400px] bg-white  px-2 pb-2">
          <div className="flex justify-between items-center border-b-0">
            <h2 className="text-lg font-semibold text-gray-800">
              Are you sure you want to proceed?
            </h2>
          </div>
          <p className="text-sm text-gray-600 mt-3">
            Deleting this item will permanently remove it from your account.
            This action cannot be undone.
          </p>
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => setWarning(false)}
              className="py-2 px-4 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200"
            >
              No
            </button>
            <button
              onClick={() => {
                DeletCv(fileIdUpdate);
                setWarning(false);
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-gradient-to-tr from-purple-400 to-sky-400 rounded-lg hover:from-purple-500 hover:to-sky-500"
            >
              Yes, Delete Now
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={warningDefault}
        handler={handleWarningDefault}
        onClose={handleWarningDefault}
        className="bg-transparent flex justify-center items-center"
        PaperProps={{
          sx: {
            borderRadius: "24px 0px 24px 24px",
            padding: "12px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            position: "relative", // Ensures child elements respect absolute positioning
          },
          className: "bg-white max-w-sm w-full transition-all duration-300",
        }}
      >
        <button
          onClick={() => setWarningDefault(false)}
          className="absolute top-0 right-1 text-gray-500 hover:text-gray-800"
        >
          ✖
        </button>
        <div className="w-[400px] bg-white rounded-lg px-2 pb-2 ">
          <div className="flex justify-between items-center border-b-0">
            <h2 className="text-lg font-semibold text-gray-800">
              Are you sure you want to proceed?
            </h2>
          </div>
          <p className="text-sm text-gray-600 mt-3">
            Making this Resume default will set it as your primary resume.
          </p>
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => setWarningDefault(false)}
              className="py-2 px-4 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200"
            >
              No
            </button>
            <button
              onClick={() => {
                setDefaultCv(fileIdUpdate);
                setWarningDefault(false);
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-gradient-to-tr from-purple-400 to-sky-400 rounded-lg hover:from-purple-500 hover:to-sky-500"
            >
              Yes, Make it
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Resume;
