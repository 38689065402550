import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import * as Yup from "yup";
import axios from "axios";
import { Toaster, resolveValue, toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import LoginFooter from "../loginFooter";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Auth } from "../../../redux copy/actionTypes";
import CircularProgress from "@mui/material/CircularProgress";
import { getCandidateNames } from "../../../utils/candidate.api/candidate.api.services";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";

// import ForgotPasswordModal from "./ForgetPass";

const WithPassword = ({ switchToOtp }) => {
  const [form, setForm] = useState({ password: "", email: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from;

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("please enter a valid password"),
    email: Yup.string()
      .email("Please enter a valid email address") // Built-in validation
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test("valid-domain", "Invalid email format", (value) =>
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      )
      .test("contains-at", "Invalid email format", (value) =>
        value.includes("@")
      ),
  });

  const [errors, setErrors] = useState({});

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(form, { abortEarly: false });
      setLoading(true);
      const config1 = {
        method: "post",
        url: `${config.apiUrl}candidate/login`,
        data: form,
      };

      const response = await axios(config1);

      if (response.status === 200) {
        console.log(response.data);
        console.log(response.data.access_token);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("role", "candidate");
        dispatch({
          type: Auth,
          payload: response.data,
        });

        const candidateNames = await getCandidateNames(
          response.data.refresh_token
        );

        // console.log(candidateNames)
        const name = candidateNames.data.name;
        await localStorage.setItem("Name", name);

        if (response.data.profile_exists && response.data.cv_exists) {
          navigate("/candidate/dashboard", {
            replace: true,
            state: {
              from: location.state?.from,
              showSuccessMessage: true,
            },
          });
        } else {
          navigate("/candidate/upload-resume", {
            replace: true,
            state: {
              from: location.state?.from,
              showSuccessMessage: true,
            },
          });
        }
      } else {
        toast.dismiss();
        toast.error("Something went wrong");
      }
    } catch (error) {
      // Handling Yup validation errors first (to show inline errors)
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors); // Set Yup validation errors in state (inline)
        return; // Stop execution if there are Yup validation errors
      }

      // Handling server-side (API) errors (toast notifications only)
      if (error.response) {
        console.error("API Error:", error.response.data);

        // If the error response is a string (like "Please enter a valid email address")

        if (typeof error.response?.data?.detail === "string") {
          toast.dismiss();
          toast.error(error.response.data.detail);
        }

        // If the error is an array of errors (like the array logic you already have)
        else if (Array.isArray(error.response.data.detail)) {
          // Display a generic toast error, as you don't want to show inline errors
          toast.dismiss();
          toast.error("There are errors in your submission.");

          // Optionally, you could map through the array and display all error messages in toast notifications
          error.response.data.detail.forEach((err) => {
            toast.dismiss();
            toast.error(err.msg); // Display each API error message in the toast
          });
        }
      }
      // Handling other unexpected errors
      else {
        console.error("Unexpected error:", error);
        toast.dismiss();
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => ({ ...old, [name]: value }));
    setErrors((old) => ({ ...old, [name]: "" }));
  };

  // forget password
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    navigate("/candidate/forgot-password");
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false);
    setEmail("");
    setEmailError("");
  };

  return (
    <>
      <Toaster />
      <div className="">
        <form className="space-y-2" onSubmit={handleLogin}>
          <div>
            <TextField
              type="email"
              name="email"
              size="small"
              value={form.email}
              onChange={inputHandler}
              className={`mt-1 mb-1 block w-full p-3 border placeholder-gray-400 focus:outline-none sm 
                ${errors.email ? "border-red-500" : "border-gray-300"}`}
              placeholder="Enter your email"
              label={
                <span>
                  Email <span className="text-red-500">*</span>
                </span>
              }
              variant="outlined"
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email}
              // InputLabelProps={{
              //   shrink: true, // Ensures label stays above the input field
              // }}
              sx={{
                "& .MuiFormHelperText-root": {
                  color: "red", // Change the helper text color
                  fontSize: "14px", // Adjust the font size
                  // fontStyle: "italic", // Make it italic
                  p: 0,
                  mx: 0,
                  boxShadow: "none",
                },
              }}
            />
          </div>
          <div className="">
            <TextField
              type={showPassword ? "text" : "password"}
              name="password"
              value={form.password}
              onChange={inputHandler}
              variant="outlined"
              fullWidth
              size="small"
              placeholder="Enter your password"
              label={
                <span>
                  Password <span className="text-red-500">*</span>
                </span>
              }
              error={Boolean(errors.password)}
              helperText={errors.password || ""}
              sx={{
                "& .MuiFormHelperText-root": {
                  color: "red", // Change the helper text color
                  fontSize: "14px", // Adjust the font size
                  // fontStyle: "italic", // Make it italic
                  p: 0,
                  mx: 0,
                  boxShadow: "none",
                },
                mt: 1,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {/* <div className="flex items-center justify-between mb-4"> */}
          {/* <div>
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ms-2 dark:text-gray-300">Remember me<span className="text-red-500">*</span></label>
            </div> */}
          <div>
            <div
              onClick={handleOpen}
              className="float-left mb-3 text-blue-500 text-[14px] text-decoration-none cursor-pointer hover:underline"
              style={{ fontFamily: "poppins medium" }}
            >
              Forgot Password?
            </div>
            <Link
              to="#"
              onClick={switchToOtp}
              className="float-right mb-3 text-blue-500 hover:underline"
              style={{ fontFamily: "poppins medium" }}
            >
              Login with OTP
            </Link>
          </div>
          {/* </div> */}
          <div>
            <button
              type="submit"
              className="theme-btn mr-2 text-white w-full rounded-md"
            >
              Log In
              {loading == true ? (
                <CircularProgress
                  size={20}
                  disableShrink
                  style={{ color: "#fff", margin: "0 10px" }}
                />
              ) : null}
            </button>
          </div>
        </form>
      </div>
      {/* <ForgotPasswordModal open={open} handleClose={handleClose} /> */}
    </>
  );
};

export default WithPassword;
