import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  CircularProgress,
  DialogContentText,
} from "@mui/material";
import { useSelector } from "react-redux";
import config from "../../../config/config";
import axiosClient from "../../../config/axios.Config";
import { toast } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../../utils/Loader";
import { set } from "lodash";

function Interviewer() {
  // Individual state variables
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [warning, setWarning] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [interviewers, setInterviewers] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedInterviewer, setSelectedInterviewer] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [loading , setLoading] = useState(true);
  const [openOption, setOpenOption] = useState(false);
  const [openSingleInterview, setOpenSingleInterview] = useState(false);
  const [serverError, setServerError] = useState("");

  // create from data with formik
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
    }),
    onSubmit: async (values) => {
      setServerError("");
      try {
        const res = await axiosClient.post(
          `${BASE_URL}employer/interviewer/add`,
          values,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        toast.success("Interviewer added successfully");
        setOpenSingleInterview(false);
        setTriggerFetch((prev) => !prev);
      } catch (error) {
        console.error("API Error:", error?.response);
        setServerError(
          error?.response?.data?.detail || "Failed to add interviewer"
        );
      }
    },
  });

  // Refs
  const fileInputRef = useRef(null);

  // Redux selector
  const { access_token: token } = useSelector((state) => state.auth.Auth);
  const BASE_URL = config.apiUrl;

  const handleClickOpen = () => {
    setOpenOption(true);
  };

  const handleCloseOption = () => {
    setOpenOption(false);
  };

  const handleSingleInterviewer = () => {
    console.log("Add Single Interviewer");
    setServerError("");
    setOpenOption(false);
    setOpenSingleInterview(true);
    formik.resetForm();
  };

  const handleMultipleInterviewer = () => {
    console.log("Import from File");
    setOpenOption(false);
    setOpen(true);
  };

  // Fetch Interviewers
  const fetchInterviewers = async (currentPage, isNewFetch = false) => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `${BASE_URL}employer/interviewers-list?page=${currentPage}&page_size=10`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const newData = response.data.interviewers || [];
      if (newData.length > 0) {
        setInterviewers((prev) =>
          isNewFetch ? newData : [...prev, ...newData]
        );
        setPage(currentPage);
        setHasMore(newData.length === 10);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast.error("Failed to fetch interviewers");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Load more data
  const loadMoreData = () => {
    if (hasMore) {
      fetchInterviewers(page + 1);
    }
  };
  console.log(page, hasMore);

  // Initial load
  useEffect(() => {
    fetchInterviewers(1, true);
  }, [triggerFetch]);

  // Handle menu functions
  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };
  const handleMenuClick = (event, interviewer) => {
    setAnchorEl(event.currentTarget);
    setSelectedInterviewer(interviewer);
  };
  const handleMenuClose = () => setAnchorEl(null);
  const handleEdit = () => {
    setOpenEdit(true);
    setAnchorEl(null);
  };
  const handleWarning = () => setWarning((prev) => !prev);

  // File upload handler
  const handleFileUpload = useCallback(async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      await axiosClient.post(
        `${BASE_URL}employer/interviewers-list/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Interviewers uploaded successfully");
      setOpen(false);
      setPage(1);
      setInterviewers([]);
      setTriggerFetch((prev) => !prev);
    } catch (error) {
      toast.error("Upload failed. Please try again.");
    }
  }, [selectedFile, BASE_URL, token]);

  // Delete handler
  const handleDelete = async () => {
    if (!selectedInterviewer?.id) return;

    try {
      await axiosClient.delete(`${BASE_URL}employer/interviewers-list/delete`, {
        data: { interviewer_ids: [selectedInterviewer.id] },
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Interviewer deleted successfully");
      setWarning(false);
      setInterviewers((prev) =>
        prev.filter((i) => i.id !== selectedInterviewer.id)
      );
      setTriggerFetch((prev) => !prev);
      setAnchorEl(null);
    } catch (error) {
      toast.error("Delete failed. Please try again.");
    }
  };

  // Edit handler
  const handleSaveEdit = async () => {
    if (!selectedInterviewer?.id) return;

    try {
      await axiosClient.put(
        `${BASE_URL}employer/interviewers-list/update`,
        {
          interviewer_ids: [selectedInterviewer.id],
          name: selectedInterviewer.name,
          email: selectedInterviewer.email,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      toast.success("Interviewer updated successfully");
      setOpenEdit(false);
      setSelectedInterviewer(null);
      setInterviewers((prev) =>
        prev.map((i) =>
          i.id === selectedInterviewer.id ? selectedInterviewer : i
        )
      );
    } catch (error) {
      toast.error("Update failed. Please try again.");
    }
  };

  return (
    <div>
    {loading ? <Loader /> : (
      <div className="py-1 h-full">
        <div className="flex justify-between items-end">
          <div className="">
            <p className="text-gray-600 text-lg">
              Add and manage interviewers to provide efficient hiring for your
              company.
            </p>
          </div>
          <button
            className="border flex items-center gap-1 px-1 py-1 text-[14px] rounded-lg bg-gray-50 pr-1"
            aria-label="Upload Resume"
            onClick={() => setOpenOption(true)}
          >
            <p className="bg-gradient-to-tr from-purple-400 to-sky-400 py-[6px] text-white flex items-center gap-1 px-2 rounded-lg">
              <svg
                width="14"
                height="15"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2519_30917)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4.5C12.5523 4.5 13 4.94772 13 5.5V19.5C13 20.0523 12.5523 20.5 12 20.5C11.4477 20.5 11 20.0523 11 19.5V5.5C11 4.94772 11.4477 4.5 12 4.5Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 12.5C4 11.9477 4.44772 11.5 5 11.5H19C19.5523 11.5 20 11.9477 20 12.5C20 13.0523 19.5523 13.5 19 13.5H5C4.44772 13.5 4 13.0523 4 12.5Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2519_30917">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </p>
            <span className="font-semibold text-gray-700">
              <span className="hidden sm:inline">Add</span> Interviewers
            </span>
          </button>
        </div>

        {interviewers.length > 0 ? (
          <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "500px", overflow: "auto" }}
              id="scrollable-table"
            >
              <InfiniteScroll
                dataLength={interviewers.length}
                next={loadMoreData}
                hasMore={hasMore}
                loader={
                  <div style={{ textAlign: "center", padding: "10px" }}>
                    <CircularProgress size={24} />
                  </div>
                }
                scrollableTarget="scrollable-table"
              >
                <Table stickyHeader aria-label="applicants table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", paddingLeft: 0 }}
                        align="left"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", paddingLeft: 0 }}
                        align="left"
                      >
                        Email
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }} align="center">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {interviewers.map((interviewer, index) => (
                      <TableRow
                        key={index}
                        className="relative border-none after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[1px] after:bg-gray-50 after:opacity-80"
                      >
                        <TableCell
                          sx={{ paddingLeft: 0, paddingY: 1 }}
                          align="left"
                        >
                          {interviewer.name}
                        </TableCell>
                        <TableCell
                          sx={{ paddingLeft: 0, paddingY: 1 }}
                          align="left"
                        >
                          {interviewer.email}
                        </TableCell>
                        <TableCell
                          sx={{ paddingLeft: 0, paddingY: 1 }}
                          align="center"
                        >
                          <IconButton
                            aria-controls={
                              Boolean(anchorEl) ? "menu-list" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              Boolean(anchorEl) ? "true" : undefined
                            }
                            onClick={(event) =>
                              handleMenuClick(event, interviewer)
                            }
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_76_19235)">
                                <path
                                  d="M2.58984 14.4663C3.69441 14.4663 4.58984 13.5709 4.58984 12.4663C4.58984 11.3617 3.69441 10.4663 2.58984 10.4663C1.48527 10.4663 0.589844 11.3617 0.589844 12.4663C0.589844 13.5709 1.48527 14.4663 2.58984 14.4663Z"
                                  fill="#374957"
                                />
                                <path
                                  d="M12.5898 14.4663C13.6944 14.4663 14.5898 13.5709 14.5898 12.4663C14.5898 11.3617 13.6944 10.4663 12.5898 10.4663C11.4853 10.4663 10.5898 11.3617 10.5898 12.4663C10.5898 13.5709 11.4853 14.4663 12.5898 14.4663Z"
                                  fill="#374957"
                                />
                                <path
                                  d="M22.5899 14.4663C23.6944 14.4663 24.5899 13.5709 24.5899 12.4663C24.5899 11.3617 23.6944 10.4663 22.5899 10.4663C21.4853 10.4663 20.5898 11.3617 20.5898 12.4663C20.5898 13.5709 21.4853 14.4663 22.5899 14.4663Z"
                                  fill="#374957"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_76_19235">
                                  <rect
                                    width="24"
                                    height="24"
                                    fill="white"
                                    transform="translate(0.589844 0.46582)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </IconButton>

                          <Menu
                            id="menu-list"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            sx={{
                              "& .MuiPaper-root": {
                                boxShadow: "none",
                                border: "1px solid #d1d1d1",
                                borderRadius: "8px",
                              },
                            }}
                          >
                            <MenuItem onClick={handleEdit}>Edit</MenuItem>
                            <MenuItem onClick={handleWarning}>Delete</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </TableContainer>
          </Paper>
        ) : (
          <div className="flex h-[500px] justify-center items-center">
            <p className="text-center text-gray-400">No Interviewer found</p>
          </div>
        )}
      </div>
    )}
      {/* options */}
      <Dialog
        open={openOption}
        onClose={handleCloseOption}
        aria-labelledby="import-dialog-title"
        aria-describedby="import-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="import-dialog-title">
          Add Interviewer Details
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="import-dialog-description" sx={{ mb: 3 }}>
            Please select how you would like to add Interviewer Details:
          </DialogContentText>

          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}
          >
            <Paper
              elevation={3}
              sx={{
                p: 3,
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": { bgcolor: "action.hover" },
                transition: "background-color 0.3s",
              }}
              onClick={handleSingleInterviewer}
            >
              <AddCircleOutlineIcon
                color="primary"
                sx={{ fontSize: 48, mb: 2 }}
              />
              <Typography variant="h6" align="center">
                Add Single Interviewer Details
              </Typography>
              <Typography
                variant="body2"
                align="center"
                color="text.secondary"
                sx={{ mt: 1 }}
              >
                Manually enter data for one Interviewer Details
              </Typography>
            </Paper>

            <Paper
              elevation={3}
              sx={{
                p: 3,
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": { bgcolor: "action.hover" },
                transition: "background-color 0.3s",
              }}
              onClick={handleMultipleInterviewer}
            >
              <FileUploadIcon color="primary" sx={{ fontSize: 48, mb: 2 }} />
              <Typography variant="h6" align="center">
                Import from File
              </Typography>
              <Typography
                variant="body2"
                align="center"
                color="text.secondary"
                sx={{ mt: 1 }}
              >
                Upload Excel or CSV file with multiple Interviewer Details
              </Typography>
            </Paper>
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={handleCloseOption} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* single upload */}
      <Dialog
        open={openSingleInterview}
        onClose={() => {
          setOpenSingleInterview(false);
          formik.resetForm();
        }}
        maxWidth="xs"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Add Interviewer Details</DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              {...formik.getFieldProps("name")}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              // sx={{
              //   "& .MuiOutlinedInput-input": {
              //     padding: "6px",
              //   },
              //   "& .MuiInputLabel-root": {
              //     top: "12px",
              //     position: "unset",

              //   },
              // }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              {...formik.getFieldProps("email")}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onChange={(e) => {
                formik.handleChange(e); // Formik ka handleChange call karna zaroori hai
                setServerError(null); // Server error ko reset karna
              }}
            />

            {/* Show API Error Message in Red */}
            {serverError && (
              <Typography color="error" className="mt-2">
                {serverError}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => {
                setOpenSingleInterview(false);
                formik.resetForm();
              }}
              className="theme-btn bordered-btn hover:text-white mr-3"
            >
              <span className="font-semibold">Cancel</span>
            </button>
            <button
              type="submit"
              className="theme-btn font-semibold py-2 rounded-md"
              variant="contained"
            >
              Submit
            </button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Upload Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Upload Interviewer List</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" mb={2}>
            You can upload a Excel file or CSV with name and email in order.
          </Typography>

          <div className="relative mt-4">
            {/* Read-only input to display selected file name */}
            <input
              type="text"
              value={selectedFile?.name || "No file chosen"}
              readOnly
              className="w-full py-2 pl-2 border border-gray-300 rounded-md text-gray-500 cursor-pointer"
              onClick={() => fileInputRef.current.click()}
            />

            {/* Hidden file input */}
            <input
              id="file-upload"
              ref={fileInputRef}
              type="file"
              accept=".csv,.xlsx"
              className="hidden"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />

            {/* Label acting as a button */}
            <label
              htmlFor="file-upload"
              className="absolute right-[1px] top-1/2 transform -translate-y-1/2 bg-gray-200 text-black px-4 py-2 rounded-md cursor-pointer hover:bg-gray-300"
            >
              Choose File
            </label>

            {/* Error message */}
            <p className="text-red-500 text-sm mt-1 hidden" id="error-message">
              File is required (Only .csv or .xlsx allowed)
            </p>
          </div>
        </DialogContent>

        <DialogActions className="flex justify-between">
          <button
            onClick={handleClose}
            className="theme-btn bordered-btn hover:text-white mr-3"
          >
            <span className="font-semibold">Cancel</span>
          </button>

          <button
            onClick={() => {
              if (!fileInputRef.current.files.length) {
                document
                  .getElementById("error-message")
                  .classList.remove("hidden");
                return;
              }
              document.getElementById("error-message").classList.add("hidden");
              handleFileUpload();
            }}
            className="theme-btn mr-3"
          >
            <span className="text-white font-semibold">Upload</span>
          </button>
        </DialogActions>
      </Dialog>

      {/* Warning Dialog */}
      <Dialog
        className="bg-transparent shadow-transparent flex justify-center p-4"
        open={warning}
        onClose={handleWarning}
      >
        <div className="w-[400px] bg-white  pr-4 pl-4 pb-2 p-4">
          <div className="flex justify-between items-center border-b-0">
            <h2 className="text-lg font-semibold text-gray-800">
              Are you sure you want to proceed?
            </h2>
            <button
              onClick={() => setWarning(false)}
              className="text-gray-500 hover:text-gray-800 text-right p-2 ml-auto"
            >
              ✖
            </button>
          </div>
          <p className="text-sm text-gray-600 mt-3">
            Deleting this item will permanently remove it from your account.
            This action cannot be undone.
          </p>
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => setWarning(false)}
              className="py-2 px-4 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200"
            >
              No
            </button>
            <button
              onClick={handleDelete}
              className="py-2 px-4 text-sm font-medium text-white bg-gradient-to-tr from-purple-400 to-sky-400 rounded-lg hover:from-purple-500 hover:to-sky-500"
            >
              Yes, Delete Now
            </button>
          </div>
        </div>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false) && setSelectedInterviewer(null)}
        maxWidth="xs"
      >
        <DialogTitle>Edit Interviewer</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={selectedInterviewer?.name || ""}
            onChange={(e) =>
              setSelectedInterviewer({
                ...selectedInterviewer,
                name: e.target.value,
              })
            }
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={selectedInterviewer?.email || ""}
            onChange={(e) =>
              setSelectedInterviewer({
                ...selectedInterviewer,
                email: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenEdit(false) && setSelectedInterviewer(null)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveEdit}
            className="theme-btn font-semibold py-2 rounded-md"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Interviewer;
