import React, { useState } from "react";
import WithPassword from "./withPassword";
import WithOTP from "./withOtp";
import LoginFooter from "../loginFooter";

const CandidateLogin = () => {
  const [loginMethod, setLoginMethod] = useState("password");

  const switchToOtp = () => setLoginMethod("otp");
  const switchToPassword = () => setLoginMethod("password");
  React.useEffect(() => {
    localStorage.removeItem("settingTab");
    localStorage.removeItem("tab");
  }, []);

  return (
    <div className="flex flex-col px-2">
      <span className="text-2xl font-bold text-gray-800 text-center mb-6">
        <span className="color-text text-2xl">Candidate Login </span>
      </span>
      {loginMethod === "password" ? (
        <WithPassword switchToOtp={switchToOtp} />
      ) : (
        <WithOTP switchToPassword={switchToPassword} />
      )}
    </div>
  );
};

export default CandidateLogin;
