import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../Appbar';
import Drawer from '../Drawer';
import DrawerHeader from '../DrawerHeader';
import AvatarIcon from '../AvatarIcon';
import History from './History';

export default function ChatHistory() {
    return (
<Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar />
            <Drawer />
            <Box component="main" sx={{ flexGrow: 1, }} className="mainContent">
                <DrawerHeader />
                <History />
            </Box>
        </Box>
    );
}
