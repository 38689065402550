import React from 'react';
import { useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { toggleDrawer } from '../../../redux copy/actionTypes';
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";

const DrawerHeaderStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  zIndex:0,
  ...theme.mixins.toolbar,
}));

export default function DrawerHeader() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleDrawerClose = () => {
    dispatch(toggleDrawer(false));
  };
  const isDrawerOpen = useSelector((state) => state.auth.drawerOpen);
  const open = useSelector((state) => state.auth.drawerOpen);
  console.log(isDrawerOpen);

  const handleDrawerOpen = () => {
    dispatch(toggleDrawer(true));
  };

  return (
    <DrawerHeaderStyled>
    {isDrawerOpen ? (
    <>
    <h1 className='logo-side mr-auto pt-2 ps-2'>
    <img src="/images/hirree.svg" alt="logo" className="h-8" />
      </h1>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    
    </>
    ) : (
    <IconButton
          color="#000"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: "4px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
    )}
    </DrawerHeaderStyled>
  );
}
