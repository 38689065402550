import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Toaster, resolveValue, toast } from "react-hot-toast";
import axios from "axios";
import config from "../../../config/config";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  // State management
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpErrors, setOtpErrors] = useState("");
  const [isShowOtp, setIsShowOtp] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [reset_token, setResetToken] = useState("");

  const navigate = useNavigate();

  const otpInputRefs = Array(6)
    .fill(0)
    .map(() => React.createRef());

  // Timer for OTP resend countdown
  useEffect(() => {
    let timer;
    if (resendTimer > 0 && isShowOtp) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer, isShowOtp]);

  // Reset all states when modal closes
  const handleModalClose = () => {
    setEmail("");
    setEmailError("");
    setOtp(["", "", "", "", "", ""]);
    setOtpErrors("");
    setIsShowOtp(false);
    setIsVerified(false);
    setNewPassword("");
    setNewPasswordError("");
    setConfirmPassword("");
    setConfirmPasswordError("");
    setResetToken("");
    setShowPassword(false);
    setShowConfirmPassword(false);
    setLoading(false);
    setResendTimer(0);
    // handleClose();
  };

  // Email validation
  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      setEmailError("Email is required");
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    } else if (!email.includes("@")) {
      setEmailError("Invalid email format");
      return false;
    }

    setEmailError("");
    return true;
  };

  // Request password reset
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail()) return;

    setLoading(true);

    try {
      axios
        .post(`${config.apiUrl}candidate/reset-password/request`, {
          email,
        })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setIsShowOtp(true);
          setResendTimer(30); // 60 seconds countdown for resend
          toast.success(`OTP sent to ${email}`);

          // Focus the first OTP input field
          if (otpInputRefs[0].current) {
            otpInputRefs[0].current.focus();
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }

    // // Simulate API call
    // setTimeout(() => {
    //   setLoading(false);
    //   setIsShowOtp(true);
    //   setResendTimer(60); // 60 seconds countdown for resend
    //   toast.success(`OTP sent to ${email}`);

    //   // Focus the first OTP input field
    //   if (otpInputRefs[0].current) {
    //     otpInputRefs[0].current.focus();
    //   }
    // }, 1500);
  };

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/\D/.test(value)) return; // Prevent non-numeric values

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setOtpErrors(""); // Clear OTP error

    // Focus the next input box if there's a value entered
    if (value && index < otp.length - 1 && otpInputRefs[index + 1].current) {
      otpInputRefs[index + 1].current.focus();
    }
  };

  // Handle keydown for backspace
  const handleOtpKeyDown = (e, index) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      otpInputRefs[index - 1].current
    ) {
      otpInputRefs[index - 1].current.focus(); // Focus the previous input box
    }
  };

  // Handle OTP paste
  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    if (!/^\d+$/.test(pastedData)) return; // Ensure only numeric values are pasted
    setOtpErrors("");
    const pastedArray = pastedData.split("");
    const newOtp = [...otp];
    for (let i = 0; i < pastedArray.length && i < otp.length; i++) {
      newOtp[i] = pastedArray[i];
    }
    setOtp(newOtp);

    // Focus the next input box after pasting
    const nextIndex = Math.min(pastedArray.length, otp.length - 1);
    if (otpInputRefs[nextIndex].current) {
      otpInputRefs[nextIndex].current.focus();
    }
  };

  // Verify OTP
  const handleOtpSubmit = (e) => {
    const otpValue = otp.join("");
    console.log("otp", otpValue);
    e.preventDefault();
    setEmailError("");
    setOtpErrors("");
    if (otpValue.length < 6) {
      setOtpErrors("Please enter a 6-digit OTP");
      return;
    }

    setLoading(true);

    try {
      axios
        .post(`${config.apiUrl}candidate/reset-password/verify-otp`, {
          email,
          otp: otpValue,
        })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setResetToken(res.data.reset_token);
          toast.success(res.data.message);
          setIsVerified(true);
          setOtpErrors("");
        })
        .catch((err) => {
          console.log(err.response.data);
          // toast.error(err.response.data.error);

          setOtpErrors(err.response.data.detail);
          setLoading(false);
          // empty otp
          setOtp(["", "", "", "", "", ""]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Resend OTP
  const handleResendOtp = () => {
    if (resendTimer > 0) return;

    setLoading(true);

    try {
      axios
        .post(`${config.apiUrl}candidate/reset-password/request`, {
          email,
        })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setResendTimer(30); // Reset timer
          toast.success(`OTP resent to ${email}`);
          setOtp(["", "", "", "", "", ""]);
          setEmailError("");
          setOtpErrors("");
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }

    // // Simulate API call
    // setTimeout(() => {
    //   setLoading(false);
    //   setResendTimer(60); // Reset timer
    //   toast.success(`OTP resent to ${email}`);
    // }, 1500);
  };

  // Password validation
  const validatePassword = () => {
    // Password requirements: min 8 chars, at least one uppercase, one lowercase, one number, one special char
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    if (!newPassword) {
      setNewPasswordError("Password is required");
      return false;
    } else if (newPassword.length < 8) {
      setNewPasswordError("Password must be at least 8 characters long");
      return false;
    } else if (!passwordRegex.test(newPassword)) {
      setNewPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      );
      return false;
    } else if (newPassword.length > 12) {
      setNewPasswordError("Password must be at most 12 characters long");
      return false;
    }

    setNewPasswordError("");
    return true;
  };

  // Confirm password validation
  const validateConfirmPassword = () => {
    if (!confirmPassword) {
      setConfirmPasswordError("Please confirm your password");
      return false;
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    }

    setConfirmPasswordError("");
    return true;
  };

  // Reset password
  const handleResetPassword = (e) => {
    e.preventDefault();

    const isPasswordValid = validatePassword();
    const isConfirmPasswordValid = validateConfirmPassword();

    if (!isPasswordValid || !isConfirmPasswordValid) return;

    setLoading(true);

    try {
      axios
        .post(`${config.apiUrl}candidate/reset-password/set-password`, {
          email,
          new_password: newPassword,
          confirm_new_password: confirmPassword,
          reset_token,
        })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          toast.success(res.data.message);
          handleModalClose();
          window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Default width for extra-small screens
    maxWidth: "500px", // Prevents modal from being too large
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    maxHeight: "90vh",
    overflow: "auto",

    // Responsive width settings using MUI's sx breakpoints
    "@media (min-width:600px)": { width: "400px" }, // Small screens
    "@media (min-width:900px)": { width: "450px" }, // Medium screens
    "@media (min-width:1200px)": { width: "450px" }, // Large screens
  };

  return (
    <div className="bg-white min-h-screen">
      {/* Logo */}
      <div
        className="absolute top-8 left-8 cursor-pointer"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <img src="/images/hirree.svg" alt="logo" className="h-8" />
      </div>

      {/* Main content */}
      <div className="container mx-auto py-24">
        <div className="grid grid-cols-12 border rounded-lg shadow-md overflow-hidden mt-6 mx-4 md:mx-24 relative">
          {/* Back Navigation - Inside the box at top-left corner */}

          {/* Left panel - Marketing content */}
          <div className="md:col-span-7 col-span-12 bg-white pt-12 md:pt-0">
            <div className="hidden md:flex flex-col justify-center h-full p-8 md:p-12">
              {/* Image Section */}
              <div className="w-full justify-center hidden md:flex">
                <img
                  src="/images/forgotPass.jpeg"
                  alt="Employer Login"
                  className="w-4/5 max-w-md h-auto rounded-lg"
                />
              </div>
            </div>
          </div>

          {/* Right panel - Login form */}
          <div className="bg-white p-6 md:p-10 md:col-span-5 col-span-12 flex flex-col justify-center">
            <div className="mx-auto w-full max-w-md">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  bgcolor: "white",
                  p: 4,
                }}
              >
                {isVerified ? (
                  <>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      mb={3}
                      textAlign="center"
                    >
                      <span className="color-text">Set New Password</span>
                    </Typography>

                    <TextField
                      fullWidth
                      label={
                        <span>
                          New Password<span className="text-red-500">*</span>
                        </span>
                      }
                      variant="outlined"
                      size="medium"
                      type={showPassword ? "text" : "password"}
                      sx={{
                        mb: newPasswordError ? 1 : 3,
                        "& .MuiFormHelperText-root": {
                          color: "red",
                          p: 0,
                          mx: 0,
                          boxShadow: "none",
                        },
                      }}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        if (newPasswordError) validatePassword();
                      }}
                      value={newPassword}
                      error={!!newPasswordError}
                      helperText={newPasswordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {!newPasswordError && (
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ display: "block", mb: 3, px: 1 }}
                      >
                        Password must be at least 8 characters with uppercase,
                        lowercase, number, and special character
                      </Typography>
                    )}

                    <TextField
                      fullWidth
                      label={
                        <span>
                          Confirm Password
                          <span className="text-red-500">*</span>
                        </span>
                      }
                      variant="outlined"
                      size="medium"
                      type={showConfirmPassword ? "text" : "password"}
                      sx={{
                        mb: 3,
                        "& .MuiFormHelperText-root": {
                          color: "red",
                          p: 0,
                          mx: 0,
                          boxShadow: "none",
                        },
                      }}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        if (confirmPasswordError) validateConfirmPassword();
                      }}
                      value={confirmPassword}
                      error={!!confirmPasswordError}
                      helperText={confirmPasswordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <button
                      className="theme-btn mr-2 text-white w-full rounded-md"
                      onClick={handleResetPassword}
                      disabled={loading}
                      sx={{
                        p: 1.5,
                        textTransform: "none",
                        fontWeight: "bold",
                        bgcolor: "primary.main",
                        "&:hover": { bgcolor: "primary.dark" },
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Reset Password"
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      mb={3}
                      textAlign="center"
                    >
                      <span className="color-text">Forgot Password</span>
                    </Typography>

                    {!isShowOtp ? (
                      <>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          mb={3}
                          textAlign="center"
                        >
                          Enter your email address, and we'll send you a
                          verification code to reset your password.
                        </Typography>

                        <TextField
                          fullWidth
                          label={
                            <span>
                              Email Address
                              <span className="text-red-500">*</span>
                            </span>
                          }
                          variant="outlined"
                          size="medium"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (emailError) validateEmail();
                            setEmailError("");
                          }}
                          error={!!emailError}
                          helperText={emailError}
                          sx={{
                            mb: 3,
                            "& .MuiFormHelperText-root": {
                              color: "red",
                              p: 0,
                              mx: 0,
                              boxShadow: "none",
                            },
                          }}
                          disabled={loading}
                        />

                        <button
                          className="theme-btn mr-2 p-3 text-white w-full rounded-md"
                          onClick={handleSubmit}
                          disabled={loading}
                          sx={{
                            p: 1.5,
                            textTransform: "none",
                            fontWeight: "bold",
                            bgcolor: "primary.main",
                            "&:hover": { bgcolor: "primary.dark" },
                          }}
                        >
                          {loading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Send Verification Code"
                          )}
                        </button>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          mb={3}
                          textAlign="center"
                        >
                          Enter the 6-digit code sent to{" "}
                          <strong>{email}</strong>
                        </Typography>

                        <Box sx={{ mb: 3 }}>
                          <div className="flex justify-center gap-1 mb-2">
                            {otp.map((digit, index) => (
                              <input
                                key={index}
                                ref={otpInputRefs[index]}
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength={1}
                                className={`w-8 h-10 sm:w-[55px] sm:h-12 text-center border ${
                                  otpErrors
                                    ? "border-red-600"
                                    : "border-gray-600"
                                } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg`}
                                value={digit}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                onPaste={
                                  index === 0 ? handleOtpPaste : undefined
                                }
                                disabled={loading}
                              />
                            ))}
                          </div>

                          {otpErrors && (
                            <Typography
                              color="error"
                              variant="caption"
                              sx={{
                                display: "block",
                                textAlign: "center",
                                mb: 1,
                              }}
                            >
                              {otpErrors}
                            </Typography>
                          )}

                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center" }}
                          >
                            Didn't receive the code?{" "}
                            {resendTimer > 0 ? (
                              <p className="text-sm">
                                Resend OTP in {Math.floor(resendTimer / 60)}:
                                {(resendTimer % 60).toString().padStart(2, "0")}
                              </p>
                            ) : (
                              <p className="text-sm cursor-pointer">
                                Didn't get the OTP?{" "}
                                <span
                                  className="text-blue-500 cursor-pointer hover:underline"
                                  onClick={handleResendOtp}
                                >
                                  Resend OTP
                                </span>
                              </p>
                            )}
                          </Typography>
                        </Box>

                        <button
                          fullWidth
                          className="theme-btn mr-2 p-4 text-white w-full rounded-md"
                          disableElevation
                          onClick={handleOtpSubmit}
                          //   disabled={loading || otp.some(digit => !digit)}
                          sx={{
                            p: 1.5,
                            textTransform: "none",
                            fontWeight: "bold",
                            bgcolor: "primary.main",
                            "&:hover": { bgcolor: "primary.dark" },
                          }}
                        >
                          {loading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Verify Code"
                          )}
                        </button>
                      </>
                    )}
                  </>
                )}
                <Button
                  fullWidth
                  variant="text"
                  onClick={() => {
                    setIsShowOtp(false);
                    setOtp(["", "", "", "", "", ""]);
                    setOtpErrors("");
                    navigate("/candidate/login");
                  }}
                  sx={{ mt: 2, textTransform: "none" }}
                  disabled={loading}
                >
                  Back to Login
                </Button>
              </Box>

              {/* login btn */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
