import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import axiosClient from "../../../config/axios.Config";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  width: "auto",
};

const DeleteJobs = ({ openDeleteModal, handleCloseDelete, jobId }) => {
  const [feedback, setFeedback] = useState(""); // State to store feedback
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false); // Control visibility of feedback field
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // Handle delete confirmation and show feedback field
  const handleConfirmDelete = () => {
    // fetch total applicants
    setLoading(true);
    axiosClient
      .get(`${config.apiUrl}employer/jobs/${jobId}/applicants`, {
        headers: {
          Authorization: `Bearer ${loginData.access_token}`,
        },
      })
      .then((res) => {
        // Log the total number of applicants
        console.log("Total Applicants:", res.data.total_applicants);

        if (res.data.total_applicants > 5) {
          setIsFeedbackVisible(true);
        } else {
          handleSubmitDelete();
        }
      })
      .catch((error) => {
        console.error("Error fetching applicants:", error);
        toast.dismiss();
        toast.error("An error occurred while fetching applicants.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Handle the feedback change
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };
  const loginData = useSelector((state) => state.auth.Auth);

  const handleSubmitDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}employer/jobs/${jobId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loginData.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ feedback: feedback }),
      });

      if (response.ok) {
        toast.dismiss();
        toast.success("Job deleted successfully!");
        handleCloseDelete();
        navigate("/employer/job-listing");
        // handleJobDelete()
      } else {
        toast.dismiss();
        toast.error("Failed to delete the job");
        handleCloseDelete(false);
      }
    } catch (error) {
      console.error("Error deleting job:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCloseModal = () => {
    setIsFeedbackVisible(false); // Reset feedback field visibility
    setFeedback(""); // Optionally reset the feedback text
    handleCloseDelete(); // Close the modal
  };
  return (
    <>
      {/* <Modal open={openDeleteModal}>
        <Box sx={style}>
          {!isFeedbackVisible ? (
            <>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="text-center"
              >
                Are you sure you want to delete this job ?
              </Typography>
              <div className="justify-center items-center mt-5 flex">
                <button
                  onClick={handleCloseDelete}
                  className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300"
                >
                  No
                </button>
                <button
                  onClick={handleConfirmDelete} // Show the feedback field
                  className="py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn rounded-lg"
                >
                  Yes
                </button>
              </div>
            </>
          ) : (
            <>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="text-center"
              >
                Please provide a reason for deleting the job
              </Typography>
              <textarea
                value={feedback}
                onChange={handleFeedbackChange}
                rows="4"
                placeholder="Enter reason..."
                className="w-full mt-3 p-2 border rounded-md"
              />
              <div className="justify-center items-center mt-5 flex">
                <button
                  onClick={handleCloseModal}
                  className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmitDelete} // Submit the delete request with feedback
                  className="py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn rounded-lg"
                >
                  Confirm Delete
                </button>
              </div>
            </>
          )}
        </Box>
      </Modal> */}
      <Dialog
        open={openDeleteModal}
        // handler={handleWarning}
        onClose={handleCloseModal}
        className="bg-transparent flex justify-center items-center"
        PaperProps={{
          sx: {
            borderRadius: "24px 0px 24px 24px",
            padding: "12px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            position: "relative", // Ensures child elements respect absolute positioning
          },
          className: "bg-white max-w-sm w-full transition-all duration-300",
        }}
      >
        <button
          onClick={handleCloseModal}
          className="absolute top-0 right-1 text-gray-500 hover:text-gray-800"
        >
          ✖
        </button>
        {!isFeedbackVisible ? (
          <div className="w-[400px] bg-white  px-2 pb-2">
            <div className="flex justify-between items-center border-b-0">
              <h2 className="text-lg font-semibold text-gray-800">
                Are you sure you want to proceed?
              </h2>
            </div>
            <p className="text-sm text-gray-600 mt-3">
              Deleting this item will permanently remove it from your account.
              This action cannot be undone.
            </p>
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={handleCloseModal}
                className="py-2 px-4 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200"
              >
                No
              </button>
              <button
               onClick={handleConfirmDelete}
                className="py-2 px-4 w-42 text-sm font-medium text-white bg-gradient-to-tr from-purple-400 to-sky-400 rounded-lg hover:from-purple-500 hover:to-sky-500"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Yes, Delete Now"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="w-[400px] bg-white  px-2 pb-2">
            <div className="flex justify-between items-center border-b-0">
              <h2 className="text-lg font-semibold text-gray-800">
                Please provide a reason for deleting the job
              </h2>
            </div>
            <textarea
              value={feedback}
              onChange={handleFeedbackChange}
              rows="4"
              placeholder="Enter reason..."
              className="w-full mt-3 p-2 border rounded-md"
            />
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={handleCloseModal}
                className="py-2 px-4 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmitDelete}
                className="py-2 px-4 w-32 text-sm font-medium text-white bg-gradient-to-tr from-purple-400 to-sky-400 rounded-lg hover:from-purple-500 hover:to-sky-500"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Confirm Delete"
                )}
              </button>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default DeleteJobs;
