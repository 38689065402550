import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import DrawerHeader from "../DrawerHeader";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axiosClient from "../../../config/axios.Config";
import { useSelector } from "react-redux";
import config from "../../../config/config";
import { toast, Toaster } from "react-hot-toast";
import UpcomingInterviews from "./UpcomingInterviews";
import { useNavigate } from "react-router-dom";
import Loader from "../../../utils/Loader";
import { CircularProgress, IconButton } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Doughnut } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { toggleDrawer } from "../../../redux copy/actionTypes";
import { useDispatch } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip as RechartsTooltip,
} from "recharts";
import Tooltip from "@mui/material/Tooltip"; // MUI Tooltip

const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export default function MainContent() {
  const [Candidate_Info, setCandidate_Info] = React.useState({
    application_stats: {
      applied_jobs: 0,
      saved_jobs: 0,
      interviewed_percentage: 0,
      unsuitable_percentage: 0,
    },
    upcoming_interviews: [],
    recommendations: [],
  });
  const loginData = useSelector((state) => state.auth.Auth);
  const [companyLogos, setCompanyLogos] = useState({});
  const [appliedJobs, setAppliedJobs] = useState({});
  const [loading, setLoading] = useState(false);
  const [greeting, setGreeting] = useState("");
  const [appliedStat, setAppliedStat] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showSuccessMessage, setShowSuccessMessage] = useState(
    location.state?.showSuccessMessage
  );
  console.log("showSuccessMessage", showSuccessMessage);

  // if(showSuccessMessage) {
  //   toast.dismiss();
  //   toast.success("Login successfully");
  //   setShowSuccessMessage(false);
  // }

  const isMobile = useScreenSize();
  useEffect(() => {
    if (isMobile) {
      dispatch(toggleDrawer(false));
    } else {
      dispatch(toggleDrawer(true));
    }
  }, [isMobile]);

  // Greeting message
  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour < 18) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }

    if (showSuccessMessage) {
      setTimeout(() => {
        toast.success("Login successful! Let’s get you hired.");
      }, 2000);
    }
  }, []);

  const handleNavigate = () => {
    navigate("/candidate/jobs/recomendation"); // Update the path to match your route
  };

  const saveJob = async (jobId) => {
    try {
      console.log("access token", loginData.access_token);
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/save-job/${jobId}`,
        {}, // Empty body if no data is required
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 201) {
        // console.log("Job saved successfully!", response.data);
        toast.dismiss();
        toast.success(response.data.message);
        getCandidateInfo();
      }
    } catch (error) {
      console.error("Error saving job:", error);
      // Handle error - you might want to display an error message to the user
    }
  };
  const unSaveJob = async (jobId) => {
    try {
      console.log("access token", loginData.access_token);
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/unsave-job/${jobId}`,
        {}, // Empty body if no data is required
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 200) {
        // console.log("Job saved successfully!", response.data);
        toast.dismiss();
        toast.success(response.data.message);
        getCandidateInfo();
      }
    } catch (error) {
      console.error("Error saving job:", error);
      // Handle error - you might want to display an error message to the user
    }
  };

  const fetchCompanyLogo = async (logoId) => {
    try {
      if (logoId === "None") return "";
      if (companyLogos[logoId]) return companyLogos[logoId];
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/company-logo/${logoId}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
          // responseType: "blob",
        }
      );
      const logoUrl = response.data.url;
      setCompanyLogos((prev) => ({ ...prev, [logoId]: logoUrl }));
    } catch (error) {
      console.log("Error fetching company logo:", error);
      return null;
    }
  };
  useEffect(() => {
    Candidate_Info.recommendations.forEach((job) => {
      if (job?.employer_id && !companyLogos[job?.employer_id]) {
        fetchCompanyLogo(job?.employer_id);
      }
    });

    return () => {
      Object.values(companyLogos).forEach((url) => URL.revokeObjectURL(url)); // Clean up blob URLs
    };
  }, [Candidate_Info, companyLogos]);

  const fetchCompanyLogosBatch = async (logoIds) => {
    try {
      const response = await axiosClient.post(
        `${config.apiUrl}company/logos/batch`,
        {
          ids: logoIds,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching company logos:", error);
      return {};
    }
  };
  // Function to fetch the company logo using the logoId

  const applyForJob = async (jobId) => {
    try {
      const response = await axiosClient.post(
        `${config.apiUrl}candidate/apply-job/${jobId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      if (response.status === 201) {
        toast.dismiss();
        toast.success(response.data.message);
        setAppliedJobs((prev) => ({ ...prev, [jobId]: true }));
        getCandidateInfo();
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.detail);
      console.log(error);
    }
  };

  const getCandidateInfo = async () => {
    try {
      console.log("Token" + loginData.access_token);
      setLoading(true);
      const response = await axiosClient.get(
        `${config.apiUrl}candidate/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      console.log(response.data);
      if (response.status === 200) {
        setCandidate_Info(response.data);
        const logoIds = response.data.recommendations
          .filter((job) => job?.employer_id && !companyLogos[job.id])
          .map((job) => job?.employer_id);
          setAppliedStat(response.data.application_stats);
        // if (logoIds.length > 0) {
        //   const logos = await fetchCompanyLogosBatch(logoIds);

        //   const logoMap = response.data.reduce((acc, job) => {
        //     if (logos[job?.employer_id]) {
        //       acc[job.id] = logos[job?.employer_id];
        //     }
        //     return acc;
        //   }, {});

        //   setCompanyLogos((prevLogos) => ({ ...prevLogos, ...logoMap }));
        // }
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCandidateInfo();
  }, []);
  const createGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    gradient.addColorStop(0, "rgba(26, 217, 223, 1)"); // Start color
    gradient.addColorStop(1, "rgba(168, 36, 254, 1)"); // End color
    return gradient;
  };

  const createHoverGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    gradient.addColorStop(0, "#5670f5"); // Start hover color
    gradient.addColorStop(1, "#42a5f5"); // End hover color
    return gradient;
  };

  const data = {
    labels: ["Unsuitable", "Interviewed"],
    datasets: [
      {
        data: [
          Candidate_Info.application_stats.unsuitable_percentage,
          Candidate_Info.application_stats.interviewed_percentage,
        ],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          // Fallback while rendering
          if (!chartArea) {
            return "#dadbe0";
          }

          return [
            createGradient(ctx, chartArea), // Gradient for the first segment
            "#dadbe0", // Static color for the second segment
          ];
        },
        hoverBackgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          // Fallback while rendering
          if (!chartArea) {
            return "#dadbe0";
          }

          return [
            createHoverGradient(ctx, chartArea), // Gradient for the first segment on hover
            "#dadbe0", // Static color for the second segment on hover
          ];
        },
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true, // Tooltips enabled
      },
    },
    cutout: "65%",
  };

  // Function to format salary with 'k'
  const formateSalary = (salary, salary_type) => {
    if (salary) {
      if (salary_type === "Monthly") {
        let yearlySalary = salary * 12;
        return yearlySalary / 100000;
      } else if (salary_type === "Yearly") {
        return salary / 100000;
      }
    } else {
      return "Salary not provided";
    }
  };

  const title = (title) => {
    if (title.length > 20) {
      return title.substring(0, 20) + "...";
    } else {
      return title;
    }
  };

  const [isHovered, setIsHovered] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loadingJobId, setLoadingJobId] = useState(null);
  const [loadingJobIdApply, setLoadingJobIdApply] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShare = (platform) => {
    // const id = selectedJob.id;
    const jobUrl = selectedJob?.replace(":8000", ":3000");

    const encodedTitle = encodeURIComponent("");
    const encodedUrl = encodeURIComponent("");

    let shareLink = "";
    switch (platform) {
      case "linkedin":
        shareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
        break;
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case "twitter":
        shareLink = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`;
        break;
      case "copy":
        navigator.clipboard.writeText(jobUrl);
        toast.success("Link copied to clipboard");
        return;
      default:
        return;
    }

    window.open(shareLink, "_blank");
    handleClose();
  };

  const [matchedData, setMatchedData] = useState([]);
  const matchData = [
    { label: "Experience Level", value: matchedData?.experience_level || 0 },
    { label: "Skills", value: matchedData?.skills_match || 0 },
    { label: "Salary", value: matchedData?.salary_match || 0 },
    { label: "Location", value: matchedData?.location_match || 0 },
    { label: "Job Type", value: matchedData?.job_type_match || 0 },
    { label: "Notice Period", value: matchedData?.notice_period_match || 0 },
  ]
    .filter((item) => item.value > 0) // Remove items with 0 value
    .sort((a, b) => b.value - a.value) // Sort in descending order
    .slice(0, 5); // Take only the top 5

  const dataBar = [
    {
      name: "Applied Jobs",
      value: Candidate_Info?.application_stats?.applied_jobs || 0,
      link: "/candidate/jobs/my-applications",
    },
    {
      name: "Saved Jobs",
      value: Candidate_Info?.application_stats?.saved_jobs || 0,
      link: "/candidate/jobs/saved-jobs",
    },
    {
      name: "Pending Interviews",
      value:
        Candidate_Info?.application_stats?.interview_stats
          ?.pending_interviews || 0,
      link: "/candidate/interviews",
    },
  ];

  const handleBarClick = (data) => {
    navigate(data.link);
  };

  const pieData = [
    {
      name: "Unsuitable",
      value: Candidate_Info?.application_stats?.unsuitable_percentage || 0,
    },
    {
      name: "Interviewed",
      value: Candidate_Info?.application_stats?.interviewed_percentage || 0,
    },
  ];

  const COLORS = ["#1AD9DF", "#A824FE"];

  const categories = [
    {
      name: "Short-listed",
      value: appliedStat?.["shortlisted_jobs"] || 0,
      color: "#7C3AED", // Purple
    },
    // {
    //   name: "Part-Time",
    //   value: applicationType?.["Part-time"] || 0,
    //   color: "#34D399", // Green
    // },
    {
      name: "Hired",
      value: appliedStat?.["hired_jobs"] || 0,
      color: "#FACC15",
    }, // Yellow
    {
      name: "Rejected",
      value: appliedStat?.["rejected_jobs"] || 0,
      color: "#EF4444",
    }, // Red
    {
      name: "In Review",
      value: appliedStat?.applied_jobs - (appliedStat?.["hired_jobs"] + appliedStat?.["rejected_jobs"] + appliedStat?.["shortlisted_jobs"]) || 0,
      color: "#3B82F6",
    }
  ];

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <DrawerHeader />
      <Toaster />
      {/* <UploadResumeModal /> */}
      {!loading ? (
        <div>
          <div className="flex justify-between items-center bg-gray-50 mt-3 px-6 py-2 pr-10">
            {/* Left Section */}
            <div>
              <h1 className="text-xl font-semibold text-gray-800">
                {greeting} ,{" "}
                <h3 className=" inline-block text-xxl text-gray-500">
                  {Candidate_Info.name?.charAt(0).toUpperCase() + Candidate_Info.name?.slice(1)}
                </h3>
              </h1>

              <p className="text-sm text-gray-600">
                Here is your daily activities and job alerts.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap md:flex-nowrap gap-6 px-6 bg-gray-50">
            {/* Left Panel */}
            <div className="w-full md:w-2/5 flex flex-col gap-4">
              <div className="w-full h-72 p-5 bg-white border border-gray-200 rounded-2xl">
                <h2 className="text-xl font-bold text-center mb-3">
                  Application Statistics
                </h2>
                <ResponsiveContainer width="100%" height="90%">
                  <BarChart data={dataBar}>
                    <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                    <YAxis tick={{ fontSize: 12 }} />
                    <RechartsTooltip cursor={{ fill: "rgba(0, 0, 0, 0.1)" }} />
                    <Bar
                      dataKey="value"
                      fill="#8884d8"
                      barSize={50}
                      onClick={(event, index) => handleBarClick(dataBar[index])}
                      cursor="pointer"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Middle Panel */}
            <div className="w-full md:w-1/5 bg-white border border-gray-200 rounded-2xl py-6 px-4 cursor-pointer">
              <div
                className="bg-white"
                onClick={() => navigate("/candidate/jobs/my-applications")}
              >
                <h2 className="text-xl font-semibold mb-4">
                  Application Status
                </h2>
                <div className="flex items-baseline">
                  <p className="text-4xl font-bold">{appliedStat?.applied_jobs}</p>
                  <p className="text-gray-500 mt-2 ml-2">Applied Job</p>
                </div>
                <div className="flex items-center mt-4 w-full h-4 rounded-full overflow-hidden bg-gray-200">
                  {categories.map((category, index) => (
                    <div
                      key={index}
                      style={{
                        width:
                        appliedStat?.applied_jobs > 0 ? `${(category.value / appliedStat?.applied_jobs) * 100}%` : "0%",
                        backgroundColor: category.color,
                      }}
                      className="h-full"
                      title={`${category.name}: ${category.value} (${(
                        (category.value / appliedStat?.applied_jobs) *
                        100
                      ).toFixed(1)}%)`}
                    ></div>
                  ))}
                </div>
                <div className="grid grid-cols-1 gap-y-2 mt-4">
                  {categories.map((category, index) => (
                    <div key={index} className="flex items-center text-sm">
                      <span
                        className="inline-block w-4 h-4 rounded mr-1 font-semibold"
                        style={{ backgroundColor: category.color }}
                      ></span>
                      {category.name}: {category.value}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Right Panel */}
            <UpcomingInterviews
              upcoming_interviews={Candidate_Info.upcoming_interviews}
            />
          </div>

          <div className="bg-gray-50 px-6 py-4">
            <div className="flex justify-between">
              <h2 className="menu-heading pl-0 lg:mb-4">Recommendations</h2>
              <div
                className="flex items-center text-blue-500 text-lg cursor-pointer hover:underline hover:text-blue-700"
                onClick={handleNavigate}
              >
                See All <KeyboardArrowRightIcon />
              </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-4">
              {Candidate_Info.recommendations.slice(0, 2).map((item) => (
                <div
                  className={`card1 mb-3 ${
                    item.applied_status === true ? "" : ""
                  }`}
                  key={item.id}
                  onMouseLeave={() => setIsHovered(null)}
                >
                  {isHovered === item.id ? (
                    <div className=" card-data max-h-[180px] min-h-[180px] p-2 w-full">
                      <h3 className="flex items-center gap-2">
                        <img src="images/icons/star1.svg" alt="match" />
                        <span className="text-sm font-semibold text-black">
                          Why This Job Is A Match
                        </span>
                      </h3>

                      {/* <p className="text-gray-600 mt-1">
                        CrowdStrike is a cybersecurity technology firm that
                        provides...
                      </p> */}
                      <div className="flex justify-around mt-4 gap-8">
                        {matchData.map((item, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center"
                          >
                            <div className="relative">
                              <CircularProgress
                                variant="determinate"
                                value={item.value}
                                size={60}
                                thickness={4}
                                className="text-green-400"
                              />
                              <span className="absolute inset-0 flex items-center justify-center text-black font-semibold">
                                {parseInt(item.value)}%
                              </span>
                            </div>
                            <p className="text-sm text-black font-semibold mt-2">
                              {item.label}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="card-data  cursor-pointer min-h-[180px] p-2"
                      onClick={toggleDrawer(true, item.id)}
                    >
                      <div className="flex items-center gap-x-2 mt-3">
                        {/* Display company logo if available */}
                        <div className="section-1">
                          {companyLogos[item?.employer_id] ? (
                            <img
                              src={companyLogos[item?.employer_id]}
                              alt="Company logo"
                              className="company-logo"
                            />
                          ) : (
                            <img
                              src="images/default.png"
                              alt="Default logo"
                              className="company-logo"
                            />
                          )}
                        </div>
                        <div className="  section-2">
                          <h4 className="text-lg font-semibold text-gray-900">
                            {item.company_name}
                          </h4>
                          {/* <p className="text-gray-600"><span className="font-bold text-gray-800">{item.company_name}</span></p> */}
                          <p className="text-gray-600">
                            <span className="font-bold text-gray-800">
                              {title(item.title)}
                            </span>
                            {item.job_location ? ` • ${item.job_location}` : ""}
                            <p>
                              {" "}
                              ₹
                              {formateSalary(
                                item.expected_salary_min,
                                item.salary_type
                              )}{" "}
                              - ₹
                              {formateSalary(
                                item.expected_salary_max,
                                item.salary_type
                              )}{" "}
                              LPA
                            </p>
                          </p>
                        </div>
                        {/* <div className="section-3">
                          <button
                            className="drawer-open-arrow bg-[#E7E7E7] rounded-full"
                            onClick={toggleDrawer(true, item.id)}
                          >
                            {" "}
                          
                            <img
                              src="images/icons/send-arrow.svg"
                              className="brand-icon"
                              alt="view details"
                            />
                          </button>
                        </div> */}
                      </div>
                      <div className="my-3 type-btns flex items-center gap-2">
                        <div>
                          <img
                            src="images/icons/location.svg"
                            alt="location"
                            className="mr-1"
                          />
                          {/* seperate with commas */}
                          {item.job_mode.split(",").join(", ")}
                        </div>
                        <div>
                          <img
                            src="images/icons/cap.svg"
                            alt="experience"
                            className="mr-1"
                          />
                          {item.experience}
                        </div>
                        <div>
                          <img
                            src="images/icons/job1.svg"
                            alt="job type"
                            className="mr-1"
                          />
                          {item.job_type}
                        </div>
                      </div>
                      <div className="flex justify-between type-btns">
                        <span>
                          <img
                            src="images/icons/history.svg"
                            className="md:mr-1 lg:mr-2 inline-block"
                            alt="posted date"
                          />
                          {item.posted_date}
                        </span>

                        <span>Np : {item.notice_period}</span>
                      </div>
                    </div>
                  )}
                  <div className="foot-content flex items-center px-6 py-3 rounded-lg shadow-md">
                    {item.is_early_applicant ? (
                      <span className="bg-gray-100 px-3 py-1 rounded-2xl flex items-center text-sm">
                        <img
                          src="images/icons/early.svg"
                          className="mr-2"
                          alt="early applicant"
                        />
                        Be an early applicant
                      </span>
                    ) : (
                      <span className="bg-gray-100 px-3 py-1 rounded-2xl flex items-center text-sm">
                        <img
                          src="images/icons/man.svg"
                          className="mr-2"
                          alt="applicants"
                        />
                        {item.applicants_count} applicants
                      </span>
                    )}
                    {!item.is_accepting_applicants && (
                      <h2 className="text-xs text-gray-400 ml-4">
                        This job is no longer accepting applications.
                      </h2>
                    )}
                    <div className="ml-auto flex items-center space-x-4">
                      {/* Share Button */}
                      <div>
                        <Tooltip title="Share Job">
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectedJob(item.share_url);
                            }}
                            sx={{
                              color:
                                "linear-gradient(150deg, rgba(168, 36, 254, 1) 0%, rgba(26, 217, 223, 1) 100%)", // Ensure the icon remains visible
                            }}
                          >
                            <ShareIcon />
                          </IconButton>
                        </Tooltip>

                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          sx={{
                            // remove default MUI styles
                            "& .MuiMenu-paper": {
                              boxShadow: "0 0 0 0",
                              borderRadius: "8px",
                              border: "1px solid #e0e0e0",
                            },
                          }}
                        >
                          <MenuItem onClick={() => handleShare("linkedin")}>
                            <LinkedInIcon className="mr-2 text-blue-600" />
                            Share on LinkedIn
                          </MenuItem>
                          {/* <MenuItem onClick={() => handleShare("facebook")}>
                              <FacebookIcon className="mr-2 text-blue-700" />
                              Share on Facebook
                            </MenuItem>
                            <MenuItem onClick={() => handleShare("twitter")}>
                              <TwitterIcon className="mr-2 text-blue-400" />
                              Share on Twitter
                            </MenuItem> */}
                          <MenuItem onClick={() => handleShare("copy")}>
                            <ContentCopyIcon className="mr-2 text-gray-600" />
                            Copy link
                          </MenuItem>
                        </Menu>
                      </div>
                      {/* Save Job Button */}
                      <Tooltip
                        title={item.is_saved ? "Unsave Job" : "Save Job"}
                      >
                        {loadingJobId === item.id ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <button type="button" className="flex items-center ">
                            {item.is_saved ? (
                              <img
                                src="images/icons/save2.svg"
                                alt="save"
                                onClick={() => unSaveJob(item.id)}
                              />
                            ) : (
                              <img
                                src="images/icons/save.svg"
                                alt="save"
                                onClick={() => saveJob(item.id)}
                              />
                            )}
                          </button>
                        )}
                      </Tooltip>
                      {/* Apply Button */}
                      <Tooltip title={item.applied_date} arrow>
                        <button
                          type="button"
                          className={`text-white text-sm w-32 ${
                            item.job_status === "On Hold" ||
                            item.job_status === "Closed" ||
                            !item.is_accepting_applicants ||
                            item.applied_status === true
                              ? "bg-gray-400 px-8 p-[6px] rounded-[16px]"
                              : "theme-btn"
                          }`}
                          onClick={() => {
                            if (item.use_external_apply) {
                              window.open(item.external_job_url, "_blank");
                            } else {
                              applyForJob(item.id);
                            }
                          }}
                          disabled={
                            item.job_status === "On Hold" ||
                            item.job_status === "Closed" ||
                            !item.is_accepting_applicants ||
                            item.applied_status === true
                          }
                        >
                          {loadingJobIdApply === item.id ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : item.applied_status === true ? (
                            "Applied"
                          ) : (
                            "Apply Now"
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                  {/* matching job requirements  */}
                  {isHovered !== item.id && (
                    <>
                      {parseInt(item.rank) === 0 && (
                        <button
                          className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                          style={{ background: "none" }}
                          // onMouseEnter={() => {
                          //   setIsHovered(item.id);
                          //   setMatchedData(item?.component_scores);
                          // }}
                        >
                          {/* MUI Circular Progress */}
                          <div className="relative w-24 h-24 flex items-center justify-center">
                            <Tooltip
                              title="Complete your profile for better recommendations!"
                              arrow
                            >
                              <div className="relative flex items-center justify-center">
                                <CircularProgress
                                  variant="determinate"
                                  value={parseInt(item.rank)}
                                  size={100} // Increased size
                                  thickness={3} // Thicker border
                                  sx={{
                                    color: "rgb(56 189 248)",
                                    position: "relative",
                                  }}
                                />
                                {/* Text Inside the Circle */}
                                <div className="absolute flex flex-col items-center justify-center">
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontSize: "18px", // Bigger percentage text
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {parseInt(item.rank)}%
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      fontSize: "14px", // Bigger "Fair Match" text
                                      fontWeight: "bold",
                                      color: "gray",
                                    }}
                                  >
                                    Fair Match
                                  </Typography>
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                        </button>
                      )}

                      {parseInt(item.rank) < 60 && item.rank > 0 && (
                        <button
                          className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                          style={{ background: "none" }}
                          onMouseEnter={() => {
                            setIsHovered(item.id);
                            setMatchedData(item?.component_scores);
                          }}
                        >
                          {/* MUI Circular Progress */}
                          <div className="relative w-24 h-24 flex items-center justify-center">
                            <Tooltip
                              title={`${parseInt(item.rank)}% Match`}
                              arrow
                            >
                              <div className="relative flex items-center justify-center">
                                <CircularProgress
                                  variant="determinate"
                                  value={parseInt(item.rank)}
                                  size={100} // Increased size
                                  thickness={3} // Thicker border
                                  sx={{
                                    color: "rgb(56 189 248)",
                                    position: "relative",
                                  }}
                                />
                                {/* Text Inside the Circle */}
                                <div className="absolute flex flex-col items-center justify-center">
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontSize: "18px", // Bigger percentage text
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {parseInt(item.rank)}%
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      fontSize: "14px", // Bigger "Fair Match" text
                                      fontWeight: "bold",
                                      color: "gray",
                                    }}
                                  >
                                    Fair Match
                                  </Typography>
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                        </button>
                      )}

                      {parseInt(item.rank) >= 60 &&
                        parseInt(item.rank) < 70 && (
                          <button
                            className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                            style={{ background: "none" }}
                            onMouseEnter={() => {
                              setIsHovered(item.id);
                              setMatchedData(item?.component_scores);
                            }}
                          >
                            {/* MUI Circular Progress */}
                            <div className="relative w-24 h-24 flex items-center justify-center">
                              <Tooltip
                                title={`${parseInt(item.rank)}% Match`}
                                arrow
                              >
                                <div className="relative flex items-center justify-center">
                                  <CircularProgress
                                    variant="determinate"
                                    value={parseInt(item.rank)}
                                    size={100} // Increased size
                                    thickness={3} // Thicker border
                                    sx={{
                                      color: "rgb(56 189 248)",
                                      position: "relative",
                                    }}
                                  />
                                  {/* Text Inside the Circle */}
                                  <div className="absolute flex flex-col items-center justify-center">
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px", // Bigger percentage text
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {parseInt(item.rank)}%
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        fontSize: "14px", // Bigger "Fair Match" text
                                        fontWeight: "bold",
                                        color: "gray",
                                      }}
                                    >
                                      Good Match
                                    </Typography>
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </button>
                        )}

                      {parseInt(item.rank) >= 70 &&
                        parseInt(item.rank) < 80 && (
                          <button
                            className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                            style={{ background: "none" }}
                            onMouseEnter={() => {
                              setIsHovered(item.id);
                              setMatchedData(item?.component_scores);
                            }}
                          >
                            {/* MUI Circular Progress */}
                            <div className="relative w-24 h-24 flex items-center justify-center">
                              <Tooltip
                                title={`${parseInt(item.rank)}% Match`}
                                arrow
                              >
                                <div className="relative flex items-center justify-center">
                                  <CircularProgress
                                    variant="determinate"
                                    value={parseInt(item.rank)}
                                    size={100} // Increased size
                                    thickness={3} // Thicker border
                                    sx={{
                                      color: "rgb(56 189 248)",
                                      position: "relative",
                                    }}
                                  />
                                  {/* Text Inside the Circle */}
                                  <div className="absolute flex flex-col items-center justify-center">
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px", // Bigger percentage text
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {parseInt(item.rank)}%
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        fontSize: "14px", // Bigger "Fair Match" text
                                        fontWeight: "bold",
                                        color: "gray",
                                      }}
                                    >
                                      Strong Match
                                    </Typography>
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </button>
                        )}

                      {parseInt(item.rank) >= 80 &&
                        parseInt(item.rank) < 90 && (
                          <button
                            className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                            style={{ background: "none" }}
                            onMouseEnter={() => {
                              setIsHovered(item.id);
                              setMatchedData(item?.component_scores);
                            }}
                          >
                            {/* MUI Circular Progress */}
                            <div className="relative w-24 h-24 flex items-center justify-center">
                              <Tooltip
                                title={`${parseInt(item.rank)}% Match`}
                                arrow
                              >
                                <div className="relative flex items-center justify-center">
                                  <CircularProgress
                                    variant="determinate"
                                    value={parseInt(item.rank)}
                                    size={100} // Increased size
                                    thickness={3} // Thicker border
                                    sx={{
                                      color: "rgb(56 189 248)",
                                      position: "relative",
                                    }}
                                  />
                                  {/* Text Inside the Circle */}
                                  <div className="absolute flex flex-col items-center justify-center">
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px", // Bigger percentage text
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {parseInt(item.rank)}%
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        fontSize: "14px", // Bigger "Fair Match" text
                                        fontWeight: "bold",
                                        color: "gray",
                                      }}
                                    >
                                      Best Match
                                    </Typography>
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </button>
                        )}

                      {parseInt(item.rank) >= 90 && (
                        <button
                          className="schedule-btn flex flex-col px-4 items-center gap-2 mt-4"
                          style={{ background: "none" }}
                          onMouseEnter={() => {
                            setIsHovered(item.id);
                            setMatchedData(item?.component_scores);
                          }}
                        >
                          {/* MUI Circular Progress */}
                          <div className="relative w-24 h-24 flex items-center justify-center">
                            <Tooltip
                              title={`${parseInt(item.rank)}% Match`}
                              arrow
                            >
                              <div className="relative flex items-center justify-center">
                                <CircularProgress
                                  variant="determinate"
                                  value={parseInt(item.rank)}
                                  size={100} // Increased size
                                  thickness={3} // Thicker border
                                  sx={{
                                    color: "rgb(56 189 248)",
                                    position: "relative",
                                  }}
                                />
                                {/* Text Inside the Circle */}
                                <div className="absolute flex flex-col items-center justify-center">
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontSize: "18px", // Bigger percentage text
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {parseInt(item.rank)}%
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      fontSize: "14px", // Bigger "Fair Match" text
                                      fontWeight: "bold",
                                      color: "gray",
                                    }}
                                  >
                                    Elite Match
                                  </Typography>
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                        </button>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Box>
  );
}
