import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Badge, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotificationsOutlined, CheckCircleOutline } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { toggleDrawer, LOGOUT } from "../../../redux copy/actionTypes";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import config from "../../../config/config";
import { Toaster } from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";
import axiosClient from "../../../config/axios.Config";
import { getCompanyInfo } from "../../../utils/employer.api/employer.api.services";
import { Menu, MenuItem, Avatar, Button } from "@mui/material";
const drawerWidth = 250;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};
const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 0,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppBar() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.auth.drawerOpen);
  const loginData = useSelector((state) => state.auth.Auth);
  const [companyName, setCompanyName] = useState("");
  const [status, setStatus] = useState(localStorage.getItem("status"));
  const [showSuccess, setShowSuccess] = useState(false);
  const [reason, setReason] = useState();
  const [isCompaniInfo, setIsCompanyInfo] = useState(false);
  const [avatar, setAvatar] = useState(
    localStorage.getItem("Name") || "Employer"
  );

  const navigate = useNavigate();

  const BASE_URL = config.apiUrl;

  // fetch company info

  useEffect(() => {
    if (
      !localStorage.getItem("companyName") ||
      localStorage.getItem("status") == "rejected"
    ) {
      const fetchCompanyInfo = () => {
        axiosClient
          .get(`${BASE_URL}employer/company-details`, {
            headers: {
              Authorization: `Bearer ${loginData.access_token}`, // Ensure token is valid
            },
          })
          .then((res) => {
            if (res.data?.company_name) {
              setCompanyName(res.data.company_name);
              setReason(res.data?.rejection_reason);
            } else {
              console.warn("Company name not found in response.");
            }
          })
          .catch((err) => {
            console.error(
              "Error fetching company info:",
              err.response?.data || err.message
            );
          });
      };
      fetchCompanyInfo();
    } else {
      setCompanyName(localStorage.getItem("companyName"));
    }
    const checkInfo = async () => {
      try {
        const response = await getCompanyInfo(loginData.token);
        console.log("Company Info Response:", response.data);
        if (!response.data.id) {
          console.log("Company Info not found in response.");
          setIsCompanyInfo(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch company info
    checkInfo();

    if (status === "approved") {
      setShowSuccess(true);
    }
  }, [loginData.token]);

  // update auth token with new token in redux
  const updateToken = async (newToken) => {};

  const handleLogout = async () => {
    await indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => {
        indexedDB.deleteDatabase(db.name);
        console.log(`Deleted database: ${db.name}`);
      });
    });
    dispatch({ type: LOGOUT });
    await localStorage.removeItem("status");
    await localStorage.removeItem("companyName");
    await localStorage.removeItem("Name");
    await localStorage.removeItem("token");
    await localStorage.removeItem("refresh_token");

    navigate("/employer/login", { replace: true });
  };

  const handleDrawerOpen = () => {
    dispatch(toggleDrawer(true));
  };
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  // Fetch Interviewers
  const fetchInterviewers = async () => {
    try {
      const response = await axiosClient.get(
        `${BASE_URL}employer/interviewers-list?page=1&page_size=10`,
        {
          headers: { Authorization: `Bearer ${loginData.token}` },
        }
      );

      return response.data.total_count > 0;
    } catch (error) {
      toast.error("Failed to fetch interviewers");
      console.error(error);
    }
  };

  const handleJobPost = () => {
    if (status === "approved") {
      navigate("/employer/post-job");
    } else {
      toast.dismiss();
      toast.error(
        "Your company is under verification. You can post job once it is approved."
      );
    }
  };

  const [openDialog, setOpenDialog] = useState(false);
  const menuRef = useRef(null);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (!name || name.trim() === "") {
      return { sx: { bgcolor: "#ccc" }, children: "" }; // Default color & empty avatar
    }

    const nameParts = name.trim().split(" ");
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0]}${nameParts[1][0]}`
        : nameParts[0][0];

    return {
      sx: { bgcolor: stringToColor(name) },
      children: initials.toUpperCase(),
    };
  }

  // Function to open menu
  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  // Function to close menu
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Close menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleCloseDialog();
      }
    }

    if (openDialog) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDialog]);

  const handleOpen = () => setOpenModal(true);
  // const handleClose = () => setOpenModal(false);


  // notification
  const token = loginData.access_token;
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openNot = Boolean(anchorEl);
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();

  // const unreadCount = notifications.filter((n) => !n.is_read).length;

  const handleOpenNot = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchNotifications = async () => {
    try {
      const response = await axiosClient.get(
        `${BASE_URL}employer/notifications?page=1&page_size=20`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const newData = response.data?.notifications || [];
      setNotifications(newData);
      setUnreadCount(newData.filter((n) => !n.is_read).length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

//   const handleWebSocketMessage = (message) => {
//     if (message === "ping" || message === "pong") return; // Ignore these messages
//       console.log("Message received on the jsx:", message);
//     try {
//         const parsedMessage = JSON.parse(message);
//         if (parsedMessage.type === "notification") {
//             const msg = JSON.parse(parsedMessage.data);
//             setNotifications([msg.messages[0], ...notifications]);
//             setUnreadCount(unreadCount + 1);
//             console.log("Notification received:", msg);
//         }
//     } catch (error) {
//         console.warn("Non-JSON message received, ignoring:", message);
//     }
// };


// useWebSocket(`ws://localhost:8000/ws/notifications/employer/67ade9336ff253083e0c9164?token=${token}`, handleWebSocketMessage);




  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleCloseNot = () => {
    setAnchorEl(null);
  };

  const handleNotificationRead = (id) => {
    // backend call to mark notification as read

    // string list of notification ids
    const notificationIds = Array.isArray(id) ? id : [id];

    axiosClient
      .post(`${BASE_URL}employer/mark-read`, notificationIds, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Notification marked as read:", response.data);
        setUnreadCount(unreadCount - notificationIds.length);
        // setUnreadCount(response.data.updated_count);
      })
      .catch((error) => {
        console.error("Error marking notification as read:", error);
      });
  };

  const markAsRead = (id) => {
    setNotifications(
      notifications.map((n) => (n.id === id ? { ...n, is_read: true } : n))
    );
    handleNotificationRead(id);
  };

  const markAllAsRead = () => {
    setNotifications(notifications.map((n) => ({ ...n, is_read: true })));
    // send list of all notification ids
    handleNotificationRead(notifications.map((n) => n.id));
  };

  const handleSeeMore = () => {
    navigate("/employer/notifications");
  };




  return (
    <AppBarStyled position="fixed" open={open} className="appbar">
      <Toaster />
      <Toolbar>
        <IconButton
          color="#000"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {/* <Link to={`${config.employerUrl}dashboard`}> */}
        <Typography variant="h6" noWrap component="div">
          <h2 className="logo text-2xl font-semibold font-family">
            <span className="color-text font-family">{companyName}</span>
          </h2>
        </Typography>
        {/* </Link> */}
        {/* Conditional Status Message */}
        {status === "rejected" && (
          <div className="flex items-center justify-center w-[70%]  ml-auto">
            <div className="w-[75%] text-center bg-red-100 mb-0">
              <Typography variant="subtitle1" color="error">
                {/* <p className="text-red-500"> */}
                {reason?.trim() ||
                  "Your company verification has been rejected. Please re-upload your document."}
                {/* </p> */}
              </Typography>
            </div>
          </div>
        )}
        {/* {showSuccess && (
        <div
          className="bg-green-300 text-green-800 p-2 rounded-lg shadow-md flex justify-between items-center transition-transform transform w-[75%]"
          style={{
            opacity: 1,
            transform: "translateY(0)",
            transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
          }}
        >
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4m5 10a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>
            Congratulations! Your company has been successfully approved. You can now post jobs and connect with top talent.
            </span>
          </div>

          <button
            className="ml-4 bg-transparent hover:bg-green-700 rounded-lg p-1"
            onClick={() => setShowSuccess(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      )} */}

        <div className="ms-auto ">
          {["/employer/post-job", "/employer/candidates"].includes(
            window.location.pathname
          ) ? null : status === "rejected" ? (
            <Tooltip
              title="Your company verification has been rejected. You can't post a job."
              arrow
            >
              <button
                type="button"
                className="theme-btn mr-3 cursor-not-allowed opacity-50"
                onClick={handleJobPost}
                disabled
              >
                + Post Job
              </button>
            </Tooltip>
          ) : (
            window.location.pathname === "/employer/job-listing" && (
              <div className="relative group inline-block min-w-full">
                <button
                  type="button"
                  className="theme-btn mr-3 hover:bg-blue-600 transition-all duration-300"
                  onClick={handleJobPost}
                  disabled={isCompaniInfo}
                >
                  + Post Job
                </button>
                {isCompaniInfo && (
                  <span className="absolute min-w-full top-12 left-1/2 -translate-x-1/2 text-center  hidden group-hover:block bg-gray-500 text-white text-xs rounded py-1 px-2">
                    Complete company Info
                  </span>
                )}
              </div>
            )
          )}
        </div>

        {/* notification */}
        <div className="relative">
        {location.pathname === "/employer/notifications" ? (
          <IconButton
            onClick={handleOpenNot}
            color="inherit"
            disabled
            // className="bg-gradient-to-r from-[#C1EBF3] to-[#E4D0F4] text-blue-600"
          >
            {/* outlined */}
            <NotificationsIcon fontSize="large" className="text-gray-600" />
          </IconButton>
        ) : (
          // filled with bg-gradient-to-r from-[#C1EBF3] to-[#E4D0F4] and text-blue-600 and disabled
          <IconButton onClick={handleOpenNot} color="inherit">
            <Badge badgeContent={unreadCount} color="error">
            {openNot ? (
              <NotificationsIcon fontSize="large" className="text-gray-600" />
              ) : (
                <NotificationsOutlined fontSize="large" className="text-gray-600" />
                )}

              
            </Badge>
          </IconButton>
        )}

        <Menu
          anchorEl={anchorEl}
          open={openNot}
          onClose={handleCloseNot}
          PaperProps={{
            className: "w-80 max-h-[400px] overflow-hidden",
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* Header */}
          <div className="flex items-center justify-between p-3 border-b border-gray-200 bg-gray-50">
            <div className="flex items-center gap-2">
              {/* <NotificationsOutlined className="text-gray-600" /> */}
              <span className="font-medium text-gray-900">Notifications</span>
            </div>
            {unreadCount > 0 && (
              <button
                onClick={markAllAsRead}
                className="text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
              >
                <CheckCircleOutline className="w-4 h-4" />
                <span>Mark all read</span>
              </button>
            )}
          </div>

          {/* Notifications List */}
          <div className="overflow-y-auto max-h-[300px]">
            {notifications.length > 0 ? (
              notifications.slice(0, 4).map((notification) => (
                <MenuItem
                  key={notification.id}
                  onClick={() => markAsRead(notification.id)}
                  className={`
                group px-3 py-2.5 hover:bg-gray-50
                ${notification.is_read ? "bg-white" : "bg-blue-50"}
              `}
                >
                  <div className="flex items-start justify-between w-full gap-3">
                    <p className="text-sm text-gray-900 flex-1 text-wrap break-words">
                      {notification.messages[0]}
                    </p>
                    <div className="flex flex-col items-end gap-2">
                      <span className="text-xs text-gray-500 whitespace-nowrap">
                        {new Date(notification.timestamp).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </span>
                      {!notification.is_read && (
                        <div className="w-2 h-2 rounded-full bg-blue-600" />
                      )}
                    </div>
                  </div>
                </MenuItem>
              ))
            ) : (
              <div className="py-8">
                <p className="text-sm text-gray-500 text-center">
                  No notifications
                </p>
              </div>
            )}
          </div>

          {/* Footer */}
          {notifications.length > 4 && (
            <MenuItem
              onClick={handleSeeMore}
              className="justify-center text-sm text-blue-600 hover:text-blue-700 hover:bg-gray-50 border-t border-gray-100"
            >
              See all notifications
            </MenuItem>
          )}
        </Menu>
      </div>



        {/* <PostJobModal openForm={openForm} handleCloseFrom={handleCloseFrom} setOpenForm={setOpenForm} /> */}
      </Toolbar>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
          >
            Are you sure want to logout ?
          </Typography>

          <div className="justify-center items-center mt-5 flex">
            <button
              onClick={handleClose}
              color="primary"
              sx={{ mt: 2 }}
              className="py-2.5 px-5 me-2 mb-2  text-sm font-medium text-black rounded-lg bg-gray-300"
            >
              No
            </button>
            <button
              onClick={handleLogout}
              sx={{ mt: 2 }}
              className=" py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn   rounded-lg"
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </AppBarStyled>
  );
}
