import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DrawerHeader from "./DrawerHeader";
import { Link } from "react-router-dom";
import config from "../../../config/config";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../../redux copy/actionTypes";
import Tooltip from "@mui/material/Tooltip";
import FeedBack from "./feedBack";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Chatbot from "../chatbot";
import { Menu, MenuItem, Avatar, Button, Popover } from "@mui/material";
import { useNavigate } from "react-router-dom";
const drawerWidth = 250;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerStyled = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  height: "100%",
  zIndex: theme.zIndex.drawer - 1,

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const menuList = [
  {
    name: "Dashboard",
    icon: "images/icons/home.svg",
    link: "dashboard",
  },
  {
    name: "Jobs",
    icon: "images/icons/job.svg",
    link: "job-listing",
  },
  {
    name: "All Applicants",
    icon: "images/icons/applicants.svg",
    link: "all-applicants",
  },
  {
    name: "Interview Calender",
    icon: "images/icons/calender.svg",
    link: "mySchedule",
  },
  {
    name: "Reschedule Request",
    icon: "images/icons/request.svg",
    link: "requests",
  },
  {
    name: "All Candidates",
    icon: "images/icons/candidate.svg",
    link: "candidates",
  },
  // {
  //   name:"Settings",
  //   icon:'images/icons/setting.svg',
  //   link:"setting"
  // },
];
const menuList2 = [
  // {
  //   name:"Settings",
  //   icon:'images/icons/setting.svg',
  //   link:"setting"
  // },
  // {
  //   name:"Company",
  //   icon:"images/icons/company.svg",
  //   link:"company"
  // },
  // {
  //   name:"Configurable Hiring",
  //   icon:"images/icons/config.svg",
  //   link:"configuration"
  // },
  // {
  //   name:"Help centre",
  //   icon:"images/icons/help.svg",
  //   link:"help"
  // }
];
export default function Drawer() {
  const open = useSelector((state) => state.auth.drawerOpen);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isStatus, setIsStatus] = useState(
    localStorage.getItem("status") !== "approved" ? true : false
  );
  const { pathname } = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [avatar, setAvatar] = useState(
    localStorage.getItem("Name") || "Employer"
  );

  const navigate = useNavigate();
  const menuRef = useRef(null);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (!name || name.trim() === "") {
      return { sx: { bgcolor: "#ccc" }, children: "" }; // Default color & empty avatar
    }

    const nameParts = name.trim().split(" ");
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0]}${nameParts[1][0]}`
        : nameParts[0][0];

    return {
      sx: { bgcolor: stringToColor(name) },
      children: initials.toUpperCase(),
    };
  }

  // Function to open menu
  const handleOpenDialog = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenDialog(!openDialog);
  };

  // Function to close menu
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Close menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleCloseDialog();
      }
    }

    if (openDialog) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDialog]);

  // const handleOpen = () => setOpenModal(true);
  const handleOpenFeedBack = () => {
    console.log("Opening Feedback Modal");
    setIsFeedbackOpen(true);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleLogout = async () => {
    await indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => {
        indexedDB.deleteDatabase(db.name);
        console.log(`Deleted database: ${db.name}`);
      });
    });
    dispatch({ type: LOGOUT });
    await localStorage.removeItem("status");
    await localStorage.removeItem("companyName");
    await localStorage.removeItem("Name");
    await localStorage.removeItem("token");
    await localStorage.removeItem("refresh_token");

    navigate("/employer/login", { replace: true });
  };

  return (
    <DrawerStyled variant="permanent" open={open}>
      <Toaster />
      <DrawerHeader />
      <List>
        {menuList.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <div className="flex  justify-center items-center">
              <div
                className={`w-[4px] h-[32px] rounded-[4px]   ${
                  pathname === config.employerUrl + item.link
                    ? " bg-[#516AF4]"
                    : " bg-transparent "
                }`}
              ></div>
              <ListItemButton
                disabled={isStatus}
                component={Link}
                to={`${config.employerUrl}${item.link}`}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  borderRadius: 2,
                  mx: 1,
                  ...(isStatus && {
                    color: "red", // Apply red color when disabled
                    cursor: "not-allowed", // Change cursor to not-allowed
                  }),
                }}
                className={`${
                  pathname === config.employerUrl + item.link
                    ? "bg-active-button-gradient text-[#516AF4]"
                    : "text-[#5E5E5E]"
                }`}
              >
                <Tooltip title={item.name} placement="right">
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src={item.icon} alt="logo" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  className="menu-name"
                  sx={{ opacity: open ? 1 : 0 }}
                >
                  {item.name}
                </ListItemText>
              </ListItemButton>
            </div>
          </ListItem>
        ))}
        <ListItem disablePadding sx={{ display: "block" }}>
          <div className="flex  justify-center items-center">
            <div
              className={`w-[4px] h-[32px] rounded-[4px]   ${
                isFeedbackOpen ? " bg-[#516AF4]" : " bg-transparent "
              }`}
            ></div>
            <ListItemButton
              component="a"
              onClick={handleOpenFeedBack}
              sx={{
                minHeight: 48,
                justifyContent: isFeedbackOpen ? "initial" : "center",
                px: 2.5,
                mx: 1,
                borderRadius: 2,
              }}
            >
              <Tooltip title="Feedback" placement="right">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="images/icons/feedback.svg" alt="Feedback Icon" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              >
                Feedback
              </ListItemText>
            </ListItemButton>
          </div>
        </ListItem>
      </List>
      <List style={{ top: "10%" }}>
        {menuList2.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <div className="flex  justify-center items-center">
              <div
                className={`w-[4px] h-[32px] rounded-[4px]   ${
                  pathname === config.employerUrl + item.link
                    ? " bg-[#516AF4]"
                    : " bg-transparent "
                }`}
              ></div>
              <ListItemButton
                // disabled={isStatus}
                component={Link}
                to={`${config.employerUrl}${item.link}`}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  borderRadius: 2,
                  mx: 1,
                  ...(isStatus && {
                    color: "red", // Apply red color when disabled
                    cursor: "not-allowed", // Change cursor to not-allowed
                  }),
                }}
                className={`${
                  pathname === config.employerUrl + item.link
                    ? "bg-active-button-gradient text-[#516AF4]"
                    : "text-[#5E5E5E]"
                }`}
              >
                <Tooltip title={item.name} placement="right">
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src={item.icon} alt="logo" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  className="menu-name"
                  sx={{ opacity: open ? 1 : 0 }}
                >
                  {item.name}
                </ListItemText>
              </ListItemButton>
            </div>
          </ListItem>
        ))}
        {/* <ListItem disablePadding sx={{ display: "block" }}>
        <div className='flex  justify-center items-center'>
              <div
                className={`w-[4px] h-[32px] rounded-[4px]   ${
                  isFeedbackOpen
                    ? " bg-[#516AF4]"
                    : " bg-transparent "
                }`}
              ></div>
          <ListItemButton
            component="a"
            onClick={handleOpenFeedBack}
            sx={{
              minHeight: 48,
              justifyContent: isFeedbackOpen ? "initial" : "center",
              px: 2.5,
              mx: 1,
              borderRadius: 2,
            }}
          >
            <Tooltip title="Feedback" placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: "center",
                }}
              >
                <img src="images/icons/feedback.svg" alt="Feedback Icon" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className="menu-name" sx={{ opacity: open ? 1 : 0  }}>
              Feedback
            </ListItemText>
          </ListItemButton>
          </div>
        </ListItem> */}
      </List>
      <FeedBack open={isFeedbackOpen} setOpen={setIsFeedbackOpen} />

      {/* avatar */}
      <div
        className={` absolute bottom-12 ${
          open ? "left-[14px]" : "left-[4px]"
        }`}
        onClick={handleOpenDialog}
      >
        {/* Button to open menu */}
        <button
          id="basic-button"
          aria-haspopup="true"
          aria-expanded={openDialog ? "true" : undefined}
          onClick={handleOpenDialog}
          className="m-2 flex justify-center items-center gap-2"
        >
          <Avatar className="" {...stringAvatar(avatar)} />{open && avatar}
        </button>
        

        {/* Popover Menu (Opens Above) */}
        <Popover
          open={openDialog}
          anchorEl={anchorEl} // Correctly positioned
          onClose={handleCloseDialog}
          anchorOrigin={{
            vertical: "top", // Attach at the top of the avatar
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom", // Move the popover upwards
            horizontal: "center",
          }}
          PaperProps={{
            className: "shadow-lg bg-white rounded-lg p-2 z-[9999]", // High z-index
          }}
          sx={{
            mb: 1,
            "& .MuiPaper-root": {
              borderRadius: "12px",
            },
          }}
        >
          <div className="w-40">
            <MenuItem
              className="flex items-center text-sm"
              onClick={() => {
                console.log("Settings Clicked");
                navigate("/employer/setting");
                handleCloseDialog();
              }}
            >
              <img
                src="images/icons/setting.svg"
                className="mr-2 py-1"
                alt="Settings"
              />
              <span className="text-black">Settings</span>
            </MenuItem>
            <MenuItem
              className="flex items-center text-sm"
              onClick={handleOpen}
            >
              <img
                src="images/icons/logout.svg"
                className="mr-2"
                alt="Logout"
              />
              <span className="text-black">Logout</span>
            </MenuItem>
          </div>
        </Popover>
      </div>

      {/* <List style={{top:"20%"}}>
          <ListItem  disablePadding sx={{ display: 'block' }}>
          <div className='flex  justify-center items-center'>
              <div
                className={`w-[4px] h-[32px] rounded-[4px]   ${
                  pathname === config.employerUrl+"logout"
                    ? " bg-[#516AF4]"
                    : " bg-transparent "
                }`}
              ></div>
            <ListItemButton
             onClick={handleOpen}
            component={Link}
            //  to={`${config.employerUrl}${item.link}`}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                borderRadius: 2,
                mx:1,
              }}
            >
               <Tooltip title="Log-out" placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                }}
              >
               <img src="images/icons/logout.svg" alt="logo"/>
              </ListItemIcon>
              </Tooltip>
              <ListItemText  className='menu-name'  sx={{ opacity: open ? 1 : 0 }}>Log-out</ListItemText>
            </ListItemButton>
          </div>
          </ListItem>
      </List> */}
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
          >
            Are you sure want to logout ?
          </Typography>

          <div className="justify-center items-center mt-5 flex">
            <button
              onClick={handleClose}
              color="primary"
              sx={{ mt: 2 }}
              className="py-2.5 px-5 me-2 mb-2  text-sm font-medium text-black rounded-lg bg-gray-300"
            >
              No
            </button>
            <button
              onClick={handleLogout}
              sx={{ mt: 2 }}
              className=" py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn   rounded-lg"
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
      {pathname !== "/employer/history" && <Chatbot />}
    </DrawerStyled>
  );
}
