import React, { useState } from "react";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { REMOVE_PARSE_CV_DATA, LOGOUT } from "../../redux copy/actionTypes";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const loginData = useSelector((state) => state.auth.Auth);
  const dispatch = useDispatch();
  const handleLogout = () => {
    navigate(`${config.candidateUrl}login`);
    dispatch({ type: LOGOUT });
    dispatch({ type: REMOVE_PARSE_CV_DATA });
    handleClose();
  };
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const jobTab = [
    {
      name: "Recommended Jobs",
      // icon:"images/icons/recommendations.svg",
      link: "candidate/jobs/recomendation",
    },
    {
      name: "My Applications",
      icon: "images/icons/applications.svg",
      link: "candidate/jobs/my-applications",
    },
    {
      name: "Saved Jobs",
      icon: "images/icons/saved.svg",
      link: "candidate/jobs/saved-jobs",
    },
  ];

  return (
    <header className=" mb-3 mt-0 py-4 relative">
      <div className="container mx-auto">
        <div className="flex justify-between items-center relative">
          {/* Left Section */}
          <div className="flex gap-12">
            <div className="flex items-end">
              <Link to={`${config.baseUrl}`} className="text-3xl font-bold">
                <img src="/images/hirree.svg" alt="logo" className="h-8" />
              </Link>
            </div>
            <div className="flex justify-end items-center">
            {!loginData.access_token ? (
              <div className="flex flex-wrap gap-4">
                <a
                  href="/"
                  className="flex gap-2 items-center px-2 py-1 text-sm font-extrabold bg-active-button-gradient text-[#000000] rounded-lg text-[14px] hover:bg-gradient-to-r from-purple-200 to-sky-200 hover:text-[#516AF4] hover:rounded-lg"
                >
                  <span className="font-extrabold hover:text-black">
                    Home
                  </span>
                </a>
              </div>
            ) : (
              <div className="flex flex-wrap gap-4">
                {jobTab.map((tab, index) => {
                  const isActive = location.substring(1) === tab.link;
                  return (
                    <a
                      href={tab.link}
                      key={index}
                      className={`flex gap-2 items-center px-2 py-1 text-sm font-extrabold
                            ${
                              isActive
                                ? "bg-active-button-gradient text-[#516AF4] rounded-lg text-[14px]"
                                : "bg-active-button-gradient text-[#000000] rounded-lg text-[14px] "
                            } hover:bg-gradient-to-r from-purple-200 to-sky-200 hover:text-[#516AF4] hover:rounded-lg`}
                    >
                      <span className="font-extrabold">{tab.name}</span>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
          </div>

          {/* middle */}


          {/* Right Section */}
          <div className="flex justify-end items-center">
            {!loginData.access_token ? (
              <div className="authen flex space-x-4 z-10">
                <>
                  <Link to={`${config.candidateUrl}login`}>
                    <button type="button" className="theme-btn bordered-btn">
                      <span className="color-text">Login</span>
                    </button>
                  </Link>
                  <Link to={`${config.candidateUrl}signup`}>
                    <button type="button" className="theme-btn text-white">
                      Sign Up
                    </button>
                  </Link>
                </>
              </div>
            ) : (
              <button
                type="button"
                className="theme-btn text-white z-20"
                onClick={handleOpen}
              >
                Logout
              </button>
            )}

            {/* Balloons Image */}
            {/* <img 
        src="/images/christmas/ballons.png" 
        alt="balloons" 
        className="w-[200px] absolute top-0 right-0 z-0 -mt-4 -mr-10" 
      /> */}
          </div>
        </div>

        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
            >
              Are you sure want to logout ?
            </Typography>

            <div className="justify-center items-center mt-5 flex">
              <button
                onClick={handleClose}
                color="primary"
                sx={{ mt: 2 }}
                className="py-2.5 px-5 me-2 mb-2  text-sm font-medium text-black rounded-lg bg-gray-300"
              >
                No
              </button>
              <button
                onClick={handleLogout}
                sx={{ mt: 2 }}
                className=" py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn   rounded-lg"
              >
                Yes
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </header>
  );
};

export default Header;
