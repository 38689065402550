import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import axiosClient from "../../../config/axios.Config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  width:'auto'
};

const DeleteJobs = ({ openDeleteModal, handleCloseDelete, jobId }) => {
  const [feedback, setFeedback] = useState(""); // State to store feedback
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false); // Control visibility of feedback field

  // Handle delete confirmation and show feedback field
  const handleConfirmDelete = () => {

    // fetch total applicants
    axiosClient.get(`${config.apiUrl}employer/jobs/${jobId}/applicants`, {
      headers: {
        Authorization: `Bearer ${loginData.access_token}`,
      },
    }).then((res) => {
      // Log the total number of applicants
      console.log("Total Applicants:", res.data.total_applicants);
    
      if (res.data.total_applicants > 5) {
        setIsFeedbackVisible(true);
        
      } else {
        handleSubmitDelete();
      }
    }).catch((error) => {
      console.error("Error fetching applicants:", error);
      toast.dismiss()
      toast.error("An error occurred while fetching applicants.");
    });
    

  };

  // Handle the feedback change
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };
  const loginData = useSelector((state) => state.auth.Auth);

  const handleSubmitDelete = async () => {
    try {
      const response = await fetch(`${config.apiUrl}employer/jobs/${jobId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loginData.access_token}`, 
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ feedback: feedback }), 
      });

      if (response.ok) {
        toast.dismiss()
        toast.success("Job deleted successfully!");
        handleCloseDelete(); 
        window.location.reload()
        // handleJobDelete()
      } else {
        toast.dismiss()
        toast.error("Failed to delete the job");
        handleCloseDelete(false)
      }
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };
  const handleCloseModal = () => {
    setIsFeedbackVisible(false); // Reset feedback field visibility
    setFeedback(""); // Optionally reset the feedback text
    handleCloseDelete(); // Close the modal
  };
  return (
    <Modal
      open={openDeleteModal}
    >

      <Box sx={style}>
        {!isFeedbackVisible ? (
          <>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
            >
              Are you sure you want to delete this job ?
            </Typography>
            <div className="justify-center items-center mt-5 flex">
              <button
                onClick={handleCloseDelete}
                className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300"
              >
                No
              </button>
              <button
                onClick={handleConfirmDelete} // Show the feedback field
                className="py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn rounded-lg"
              >
                Yes
              </button>
            </div>
          </>
        ) : (
          <>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
            >
              Please provide a reason for deleting the job 
            </Typography>
            <textarea
              value={feedback}
              onChange={handleFeedbackChange}
              rows="4"
              placeholder="Enter reason..."
              className="w-full mt-3 p-2 border rounded-md"
            />
            <div className="justify-center items-center mt-5 flex">
              <button
                onClick={handleCloseModal}
                className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmitDelete} // Submit the delete request with feedback
                className="py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn rounded-lg"
              >
                Confirm Delete
              </button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default DeleteJobs;
